const initialState = {
  path: "/home",
  props: {},
  magicMouseState: false,
  focusedContent: null,
  menuIndex: 1,
};
function reducer(state = initialState, action) {
  switch (action.type) {
    case "NAVIGATE":
      return {
        ...state,
        path: action.path,
        props: action.props,
      };
    case "FOCUSED_CONTENT":
      return {
        ...state,
        focusedContent: action.focusedContent,
      };
    case "SET_MAGIC_MOUSE_STATE":
      return {
        ...state,
        magicMouseState: action.magicMouseState,
      };
    case "SET_MENU_INDEX":
      return {
        ...state,
        menuIndex: action.menuIndex,
      };

    default:
      return state;
  }
}
export default reducer;
