import gql from 'graphql-tag';

const searchContent = gql`
  query searchContent($term: String) {
    searchContentByTerm(term: $term) {
      cdn {
        domainName
        aliases
      }
      count
      data
    }
  }
`;

export default { searchContent };
