import * as R from 'ramda';
import { fork, put, call, takeEvery } from 'redux-saga/effects';

import favoritesActions, { constants } from './actions';

import queries from './queries';
import mutations from './mutations';
import { Failure, getCdnDomainName } from '../../../lib/utilities';

const favoritesSaga = ({ gqlClient }) => {
  function* fetchFavorites({ payload: { refresh, limit, skip } }) {
    try {
      const response = yield call(
        [gqlClient, gqlClient.rawRequest],
        queries.fetchFavorites,
        {
          limit,
          skip,
        },
      );

      if (response.errors || !response.data?.getFavorites) throw new Failure();

      const {
        count,
        list: favorites,
        isMoreContentAvailable,
      } = response.data.getFavorites;

      const favoriteContentsById = R.indexBy(R.prop('id'), favorites);

      yield put(
        favoritesActions.getFavoriteListSuccess({
          count,
          refresh,
          isMoreContentAvailable,
          favoriteContentsById,
          favorites,
        }),
      );
    } catch (e) {
      const errorMessage =
        (e instanceof Failure && e.message) || 'Something went wrong';
      yield put(
        favoritesActions.getFavoriteListFailure({ reason: errorMessage }),
      );
    }
  }

  function* addToFavorites({ payload: { id, content } }) {
    try {
      const response = yield call(
        [gqlClient, gqlClient.rawRequest],
        mutations.addToFavorites,
        {
          contentId: id,
        },
      );

      if (response.errors || !response.data?.addFavorites) throw new Failure();

      const { cdn, contentMeta } = response.data.addFavorites;
      const cdnDomainName = getCdnDomainName(cdn);
      yield put(
        favoritesActions.addToFavoritesListSuccess({
          id,
          content: {
            ...content,
            ...contentMeta,
            cdnDomainName,
          },
        }),
      );
    } catch (e) {
      const errorMessage =
        (e instanceof Failure && e.message) || 'Something went wrong';
      yield put(
        favoritesActions.addToFavoritesListFailure({ reason: errorMessage }),
      );
    }
  }

  function* removeFromFavorites({ payload: { id } }) {
    try {
      const response = yield call(
        [gqlClient, gqlClient.rawRequest],
        mutations.removeFromFavorites,
        {
          contentId: id,
        },
      );

      if (response.errors || !response.data?.removeFavorite)
        throw new Failure();

      yield put(
        favoritesActions.removeFromFavoritesListSuccess({
          id,
        }),
      );
    } catch (e) {
      const errorMessage =
        (e instanceof Failure && e.message) || 'Something went wrong';
      yield put(
        favoritesActions.removeFromFavoritesListFailure({
          reason: errorMessage,
        }),
      );
    }
  }

  function* checkContentIdInFavorites({ payload: { id, content } }) {
    try {
      const response = yield call(
        [gqlClient, gqlClient.rawRequest],
        queries.isPresentInFavorites,
        {
          contentId: id,
        },
      );
      if (response.errors || !response.data) throw new Failure();
      const { isPresentInFavorites } = response.data;
      yield put(
        favoritesActions.checkContentIdInFavoritesSuccess({
          id,
          isPresentInFavorites,
          content,
        }),
      );
    } catch (e) {
      const errorMessage =
        (e instanceof Failure && e.message) || 'Something went wrong';
      yield put(
        favoritesActions.checkContentIdInFavoritesFailure({
          reason: errorMessage,
        }),
      );
    }
  }

  function* fetchFavoritesWatcher() {
    yield takeEvery(constants.GET_FAVORITE_LIST_PENDING, fetchFavorites);
  }

  function* addToFavoritesWatcher() {
    yield takeEvery(constants.ADD_TO_FAVORITES_PENDING, addToFavorites);
  }

  function* removeFromFavoritesWatcher() {
    yield takeEvery(
      constants.REMOVE_FROM_FAVORITES_PENDING,
      removeFromFavorites,
    );
  }

  function* checkContentIdInFavoritesWatcher() {
    yield takeEvery(
      constants.CHECK_CONTENT_IN_FAVORITES_PENDING,
      checkContentIdInFavorites,
    );
  }

  function* watcher() {
    yield fork(fetchFavoritesWatcher);
    yield fork(addToFavoritesWatcher);
    yield fork(removeFromFavoritesWatcher);
    yield fork(checkContentIdInFavoritesWatcher);
  }

  return {
    watcher,
  };
};

export default favoritesSaga;
