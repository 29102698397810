/* eslint-disable */
import kind from "@enact/core/kind";
import Icon from "@enact/sandstone/Icon";
import ImageItem from "@enact/sandstone/ImageItem";
import Scroller from "@enact/sandstone/Scroller";
import React, { useEffect, useRef, useState } from "react";
import c from "./channelguide.css";
import ri from "@enact/ui/resolution";
import BodyText from "@enact/sandstone/BodyText";
import SmallVideoPlayer from "./SmallVideoPlayer";
import { useDispatch } from "react-redux";
import { navigate } from "../../store/modules/navigate/actions";
import Spotlight from "@enact/spotlight";
import { SpottableComponent } from "../../components/SpottableComponent";

const EPG = ({ data, setDetail, ...rest }) => {
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  // Time Slots division
  const [currentTime, setCurrentTime] = useState(
    new Date(new Date().toLocaleString("en-US", { timeZone: "IST" }))
  );
  const [playUrl, setPlayUrl] = useState(
    "https://stream-us-east-1.getpublica.com/playlist.m3u8?avod=1&live=1&hls_marker=1&network_id=2220&cb=0.61499200%201695369799&did=3440a3cf-3013-5b99-20dc-78b637b8&ip=223.238.71.86&ua=[UA]&us_privacy=[US_PRIVACY]&consent=[CONSENT]&coppa=[COPPA]&gdpr=[GDPR]&ifa_type=[IFA_TYPE]&is_lat=[LIMITED_AD_TRACKING]&site_page=galxy.tv/freetv&site_name=GalxyTV&app_category=IAB1-7,IAB1,IAB1-5&app_name=GalxyTV&app_bundle=GalxyTV&app_store_url=www.galxy.tv&app_version=1&device_type=[DEVICE_TYPE]&player_height=1080&player_width=1920&content_channel=Business%20Rockstars&content_genre=business,entertainment&content_series=[CONTENT_SERIES]&content_title=[CONTENT_TITLE]&content_season=[CONTENT_SEASON]&content_episode=[CONTENT_EPISODE]&content_cat=IAB3,IAB4,IAB5,IAB4-11,IAB1-5,IAB1-7&content_keywords=business%20insights,founders,business%20leaders,Empowerista,Legal%20on%20the%20Go,Officeland,Female%20Founders&content_rating=TV-G&content_length=[CONTENT_LENGTH]&content_language=English&content_url=[CONTENT_URL]&content_id=[CONTENT_ID]&pod_duration=121&min_ad_duration=6&max_ad_duration=120&publica_cookie=6541d24b-c7c3-46ca-8615-125a832db40b&content_channel=Business+Rockstars&content_context=1&content_id=5444&content_url=https%3A%2F%2Fgalxy.tv%2Ftitles%2F5444&content_season=4281&content_length=1140&content_series=Brittany%2BLehman%2BInterviews%2BBen%2BGrubbs&content_title=Brittany%2BLehman%2BInterviews%2BBen%2BGrubbs"
  );
  const [isFocussed, setIsFocussed] = useState(false);

  useEffect(() => {
    // Update the current time every minute
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Generate time slots starting from the current time in 30-minute increments
  const timeSlots = [];
  let currentHour = currentTime.getHours();
  let currentMinute = currentTime.getMinutes(); // Get the exact minutes
  let count = 0;
  while (count < 24) {
    if (currentHour > 23) currentHour = 0;
    while (currentMinute < 60) {
      // Round down the minutes to the nearest 30-minute increment
      const roundedMinute = Math.floor(currentMinute / 30) * 30;

      const time = `${currentHour.toString().padStart(2, "0")}:${roundedMinute
        .toString()
        .padStart(2, "0")}`;
      timeSlots.push(time);

      // Move to the next 30-minute slot
      currentMinute = roundedMinute + 30;
    }
    currentHour++;
    currentMinute = 0;
    count++;
  }
  const ClickHandler = (channel) => {
    const url = (() => {
      switch (channel?.channelContent?.type) {
        case "CHANNEL":
          return channel?.channelContent?.restreamHlsUrl;
        case "LIVE_EVENT":
          return channel?.channelContent?.sourceLink;
        default:
          return null;
      }
    })();
    console.log(url);
    dispatch(
      navigate({
        path: "play",
        props: {
          id: channel?.channelContent?.id,
          type: channel?.channelContent?.type,
          url: url,
        },
      })
    );
    // setDetail(channel);
  };
  const FocusHandler = (channel) => {
    setIsFocussed(false);
    const URL = channel?.channelContent?.restreamHlsUrl;
    setPlayUrl(URL);
    setTimeout(() => {
      setIsFocussed(true);
    }, 500);
  };

  const renderChannelOverlay = () => {
    return (
      <div className="fixed right-52 z-10 bottom-[2rem] h-96 w-160 overflow-hidden">
        {isFocussed && <SmallVideoPlayer videoUrl={playUrl} controls={false} />}
      </div>
    );
  };
  const totalWidth = timeSlots.length * 9.6;
  const totalDurationInSeconds = 24 * 60 * 60; // Total duration of a day in seconds

  let currentStartTime = new Date();
  currentStartTime.setHours(
    currentTime.getHours(),
    Math.floor(currentTime.getMinutes() / 30) * 30
  );
  const currPos =
    ((currentTime - currentStartTime) / 1000 / totalDurationInSeconds) *
    totalWidth;
  const handleChannelNavigation = (e, index) => {
    const key = e.keyCode;
    if (key === 33 || key === 500) Spotlight.focus(`ch${index - 1}`);
    else if (key === 34) Spotlight.focus(`ch${index + 1}`);
  };
  return (
    <>
      {playUrl && renderChannelOverlay()}
      <Scroller verticalScrollbar="hidden" direction="both">
        <div {...rest}>
          <div className="flex gap-7">
            <div className="time-line min-w-64 text-subText flex-col">
              <p className="text-[0.8rem] text-[#788C9A] font-medium w-52 mb-[3rem]">
                Channel
              </p>
              {data?.map((channel, index) => {
                if (channel?.length > 0) console.log(channel);
                return (
                  <SpottableComponent
                    onKeyDown={(e) => handleChannelNavigation(e, index)}
                    className="w-60 epg h-44 focus:border-[0.01rem] pt-[10%] pb-[8%] px-0 my-2"
                    style={{ backgroundColor: channel?.ctv_bck_color }}
                    onFocus={() => FocusHandler(channel)}
                    spotlightId={`ch${index}`}
                    onClick={() => ClickHandler(channel)}
                    key={channel?.channel_name}
                    children={
                      <img
                        src={
                          "https://1577787583.rsc.cdn77.org/" +
                          channel?.channelContent?.coverimage[0]?.coverUrl
                        }
                        alt={channel?.channel_name}
                        className="channel_img px-0 m-0"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                      />
                    }
                  />
                );
                return null;
              })}
            </div>
            <div
              className="overflow-hidden w-[80vw]"
              style={{ scrollbarWidth: "none" }}
              onScroll={(e) => {
                const { scrollLeft, scrollWidth, clientWidth } = e.target;
                if (scrollLeft + clientWidth >= scrollWidth - 3) {
                  Spotlight.move("down");
                  console.log(scrollLeft + clientWidth, scrollWidth);
                }
              }}
            >
              <Scroller verticalScrollbar="hidden" direction="horizontal">
                <div className="pl-4 flex flex-row h-32 w-[90%]">
                  {timeSlots.map((time, index) => (
                    <SpottableComponent
                      spotlightId={`id-${index}`}
                      children={
                        <div className="time-slot" key={index}>
                          <span className="text-[0.7rem] text-[#788C9A]">
                            {time}
                          </span>
                        </div>
                      }
                      onSpotlightRight={() => {
                        Spotlight.focus(`id-${index + 4}`, {
                          toOuterContainer: false,
                        });
                      }}
                      onSpotlightLeft={() => {
                        Spotlight.focus(`id-${index - 4}`, {
                          toOuterContainer: false,
                        });
                      }}
                    />
                  ))}
                </div>
                <Icon style={{ marginLeft: `${currPos}rem`, marginBlock: 0 }}>
                  triangledown
                </Icon>
                {data?.map((channel, index) => {
                  const slots = {};
                  timeSlots.map((ts) => (slots[ts] = false));
                  if (channel?.programs?.length > 0)
                    return (
                      <div key={index} className="flex items-center mb-4">
                        <Scroller
                          direction="horizontal"
                          horizontalScrollbar="hidden"
                        >
                          <div className="pl-4 flex flex-row h-44 w-[90%]">
                            <ul className={`flex w-[${totalWidth}rem]`}>
                              {channel?.programs.map((event, eventIndex) => {
                                // Get the current time
                                const eventStartTime = new Date(
                                  event.startTime
                                );
                                const eventEndTime = new Date(event.endTime);
                                if (eventEndTime < eventStartTime)
                                  eventEndTime.setDate(
                                    eventEndTime.getDate() + 1
                                  );

                                // Check if the event has not ended yet (end time is greater than the current time)
                                if (
                                  eventEndTime > currentTime &&
                                  eventStartTime - currentTime < 24 * 3600000
                                ) {
                                  let eventDuration =
                                    (eventEndTime - eventStartTime) / 1000; //converting to duration in seconds if not available
                                  //mark slot true if filled
                                  // slots[`${eventEndTimeParts[0].toString().padStart(2, "0")}:${(Math.floor( parseInt(eventEndTimeParts[1],10) / 30) * 30).toString().padStart(2, "0")}`] = true;

                                  // console.log(currentStartTime - eventStartTime,event.episode_title, eventDuration)
                                  if (eventStartTime < currentTime) {
                                    eventDuration -=
                                      (currentStartTime - eventStartTime) /
                                      1000;
                                  }
                                  //showing schedule only for next 24 hours
                                  const widthPercentage =
                                    (eventDuration / totalDurationInSeconds) *
                                    100;
                                  const remValue =
                                    (widthPercentage / 100) * totalWidth;
                                  let marginLeft = 0; // needed for gaps between schedule
                                  const marginRight = 0; // Adjust this value to add spacing between events

                                  if (eventStartTime > currentTime) {
                                    let c = 0;
                                    let currentHour = eventStartTime.getHours();
                                    let currentMinute =
                                      Math.floor(
                                        eventStartTime.getMinutes() / 30
                                      ) * 30;
                                    while (
                                      slots[
                                        `${currentHour}:${currentMinute}`
                                      ] === false
                                    ) {
                                      if (currentMinute < 60)
                                        currentMinute += 30;
                                      else {
                                        currentHour++;
                                        currentMinute = "00";
                                      }
                                      c++;
                                    }
                                    if (c > 0)
                                      marginLeft =
                                        ((eventStartTime - c * 9) /
                                          1000 /
                                          totalDurationInSeconds) *
                                        totalWidth;
                                  }
                                  function formatDuration(time) {
                                    const start = new Date(time);
                                    const formattedMinutes =
                                      (start.getMinutes() < 10 ? "0" : "") +
                                      start.getMinutes();
                                    const ampm =
                                      start.getHours() < 12 ? "AM" : "PM";
                                    const formattedTime =
                                      ((start.getHours() + 11) % 12) +
                                      1 +
                                      ":" +
                                      formattedMinutes +
                                      " " +
                                      ampm;
                                    return formattedTime;
                                  }
                                  return (
                                    <div
                                      className="mr-2 epg flex flex-col justify-center py-5 h-44 focus:border-[0.2rem] focus:border-[#64E0F1]"
                                      style={{
                                        width: `${remValue}rem`,
                                        maxHeight: 200,
                                        overflow: "hidden",
                                        marginRight: `${marginRight}px`,
                                        paddingInline: `${
                                          remValue < 2 ? 0.1 : 0.5
                                        }rem`,
                                        backgroundImage:
                                          eventStartTime > currentTime
                                            ? ""
                                            : `linear-gradient(to right, rgba(255,255,255,0.1) ${currPos}rem, rgba(24, 24, 24, 0) ${currPos}rem), url(${
                                                event.backdrop ||
                                                event.poster_url
                                              })`,
                                      }}
                                      spotlightId={`${index}i${eventIndex}`}
                                    >
                                      <div className="flex justify-between items-center">
                                        <div>
                                          <BodyText
                                            noWrap
                                            className={`m-0 font-semibold text-10 leading-4`}
                                          >
                                            {event?.title}
                                          </BodyText>
                                          <p
                                            className={`text-[0.6rem] text-subText`}
                                          >
                                            {`${formatDuration(
                                              eventStartTime
                                            )} - ${formatDuration(
                                              eventEndTime
                                            )}`}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }

                                return null; // Return null for events that have already ended
                              })}
                            </ul>
                          </div>
                        </Scroller>
                      </div>
                    );
                  return null;
                })}
              </Scroller>
            </div>
          </div>
        </div>
      </Scroller>
    </>
  );
};

const EPGComponentBase = kind({
  name: "EPGComponent",
  css: {
    c,
  },

  render: ({ data, ...rest }) => <EPG data={data} {...rest} />,
});

export default EPGComponentBase;
