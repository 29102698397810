import { fork, put, call, take } from 'redux-saga/effects';
import {Buffer} from 'buffer';
import playlistQueries from './queries';

import playerPlaylistActions, { constants } from './actions';
import { Failure } from '../../../lib/utilities';

const playerSaga = ({ gqlClient, deliveryClient }) => {
  function* getPlayerPlaylistWatcher() {
    while (true) {
      try {
        const { payload } = yield take(constants.FETCH_PLAYER_PLAYLIST_PENDING);

        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          playlistQueries.getContentById,
          payload,
        );

        if (response.errors || !response.data || !response.data.searchContent)
          throw new Failure();

        const { cdn, data } = response.data.searchContent;
        let cdnDomainName = '';
        if (cdn.aliases && cdn.aliases.quantity > 0) {
          // eslint-disable-next-line prefer-destructuring
          cdnDomainName = cdn.aliases.items[0];
        } else {
          cdnDomainName = cdn.domainName;
        }

        yield put(
          playerPlaylistActions.fetchPlayerPlaylistSuccess({
            ids: payload.ids,
            cdnDomainName,
            data,
          }),
        );
      } catch (e) {
        const errorMessage =
          (e instanceof Failure && e.message) || 'Something went wrong';
        yield put(
          playerPlaylistActions.fetchPlayerPlaylistFailure({
            reason: errorMessage,
          }),
        );
      }
    }
  }

  function* getSignedURL() {
    while (true) {
      try {
        const {
          payload: { id },
        } = yield take(constants.SIGN_URL_PENDING);
        const response = yield call(
          deliveryClient.makeRequest,
          `/delivery/v1/signurl?contentId=${id}`,
        );

        if (!response.ok || !response.data)
          throw new Failure(response.data?.errorCode || 10);
        yield put(
          playerPlaylistActions.signUrlSuccess({
            data: JSON.parse(Buffer.from(response.data,'base64')),
          }),
        );
      } catch (e) {
        // SIGN URL ERROR Code also is being passed in reason payload
        const errorMessage =
          (e instanceof Failure && e.message) || 'Something went wrong';
        yield put(
          playerPlaylistActions.signUrlFailure({ reason: errorMessage }),
        );
      }
    }
  }

  function* previousAndNextContent() {
    while (true) {
      try {
        const { payload } = yield take(
          constants.GET_PREVIOUS_AND_NEXT_CONTENT_PENDING,
        );

        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          playlistQueries.previousAndNextContent,
          payload,
        );
        if (response.errors || !response.data) throw new Failure();

        yield put(
          playerPlaylistActions.getPreviousAndNextContentSuccess(
            response.data.previousAndNextContent,
          ),
        );
      } catch (e) {
        const errorMessage =
          (e instanceof Failure && e.message) || 'Something went wrong';
        yield put(
          playerPlaylistActions.getPreviousAndNextContentFailure({
            reason: errorMessage,
          }),
        );
      }
    }
  }

  function* watcher() {
    yield fork(getPlayerPlaylistWatcher);
    yield fork(getSignedURL);
    yield fork(previousAndNextContent);
  }

  return {
    watcher,
  };
};

export default playerSaga;
