import { fork, put, call, take, takeEvery, select } from 'redux-saga/effects';

import staticSectionActions, { constants } from './actions';

import fetchFavoritesQuery from '../favorites/queries';

import queries from './queries';
import mutations from './mutations';

import { DYNAMIC_SLUGS } from '../../../lib/constants';
import { Failure } from '../../../lib/utilities';

const staticSectionsSaga = ({ gqlClient }) => {
  function* fetchStaticSection({ payload: { slug, limit = 20, skip = 0 } }) {
    const variables = {
      limit,
      skip,
    };
    // make an api call to fetch the content type array
    if (slug === DYNAMIC_SLUGS.favorites) {
      // If slug is favorite use getFavorites query
      try {
        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          fetchFavoritesQuery.fetchFavorites,
          variables,
        );

        if (response.errors || !response.data.getFavorites) throw new Failure();

        const { count, list } = response.data.getFavorites;
        const { cdn } = yield select(state => state.storefront?.config?.data);
        yield put(
          staticSectionActions.fetchStaticSectionSuccess({
            data: list,
            cdn,
            count,
            slug,
            cdnDomainName: cdn.domainName,
            isMoreContentAvailable: limit <= list.length,
          }),
        );
      } catch (e) {
        yield put(staticSectionActions.fetchStaticSectionFailure({ slug }));
      }
    } else {
      try {
        const response = yield call(
          [gqlClient, gqlClient.query],

          {
            query: queries.getSectionByDynamicSlug,
            variables: { slug, ...variables },
          },
        );

        if (response.errors || !response.data.getVideosByType)
          throw new Failure();

        const { list, cdn, count } = response.data.getVideosByType;

        yield put(
          staticSectionActions.fetchStaticSectionSuccess({
            data: list,
            count,
            cdn,
            cdnDomainName: cdn.domainName,
            slug,
            isMoreContentAvailable: limit <= list.length,
          }),
        );
      } catch (e) {
        yield put(staticSectionActions.fetchStaticSectionFailure({ slug }));
      }
    }
  }

  function* removeStaticItemWatcher() {
    const { payload } = yield take(constants.REMOVE_STATIC_ITEM_PENDING);
    const { section, index, id } = payload;
    try {
      const response = yield call(
        [gqlClient, gqlClient.rawRequest],
        mutations.deleteFromWatchHistory,
        {
          id,
        },
      );

      if (response.errors || !response.data.deleteFromWatchHistory)
        throw new Error();

      yield put(
        staticSectionActions.removeStaticItemSuccess({ section, index, id }),
      );
    } catch (e) {
      yield put(staticSectionActions.removeStaticItemFailure({ section, id }));
    }
  }

  function* fetchStaticSectionWatcher() {
    yield takeEvery(constants.FETCH_STATIC_SECTION_PENDING, fetchStaticSection);
  }

  function* watcher() {
    yield fork(fetchStaticSectionWatcher);
    yield fork(removeStaticItemWatcher);
  }

  return {
    watcher,
  };
};

export default staticSectionsSaga;
