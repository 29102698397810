export const constants = {
  FETCH_PROFILE_INFO_PENDING: 'profileInfo/FETCH_PROFILE_INFO_PENDING',
  FETCH_PROFILE_INFO_SUCCESS: 'profileInfo/FETCH_PROFILE_INFO_SUCCESS',
  FETCH_PROFILE_INFO_FAILURE: 'profileInfo/FETCH_PROFILE_INFO_FAILURE',

  UPDATE_PROFILE_INFO_PENDING: 'profileInfo/UPDATE_PROFILE_INFO_PENDING',
  UPDATE_PROFILE_INFO_SUCCESS: 'profileInfo/UPDATE_PROFILE_INFO_SUCCESS',
  UPDATE_PROFILE_INFO_FAILURE: 'profileInfo/UPDATE_PROFILE_INFO_FAILURE',

  FETCH_PROFILES_PENDING: 'profileInfo/FETCH_PROFILES_PENDING',
  FETCH_PROFILES_SUCCESS: 'profileInfo/FETCH_PROFILES_SUCCESS',
  FETCH_PROFILES_FAILURE: 'profileInfo/FETCH_PROFILES_FAILURE',

  CREATE_USER_PROFILE_PENDING: `profileInfo/CREATE_USER_PROFILE_PENDING`,
  CREATE_USER_PROFILE_SUCCESS: `profileInfo/CREATE_USER_PROFILE_SUCCESS`,
  CREATE_USER_PROFILE_FAILURE: `profileInfo/CREATE_USER_PROFILE_FAILURE`,

  GET_ORDER_HISTORY_PENDING: 'profileInfo/GET_ORDER_HISTORY_PENDING',
  GET_ORDER_HISTORY_SUCCESS: 'profileInfo/GET_ORDER_HISTORY_SUCCESS',
  GET_ORDER_HISTORY_FAILURE: 'profileInfo/GET_ORDER_HISTORY_FAILURE',

  RESET_ORDER_HISTORY: 'profileInfo/RESET_ORDER_HISTORY',

  UPDATE_PROFILE_IMAGE_PENDING: 'profileInfo/UPDATE_PROFILE_IMAGE_PENDING',
  UPDATE_PROFILE_IMAGE_SUCCESS: 'profileInfo/UPDATE_PROFILE_IMAGE_SUCCESS',
  UPDATE_PROFILE_IMAGE_FAILURE: 'profileInfo/UPDATE_PROFILE_IMAGE_FAILURE',

  UPDATE_USER_PROFILE_PENDING: 'profileInfo/UPDATE_USER_PROFILE_PENDING',
  UPDATE_USER_PROFILE_SUCCESS: 'profileInfo/UPDATE_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE_FAILURE: 'profileInfo/UPDATE_USER_PROFILE_FAILURE',

  DELETE_USER_PROFILE_PENDING: `profileInfo/DELETE_USER_PROFILE_PENDING`,
  DELETE_USER_PROFILE_SUCCESS: `profileInfo/DELETE_USER_PROFILE_SUCCESS`,
  DELETE_USER_PROFILE_FAILURE: `profileInfo/DELETE_USER_PROFILE_FAILURE`,

  RESET_PROFILE_STATUS_STATE: 'profileInfo/RESET_PROFILE_STATUS_STATE',

  MODIFY_PROFILE_INFO: 'profileInfo/MODIFY_PROFILE_INFO',
};

const fetchProfileInfoPending = () => ({
  type: constants.FETCH_PROFILE_INFO_PENDING,
});

const fetchProfileInfoSuccess = ({ data }) => {
  return {
    type: constants.FETCH_PROFILE_INFO_SUCCESS,
    payload: { data },
  };
};

const fetchProfileInfoFailure = () => ({
  type: constants.FETCH_PROFILE_INFO_FAILURE,
});

const updateProfileInfoPending = payload => {
  return {
    type: constants.UPDATE_PROFILE_INFO_PENDING,
    payload,
  };
};

const updateProfileInfoSuccess = payload => {
  return {
    type: constants.UPDATE_PROFILE_INFO_SUCCESS,
    payload,
  };
};

const updateProfileInfoFailure = payload => ({
  type: constants.UPDATE_PROFILE_INFO_FAILURE,
  payload,
});

/// ---------------- FETCH PROFILES ACTIONS START -------
// Get multiple profiles associated with this account
const fetchProfilesPending = payload => {
  return {
    type: constants.FETCH_PROFILES_PENDING,
    payload,
  };
};
const fetchProfilesSuccess = payload => {
  return {
    type: constants.FETCH_PROFILES_SUCCESS,
    payload,
  };
};
const fetchProfilesFailure = payload => {
  return {
    type: constants.FETCH_PROFILES_FAILURE,
    payload,
  };
};
/// ---------------- FETCH PROFILES ACTIONS END -------

const createUserProfilePending = payload => ({
  type: constants.CREATE_USER_PROFILE_PENDING,
  payload,
});

const createUserProfileSuccess = payload => ({
  type: constants.CREATE_USER_PROFILE_SUCCESS,
  payload,
});

const createUserProfileFailure = payload => ({
  type: constants.CREATE_USER_PROFILE_FAILURE,
  payload,
});

const deleteUserProfilePending = payload => ({
  type: constants.DELETE_USER_PROFILE_PENDING,
  payload,
});

const deleteUserProfileSuccess = payload => ({
  type: constants.DELETE_USER_PROFILE_SUCCESS,
  payload,
});

const deleteUserProfileFailure = payload => ({
  type: constants.DELETE_USER_PROFILE_FAILURE,
  payload,
});

const resetProfileStatusState = payload => ({
  type: constants.RESET_PROFILE_STATUS_STATE,
  payload,
});

/// ---------------- UPDATE USER PROFILE ACTIONS START -------
// Update user profile associated with this account

const updateUserProfilePending = payload => {
  return {
    type: constants.UPDATE_USER_PROFILE_PENDING,
    payload,
  };
};

const updateUserProfileSuccess = payload => {
  return {
    type: constants.UPDATE_USER_PROFILE_SUCCESS,
    payload,
  };
};

const updateUserProfileFailure = payload => {
  return {
    type: constants.UPDATE_USER_PROFILE_FAILURE,
    payload,
  };
};
/// ---------------- UPDATE USER PROFILE ACTIONS END -------

const getOrderHistoryPending = payload => {
  return {
    type: constants.GET_ORDER_HISTORY_PENDING,
    payload,
  };
};

const getOrderHistorySuccess = payload => {
  return {
    type: constants.GET_ORDER_HISTORY_SUCCESS,
    payload,
  };
};

const getOrderHistoryFailure = payload => ({
  type: constants.GET_ORDER_HISTORY_FAILURE,
  payload,
});

const resetOrderHistory = payload => ({
  type: constants.RESET_ORDER_HISTORY,
  payload,
});

const modifyProfileInfo = ({ field, value }) => {
  return { type: constants.MODIFY_PROFILE_INFO, payload: { field, value } };
};

const updateProfileImagePending = ({ action = 'UPDATE', uri }) => {
  return {
    type: constants.UPDATE_PROFILE_IMAGE_PENDING,
    payload: { action, uri },
  };
};

const updateProfileImageSuccess = ({ avatar }) => {
  return {
    type: constants.UPDATE_PROFILE_IMAGE_SUCCESS,
    payload: { avatar },
  };
};

const updateProfileImageFailure = () => ({
  type: constants.UPDATE_PROFILE_IMAGE_FAILURE,
});

export default {
  fetchProfileInfoFailure,
  fetchProfileInfoPending,
  fetchProfileInfoSuccess,
  //
  updateProfileInfoPending,
  updateProfileInfoSuccess,
  updateProfileInfoFailure,
  //
  fetchProfilesPending,
  fetchProfilesSuccess,
  fetchProfilesFailure,
  resetProfileStatusState,
  //
  createUserProfilePending,
  createUserProfileSuccess,
  createUserProfileFailure,
  //
  deleteUserProfilePending,
  deleteUserProfileSuccess,
  deleteUserProfileFailure,
  //
  updateUserProfilePending,
  updateUserProfileSuccess,
  updateUserProfileFailure,
  //
  getOrderHistoryPending,
  getOrderHistorySuccess,
  getOrderHistoryFailure,
  resetOrderHistory,

  updateProfileImagePending,
  updateProfileImageSuccess,
  updateProfileImageFailure,

  modifyProfileInfo,
};
