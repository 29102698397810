/* eslint-disable */
import { useRef, useEffect } from 'react';
import videojs from 'video.js';
import "./video-custom.css";

const SmallVideoPlayer = ({ videoUrl, controls }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    // Initialize Video.js with options
    const options = {
      autoplay: true,
      controls: controls,
      fluid: true,
      sources: [{ src: videoUrl, type: 'application/x-mpegURL' }],
    };

    const player = videojs(videoRef.current, options);
    // Cleanup when the component unmounts
    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [videoUrl]);

  return (
    <div className="rounded-12">
      <video ref={videoRef} className="video-js" style={{borderRadius:".5rem"}} />
    </div>
  );
};

export default SmallVideoPlayer;
