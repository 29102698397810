import { constants } from './actions';

const initialState = {
  relatedContentsByContentId: {
    /**
     * [id]: {
     *  loading: boolean;
     *  success: boolean;
     *  error: boolean;
     *  message: string;
     *  cdnDomainName: string;
     *  cdn: any;
     *  queryName: string;
     *  count: number;
     *  list: [],
     * }
     */
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.FETCH_RELATED_CONTENT_PENDING: {
      const { id } = payload;
      return {
        ...state,
        relatedContentsByContentId: {
          ...state.relatedContentsByContentId,
          [id]: {
            ...state.relatedContentsByContentId?.[id],
            loading: true,
          },
        },
      };
    }

    case constants.FETCH_RELATED_CONTENT_SUCCESS: {
      const { list, count, cdn, cdnDomainName, id, queryName } = payload;
      return {
        ...state,
        relatedContentsByContentId: {
          ...state.relatedContentsByContentId,
          [id]: {
            ...state.relatedContentsByContentId?.[id],
            cdn,
            cdnDomainName,
            count,
            list,
            queryName,
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    }

    case constants.FETCH_RELATED_CONTENT_FAILURE: {
      const { id, reason } = payload;

      return {
        ...state,
        relatedContentsByContentId: {
          ...state.relatedContentsByContentId,
          [id]: {
            ...state.relatedContentsByContentId?.[id],
            loading: false,
            success: false,
            error: true,
            message: reason,
          },
        },
      };
    }
    case constants.RESET_RELATED_CONTENTS_STATE: {
      return initialState;
    }

    default:
      return state;
  }
}

export default reducer;
