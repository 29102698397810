import * as R from 'ramda';
import { fork, put, call, takeEvery, take } from 'redux-saga/effects';

import { SUBSCRIPTION_FAILED_VIEWED_KEY } from '../../../lib/constants';
import subscriptionsActions, { constants } from './actions';
import authActions from '../auth/actions';

import queries from './queries';
import { Failure } from '../../../lib/utilities';

const subscriptionsSaga = ({ gqlClient }) => {
  function* _getSubscriptionsWatcher({ payload }) {
    // make an api call to fetch the available subscription plans
    try {
      const variables = {
        ...payload,
        filter: 'PUBLISHED',
      };

      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.fetchSubscriptionPlans,
        variables,
      });

      if (
        response.errors ||
        !response.data?.fetchSubscriptionPlans ||
        !response.data?.me
      )
        throw new Failure();

      const { me, fetchSubscriptionPlans } = response.data;
      const {
        subscriptionPlans: subscriptions,
        count,
      } = fetchSubscriptionPlans;

      if (me.userSubscription) {
        yield put(
          authActions.updateUserSubscription({
            subscription: me.userSubscription,
          }),
        );
      }

      const subscriptionsById = R.indexBy(R.prop('id'), subscriptions);

      yield put(
        subscriptionsActions.getSubscriptionsSuccess({
          subscriptionsById,
          subscriptions,
          count,
        }),
      );
    } catch (e) {
      const errorMessage =
        (e instanceof Failure && e.message) || 'Something went wrong';
      yield put(
        subscriptionsActions.getSubscriptionsFailure({
          reason: errorMessage,
        }),
      );
    }
  }

  function* getUpgradableSubscriptionsWatcher() {
    while (true) {
      yield take(constants.GET_UPGRADABLE_SUBSCRIPTIONS_PENDING);
      // make an api call to fetch the upgradable subscription plans
      try {
        const variables = {
          limit: 10,
          skip: 0,
        };

        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          queries.fetchUpgradeSubscriptionPlans,
          variables,
        );

        if (response.errors || !response.data?.fetchUpgradeSubscriptionPlans)
          throw new Failure();

        const { fetchUpgradeSubscriptionPlans } = response.data;

        yield put(
          subscriptionsActions.getUpgradableSubscriptionsSuccess(
            fetchUpgradeSubscriptionPlans,
          ),
        );
      } catch (e) {
        const errorMessage =
          (e instanceof Failure && e.message) || 'Something went wrong';
        yield put(
          subscriptionsActions.getUpgradableSubscriptionsFailure({
            reason: errorMessage,
          }),
        );
      }
    }
  }

  function* subscriptionFailedInfoAcceptedWatcher() {
    try {
      const { payload } = yield take(
        constants.SUBSCRIPTION_FAILED_INFO_ACCEPTED_PENDING,
      );
      const { user } = payload;
      const subscriptionStatus = user?.userSubscription?.status;
      // yield call(
      //   AsyncStorage.setItem,
      //   `${SUBSCRIPTION_FAILED_VIEWED_KEY}_${user?.id}`,
      //   subscriptionStatus,
      // );
      yield put(subscriptionsActions.subscriptionFailedInfoAcceptedSuccess());
    } catch (e) {
      yield put(subscriptionsActions.subscriptionFailedInfoAcceptedFailure());
    }
  }
  function* videoSubscriptionsByContentId() {
    while (true) {
      try {
        yield take(constants.FETCH_VIEWERS_PENDING);
        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          queries.videoSubscriptionByContentId
        );
        if (!response.data) throw new Error();
        yield put(
          subscriptionsActions.fetchViewersSuccess({
            simultaneousViews: response.data?.videoSubscriptionsByContentId
          })
        );
      } catch {
        yield put(subscriptionsActions.fetchViewerFailure());
      }
    }
  }
  function* getSubscriptionsWatcher() {
    yield takeEvery(
      constants.GET_SUBSCRIPTIONS_PENDING,
      _getSubscriptionsWatcher,
    );
  }

  function* watcher() {
    yield fork(getSubscriptionsWatcher);
    yield fork(getUpgradableSubscriptionsWatcher);
    yield fork(subscriptionFailedInfoAcceptedWatcher);
    yield fork(videoSubscriptionsByContentId);
  }

  return {
    watcher,
  };
};

export default subscriptionsSaga;
