import { constants } from './actions';

const initialState = {
  otherTabsContent: { data: {}, loading: false, error: false },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.FETCH_OTHER_CONTENTS_PENDING: {
      return {
        ...state,
        otherTabsContent: {
          ...state.otherTabsContent,
          loading: true,
        },
      };
    }

    case constants.FETCH_OTHER_CONTENTS_FAILURE: {
      return {
        ...state,
        otherTabsContent: {
          ...state.otherTabsContent,
          loading: false,
          error: true,
        },
      };
    }

    case constants.FETCH_OTHER_CONTENTS_SUCCESS: {
      return {
        ...state,
        otherTabsContent: {
          ...state.otherTabsContent,
          data: action.payload.data,
          loading: false,
          error: false,
        },
      };
    }

    default:
      return state;
  }
}

export default reducer;
