import { fork, put, call, take, takeLatest } from 'redux-saga/effects';

import menuActions, { constants } from './actions';

import queries from './queries';

const menuItemsSaga = ({ gqlClient }) => {
  function* fetchMenuItems() {
    yield take(constants.FETCH_MENU_ITEMS_PENDING);

    try {
      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.fetchMenuItems,
        variables: {
          limit: 0,
          skip: 0,
        },
      });

      const menuItemsTree = response.data.fetchMenuItems.menuItems[0].menuTree;

      yield put(
        menuActions.fetchMenuItemsSuccess({
          menuItemsTree,
        }),
      );
    } catch (e) {
      yield put(menuActions.fetchMenuItemsFailure());
    }
  }

  function* fetchHeaderMenu({payload}) {
    try {
      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.fetchHeaderMenu,
        variables: {locale: payload.locale}
      });

      const tree = response.data.fetchMenu.menuTree;

      yield put(
        menuActions.fetchHeaderMenuSuccess({
          tree,
        }),
      );
    } catch (e) {
      yield put(menuActions.fetchHeaderMenuFailure());
    }
  }

  function* fetchFooterMenu() {
    yield take(constants.FETCH_FOOTER_MENU_PENDING);
    try {
      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.fetchFooterMenu,
      });

      const tree = response.data.fetchMenu.menuTree;

      yield put(
        menuActions.fetchFooterMenuSuccess({
          tree,
        }),
      );
    } catch (e) {
      yield put(menuActions.fetchFooterMenuFailure());
    }
  }

  function* fetchHeaderMenuWatcher() {
    yield takeLatest(
      constants.FETCH_HEADER_MENU_PENDING,
      fetchHeaderMenu,
    );
  }
  function* watcher() {
    yield fork(fetchMenuItems);
    yield fork(fetchHeaderMenuWatcher);
    yield fork(fetchFooterMenu);
  }

  return {
    watcher,
  };
};

export default menuItemsSaga;
