export const constants = {
  FETCH_CHANNELS_PENDING: 'channel/FETCH_CHANNELS_PENDING',
  FETCH_CHANNELS_SUCCESS: 'channel/FETCH_CHANNELS_SUCCESS',
  FETCH_CHANNELS_FAILURE: 'channel/FETCH_CHANNELS_FAILURE',

  FETCH_CHANNEL_CONTENT_PENDING: 'FETCH_CHANNEL_CONTENT_PENDING',
  FETCH_CHANNEL_CONTENT_SUCCESS: 'FETCH_CHANNEL_CONTENT_SUCCESS',
  FETCH_CHANNEL_CONTENT_FAILURE: 'FETCH_CHANNEL_CONTENT_FAILURE',
};

const fetchChannelsPending = () => {
  return {
    type: constants.FETCH_CHANNELS_PENDING,
  };
};

const fetchChannelsSuccess = ({ cdn, count, data }) => ({
  type: constants.FETCH_CHANNELS_SUCCESS,
  payload: { cdn, count, data },
});

const fetchChannelsFailure = () => ({
  type: constants.FETCH_CHANNELS_FAILURE,
});

const fetchChannelContentPending = ({ id }) => ({
  type: constants.FETCH_CHANNEL_CONTENT_PENDING,
  payload: { id },
});

const fetchChannelContentSuccess = ({ cdn, data }) => ({
  type: constants.FETCH_CHANNEL_CONTENT_SUCCESS,
  payload: { cdn, data },
});

const fetchChannelContentFailure = () => ({
  type: constants.FETCH_CHANNEL_CONTENT_FAILURE,
});

export default {
  fetchChannelsPending,
  fetchChannelsSuccess,
  fetchChannelsFailure,

  fetchChannelContentPending,
  fetchChannelContentSuccess,
  fetchChannelContentFailure,
};
