export const constants = {
  FETCH_PAGE_INITIAL_DATA_V2_PENDING: 'page/FETCH_PAGE_INITIAL_DATA_V2_PENDING',
  FETCH_PAGE_INITIAL_DATA_V2_SUCCESS: 'page/FETCH_PAGE_INITIAL_DATA_V2_SUCCESS',
  FETCH_PAGE_INITIAL_DATA_V2_FAILURE: 'page/FETCH_PAGE_INITIAL_DATA_V2_FAILURE',

  FETCH_PAGE_INITIAL_DATA_PENDING: 'page/FETCH_PAGE_INITIAL_DATA_PENDING',
  FETCH_PAGE_INITIAL_DATA_SUCCESS: 'page/FETCH_PAGE_INITIAL_DATA_SUCCESS',
  FETCH_PAGE_INITIAL_DATA_FAILURE: 'page/FETCH_PAGE_INITIAL_DATA_FAILURE',
  UPDATE_PAGINATION_DATA: 'page/UPDATE_PAGINATION_DATA',

  FETCH_BANNER_DATA_PENDING: 'page/FETCH_BANNER_DATA_PENDING',
  FETCH_BANNER_DATA_SUCCESS: 'page/FETCH_BANNER_DATA_SUCCESS',
  FETCH_BANNER_DATA_FAILURE: 'page/FETCH_BANNER_DATA_FAILURE',

  FETCH_CONTENT_GROUP_PENDING: 'page/FETCH_CONTENT_GROUP_PENDING',
  FETCH_CONTENT_GROUP_SUCCESS: 'page/FETCH_CONTENT_GROUP_SUCCESS',
  FETCH_CONTENT_GROUP_FAILURE: 'page/FETCH_CONTENT_GROUP_FAILURE',

  FETCH_SECTION_DATA_PENDING: 'page/FETCH_SECTION_DATA_PENDING',
  FETCH_SECTION_DATA_SUCCESS: 'page/FETCH_SECTION_DATA_SUCCESS',
  FETCH_SECTION_DATA_FAILURE: 'page/FETCH_SECTION_DATA_FAILURE',

  FETCH_PAGE_LAYOUT_PENDING: 'page/FETCH_PAGE_LAYOUT_PENDING',
  FETCH_PAGE_LAYOUT_SUCCESS: 'page/FETCH_PAGE_LAYOUT_SUCCESS',
  FETCH_PAGE_LAYOUT_FAILURE: 'page/FETCH_PAGE_LAYOUT_FAILURE',

  RESET_PAGE_DATA: 'page/RESET_PAGE_DATA'
};
export const fetchPageInitialDataV2Pending = ({ path, locale }) => {
  return {
    type: constants.FETCH_PAGE_INITIAL_DATA_V2_PENDING,
    payload: { path, locale }
  };
};

export const fetchPageInitialDataV2Success = ({ path, data }) => ({
  type: constants.FETCH_PAGE_INITIAL_DATA_V2_SUCCESS,
  payload: { path, data }
});

export const fetchPageInitialDataV2Failure = ({ path }) => ({
  type: constants.FETCH_PAGE_INITIAL_DATA_V2_FAILURE,
  payload: { path }
});

// pageConfig actions
const fetchPageInitialDataPending = ({ path, locale }) => {
  return {
    type: constants.FETCH_PAGE_INITIAL_DATA_PENDING,
    payload: { path, locale },
  };
};

const fetchPageInitialDataSuccess = ({ path, data }) => ({
  type: constants.FETCH_PAGE_INITIAL_DATA_SUCCESS,
  payload: { path, data },
});

const fetchPageInitialDataFailure = ({ path }) => ({
  type: constants.FETCH_PAGE_INITIAL_DATA_FAILURE,
  payload: { path },
});

const updatePaginationData = ({ path, newSections, restSections }) => ({
  type: constants.UPDATE_PAGINATION_DATA,
  payload: { path, newSections, restSections }
});

// bannerData actions
const fetchBannerDataPending = ({ id, path, locale }) => ({
  type: constants.FETCH_BANNER_DATA_PENDING,
  payload: { id, path, locale },
});

const fetchBannerDataSuccess = ({ path, bannerCollection }) => ({
  type: constants.FETCH_BANNER_DATA_SUCCESS,
  payload: { path, bannerCollection },
});

const fetchBannerDataFailure = ({ path }) => ({
  type: constants.FETCH_BANNER_DATA_FAILURE,
  payload: { path },
});

// contentGroup actions
const fetchContentGroupPending = ({ id, path }) => ({
  type: constants.FETCH_CONTENT_GROUP_PENDING,
  payload: { id, path },
});

const fetchContentGroupSuccess = ({ path, data }) => ({
  type: constants.FETCH_CONTENT_GROUP_SUCCESS,
  payload: { path, data },
});

const fetchContentGroupFailure = ({ path }) => ({
  type: constants.FETCH_CONTENT_GROUP_FAILURE,
  payload: { path },
});

// pageLayout actions
export const fetchPageLayoutPending = ({ limit, skip }) => {
  return {
    type: constants.FETCH_PAGE_LAYOUT_PENDING,
    payload: { limit, skip },
  };
};

export const fetchPageLayoutSuccess = fetchPages => {
  return {
    type: constants.FETCH_PAGE_LAYOUT_SUCCESS,
    payload: fetchPages,
  };
};

export const fetchPageLayoutFailure = () => {
  return {
    type: constants.FETCH_PAGE_LAYOUT_FAILURE,
  };
};

// section actions
const fetchSectionDataPending = payload => ({
  type: constants.FETCH_SECTION_DATA_PENDING,
  payload,
});

const fetchSectionDataSuccess = payload => ({
  type: constants.FETCH_SECTION_DATA_SUCCESS,
  payload,
});

const fetchSectionDataFailure = payload => ({
  type: constants.FETCH_SECTION_DATA_FAILURE,
  payload,
});

const resetPageData = () => ({
  type: constants.RESET_PAGE_DATA
})
export default {
  fetchPageInitialDataV2Pending,
  fetchPageInitialDataV2Success,
  fetchPageInitialDataV2Failure,
  fetchPageInitialDataFailure,
  fetchPageInitialDataPending,
  fetchPageInitialDataSuccess,
  fetchBannerDataFailure,
  fetchBannerDataPending,
  fetchBannerDataSuccess,
  fetchContentGroupFailure,
  fetchContentGroupPending,
  fetchContentGroupSuccess,
  fetchPageLayoutPending,
  fetchPageLayoutSuccess,
  fetchPageLayoutFailure,
  fetchSectionDataPending,
  fetchSectionDataFailure,
  fetchSectionDataSuccess,
  updatePaginationData,
  resetPageData
};
