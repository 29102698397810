import { fork, put, call, take } from 'redux-saga/effects';

import deviceManagementActions, { constants } from './actions';

import queries from '../../graphql/queries';
import mutations from '../../graphql/mutations';

const deviceManagement = ({ gqlClient }) => {
  function* fetchDevices() {
    while (true) {
      yield take(constants.FETCH_DEVICES_PENDING);

      try {
        const {
          data: {
            devices: { list, count },
          },
        } = yield call(
          [gqlClient, gqlClient.rawRequest],
          queries.profile.getDevices,
        );

        yield put(
          deviceManagementActions.fetchDevicesSuccess({
            devices: list,
            count,
          }),
        );
      } catch (e) {
        yield put(deviceManagementActions.fetchDevicesFailrue());
      }
    }
  }

  function* deleteDevice() {
    while (true) {
      const {
        payload: { id },
      } = yield take(constants.DELETE_DEVICE_PENDING);

      try {
        const {
          data: { removeDevice },
        } = yield call(
          [gqlClient, gqlClient.rawRequest],
          mutations.profile.deleteDevice,
          { id },
        );

        if (removeDevice) {
          yield put(deviceManagementActions.deleteDeviceSuccess({ id }));
        } else {
          throw new Error('delete failed');
        }
      } catch (e) {
        yield put(deviceManagementActions.deleteDeviceFailure());
      }
    }
  }

  function* watcher() {
    yield fork(fetchDevices);
    yield fork(deleteDevice);
  }

  return {
    watcher,
  };
};

export default deviceManagement;
