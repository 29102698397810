import gql from 'graphql-tag';

const fetchFavorites = gql`
  query getFavorites($limit: Int, $skip: Int) {
    getFavorites(limit: $limit, skip: $skip) {
      count
      isMoreContentAvailable
      list {
        cdn {
          domainName
        }
        contentId
        contentMeta
      }
    }
  }
`;

const isPresentInFavorites = gql`
  query isPresentInFavorites($contentId: String) {
    isPresentInFavorites(contentId: $contentId)
  }
`;

export default { isPresentInFavorites, fetchFavorites };
