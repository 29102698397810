import kind from "@enact/core/kind";
import ThemeDecorator from "@enact/sandstone/ThemeDecorator";
import { Panels, Routable, Route } from "@enact/sandstone/Panels";
import { I18nContextDecorator } from "@enact/i18n/I18nDecorator";
import { useEffect } from "react";
import ManageProfiles from "../screens/Profile/ManageProfiles";
import { Provider, useSelector, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Details from "../screens/Details";
import Signin from "../screens/Signin";
import Home from "../screens/Main";
import Player from "../screens/Player";
import { store, persistor } from "../store";
import {
  getThemeByThemeName,
  createCSSVariable,
  ThemeContext,
  DEFAULT_THEME_NAME,
} from "../themes";
import "./attachErrorHandler";
import { navigate, setMagicMouseState } from "../store/modules/navigate/actions";
import css from "./App.module.less";
import ManageDevices from "../screens/Profile/ManageDevices";
import ManageLanguages from "../screens/Profile/ManageLanguage";
import Seasons from "../screens/Details/Seasons";
import Episodes from "../screens/Details/Episodes";
import Search from "../screens/Search";
import V2PageView from "../views/V2PageView";
import ri, { ResolutionDecorator } from "@enact/ui/resolution";

const App = ({ locale, ...rest }) => {
  const path = useSelector((state) => state.navigate.path);
  const config = useSelector((state) => state.storefront.config.data);
  const dispatch = useDispatch();
  ri.config.orientationHandling = "scale";
  ri.init();
  ri.defineScreenTypes([
    {
      name: "vga",
      pxPerRem: 8,
      width: 640,
      height: 480,
      aspectRatioName: "standard",
    },
    {
      name: "xga",
      pxPerRem: 16,
      width: 1024,
      height: 768,
      aspectRatioName: "standard",
    },
    {
      name: "hd",
      pxPerRem: 16,
      width: 1280,
      height: 720,
      aspectRatioName: "hdtv",
      base: true,
    },
    {
      name: "fhd",
      pxPerRem: 24,
      width: 1920,
      height: 1080,
      aspectRatioName: "hdtv",
    },
    {
      name: "uw-uxga",
      pxPerRem: 24,
      width: 2560,
      height: 1080,
      aspectRatioName: "cinema",
    },
    {
      name: "uhd",
      pxPerRem: 48,
      width: 3840,
      height: 2160,
      aspectRatioName: "hdtv",
    },
  ]);
  useEffect(() => {
    rest.updateLocale(window.localStorage.getItem("locale"));
  }, [dispatch]);
  const theme = getThemeByThemeName(
    config?.selectedTheme || DEFAULT_THEME_NAME
  );
  const getThemeScript = () => {
    const _theme = {};
    Object.keys(theme).forEach((key) => {
      _theme[createCSSVariable(key, "color")] = theme[key];
    });

    const themeScript = `
			function setColorVariables(){
	
			  var _theme = ${JSON.stringify(_theme)};
	
			  for (let key in _theme){
				document.documentElement.style.setProperty(key,_theme[key]);
			  }
			}
			setColorVariables();
		`;
    var script = document.createElement("script");
    script.text = themeScript;
    document.head.appendChild(script);
    // 	const script1 = document.createElement('script');

    // // Set the src attribute to your desired URL
    // script1.src = 'https://cdnjs.cloudflare.com/ajax/libs/shaka-player/4.7.11/shaka-player.ui.min.js';

    // const scriptVideoControl = document.createElement('link');
    // scriptVideoControl.href = 'https://cdnjs.cloudflare.com/ajax/libs/shaka-player/4.7.11/controls.min.css';
    // scriptVideoControl.type = 'text/css';
    // scriptVideoControl.rel = 'stylesheet';

    // // Append the script element to the body of the document
    // document.head.appendChild(script1);
    // document.head.appendChild(scriptVideoControl);
    return;
  };

  useEffect(() => {
    const handleCursorStateChange = (event) => {
      if (event.detail && event.detail.visibility) {
		dispatch(setMagicMouseState(true));
      } else {
		event.preventDefault();
        event.stopPropagation();
		dispatch(setMagicMouseState(false));
      }
    };

    document.addEventListener("cursorStateChange", handleCursorStateChange);

    // Clean-up function to remove the event listener when component unmounts
    return () => {
      document.removeEventListener(
        "cursorStateChange",
        handleCursorStateChange
      );
    };
  }, []); // Empty dependency array to run effect only once when component mounts

  return (
    <ThemeContext.Provider value={{ theme }}>
      {getThemeScript()}
      <AppBase
        onNavigate={({ path }) => dispatch(navigate({ path }))}
        path={path}
        locale={locale}
        {...rest}
      />
    </ThemeContext.Provider>
  );
};
const RoutablePanels = Routable({ navigate: "onBack" }, Panels);
const AppBase = kind({
  name: "App",

  styles: {
    css,
    className: "app",
  },

  render: ({ onNavigate, getThemeScript, path, ...rest }) => (
    <RoutablePanels {...rest} onBack={onNavigate} path={path}>
      <Route path="signin" component={Signin} onNavigate={onNavigate} />
      <Route path="home" component={Home} onNavigate={onNavigate} {...rest} />
      <Route
        path="detail"
        component={Details}
        onNavigate={onNavigate}
        {...rest}
      >
        <Route
          path="seasons"
          component={Seasons}
          onNavigate={onNavigate}
          {...rest}
        />
        <Route
          path="episode"
          component={Episodes}
          onNavigate={onNavigate}
          {...rest}
        />
      </Route>
      <Route path="play" component={Player} onNavigate={onNavigate} {...rest} />
      {/* </Route> */}
      {/* </Route> */}
      <Route
        path="v2page"
        component={V2PageView}
        onNavigate={onNavigate}
        {...rest}
      />
      <Route path="search" component={Search} onNavigate={onNavigate} />
      <Route
        path="manageProfile"
        component={ManageProfiles}
        onNavigate={onNavigate}
        {...rest}
      />
      <Route
        path="manageDevice"
        component={ManageDevices}
        onNavigate={onNavigate}
      />
      <Route
        path="manageLanguage"
        component={ManageLanguages}
        onNavigate={onNavigate}
        {...rest}
      />
    </RoutablePanels>
  ),
});

const Main = kind({
  name: "App",

  styles: {
    css,
    className: "app",
  },

  render: ({ locale, ...rest }) => {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App locale={locale} {...rest} />
        </PersistGate>
      </Provider>
    );
  },
});
export default ThemeDecorator(
  ResolutionDecorator(
    I18nContextDecorator(
      {
        updateLocaleProp: "updateLocale",
        localeProp: "locale",
        rtlProp: "rtl",
      },
      Main
    )
  )
);
