import BodyText from '@enact/sandstone/BodyText';
import Scroller from '@enact/sandstone/Scroller';
import {InputField} from '@enact/sandstone/Input';
import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import searchActions from '../../store/modules/search/actions';
import {navigate} from '../../store/modules/navigate/actions';
import { getContentTypeFromQueryName } from '../../lib/utilities';
import SectionsList from '../../views/SectionsList';
import { toIString } from '@enact/i18n/$L';

function Search() {
    const dispatch = useDispatch();

    const { contentTypes, loading, error, limit } = useSelector(
      state => state.search,
    );
  
    const { imageCdn } = useSelector(state => state.storefront.config);
  
    const [searchValue, setSearchValue] = useState('');
    const handleChange = value => {
      setSearchValue(value);
  
      if (value.length < 3 && contentTypes.length !== 0) {
        dispatch(searchActions.searchContentTypesReset());
      }
  
      if (value.length >= 3) {
        dispatch(
          searchActions.searchContentTypesPending({
            term: value, // send the new query
            skip: 0, // query changed so fetch from beginning
            limit,
          }),
        );
      }
    };

    function renderErrorMessage() {
        return (
          <div p={32}>
            <BodyText>{toIString('SOMETHING_WENT_WRONG').toString()}</BodyText>
          </div>
        );
      }
    
      function renderNoSearchResults() {
        return (
          <div p={32} m="auto">
            <BodyText>{toIString('NO_RESULT_FOUND').toString()}</BodyText>
          </div>
        );
      }
    
      function renderLoading() {
        return (
          <div p={32} m="auto">
            {toIString('LOADING').toString()}
          </div>
        );
      }
    
      const renderSearchResultsBySearchTerm = () => {
        const contentTypesObject = contentTypes?.[0];
        const sections = Object.keys(contentTypesObject)?.map(contentType => {
          return {
            title: getContentTypeFromQueryName(contentType),
            contentType,
          };
        });
        // const renderSearchResult = ({
        //   item,
        //   rowRefs,
        //   styles,
        //   sectionIndex,
        //   onLoad,
        //   onItemPress,
        //   onItemFocus,
        // }) => {
        //   return (
        //     <SearchSectionList
        //       rowRefs={rowRefs}
        //       styles={styles}
        //       mode={ORIENTATIONS.landscape}
        //       cdnDomainName={imageCdn.url}
        //       sectionIndex={sectionIndex}
        //       items={contentTypesObject?.[item.contentType]}
        //       onLoad={onLoad}
        //       onItemPress={onItemPress}
        //       onItemFocus={onItemFocus}
        //     />
        //   );
        // };
        return (
          <SectionsList
            sections={sections}
            contentTypesObject={contentTypesObject}
            // renderSectionItem={renderSearchResult}
            // onItemPress={handleItemPress}
          />
        );
      };

    function renderSearchResults() {
        if (loading) return renderLoading();
        if (!loading && error) return renderErrorMessage();
        if (!contentTypes.length) return renderNoSearchResults();
        return renderSearchResultsBySearchTerm();
    }
    const renderSearchHeader = () => {
        return (
            <InputField
			className="min-w-384 h-30 self-start mb-3 mt-5"
			id="search"
			autoFocus
			iconAfter="search"
			value={searchValue}
			placeholder='Search by title'
			onChange={(e) => handleChange(e.value.toLowerCase())}
			// endEndorsement={renderCountryPhoneCodeLabel()}
		  />
        )
    }
    return (
        <Scroller>
            {renderSearchHeader()}
            {renderSearchResults()}
        </Scroller>
    )
}

export default Search;
