import * as R from 'ramda';

import { constants } from './actions';

const initialState = {
  staticSectionBySlug: {},
  removeSectionItemBySlugAndId: {},
};

const removeItemFromSection = (index, sectionSlug, state) => {
  const remaining = R.remove(
    index,
    1,
    state.staticSectionBySlug[sectionSlug].data,
  );
  return remaining;
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.FETCH_STATIC_SECTION_PENDING: {
      const { slug } = payload;
      return {
        ...state,
        staticSectionBySlug: {
          ...state.staticSectionBySlug,
          [slug]: {
            ...state.staticSectionBySlug?.[slug],
            loading: true,
            error: false,
          },
        },
      };
    }

    case constants.FETCH_STATIC_SECTION_SUCCESS: {
      const { slug, data, ...restPayload } = payload;
      return {
        ...state,
        staticSectionBySlug: {
          ...state.staticSectionBySlug,
          [slug]: {
            ...state.staticSectionBySlug?.[slug],
            loading: false,
            success: true,
            error: false,
            data: [...(state.staticSectionBySlug?.[slug]?.data || []), ...data],
            ...restPayload,
          },
        },
      };
    }

    case constants.FETCH_STATIC_SECTION_FAILURE: {
      const { slug } = payload;

      return {
        ...state,
        staticSectionBySlug: {
          ...state.staticSectionBySlug,
          [slug]: {
            ...state.staticSectionBySlug?.[slug],
            loading: false,
            success: false,
            error: true,
          },
        },
      };
    }

    case constants.REMOVE_STATIC_ITEM_PENDING: {
      const { section, id } = payload;
      return {
        ...state,
        removeSectionItemBySlugAndId: {
          ...state.removeSectionItemBySlugAndId,
          [section]: {
            ...state.removeSectionItemBySlugAndId?.[section],
            [id]: {
              ...state.removeSectionItemBySlugAndId?.[section]?.[id],
              loading: true,
            },
          },
        },
      };
    }

    case constants.REMOVE_STATIC_ITEM_SUCCESS: {
      const { section, index, id } = payload;

      return {
        ...state,
        removeSectionItemBySlugAndId: {
          ...state.removeSectionItemBySlugAndId,
          [section]: {
            ...state.removeSectionItemBySlugAndId?.[section],
            [id]: {
              ...state.removeSectionItemBySlugAndId[section]?.[id],
              loading: false,
              success: true,
              error: false,
            },
          },
        },
        staticSectionBySlug: {
          ...state.staticSectionBySlug,
          [section]: {
            ...state.staticSectionBySlug[section],
            data: removeItemFromSection(index, section, state),
          },
        },
      };
    }

    case constants.REMOVE_STATIC_ITEM_FAILURE: {
      const { section, id } = payload;
      return {
        ...state,
        removeSectionItemBySlugAndId: {
          ...state.removeSectionItemBySlugAndId,
          [section]: {
            ...state.removeSectionItemBySlugAndId[section],
            [id]: {
              ...state.removeSectionItemBySlugAndId[section][id],
              loading: false,
              success: false,
              error: true,
            },
          },
        },
      };
    }

    default:
      return state;
  }
}

export default reducer;
