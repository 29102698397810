export const constants = {
  GET_CONTENTS_BY_IDS_PENDING: 'contents/GET_CONTENTS_BY_IDS_PENDING',
  GET_CONTENTS_BY_IDS_FAILURE: 'contents/GET_CONTENTS_BY_IDS_FAILURE',
  GET_CONTENTS_BY_IDS_SUCCESS: 'contents/GET_CONTENTS_BY_IDS_SUCCESS',

  GET_CONTENTS_BY_QUERY_NAME_PENDING:
    'contents/GET_CONTENTS_BY_QUERY_NAME_PENDING',
  GET_CONTENTS_BY_QUERY_NAME_FAILURE:
    'contents/GET_CONTENTS_BY_QUERY_NAME_FAILURE',
  GET_CONTENTS_BY_QUERY_NAME_SUCCESS:
    'contents/GET_CONTENTS_BY_QUERY_NAME_SUCCESS',

  GET_CONTENTS_BY_COLLECTION_PENDING:
    'contents/GET_CONTENTS_BY_COLLECTION_PENDING',
  GET_CONTENTS_BY_COLLECTION_FAILURE:
    'contents/GET_CONTENTS_BY_COLLECTION_FAILURE',
  GET_CONTENTS_BY_COLLECTION_SUCCESS:
    'contents/GET_CONTENTS_BY_COLLECTION_SUCCESS',

  GET_CONTENT_ACCESSIBILITY_BY_IDS_PENDING:
    'contents/GET_CONTENT_ACCESSIBILITY_BY_IDS_PENDING',
  GET_CONTENT_ACCESSIBILITY_BY_IDS_FAILURE:
    'contents/GET_CONTENT_ACCESSIBILITY_BY_IDS_FAILURE',
  GET_CONTENT_ACCESSIBILITY_BY_IDS_SUCCESS:
    'contents/GET_CONTENT_ACCESSIBILITY_BY_IDS_SUCCESS',

  GET_CONTENT_WATCH_TIME_PENDING: 'contents/GET_CONTENT_WATCH_TIME_PENDING',
  GET_CONTENT_WATCH_TIME_FAILURE: 'contents/GET_CONTENT_WATCH_TIME_FAILURE',
  GET_CONTENT_WATCH_TIME_SUCCESS: 'contents/GET_CONTENT_WATCH_TIME_SUCCESS',

  UPDATE_CONTENT_WATCH_TIME_PENDING:
    'contents/UPDATE_CONTENT_WATCH_TIME_PENDING',
  UPDATE_CONTENT_WATCH_TIME_FAILURE:
    'contents/UPDATE_CONTENT_WATCH_TIME_FAILURE',
  UPDATE_CONTENT_WATCH_TIME_SUCCESS:
    'contents/UPDATE_CONTENT_WATCH_TIME_SUCCESS',

  RESET_CONTENTS_BY_ID_STATE: 'contents/RESET_CONTENTS_BY_ID_STATE',
  RESET_CONTENTS_BY_QUERY_NAME_STATE:
    'contents/RESET_CONTENTS_BY_QUERY_NAME_STATE',
  RESET_CONTENTS_BY_COLLECTION_STATE:
    'contents/RESET_CONTENTS_BY_COLLECTION_STATE',
  RESET_CONTENTS_STATE: 'contents/RESET_CONTENTS_STATE',
};

/// ---------------- GET_CONTENTS_BY_IDS START -------
const getContentsByIdsPending = payload => ({
  type: constants.GET_CONTENTS_BY_IDS_PENDING,
  payload,
});
const getContentsByIdsSuccess = payload => ({
  type: constants.GET_CONTENTS_BY_IDS_SUCCESS,
  payload,
});
const getContentsByIdsFailure = payload => ({
  type: constants.GET_CONTENTS_BY_IDS_FAILURE,
  payload,
});
/// ---------------- GET_CONTENTS_BY_IDS END -------

/// ---------------- GET_CONTENTS_BY_QUERY_NAME START -------
const getContentsByQueryNamePending = payload => ({
  type: constants.GET_CONTENTS_BY_QUERY_NAME_PENDING,
  payload,
});
const getContentsByQueryNameSuccess = payload => ({
  type: constants.GET_CONTENTS_BY_QUERY_NAME_SUCCESS,
  payload,
});
const getContentsByQueryNameFailure = payload => ({
  type: constants.GET_CONTENTS_BY_QUERY_NAME_FAILURE,
  payload,
});
/// ---------------- GET_CONTENTS_BY_QUERY_NAME END -------

/// ----------------GET_CONTENTS_BY_COLLECTION START -------
const getContentsByCollectionPending = payload => ({
  type: constants.GET_CONTENTS_BY_COLLECTION_PENDING,
  payload,
});
const getContentsByCollectionSuccess = payload => ({
  type: constants.GET_CONTENTS_BY_COLLECTION_SUCCESS,
  payload,
});
const getContentsByCollectionFailure = payload => ({
  type: constants.GET_CONTENTS_BY_COLLECTION_FAILURE,
  payload,
});
/// ----------------GET_CONTENTS_BY_COLLECTION END -------

/// ---------------- GET_CONTENT_ACCESSIBILITY_BY_IDS START -------
const getContentsAccessibilityByIdPending = payload => ({
  type: constants.GET_CONTENT_ACCESSIBILITY_BY_IDS_PENDING,
  payload,
});
const getContentsAccessibilityByIdSuccess = payload => ({
  type: constants.GET_CONTENT_ACCESSIBILITY_BY_IDS_SUCCESS,
  payload,
});
const getContentsAccessibilityByIdFailure = payload => ({
  type: constants.GET_CONTENT_ACCESSIBILITY_BY_IDS_FAILURE,
  payload,
});
/// ---------------- GET_CONTENT_ACCESSIBILITY_BY_IDS END -------

/// ---------------- GET_CONTENT_WATCH_TIME START -------
const getContentWatchTimePending = payload => ({
  type: constants.GET_CONTENT_WATCH_TIME_PENDING,
  payload,
});
const getContentWatchTimeSuccess = payload => ({
  type: constants.GET_CONTENT_WATCH_TIME_SUCCESS,
  payload,
});
const getContentWatchTimeFailure = payload => ({
  type: constants.GET_CONTENT_WATCH_TIME_FAILURE,
  payload,
});
/// ---------------- GET_CONTENT_WATCH_TIME END -------

/// ---------------- UPDATE_CONTENT_WATCH_TIME START -------
const updateContentWatchTimePending = payload => ({
  type: constants.UPDATE_CONTENT_WATCH_TIME_PENDING,
  payload,
});
const updateContentWatchTimeSuccess = payload => ({
  type: constants.UPDATE_CONTENT_WATCH_TIME_SUCCESS,
  payload,
});
const updateContentWatchTimeFailure = payload => ({
  type: constants.UPDATE_CONTENT_WATCH_TIME_FAILURE,
  payload,
});
/// ---------------- UPDATE_CONTENT_WATCH_TIME END -------

const resetContentsByIdState = payload => ({
  type: constants.RESET_CONTENTS_BY_ID_STATE,
  payload,
});
const resetContentsByQueryNameState = payload => ({
  type: constants.RESET_CONTENTS_BY_QUERY_NAME_STATE,
  payload,
});
const resetContentsByCollectionState = payload => ({
  type: constants.RESET_CONTENTS_BY_COLLECTION_STATE,
  payload,
});
const resetContentsState = payload => ({
  type: constants.RESET_CONTENTS_STATE,
  payload,
});

export default {
  getContentsByIdsFailure,
  getContentsByIdsPending,
  getContentsByIdsSuccess,
  //
  getContentsByQueryNamePending,
  getContentsByQueryNameSuccess,
  getContentsByQueryNameFailure,
  //
  getContentsByCollectionPending,
  getContentsByCollectionSuccess,
  getContentsByCollectionFailure,
  //
  getContentsAccessibilityByIdPending,
  getContentsAccessibilityByIdSuccess,
  getContentsAccessibilityByIdFailure,
  //
  getContentWatchTimePending,
  getContentWatchTimeSuccess,
  getContentWatchTimeFailure,
  //
  updateContentWatchTimePending,
  updateContentWatchTimeSuccess,
  updateContentWatchTimeFailure,
  //
  resetContentsByIdState,
  resetContentsByQueryNameState,
  resetContentsByCollectionState,
  resetContentsState,
};
