import pageQueries from './pages';
import authQueries from '../modules/auth/queries';
import searchQueries from './search';
import staticSectionQueries from '../modules/staticSections/queries';
import menuItems from '../modules/menuItems/queries';
import playerQueries from '../modules/player/queries';
import playlistQueries from '../modules/playlist/queries';
import profileQueries from '../modules/profile/queries';
import languageQueries from '../modules/language/queries';
import configuration from './configuration';
import epg from"./epg";
export default {
  page: pageQueries,
  auth: authQueries,
  search: searchQueries,
  staticSections: staticSectionQueries,
  menuItems,
  player: playerQueries,
  playlist: playlistQueries,
  profile: profileQueries,
  language: languageQueries,
  configuration,epg
};
