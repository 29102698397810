export const constants = {
  OPEN_ACTION_SHEET: 'actionSheet/OPEN_ACTION_SHEET',
  CLOSE_ACTION_SHEET: 'actionSheet/CLOSE_ACTION_SHEET',
};

const openActionSheet = payload => ({
  type: constants.OPEN_ACTION_SHEET,
  payload: {
    ...payload,
  },
});

const closeActionSheet = () => ({
  type: constants.CLOSE_ACTION_SHEET,
});

export default {
  openActionSheet,
  closeActionSheet,
};
