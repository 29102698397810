import kind from "@enact/core/kind";
import { toIString } from "@enact/i18n/$L";
import playerPlaylistActions from "../../store/modules/playlist/actions";
import { Provider, useSelector, connect, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { dummy, navigate } from "../../store/modules/navigate/actions";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import assets from "../../assets/icons";
import playerActions from "../../store/modules/player/actions";
import contentActions from "../../store/modules/contents/actions";
import { fetchViewersPending } from "../../store/modules/subscriptions/actions";
import BodyText from "@enact/sandstone/BodyText";
import Devices from "../Profile/ManageDevices";
import VideoPlay from "../../components/VideoPlayer";
const Player = kind({
  name: "Player",

  styles: {
    // css,
    // theme,
    // className: 'app'
  },

  render: ({ onNavigate, path, ...rest }) => <PlayerBase {...rest} />,
});

const PlayerBase = (props) => {
  const dispatch = useDispatch();
  const params = useSelector((state) => state.navigate.props);
  const { playlist, videoId, signUrl, prevNext } = useSelector(
    (state) => state.playlist
  );
  const id = videoId || params?.id;
  const contentData = useSelector((state) => state.contents.contentsById?.[id]);
  const contentWatchTime = useSelector(
    (state) => state.contents.contentWatchTimeById?.[id]
  );
  const typeOfVideo = params?.type;
  const streamUrl = params?.url;
  const { viewers } = useSelector(
    (state) => state.subscriptions?.simultaneousViews
  );
  const handleBackClick = (currentTime) => {
    if (currentTime)
      dispatch(
        contentActions.updateContentWatchTimePending({
          id,
          duration: Math.floor(currentTime).toString(),
        })
      );
    dispatch(playerPlaylistActions.resetPlayerPlaylist());
    if (params.seasonId)
      dispatch(
        navigate({
          path: "detail/seasons",
          props: { id: params.seasonId, seriesId: params.seriesId },
        })
      );
      else if (typeOfVideo != "VIDEO"){
        dispatch(navigate({path:"home",props:{}}));
      }
    else dispatch(navigate({ path: "detail", props: { id } }));
  };
  const [player, setPlayer] = useState(false);
  useEffect(() => {
    if (signUrl.data?.url) setPlayer(true);
  }, [signUrl.data, contentWatchTime]);
  useEffect(() => {
    dispatch(playerActions.fetchPlayerSettingsPending());
    if (!signUrl.data?.url)
      dispatch(playerPlaylistActions.signUrlPending({ id }));
    dispatch(fetchViewersPending({ id }));
  }, [id]);

  useEffect(() => {
    if (id && contentData && contentData.content) {
      dispatch(
        playerPlaylistActions.getPreviousAndNextContentPending({
          queryName: contentData.content.queryName,
          contentGroupId: params?.contentGroupId,
          id,
        })
      );
    }
  }, [id, contentData]);
  const onEnd = (autoPlay) => {
    setPlayer(false);
    dispatch(playerPlaylistActions.resetPlayerPlaylist());
    dispatch(
      contentActions.updateContentWatchTimePending({
        id,
        duration: contentData?.content?.actualmedia?.[0]?.duration?.toString(),
      })
    );
    if (Boolean(autoPlay?.isOn)) {
      if (params.isOnLoop && !prevNext.next) {
        dispatch(contentActions.getContentsByIdsPending({ ids: [id] }));
        dispatch(playerPlaylistActions.setVideoId({ id: params.firstItemId }));
      }
      if (params.autoplay || (params.seriesId && prevNext.next)) {
        dispatch(
          contentActions.getContentsByIdsPending({ ids: [prevNext.next] })
        );
        dispatch(playerPlaylistActions.setVideoId({ id: prevNext.next }));
      }
    }
  };
  const renderDeviceCheckHeader = () => {
    return (
      <div className="mb-24">
        <h2 className="text-mainText text-24 font-600 leading-relaxed">
          {toIString("DEVICE_LIMIT").toString()}
        </h2>
        <p className="text-14 text-mainText">
          {toIString("SIMULTANEOUS_VIEW_EXCEED").toString()}
        </p>
      </div>
    );
  };
  return viewers?.limitExceeds ? (
    <div className="w-xs sm:min-w-md sm:p-20 h-screen flex flex-col gap-12 justify-center items-stretch text-center">
      <Devices renderHeader={renderDeviceCheckHeader} />
    </div>
  ) : (
    <>
      <PrimaryButton
        backgroundOpacity="transparent"
        icon={assets.backbutton}
        type="button"
        className="absolute top-10 left-10 z-50 selected"
        onClick={() => {
          handleBackClick();
        }}
      />
      {typeOfVideo === "CHANNEL" ? (
        <VideoPlay
          manifestUri={streamUrl}
          onEnd={onEnd}
          onBackClick={handleBackClick}
          isVideo={false}
        />
      ) : typeOfVideo === "LIVE_EVENT" ? (
        <iframe
          width={window.innerWidth * 1}
          height={window.innerHeight * 0.82}
          src={streamUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      ) : signUrl.error && !signUrl.loading ? (
        <BodyText className="text-center h-full my-9/25">
          Oops! Your current account type does not yet have access to this
          content.
        </BodyText>
      ) : player ? (
        <VideoPlay
          manifestUri={signUrl.data?.url}
          keySystem={signUrl.data?.keySystems?.["com.widevine.alpha"]}
          onEnd={onEnd}
          currentTime={parseInt(contentWatchTime?.watchTime, 10)}
          onBackClick={handleBackClick}
          isVideo={true}
        />
      ) : (
        <BodyText className="text-center h-full my-9/25"></BodyText>
      )}
    </>
  );
};
export default Player;
