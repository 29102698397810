import { fork, put, call, takeEvery } from 'redux-saga/effects';

import seasonsActions, { constants } from './actions';

import queries from '../../graphql/queries';

const SeasonsSaga = ({ gqlClient }) => {
  function* fetchSeriesBySeason({ payload: { id } }) {
    try {
      const variables = {
        id,
        contenttype: 'series',
        relationField: 'seasons',
      };

      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.contentTypes.getRelatedContentById,
        variables,
      });

      if (!response?.data?.relatedContent?.data)
        throw new Error('Series Fetching Failed');

      if (!response.data.relatedContent.data.length)
        throw new Error('Series Fetching Failed');

      const {
        id: seriesId,
        title,
        seasonscount: seasonsCount,
        seasons,
      } = response.data.relatedContent.data[0];

      yield put(
        seasonsActions.fetchSeriesBySeasonSuccess({
          id,
          data: { id: seriesId, title, seasonsCount, seasons },
        }),
      );
    } catch (e) {
      yield put(seasonsActions.fetchSeriesBySeasonFailure({ id, e }));
    }
  }

  function* fetchSeriesBySeasonWatcher() {
    yield takeEvery(
      constants.FETCH_SERIES_BY_SEASON_PENDING,
      fetchSeriesBySeason,
    );
  }

  function* watcher() {
    yield fork(fetchSeriesBySeasonWatcher);
  }

  return {
    watcher,
  };
};

export default SeasonsSaga;
