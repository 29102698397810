/* eslint-disable no-unused-vars */
import { fork, take, put, call } from 'redux-saga/effects';

import languageActions, { constants } from './actions';

import queries from '../../graphql/queries';
import mutations from '../../graphql/mutations';
import { Failure } from '../../../lib/utilities';

const languageSaga = ({ gqlClient }) => {
  function* fetchLanguageData() {
    while (true) {
      const { payload } = yield take(constants.FETCH_LANGUAGE_PENDING);

      try {
        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          queries.language.fetchLanguages,
          {
            slug: payload.path,
          },
        );
        if (response.errors || !response.data?.fetchLanguages)
          throw new Failure();

        yield put(
          languageActions.fetchLanguageSuccess({
            data: response.data.fetchLanguages.value,
          }),
        );
      } catch (e) {
        yield put(languageActions.fetchLanguageFailure());
      }
    }
  }

  function* updateSelectedLanguageWatcher() {
    while (true) {
      const { payload } = yield take(
        constants.UPDATE_SELECTED_LANGUAGE_PENDING,
      );
      try {
        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          mutations.profile.updateProfile,
          {
            data: {
              language: {
                content: payload.data,
              },
            },
          },
        );
        if (response.errors || !response.data.updateProfile)
          throw new Failure();
        const {
          data: { updateProfile },
        } = response;
        yield put(
          languageActions.updateLanguageSuccess({
            data: updateProfile.language.content,
          }),
        );
      } catch (e) {
        yield put(languageActions.updateLanguageFailure());
      }
    }
  }

  function* watcher() {
    yield fork(fetchLanguageData);
    yield fork(updateSelectedLanguageWatcher);
  }

  return {
    watcher,
  };
};

export default languageSaga;
