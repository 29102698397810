import { fork, put, call, takeEvery } from "redux-saga/effects";

// import appConstants from 'src/lib/constants';
import pageActions, {
  fetchPageLayoutSuccess,
  fetchPageLayoutFailure,
  constants,
} from "./actions";

import queries from "../../graphql/queries";

import appConstants, { CONTENT_DATA_SORTS } from "../../../lib/constants";
import { Failure } from "../../../lib/utilities";

const pageSaga = ({ gqlClient }) => {
  function* fetchPageDataV2({ payload: { path, locale } }) {
    try {
      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.page.fetchPageDataV2,
        variables: {
          seoSlug: path,
          locale,
        },
      });
      if (!response.ok) return null;
      if (response?.data?.fetchPageV2) {
        const data = response.data.fetchPageV2;
        const { banner } = response.data.fetchPageV2;
        const { bannerData } = response.data.fetchPageV2;
        const { contentSections } = response.data.fetchPageV2;
        yield put(
          pageActions.fetchPageInitialDataV2Success({
            path,
            data,
            banner,
            bannerData,
            contentSections,
          })
        );
      }
      // Add a return statement here to satisfy the requirement
      return null; // or undefined, or any appropriate value
    } catch (e) {
      yield put(
        pageActions.fetchPageInitialDataV2Failure({
          path,
        })
      );
      // Add a return statement here to satisfy the requirement
      return null; // or undefined, or any appropriate value
    }
  }

  function* fetchHomePageData({ locale }) {
    try {
      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.page.fetchHomePageData,
        variables: { slug: "home-page", locale },
      });

      if (response.data?.fetchPage) {
        const currentSections = response.data.fetchPage?.sections.slice(0, 3);
        const restSections = response.data.fetchPage?.sections.slice(
          3,
          Infinity
        );
        const data = {
          pageConfig: {
            banner: response.data.fetchPage?.banner,
            id: response.data.fetchPage?.id,
            currentSections,
            restSections,
            isPaginated: restSections.length > 0,
          },
          bannerData: [],
          sectionDataByContentGroup: {},
          extraDataByContentGroup: {},
        };

        yield put(
          pageActions.fetchPageInitialDataSuccess({
            path: "home-page",
            data,
          })
        );
      } else {
        throw new Error("not found");
      }
    } catch (e) {
      yield put(
        pageActions.fetchPageInitialDataFailure({
          path: "home-page",
        })
      );
    }
  }

  function* fetchPageData({ payload: { path, locale } }) {
    // api call to fetch pageConfig data
    try {
      const query = queries.page.fetchPageData;
      const payload = { slug: path }; // for now using id. later change this to slug

      if (path === appConstants.homePageSlug) {
        yield call(fetchHomePageData, { locale });
      } else {
        const response = yield call([gqlClient, gqlClient.query], {
          query,
          variables: payload,
        });

        const data = {
          pageConfig: response.data.fetchPage,
          bannerData: [],
          sectionDataByContentGroup: {},
          extraDataByContentGroup: {},
        };

        yield put(
          pageActions.fetchPageInitialDataSuccess({
            path,
            data,
          })
        );
      }
    } catch (e) {
      yield put(pageActions.fetchPageInitialDataFailure({ path }));
    }
  }

  function* fetchPageDataWatcher() {
    yield takeEvery(constants.FETCH_PAGE_INITIAL_DATA_PENDING, fetchPageData);
  }

  // api call to detect pageLayout
  function* fetchPageLayoutInfo({ payload: { skip, limit } }) {
    try {
      const response = yield call(
        [gqlClient, gqlClient.rawRequest],
        queries.page.fetchPagelayout,
        { limit, skip }
      );
      const { fetchPages } = response.data;
      yield put(fetchPageLayoutSuccess(fetchPages));
    } catch (error) {
      yield put(fetchPageLayoutFailure());
    }
  }
  function* fetchPageLayoutWatcher() {
    yield takeEvery(constants.FETCH_PAGE_LAYOUT_PENDING, fetchPageLayoutInfo);
  }

  // api call to fetch bannerData
  function* fetchBannerData({ payload: { id, path, locale } }) {
    try {
      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.page.fetchBannerData,
        variables: { id, locale },
      });

      if (response.data.fetchBanner) {
        yield put(
          pageActions.fetchBannerDataSuccess({
            path,
            bannerCollection: response.data.fetchBanner.bannerContent,
          })
        );
      }
    } catch (e) {
      yield put(pageActions.fetchBannerDataFailure({ path }));
    }
  }

  function* fetchBannerDataWatcher() {
    yield takeEvery(constants.FETCH_BANNER_DATA_PENDING, fetchBannerData);
  }

  function* fetchSectionData({
    payload: {
      limit = 20,
      skip = 0,
      sectionId,
      path,
      contentGroupSlug,
      type,
      queryName,
      shuffle,
      locale,
    },
  }) {
    try {
      const contentDataSort = shuffle
        ? CONTENT_DATA_SORTS.shuffled
        : CONTENT_DATA_SORTS.default;

      const params = {
        contentDataSort,
        queryName,
        limit,
        skip,
        locale,
      };

      if (type === appConstants.sectionTypes.contentType) {
        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          queries.page.fetchContentObjects,
          params
        );

        if (response.errors || !response.data?.searchContent)
          throw new Failure();

        const {
          cdn: { domainName: cdnDomainName },
          data,
          count,
        } = response.data.searchContent;

        yield put(
          pageActions.fetchSectionDataSuccess({
            path,
            sectionId,
            data,
            totalItemsInSection: count,
            cdnDomainName,
            isMoreContentAvailable: data.length < count,
          })
        );
      } else {
        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          queries.page.fetchContentGroupData,
          { ...params, slug: contentGroupSlug }
        );

        if (response.errors || !response.data?.fetchContentGroup?.contentData)
          throw new Failure();

        const {
          cdn: { domainName: cdnDomainName, aliases },
          count,
          data,
        } = response.data.fetchContentGroup.contentData;

        const { autoplay, isOnLoop } = response.data.fetchContentGroup;

        yield put(
          pageActions.fetchSectionDataSuccess({
            path,
            sectionId,
            data,
            autoplay,
            isOnLoop,
            totalItemsInSection: count,
            cdnDomainName,
            alias: aliases?.quantity === 1 ? aliases.items?.[0] : cdnDomainName,
            isMoreContentAvailable: data.length < count,
          })
        );
      }
    } catch (e) {
      yield put(pageActions.fetchSectionDataFailure({ path, sectionId }));
    }
  }

  function* fetchSectionDataWatcher() {
    yield takeEvery(constants.FETCH_SECTION_DATA_PENDING, fetchSectionData);
  }
  function* fetchPageDataV2Watcher() {
    yield takeEvery(
      constants.FETCH_PAGE_INITIAL_DATA_V2_PENDING,
      fetchPageDataV2
    );
  }


  function* watcher() {
    yield fork(fetchPageDataV2Watcher);
    yield fork(fetchPageDataWatcher);
    yield fork(fetchBannerDataWatcher);
    yield fork(fetchSectionDataWatcher);
    yield fork(fetchPageLayoutWatcher);
  }

  return {
    watcher,
  };
};

export default pageSaga;
