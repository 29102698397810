/* eslint-disable no-param-reassign */
import { create as apiCreate } from 'apisauce';
import { print, getOperationAST } from 'graphql';

import authActions from '../store/modules/auth/actions';
// eslint-disable-next-line
import { store } from '../store';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../lib/constants';

/**
 * Create an api wrapper based on baseurl
 * @constructor
 * @param {string} baseURL - base url to make request
 * @returns {object} A list of the API functions
 */
const create = (baseURL = '') => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  // here are some default headers
  const headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json; charset=utf-8',
    credentials: 'include',
  };

  const api = apiCreate({
    // base URL is read from the "constructor"
    baseURL,
    headers,
    // 30 second timeout.
    timeout: 30000,
    withCredentials: true,
  });

  // Log out the user when the following error is sent by the backend
  api.axiosInstance.interceptors.response.use(config => {
    if (
      config?.data?.errors?.[0]?.message === 'token not found' &&
      config?.data?.errors?.[0]?.status === '404'
    ) {
      console.log(config.data);
      // The refresh token is deleted in the backend, so reloading the
      // app forcefully will try to make a call to fetch new accesstoken
      // which fails, and the user is redirected to signin
      window.localStorage.removeItem(ACCESS_TOKEN);
      window.localStorage.removeItem(REFRESH_TOKEN);      
    }
    return config;
  });

  api.addResponseTransform(response => {
    if (response.status === 401) {
      // When the auth token expires, backend throws `401`.
      // Force logout the user and take to auth screen.
    } else {
      response.data = response?.data?.data;
    }
  });

  const { setHeader, getBaseURL, setBaseURL } = api;
  /**
   * @param string url
   * @param string method
   * @param object data
   * @returns {*|promise}
   */
  const rawRequest = (query, variables = {}) => {
    const operation = getOperationAST(query);
    const operationName = operation?.name;

    // appends the graphql query name as a query param for easier filtering of network requests.
    const formattedBaseUrl = operationName?.value
      ? `${baseURL}?query=${operationName.value}&store=true`
      : baseURL;

    return api.post(
      formattedBaseUrl,
      JSON.stringify({
        query: print(query),
        variables,
      }),
    );
  };
  // const configRequest = (url,query,variables={})=>{
  //   const operation = getOperationAST(query);
  //   const operationName = operation?.name;

  //   // appends the graphql query name as a query param for easier filtering of network requests.
  //   const formattedBaseUrl = operationName?.value
  //     ? `${url}?query=${operationName.value}&store=true`
  //     : baseURL;

  //   return api.post(
  //     formattedBaseUrl,
  //     JSON.stringify({
  //       query: print(query),
  //       variables,
  //     }),
  //   );
  // }

  const query = ({ query: queryString, variables = {} }) => {
    const operation = getOperationAST(queryString);
    const operationName = operation?.name;

    // appends the graphql query name as a query param for easier filtering of network requests.
    const formattedBaseUrl = operationName?.value
      ? `${baseURL}?query=${operationName.value}&store=true`
      : baseURL;

    return api.post(
      formattedBaseUrl,
      JSON.stringify({
        query: print(queryString),
        variables,
      }),
    );
  };

  const mutation = ({ mutation: mutationString, variables = {} }) => {
    const operation = getOperationAST(mutationString);
    const operationName = operation?.name;

    // appends the graphql query name as a query param for easier filtering of network requests.
    const formattedBaseUrl = operationName?.value
      ? `${baseURL}?query=${operationName.value}&store=true`
      : baseURL;

    return api.post(
      formattedBaseUrl,
      JSON.stringify({
        query: print(mutationString),
        variables,
      }),
    );
  };

  /**
   * Show error messgae based on error code from server
   *
   * @param error: string, one of
   *        [ CLIENT_ERROR,
   *          SERVER_ERROR,
   *          TIMEOUT_ERROR,
   *          CONNECTION_ERROR,
   *          NETWORK_ERROR
   *        ]
   * @returns string: Appropriate message based on the error.
   *          if message is received as an argument that value is returned for
   *           CLIENT_ERROR
   */
  const showError = (error, message = 'Incorrect username or password') => {
    switch (error) {
      case 'CLIENT_ERROR':
        break;
      case 'SERVER_ERROR':
        message = 'Server Error';
        break;
      case 'TIMEOUT_ERROR':
        message = 'Server took too long to respond';
        break;
      case 'CONNECTION_ERROR':
        message = 'Server not available, bad dns';
        break;
      case 'NETWORK_ERROR':
        message = 'Network not available';
        break;
      default:
        message = 'Unidentified error';
    }
    return message;
  };

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    rawRequest,
    query,
    mutation,
    showError,
    // additional utilities
    setHeader,
    getBaseURL,
    setBaseURL,
    internalClient: api,
  };
};

// let's return back our create method as the default.
export default {
  create,
};
