import moment from 'moment';

import constants, {
  AGE_RESTRICTION_PIN_KEY,
  OTP_OPTIONS,
  PAYMENT_PROVIDERS,
  SUBSCRIPTION_STATUSES,
} from './constants';

export function flatten(array) {
  const localArr = array;
  if (localArr.length === 0) return localArr;
  if (Array.isArray(localArr[0]))
    return flatten(localArr[0]).concat(flatten(localArr.slice(1)));
  return [localArr[0]].concat(flatten(localArr.slice(1)));
}
export const getHourAndMinutes = date => {
  const currentDate = date || new Date();

  return currentDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
};

export const getCdnDataFromCdn = cdn => {
  const cdnObj = { image: null, media: null };

  if (cdn === undefined || !cdn) return cdnObj;
  const { videoCdn, imageCdn } = cdn;

  // if none of the video/image cdn objects exists
  if (!videoCdn && !imageCdn) return cdnObj;

  /* helper fn to extract cdn domain name from cdn object */
  function getCdnDomain(_cdn) {
    if (!_cdn) return null;
    const { aliases, url } = _cdn;
    if (Array.isArray(aliases) && aliases.length) {
      return aliases[0];
    }
    return url;
  }
  /* CDN77 serves diff cdn for image and media */
  /* AWS serves same cdn for image and media */
  cdnObj.image = getCdnDomain(imageCdn);
  cdnObj.media = getCdnDomain(videoCdn);
  return cdnObj;
};
export function getTimeStampFromDateString(date) {
  if (!date) return null;

  return Math.floor(new Date(date).getTime());
}

export function generateCSSVar(theme) {
  const cssTheme = {};

  Object.keys(theme).forEach(key => {
    cssTheme[`--color-${key}`] = theme[key];
  });

  return JSON.stringify(cssTheme);
}

export function resizeImage(url, resizeParams) {
  if (!resizeParams) return url;

  let resizedUrl = `${url}?`;

  if (resizeParams.width) {
    resizedUrl += `width=${resizeParams.width}`;
  }

  if (resizeParams.height) {
    resizedUrl += `height=${resizeParams.height}`;
  }
  return resizedUrl;
}

export function getUrlFromMedia(cdnDomainName, media, type, resizeParams = {}) {
  if (type === 'cover') {
    return resizeImage(
      `https://${cdnDomainName}/${media?.[0]?.coverUrl}`,
      resizeParams,
    );
  }

  return `https://${cdnDomainName}/${media?.[0]?.hlsUrl}`;
}

export function getContentTypeFromQueryName(queryName) {
  if (queryName.includes('_')) {
    return queryName.split('_')[0];
  }

  return queryName;
}

const validateField = (item, field, property) => {
  const value = item?.[field];
  if (!value || !value[0] || !value[0][property]) return null;
  return value[0][property];
};

/**
 *
 * @param {*} item
 * @param {*} cdnDomainName
 * @param { 'coverimage' | 'detailPageBannerImage' | 'coverimage' | 'actualmedia' | 'previewmedia' | 'coverImagePortrait' | ['detailPageBannerImage','coverimage','coverImagePortrait'] } types
 * @param {*} resizeParams
 * @param { 'coverUrl' | 'hlsUrl' } property
 * @returns { string | null }
 * E.g.
 *
 * ```
 * const imageUrl = getUrlFromContentType(
      item,
      cdnDomainName,
      'detailPageBannerImage',
      {
        width: 200,
      },
    );
 * ```
 */

export function getUrlFromContentType(
  item,
  imageCdn,
  fields = 'coverimage',
  resizeParams = {},
  property = 'coverUrl',
) {
  let path = '';
  let cdnDomainName = '';
  if (Array.isArray(fields)) {
    fields.forEach(field => {
      if(path!=='' && cdnDomainName!=='') return;
      const _cdnDomainName = imageCdn || validateField(item, field, 'cdnDomainName');

      const _path = validateField(item, field, property);
      if (_cdnDomainName && _path) {
        cdnDomainName = _cdnDomainName;
        path = _path;
      }
    });
  } else {
    const _cdnDomainName = validateField(item, fields, 'cdnDomainName');
    const _path = validateField(item, fields, property);
    if (!_cdnDomainName || !_path) return null;

    cdnDomainName = _cdnDomainName;
    path = _path;
  }
  if(!cdnDomainName) return null;
  return resizeImage(`https://${cdnDomainName}/${path}`, resizeParams);
}

/** Select the ids which need to be fetched (not present in contentsById)
 * @param {object} contentsById - content types object with key as content type id
 * @param {array} ids - list of ids to check
 * @return {array} - list of ids to fetch
 */
export const getIdsToFetch = (contentsById, ids) => {
  const idsToFetch = ids.filter(id => {
    // If content is not present in object, or failed to fetch
    if (!contentsById[id] || contentsById[id].error) return true;
    return false;
  });

  return idsToFetch;
};

/**
 * @param {object} cdn - cdn object to get either domainName or alias from
 * @return {string} - cdn domain name string
 */
export const getCdnDomainName = cdn => {
  if (!cdn) return '';

  let { domainName } = cdn;
  const { aliases } = cdn;
  if (aliases?.quantity > 0 && aliases?.items?.length > 0) {
    [domainName] = aliases.items;
  }

  return domainName;
};

export const getLabel = contentType => {
  if (!contentType || !constants.defaultContentTypesLabels[contentType])
    return null;

  return constants.defaultContentTypesLabels[contentType];
};

export const slugify = text => {
  if (!text) return '';

  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

/**
 *
 * @param {string} nameString
 * @returns {string} e.g. Rishav Bharti -> RB
 */
export const getInitials = nameString => {
  if (!nameString) return '';
  let initials = nameString
    .split(' ')
    .map(str => {
      return str ? str[0] : '';
    })
    .join('');
  if (initials.length >= 2) initials = initials[0] + initials[1];
  return initials.toUpperCase();
};

export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * Converts TimeStamp to [DD/MMM/YYYY] format
 * @param {string} timeStamp
 * @return {string} eg: 07/05/2021
 */
export const timeStampToDateInputFormat = timeStamp => {
  return moment(timeStamp).format('DD/MM/YYYY');
};

/**
 * Converts TimeStamp to [DD MMM,YYYY] format
 * @param {string} timeStamp
 * @return {string} eg: 07 May, 2021
 */
export const timeStampToDateFormat = timeStamp => {
  return moment(timeStamp).format('DD MMM YYYY');
};

export const maskEmail = (email, mask = '*') => {
  const [name, domain] = email.split('@');
  const { length: len } = name;
  const maskedName = `${name[0]}${mask}${name[len - 1]}`;
  const maskedEmail = `${maskedName}@${domain}`;
  return maskedEmail;
};

export const maskPhoneNumber = (
  phoneNumberWithCountryCode,
  suffixLength = 2,
  isPrefixIncluded = true,
  prefixLength = 2,
  mask = '*',
) => {
  const _prefixLength = isPrefixIncluded ? prefixLength : 0;
  const suffix = phoneNumberWithCountryCode.slice(-suffixLength);
  const nStars =
    phoneNumberWithCountryCode.length - (_prefixLength + suffixLength);
  const prefix = phoneNumberWithCountryCode.substring(0, _prefixLength);

  const formattedPhone = `${prefix}${mask.repeat(nStars)}${suffix}`;
  return formattedPhone;
};

/**
 * @returns { Promise<string[]> }
 * Returns the OTP request data, stored in async storage
 */
export const getOTPRequests = async () => {
  const OTPRequestsString = null;// await AsyncStorage.getItem(
  //   OTP_OPTIONS.OTPStorageKey,
  // );

  const OTPRequestObject = JSON.parse(OTPRequestsString);

  return OTPRequestObject || [];
};

// Sets OTP requests to localStorage
export const setOTPRequests = payload => {
  // return AsyncStorage.setItem(
  //   OTP_OPTIONS.OTPStorageKey,
  //   JSON.stringify(payload),
  // );
};

// Sets OTP requests to empty array in localStorage for current organization
export const clearOTPRequests = () => setOTPRequests([]);

/**
 * @param {*} object
 * @param {boolean} appendPrefix
 * @returns {string} search query e.g. `?qry=hello+world&showResults=true`
 */
export const toQueryString = (object, appendPrefix = true) => {
  if (!object) return '';
  let query = '';
  const queryKeys = Object.keys(object);
  queryKeys.forEach((key, index) => {
    query += `${key}=${encodeURIComponent(object[key])}${
      index < queryKeys.length - 1 ? '&' : ''
    }`;
  });
  return appendPrefix ? `?${query}` : query;
};

export const prepareAvatar = avatarData => {
  if (!avatarData?.avatar?.cdn || !avatarData.avatar?.path) return null;

  const avatar = `https://${avatarData.avatar.cdn.domainName}/${avatarData.avatar.path}`;

  return avatar;
};

/**
 * Class to handle custom errors
 *
 */
export class Failure extends Error {
  constructor(params) {
    // Pass arguments (including vendor specific ones) to parent constructor
    super(params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, Failure);
    }

    this.name = 'Failure';
  }
}

// send avatar object
export const getAvatarUrl = avatar => {
  if (!avatar || !avatar?.baseurl || !avatar?.key) return null;

  const { baseurl, key } = avatar;

  return `${baseurl}/${key}`;
};

/**
 * Checks whether if the user with the current subscription can watch
 * a particular content comparing user subscription rank with content
 * subscription ranks
 *
 * @param {number} userRank - current user subscription rank
 * @param {array} contentRanks - array of content ranks
 * @returns {bool} - if user can access the content or not
 */
export const canUserAccessContent = (userRank, contentRanks) => {
  if (!Number.isInteger(userRank)) return false;
  if (!Array.isArray(contentRanks)) return false;
  if (contentRanks.length === 0) return false;
  // If contentRanks is not an array of integers
  if (contentRanks.filter(rank => !Number.isInteger(rank)).length !== 0)
    return false;

  let minContentRank = contentRanks[0];
  contentRanks.forEach(rank => {
    if (rank < minContentRank) {
      minContentRank = rank;
    }
  });

  // If userRank is equal to or greater than min content rank
  // then user can access the content
  return userRank >= minContentRank;
};

/**
 * @param {array} subscriptions - content subscriptions array
 * @return {array} - content ranks
 */
export const getSubscriptionRanks = subscriptions => {
  if (!Array.isArray(subscriptions) || subscriptions.length === 0) return [];

  return subscriptions.map(sub => sub.rank);
};

/**
 * e.g.
 * ```js
 *  const isMethodAllowedInCountry =
 *  isPaymentMethodIsAllowedInCountry(method,countryCode);
 * ```
 * @param { boolean | { allowedCountries : string[]; } } method
 * @param { string } countryCode
 * @returns { boolean }
 */
export const isPaymentMethodIsAllowedInCountry = (method, countryCode) => {
  if (!method) return false;
  if (method.allowedCountries === 'all') return true;
  if (method.allowedCountries && method.allowedCountries.length)
    return method.allowedCountries.includes(countryCode);
  return false;
};

/**
 * e.g.
 * ```js
 *  const hasRecurringMethod =
 *  hasRecurringPaymentMethod(method);
 * ```
 * @param { boolean | { recurring: boolean; } } method
 * @returns { boolean }
 */
export const hasRecurringPaymentMethod = method => {
  return !!method.recurring;
};

export const truncate = (input, maxLength = 100) =>
  input.length > maxLength ? `${input.substring(0, maxLength)}...` : input;

export const autoSize = size => {
  const { width: SCREEN_WIDTH } =0// Dimensions.get('window');

  const scale = SCREEN_WIDTH / 1280;
  const newSize = size * scale;
  // if (Platform.OS === 'ios') {
  //   return Math.round(PixelRatio.roundToNearestPixel(newSize));
  // }
  return null;// Math.round(PixelRatio.roundToNearestPixel(newSize));
};

export const isMediaPublished = content => {
  return content?.actualmedia?.[0]?.publish;
};

export const getPublishedSubscriptionPlans = (
  contentSubscriptions,
  allSubscriptionsById,
) => {
  // Nullity check
  if (!contentSubscriptions) return [];
  return contentSubscriptions.filter(
    subscription =>
      allSubscriptionsById?.[subscription.id]?.artefactStatus === 'PUBLISHED',
  );
};

export const getUserSubscriptionStatus = (
  hasUserFreeSubscription,
  userSubscriptionRank,
  userSubscriptionGroupId,
  paymentProvider,
  contentSubscriptionPlans,
  allSubscriptionPlans,
) => {
  // Requires Subscription when user has free subscription
  if (hasUserFreeSubscription)
    return SUBSCRIPTION_STATUSES.requiresSubscription;

  // Invalid user subscription group
  if (!userSubscriptionGroupId)
    return SUBSCRIPTION_STATUSES.invalidSubscriptionGroup;

  // If content does not have any subscription plan in it
  if (!contentSubscriptionPlans.length)
    return SUBSCRIPTION_STATUSES.invalidOrUnpublishedSubscription;
  /**
   * { [id1]: true, [id2]:true }
   */
  const contentSubscriptionsById = {};

  contentSubscriptionPlans.forEach(contentSubscription => {
    contentSubscriptionsById[contentSubscription.id] = true;
  });

  const higherSubscriptionRankAvailable = allSubscriptionPlans.some(
    subscription => {
      return (
        subscription.groupId === userSubscriptionGroupId &&
        subscription.artefactStatus === 'PUBLISHED' &&
        contentSubscriptionsById[subscription.id] &&
        subscription.rank > userSubscriptionRank
      );
    },
  );

  // If there is no higher rank subscription available
  if (!higherSubscriptionRankAvailable)
    return SUBSCRIPTION_STATUSES.invalidOrUnpublishedSubscription;
  // Upgrade option in Razorpay is not allowed
  if (paymentProvider === PAYMENT_PROVIDERS.razorpay)
    return SUBSCRIPTION_STATUSES.noUpgradeOptionAvailable;

  // If there is a higher rank available for a content subscription, upgrade
  return SUBSCRIPTION_STATUSES.requiresSubscriptionUpgrade;
};

/**
 *
 * @param {*} lastTimeVerified
 * @param {*} durationInHour
 * @returns { boolean } isAgeRestrictionPinDurationValid
 */
export const ageRestrictionPinVerificationDurationValidityHelper = (
  lastTimeVerified,
  durationInHour,
) => {
  const now = moment();
  const prevTime = moment(lastTimeVerified);
  const verificationTimeDifference = now.diff(prevTime, 'h');
  return verificationTimeDifference < durationInHour;
};
/**
 *
 * @param {*} uid
 * @param {*} durationInHour
 * @returns { Promise<boolean> } isAgeRestrictionPinDurationValid
 */
export const isAgeRestrictionPinDurationValid = async (uid, durationInHour) => {
  const lastTimeVerified = null;// await AsyncStorage.getItem(
  //   `${AGE_RESTRICTION_PIN_KEY}-${uid}`,
  // );
  if (!lastTimeVerified) return false;
  return ageRestrictionPinVerificationDurationValidityHelper(
    +lastTimeVerified,
    durationInHour,
  );
};

export const getWatchedLength = (
  totalWatchedDuration,
  totalContentDuration,
) => {
  if (totalWatchedDuration < 1) return 0;
  return parseInt((totalWatchedDuration / totalContentDuration) * 100, 10);
};

/**
 *
 * @param {number} duration
 * @returns {string} date string
 *
 * E.g.
 * ```
 * const durationString = getDurationString((60*60*24*7*5)+(60*60*24*4)+(1*60*60*2)+(1*60*32)+(1*18)); // 5w 4d 2h 32m 18s
 * const durationString = getDurationString(60); // 1m
 * const durationString = getDurationString(60*60*24); // 1d
 * const durationString = getDurationString(60*60*2); // 2h
 *
 * ```
 * */
export const getDurationString = duration => {
  //             // 0,  1,  2,  3,  4
  const suffixes = ['s', 'm', 'h', 'd', 'w'];
  //             // m  h     d        w
  //             // 0  1     2        3
  const durations = [60, 60 * 60, 60 * 60 * 24, 60 * 60 * 24 * 7];

  const getDuration = (totalDuration, remaining, point, string = '') => {
    if (totalDuration <= 0) return '0s';
    if (point <= 0) return `${string} ${remaining}${suffixes[point]}`;

    if (totalDuration >= remaining) {
      const max = durations[point - 1];
      const suffix = suffixes[point];
      const n = Math.floor(remaining / max);
      const __string = n ? ` ${n}${suffix}` : '';
      const _string = `${string}${__string}`;
      const _remaining = remaining % max;
      return getDuration(totalDuration, _remaining, point - 1, _string);
    }
    return ''; // eslint
  };

  return getDuration(duration, duration, suffixes.length - 1).trim();
};

export const FIELD_PROCESSORS = {
  releasedDate: v => moment(v).format('LL'),
};

export const processFieldValues = (field, value) => {
  if (!FIELD_PROCESSORS[field]) return value;
  return FIELD_PROCESSORS[field](value);
};

export const getIsShownOnStorefront = (
  defaultKey,
  fieldDefinition,
  isShownOnStorefrontKey,
) => {
  return fieldDefinition?.[defaultKey]?.[isShownOnStorefrontKey];
};

export const getDisplayName = (defaultKey, fieldDefinition) => {
  return fieldDefinition?.[defaultKey]?.displayName || defaultKey;
};

export const durationFormat = (secs, asObject = false) => {
  const secondsInAnHour = 60 * 60;
  const secondsInAMinute = 60;
  let secondsLeft = 0;

  let duration;
  let hours = 0;
  let mins = 0;
  let seconds = 0;

  if (typeof secs !== 'number' || secs <= 0) {
    if (asObject) return { hours, mins, seconds };
    return '0s';
  }

  if (secs) {
    // Extract the hours (0 - No limit)
    secondsLeft = secs;
    hours = parseInt(moment.duration({ seconds: secondsLeft }).asHours(), 10);

    // Extract the mins (0 - 59)
    secondsLeft -= hours * secondsInAnHour;
    mins = parseInt(moment.duration({ seconds: secondsLeft }).asMinutes(), 10);

    // Extract the seconds (0 - 59)
    secondsLeft -= mins * secondsInAMinute;
    seconds = parseInt(
      moment.duration({ seconds: secondsLeft }).asSeconds(),
      10,
    );

    duration = `${hours}h ${mins}m `;
  }
  if (asObject) {
    return {
      hours,
      mins,
      seconds,
    };
  }
  if (hours < 1) {
    if (mins < 1) {
      return `${seconds}s`;
    }
    return `${mins}m`;
  }
  return duration;
};
