import { constants } from './actions';

const initialState = {
  loading: false,
  error: false,
  list: [],
  count: 0,
  deviceDelete: {
    id: null,
    loading: false,
    success: false,
    error: false,
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case constants.FETCH_DEVICES_PENDING: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case constants.FETCH_DEVICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        list: action.payload.devices,
        count: action.payload.count,
      };
    }

    case constants.FETCH_DEVICES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case constants.DELETE_DEVICE_PENDING: {
      const { id } = payload;
      return {
        ...state,
        deviceDelete: {
          id,
          loading: true,
        },
      };
    }

    case constants.DELETE_DEVICE_SUCCESS: {
      return {
        ...state,
        list: state.list.filter(i => i.id !== action.payload.id),
        count: state.count - 1,
        deviceDelete: {
          ...state.deviceDelete,
          loading: false,
          success: true,
        },
      };
    }

    case constants.DELETE_DEVICE_FAILURE: {
      return {
        ...state,
        deviceDelete: {
          ...state.deviceDelete,
          loading: false,
          success: false,
          error: true,
        },
      };
    }

    default:
      return state;
  }
}

export default reducer;
