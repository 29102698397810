import { constants } from './actions';

const initialState = {
  default: {
    cdn: '',
    loading: false,
    error: false,
    isMoreContentAvailable: true,
    data: [],
  },
  contentTypes: [],
  cdn: {},
  count: 0,
  limit: 50,
  searchTerm: '',
  loading: false,
  success: false,
  error: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_SEARCH_TERM: {
      const { term } = action.payload;
      return {
        ...state,
        searchTerm: term,
      };
    }
    case constants.SEARCH_CONTENT_TYPES_PENDING: {
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
    }

    case constants.SEARCH_CONTENT_TYPES_SUCCESS: {
      const { data, cdn, count, searchTerm } = action.payload;
      return {
        ...state,
        contentTypes: [...data],
        cdn: {
          ...cdn,
        },
        count,
        searchTerm,
        loading: false,
        success: true,
        error: false,
      };
    }

    case constants.SEARCH_CONTENT_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    }

    case constants.SEARCH_CONTENT_TYPES_RESET: {
      return {
        ...state,
        contentTypes: [],
        cdn: {},
        count: 0,
      };
    }

    case constants.FETCH_DEFAULT_SEARCH_CONTENT_PENDING: {
      return {
        ...state,
        default: {
          ...state.default,
          loading: true,
          error: false,
        },
      };
    }

    case constants.FETCH_DEFAULT_SEARCH_CONTENT_SUCCESS: {
      return {
        ...state,
        default: {
          loading: false,
          error: false,
          cdn: action.payload.cdn,
          isMoreContentAvailable: action.payload.isMoreContentAvailable,
          data: [...state.default.data, ...action.payload.data],
        },
      };
    }

    case constants.FETCH_DEFAULT_SEARCH_CONTENT_FAILURE: {
      return {
        ...state,
        default: {
          ...state.default,
          loading: false,
          error: false,
        },
      };
    }

    default:
      return state;
  }
}

export default reducer;
