export const navigate = ({ path, props }) => {
  return {
    type: "NAVIGATE",
    path,
    props,
  };
};

export const dummy = () => {
  return {
    type: "DUMMY",
  };
};

export const setFocusedContent = (focusedContent) => {
  return {
    type: "FOCUSED_CONTENT",
    focusedContent,
  };
};

export const setMagicMouseState = (state) => {
  return {
    type: "SET_MAGIC_MOUSE_STATE",
    magicMouseState: state,
  };
};

export const setMenuIndex = (menuIndex) => {
	return {
		type: "SET_MENU_INDEX",
		menuIndex,
	};
};

