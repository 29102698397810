// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src_App_App_app {\n  /* styles can be put here */\n  background: #060818;\n}\n", "",{"version":3,"sources":["webpack://./src/App/App.module.less"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC5B,mBAAA;AACD","sourcesContent":[".app {\n\t/* styles can be put here */\n\tbackground: #060818;\n}\n\n@__DEV__: true;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "src_App_App_app"
};
export default ___CSS_LOADER_EXPORT___;
