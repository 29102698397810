import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import ri from "@enact/ui/resolution";
import BodyText, { BodyTextBase } from "@enact/sandstone/BodyText";
import Item from "@enact/sandstone/Item";
import Image from "@enact/sandstone/Image";
import Spottable from "@enact/spotlight/Spottable";
import { SpottableComponent } from "../SectionPanel";

const HomeBanner = ({ focussedContent }) => {
  const cdnInfo = useSelector((state) => state.storefront.config.data?.cdnInfo);
  const magicMouseState = useSelector(
    (state) => state.navigate.magicMouseState
  );
  const uhd = window.innerWidth > 1280 && window.innerHeight > 720;

  const renderShimmer = () => {
    return (
      <div
        className={
          "flex flex-row items-start justify-start space-x-8 transfrom-all duration-500 pt-16"
        }
        style={{ width: ri.scale(1200), height: ri.scale(500) }}
      >
        <div
          className="w-full rounded-4 bg-shimmerBg animate-pulse md:mb-40 box-border inline-flex"
          style={{ height: ri.scale(500) }}
        />

        <div className="hidden space-x-8 md:flex">
          <div
            className="md:w-[96vw] rounded-4 bg-shimmerBg animate-pulse"
            style={{ height: ri.scale(500) }}
          />
        </div>
      </div>
    );
  };
  const renderContent = () => {
    const content = focussedContent;
    const coverImageUrl =
      focussedContent.bannerImage?.coverUrl ||
      content?.coverimage?.[0].coverUrl;
    const _coverImageUrl = `https://${
      cdnInfo?.imageCdn.url ||
      content?.coverimage?.[0].cdnDomainName ||
      content?.coverImagePortrait?.[0].cdnDomainName
    }/${coverImageUrl}?width=1920`;
    const placeHolder = `https://${
      cdnInfo?.imageCdn.url ||
      content?.coverimage?.[0].cdnDomainName ||
      content?.coverImagePortrait?.[0].cdnDomainName
    }/${coverImageUrl}?width=380`;
    return (
      <Item
        className="w-full h-full m-0 p-0 justify-center"
        inline
        style={{ maxWidth: "96vw" }} // backgroundImage: `url(${_coverImageUrl})`
        onSpotlightDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!magicMouseState && focussedContent) {
            document
              .getElementById(
                focussedContent?.id + "-" + focussedContent?.sectionIndex
              )
              .focus();
          }
        }}
      >
        {_coverImageUrl ? (
          <Image
            placeholder={placeHolder}
            src={_coverImageUrl}
            style={{ width: ri.scale(1200), height: ri.scale(500) }}
          />
        ) : (
          renderShimmer()
        )}
        {renderDetails(content)}
      </Item>
    );
  };

  const renderDescription = (content) => {
    if (!content?.description) return null;

    return (
      <div
        className="md:line-clamp-4 m-0 leading-6 text-mainText max-w-sm"
        style={{ color: "gray" }}
      >
        <BodyText>{content.description}</BodyText>
      </div>
    );
  };

  const renderTitle = (content) => {
    return (
      <div className="flex flex-shrink-0 justify-start font-semibold">
        <BodyText variant="semibold">{content?.title}</BodyText>
        {/* {renderMaturityRating()} */}
      </div>
    );
  };

  const renderMetaData = (content) => {
    return (
      <div className="flex flex-shrink-0 justify-start">
        <BodyTextBase
          className="font-roboto-flex text-[1.25rem] font-normal leading-6 mx-5"
          style={{ color: "white" }}
        >
          <span className="dot-before"></span>
          {content?.releasedDate?.slice(0, 4)}
        </BodyTextBase>
        <BodyTextBase
          className="font-roboto-flex text-[1.25rem] font-normal leading-6 mx-5"
          style={{ color: "white" }}
        >
          <span className="dot-before"></span>
          {content?.actualMedia?.[0]?.duration}
        </BodyTextBase>
        <BodyTextBase
          className="font-roboto-flex text-[1.25rem] font-normal leading-6 mx-5"
          style={{ color: "white" }}
        >
          <span className="dot-before"></span>
          {content?.language === "none" || "undefined" ? "" : content?.language}
        </BodyTextBase>
        <BodyTextBase
          className="font-roboto-flex text-[1.25rem] font-normal leading-6 mx-5"
          style={{ color: "white" }}
        >
          <span className="dot-before"></span>
          {`${
            content?.maturityRating === "none" || "undefined"
              ? ""
              : `${content?.maturityRating}+`
          }`}
        </BodyTextBase>
      </div>
    );
  };

  const renderDetails = (content) => {
    return (
      <div
        className={cx(
          "z-99 hidden xs:px-20 pr-60 pt-160 w-full h-full top-0 bottom-0 left-0",
          "bg-banner-to-t",
          "md:flex items-start justify-start select-none sm:justify-start absolute opacity-90"
        )}
      >
        <div
          className={
            "pl-32 pt-32 flex flex-col gap-12 sm:gap-12 justify-end max-w-md"
          }
        >
          {renderTitle(content)}
          {renderMetaData(content)}
          {renderDescription(content)}
          {/* {renderWatchNow(content)} */}
        </div>
      </div>
    );
  };
  const renderPageBanner = () => {
    return (
      // <Panel>
      <>{focussedContent && renderContent()}</>
      // </Panel>
    );
  };

  return renderPageBanner();
};

export default React.memo(Spottable({ emulateMouse: false }, HomeBanner));
