/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import Scroller from "@enact/sandstone/Scroller";
import pageActions from "../../store/modules/pages/actions";
import BodyText, { BodyTextBase } from "@enact/sandstone/BodyText";
import { VirtualList } from "@enact/sandstone/VirtualList";
import ri from "@enact/ui/resolution";
import Item from "@enact/sandstone/Item";
import Image from "@enact/sandstone/Image";
import Spottable from "@enact/spotlight/Spottable";
import { navigate } from "../../store/modules/navigate/actions";
import { useRef, useState } from "react";
import { getContentTypeFromQueryName } from "../../lib/utilities";
import HomeBanner from "./HomeBanner";

const Carousel = ({
  currentItem,
  id,
  containerStyle,
  path,
  width,
  height,
  onItemPress,
  onImageLoad,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { rtl } = rest;
  const cdnInfo = useSelector((state) => state.storefront.config.data?.cdnInfo);
  const banner = useSelector(
    (state) => state.pages.bannerCollectionsBySlug?.[path]
  );
  const storefrontConfig = useSelector((state) => state.storefront.config);
  const activeProfile = useSelector(
    (state) => state.auth.profiles.activeProfile
  );
  const focusedContent = useSelector((state) => state.navigate.focusedContent);
  const uhd = window.innerWidth > 1280 && window.innerHeight > 720;

  useEffect(() => {
    if (!banner)
      dispatch(
        pageActions.fetchBannerDataPending({
          id,
          path,
          locale:
            activeProfile?.language.storefront ||
            storefrontConfig.data?.defaultDisplayLanguage.current,
        })
      );
  }, [id, path]);
  // useEffect(() => {
  //   if(Spotlight.getCurrent()){
  //     const spot = Object.values(Spotlight.getCurrent())?.[1]?.['data-spotlight-id'];
  //     if(spot) setFocused(false);
  //   }

  // })
  const [current, setCurrent] = useState(0);
  let timer = useRef(null);
  useEffect(() => {
    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, []);
  const currentSlide = useRef(current);
  useEffect(() => {
    currentSlide.current = current;
  }, [current]);
  const renderShimmer = () => {
    return (
      <div
        className={
          "flex flex-row items-start justify-start space-x-8 transfrom-all duration-500 pt-16"
        }
        style={{ width: ri.scale(1200), height: ri.scale(500) }}
      >
        <div
          className="w-full rounded-4 bg-shimmerBg animate-pulse md:mb-40 box-border inline-flex"
          style={{ height: ri.scale(500) }}
        />

        <div className="hidden space-x-8 md:flex">
          <div
            className="md:w-[96vw] rounded-4 bg-shimmerBg animate-pulse"
            style={{ height: ri.scale(500) }}
          />
        </div>
      </div>
    );
  };
  const renderContent = ({ index, ...rest }) => {
    // const isLast = index === banner.data.length - 1;
    const content = banner.data[index]?.contentData;
    const coverImageUrl =
      banner.data[index]?.bannerImage?.coverUrl ||
      content?.coverimage?.[0].coverUrl;
      const _coverImageUrl = `https://${
        cdnInfo?.imageCdn.url ||
        content?.coverimage?.[0].cdnDomainName ||
        content?.coverImagePortrait?.[0].cdnDomainName
      }/${coverImageUrl}?width=1920`;
      const placeHolder = `https://${
        cdnInfo?.imageCdn.url ||
        content?.coverimage?.[0].cdnDomainName ||
        content?.coverImagePortrait?.[0].cdnDomainName
      }/${coverImageUrl}?width=380`;
    return (
      <Item
        {...rest}
        className="w-full h-full m-0 p-0 justify-center"
        inline
        onClick={() => watchNowHandler(content)}
        onFocus={() => setCurrent(index)}
        style={{ maxWidth: "96vw" }} // backgroundImage: `url(${_coverImageUrl})`
      >
        {_coverImageUrl ? (
          <Image
          placeholder={placeHolder}
            src={_coverImageUrl}
            style={{
              width: ri.scale(1200),
              height: ri.scale(565),
              backgroundPosition: rtl ? "initial" : "right",
            }}
          />
        ) : (
          renderShimmer()
        )}
        {renderDetails(content)}
      </Item>
    );
  };
  const renderBanners = () => {
    return (
      <VirtualList
        // wrap={true}
        // clientSize={{width:1110,height:565}}
        itemSize={ri.scale(1210)}
        dataSize={banner.data.length}
        direction="horizontal"
        itemRenderer={renderContent}
        className="w-full h-360"
        key="translate"
        spacing={5}
        scrollMode="translate"
        id="bannnerlist"
        horizontalScrollbar="hidden"
        noScrollByWheel
        // cbScrollTo={handleScroll}
      />
    );
  };
  const renderList = () => {
    if (!banner) return null;
    if (banner.loading) return renderShimmer();
    if (!banner.data || !banner.data.length) return null;

    return (
      <Scroller
        className={`h-4/5`}
        style={{ width: "95%" }}
        data-spotlight-id="banner"
        direction="horizontal"
      >
        {renderBanners()}
        <div className="flex justify-center mt-3">
          {banner.data.map((_, index) => (
            <div
              key={index}
              className={`w-2 h-2 mx-[0.15rem] rounded-full ${
                current === index ? "bg-primary" : "bg-disabled"
              }`}
            ></div>
          ))}
        </div>
      </Scroller>
    );
  };

  const renderBanner = () => {
    // if (currentItem) return renderSelectedItemAsBanner();
    return renderList();
  };

  const renderDescription = (content) => {
    if (!content?.description) return null;

    return (
      <div
        className="md:line-clamp-4 m-0 leading-6 text-mainText max-w-sm"
        style={{ color: "gray" }}
      >
        <BodyText>{content.description}</BodyText>
      </div>
    );
  };

  const renderTitle = (content) => {
    return (
      <div className="flex flex-shrink-0 justify-start font-semibold">
        <BodyText variant="semibold">{content?.title}</BodyText>
        {/* {renderMaturityRating()} */}
      </div>
    );
  };

  const renderMetaData = (content) => {
    return (
      <div className="flex flex-shrink-0 justify-start">
        <BodyTextBase
          className="font-roboto-flex text-[1.25rem] font-normal leading-6 mx-5"
          style={{ color: "white" }}
        >
          <span className="dot-before"></span>
          {content?.releasedDate?.slice(0, 4)}
        </BodyTextBase>
        <BodyTextBase
          className="font-roboto-flex text-[1.25rem] font-normal leading-6 mx-5"
          style={{ color: "white" }}
        >
          <span className="dot-before"></span>
          {content?.actualMedia?.[0]?.duration}
        </BodyTextBase>
        <BodyTextBase
          className="font-roboto-flex text-[1.25rem] font-normal leading-6 mx-5"
          style={{ color: "white" }}
        >
          <span className="dot-before"></span>
          {content?.language === "none" || "undefined" ? "" : content?.language}
        </BodyTextBase>
        <BodyTextBase
          className="font-roboto-flex text-[1.25rem] font-normal leading-6 mx-5"
          style={{ color: "white" }}
        >
          <span className="dot-before"></span>
          {`${content?.maturityRating}+`}
        </BodyTextBase>
      </div>
    );
  };

  const watchNowHandler = (content) => {
    const contentType = getContentTypeFromQueryName(content.queryName);
    console.log(contentType);
    console.log(content);
    if (contentType === "seasons")
      dispatch(navigate({ path: "detail/seasons", props: { id: content.id } }));
    // else if(contentType === 'CHANNEL' || contentType === ""){
    // const id =
    // const type =
    // const url =
    // if(!content.loading){
    // }
    // dispatch(navigate({path:'play', props:{id:channel?.channelContent?.id, type:channel?.channelContent?.type,url:url}}))
    // }
    else dispatch(navigate({ path: `detail`, props: { id: content.id } }));
  };

  const renderDetails = (content) => {
    const rtl = rest.rtl;
    return (
      <div
        className={cx(
          "z-99 hidden xs:px-20 pr-60 pt-160 w-full h-full top-0 bottom-0 left-0",
          rtl ? "bg-banner-to-t" : "bg-banner-to-t",
          "md:flex items-start justify-start select-none sm:justify-start absolute opacity-90"
        )}
      >
        <div
          className={
            "pl-32 pt-32 flex flex-col gap-12 sm:gap-12 justify-end max-w-md"
          }
        >
          {renderTitle(content)}
          {renderMetaData(content)}
          {renderDescription(content)}
          {/* {renderWatchNow(content)} */}
        </div>
      </div>
    );
  };
  const renderPageBanner = () => {
    return (
      // <Panel>
      renderBanner()
      // </Panel>
    );
  };

  return focusedContent ?
  <HomeBanner focussedContent={focusedContent} /> : renderPageBanner();

};

export default React.memo(Spottable({ emulateMouse: false }, Carousel));
