/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from 'classnames';
import Scroller from "@enact/sandstone/Scroller";
import ri from '@enact/ui/resolution';
import pageActions from "../../store/modules/pages/actions";
import BodyText from "@enact/sandstone/BodyText";
import { VirtualList } from "@enact/sandstone/VirtualList";
import Item from "@enact/sandstone/Item";
import Image from "@enact/sandstone/Image";
import Spottable from "@enact/spotlight/Spottable";
import { navigate } from "../../store/modules/navigate/actions";
import { useRef, useState } from "react";
import { getContentTypeFromQueryName } from '../../lib/utilities';

const Carousel = ({
  currentItem,
  id,
  containerStyle,
  path,
  width,
  height,
  onItemPress,
  onImageLoad,
  ...rest
}) => {
  const dispatch = useDispatch();
  const {rtl} = rest;
  const cdnInfo = useSelector(state => state.storefront.config.data?.cdnInfo);
  const banner = useSelector(
    (state) => state.pages.pageV2Data?.[path]?.bannerData?.bannerContent
  );
  const content = useSelector(
    (state) => state.pages.pageV2Data?.[path]?.bannerData
  );
  const storefrontConfig = useSelector(state => state.storefront.config);
  const activeProfile = useSelector(state => state.auth.profiles.activeProfile);
  const uhd = window.innerWidth > 1280 && window.innerHeight > 720;

  useEffect(() => {
    if (!banner)
      dispatch(
        pageActions.fetchBannerDataPending({
          id,
          path,
          locale: activeProfile?.language.storefront || storefrontConfig.data?.defaultDisplayLanguage.current
        })
      );
  }, [id, path]);
  // useEffect(() => {
      //   if(Spotlight.getCurrent()){
          //     const spot = Object.values(Spotlight.getCurrent())?.[1]?.['data-spotlight-id'];
          //     if(spot) setFocused(false);
          //   }
          
          // })
          const [current,setCurrent] = useState(0);
          const [focused, setFocused] = useState(false);
          let timer = useRef(null);
          useEffect(()=>{
              return ()=>{
                  if(timer.current)
                  clearInterval(timer.current)
            }
        },[])
        const currentSlide = useRef(current);
        useEffect(() => {
            currentSlide.current = current;
        }, [current])
        const renderShimmer = () => {
            return (
                <div
      className={
        'flex flex-col w-screen h-auto items-start justify-start space-x-8 transfrom-all duration-500 md:px-64 px-16 pt-16 md:pt-72'
      }
      >
      <div className="w-full md:h-vh80 h-192 rounded-4 bg-shimmerBg animate-pulse md:mb-40 box-border inline-flex" />

      <div className="hidden space-x-8 md:flex">
          <div
            className="md:w-320 md:h-192 rounded-4 bg-shimmerBg animate-pulse"
            />
      </div>
    </div>
    )
};
const renderContent = ({ index, ...rest }) => {
    const coverImageUrl = banner[index]?.bannerImage?.coverUrl;
    const _coverImageUrl = `https://${cdnInfo?.imageCdn.url}/${coverImageUrl}`;

    return (
      <Item
        {...rest}
        className="w-full h-full m-0 p-0 justify-center"
        inline onClick={() => watchNowHandler(banner)}
        onFocus={()=>setCurrent(index)}
        style={{ maxWidth: "100vw" }}// backgroundImage: `url(${_coverImageUrl})` 
      >
        {_coverImageUrl ?
        <Image
          src={_coverImageUrl}
          style={{ width: ri.scale(1200), height: ri.scale(565), backgroundPosition:(rtl ? 'initial' : 'right')}}
        /> : renderShimmer()}
        {renderDetails(content)}
      </Item>
    );
  };
  function handleScroll(scrollTo){
    timer.current = setInterval(()=>{
      let j = currentSlide.current;
        scrollTo({index:j})
        if(j<banner.length) j++;
        else j=0;
        setCurrent(j)
      },5000);
    }
  const renderBanners = () => {
    return (
      <VirtualList
        wrap={true}
        // clientSize={{width:1110,height:565}}
        itemSize={ri.scale(1210)}
        dataSize={banner.length}
        direction="horizontal"
        itemRenderer={renderContent}
        className="w-full h-360"
        key="translate"
        spacing={5}
        scrollMode='translate'
        id="bannnerlist"
        horizontalScrollbar="hidden"
        cbScrollTo={handleScroll}
      />
    );
  };
  const renderList = () => {
    if (!banner) return null;
    if (banner.loading) return renderShimmer();
    if (!banner || !banner.length) return null;

    return (
      <Scroller
        className={`h-4/5`} style={{width:'100%'}}
        data-spotlight-id="banner"
        direction="horizontal"
      >
        {renderBanners()} 
        <div className="flex justify-center mt-3">
          {banner.map((_, index) => (
            <div
              key={index}
              className={`w-2 h-2 mx-[0.15rem] rounded-full ${current === index+1 ? 'bg-primary' : 'bg-disabled'}`}
            ></div>
          ))}
        </div>
      </Scroller>
    );
  };


  const renderBanner = () => {
    // if (currentItem) return renderSelectedItemAsBanner();
    return renderList();
  };

  const renderDescription = (content) => {
    if (!content?.description) return null;

    return (
      <div
        className="md:line-clamp-4 m-0 leading-6 text-mainText max-w-sm"
        style={{ color: "gray" }}
      >
        <BodyText>{content.description}</BodyText>
      </div>
    );
  };

  const renderTitle = (content) => {
    return (
      <div className="flex flex-shrink-0 justify-start font-semibold">
        <BodyText variant="semibold">{content?.name}</BodyText>
        {/* {renderMaturityRating()} */}
      </div>
    );
  };


  const watchNowHandler = (content) => {
    const contentType = getContentTypeFromQueryName(content.queryName)
    if(contentType === 'seasons')
      dispatch(navigate({path: 'detail/seasons', props: {id:content.id} }))
    else
      dispatch(navigate({path:`detail`,props:{id:content.id}}))
  };


  const renderDetails = (content) => {
    const rtl = rest.rtl;
    return (
      <div className={cx("z-99 hidden xs:px-20 pr-60 pt-160 w-full h-full top-0 bottom-0 left-0",
       rtl ? 'bg-banner-to-t' : 'bg-banner-to-t', 'md:flex items-start justify-start select-none sm:justify-start absolute opacity-90')}>
        <div
          className={
            "pl-32 pt-32 flex flex-col gap-12 sm:gap-12 justify-end max-w-md"
          }
        >
          {renderTitle(content)}
          {/* {renderMetaData(content)} */}
          {renderDescription(content)}
          {/* {renderWatchNow(content)} */}
        </div>
      </div>
    );
  };
  const renderPageBanner = () => {
    return (
      // <Panel>
        renderBanner()
      // </Panel>
    );
  };

  return renderPageBanner();
};

export default React.memo(Spottable({emulateMouse:false},Carousel));
