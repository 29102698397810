import { constants } from './actions';

const initialState = {
  data: [],
  isActive: false,
  loading: false,
  success: false,
  error: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.SHOW_LANGUAGE_MODEL: {
      const { isActive } = action.payload;
      return { ...state, isActive, loading: false };
    }

    case constants.FETCH_LANGUAGE_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case constants.FETCH_LANGUAGE_SUCCESS: {
      const { data } = action.payload;
      return { ...state, data, success: true, loading: false };
    }

    case constants.FETCH_LANGUAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    }

    case constants.UPDATE_LANGUAGE_PENDING: {
      return { ...state, loading: true };
    }

    case constants.UPDATE_LANGUAGE_SUCCESS: {
      const { data } = action.payload;
      return { ...state, selectedLanguages: data, loading: false };
    }

    case constants.UPDATE_LANGUAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

export default reducer;
