import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { VirtualList, VirtualGridList } from '@enact/sandstone/VirtualList';
import {Image} from '@enact/sandstone/Image';
import ri from '@enact/ui/resolution';
import {Item} from '@enact/sandstone/Item';
import playerPlaylistActions from '../store/modules/playlist/actions';
import {getUrlFromContentType, getIdsToFetch} from '../lib/utilities';

import {navigate} from '../store/modules/navigate/actions';
import contentTypeActions from '../store/modules/contents/actions';
import { toIString } from '@enact/i18n/$L';

const Episodes = ({ episodeIds, seasonId, seriesId }) => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const cdnInfo = useSelector(state => state.storefront.config.data?.cdnInfo);
  const {contentsById} = useSelector(state => state?.contents);

  const storefrontConfig = useSelector(state => state.storefront.config);
  const activeProfile = useSelector(state => state.auth.profiles.activeProfile);
  const orientation = 'LANDSCAPE';
  const uhd = window.innerWidth > 1280 && window.innerHeight > 720;
  // Select the ids which need to be fetched
  const idsToFetch = getIdsToFetch(
    contentsById,
    episodeIds
  );

  // Fetches each season for the series
  useEffect(() =>{
        dispatch(
          contentTypeActions.getContentsByIdsPending({
            ids: idsToFetch,
            locale: activeProfile?.language.storefront || storefrontConfig.data?.defaultDisplayLanguage.current
          })
        )
    },[]
  );
  // creating an array of just 10 elements for swiper and tabs
  const limitedSeasonIds = [];
    let items = 0;
    episodeIds.forEach(episodeId => {
    //   if (typeof contentsById[episodeId]?.content?.episodes === 'undefined') return;
      if (items < 10) limitedSeasonIds.push(episodeId);
      items += 1;
    });

  // is Any Season is Loading
//   const areSeasonsLoading = () => {
//     const loadingItems = seasonIds.filter(id => {
//       if (!extraDataByIds[id]) return true;
//       if (extraDataByIds[id].loading) return true;
//       return false;
//     });
//     return !!loadingItems.length;
//   };

  // Don't render anything if any season in loading state
//   if (areSeasonsLoading()) return null;

const onDetailClick = (id) => {
    // console.log(id);
    dispatch(navigate({path:`detail/episode`,props:{id, seasonId, seriesId}}))
  };
  const handleTabChange = newValue => {
    setValue(newValue);
  };

  const renderShimmer = () => {
    return (
      <div
      className={
        'flex flex-col w-full h-auto items-start justify-start space-x-8 transfrom-all duration-500 px-6 pt-16 md:pt-32'
      }
    >
      <div className="w-full h-128 rounded-4 bg-shimmerBg animate-pulse md:mb-40 box-border inline-flex" />

      <div className="hidden space-x-8 md:flex">
          <div
            className="md:w-320 rounded-4 bg-shimmerBg animate-pulse"
          />
      </div>
    </div>
    )
  };
//   const renderSeasonsTabs = () => {
//     const tabs = new Array(limitedSeasonIds.length).fill(0).map((_, index) => {
//       return <Tab key={index} label={`Season ${index + 1}`} />;
//     });

//     return (
//       <Tabs value={value} handleTabChange={handleTabChange}>
//         {tabs}
//       </Tabs>
//     );
//   };
//   const renderTabContent = () => {
//     return (
//       <>
//         {limitedSeasonIds.map((seasonId, idx) => {
//           // Convert [ {id: 'epsId'}, ...] to ['epsId', 'epsId1', ...]
//           const episodeIds = contentTypesByIds[seasonId]?.episodes?.map(
//             ep => ep.id
//           );

//           return (
//             <TabContent
//               value={value}
//               containerClass="bg-bodyBg"
//               index={idx}
//               key={seasonId}
//             >
//               <div className="w-full flex justify-start items-center flex-wrap">
//                 <SeasonEpisodeCard episodeIds={episodeIds} />
//               </div>
//             </TabContent>
//           );
//         })}
//       </>
//     );
//   };
  let imageTypeArr = ['coverimage', 'detailPageBannerImage', 'coverImageForApple', 'coverImagePortrait'];
  const renderItem = ({index, ...rest}) => {
    const width =190
    const cont = contentsById[limitedSeasonIds[index]]?.content;
    const _coverImageUrl =
      // coverImageUrl && cdnDomainName
      //   ? `https://${cdnDomainName}/${coverImageUrl}`
         getUrlFromContentType(
            cont,
            cdnInfo?.imageCdn.url,
            imageTypeArr,
            {
              width: width * 2
            },
          );
    return (
        <Item {...rest} inline style={orientation==='LANDSCAPE' ? {maxWidth:'23rem'} : {maxWidth:'20rem'}}
        className={uhd ? (orientation==='LANDSCAPE' ? "w-136 h-96 m-0 p-0" : "w-128 h-192 m-0 p-0")
         : (orientation==='LANDSCAPE' ? "w-208 h-118 m-0 p-0 selected": "w-184 h-256 m-0 p-0 selected" )}
        onClick={() => {        
          dispatch(playerPlaylistActions.setVideoId({id: cont.id}));
          dispatch(contentTypeActions.getContentWatchTimePending({ id: cont.id }));
          dispatch(navigate({path:`play`,props:{id:cont?.id, seasonId, seriesId}}))
        }} spotlightId={`${cont?.id}-card`}>
          {/* <Image src={_coverImageUrl} style={{height: 184, width: 324}}/> */}
            {_coverImageUrl ? <Image src={_coverImageUrl} style={orientation==='LANDSCAPE' ? {height: 184, width: 324} : {height: 410, width: 260}}/> : renderShimmer()}
        </Item>
    );
  };
  const renderEpisodes = () => {
    return (
        <VirtualList
          itemSize={orientation === 'LANDSCAPE' ? ri.scale(330) : ri.scale(260)}
          dataSize={contentsById[seasonId].content?.episodes?.length}
          direction='horizontal'
          itemRenderer={renderItem}
          className={orientation === 'PORTRAIT' ? 'w-full h-288' : 'w-full h-128'}
          key='translate'
          spacing={5}
          id={contentsById[seriesId]}
          horizontalScrollbar='hidden'
        />
    );
  };

  return (
    <div className="flex flex-col bg-bodyBg z-30 w-full mt-20 md:mt-0">
      {/* <div className="w-full  px-20 md:px-60 "> */}
        {/* {renderSeasonsTabs()}
        {renderTabContent()} */}
      {/* </div> */}
      <div className='md:text-base text-body font-semibold'>{toIString("EPISODES").toString()}</div>
      {renderEpisodes()}
    </div>
  );
};

export default Episodes;
