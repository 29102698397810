import gql from 'graphql-tag';

const fetchVideoPlayerSettings = gql`
  query fetchVideoPlayerSettings {
    fetchVideoPlayerSettings {
      title {
        isOn
        alignment
        text
      }
      logo {
        isOn
        alignment
        linkUrl
        image {
          type
          url
        }
      }
      qualitySelector {
        isOn
      }
      videoInfo {
        title
        description
        uploadedOn
        views
      }
      watermark {
        isOn
        position
        font
        fontSize
        color
        type
        text
        imageResolution
        opacity
        image {
          type
          url
        }
      }
      volume {
        isOn
        layout
        pinVolumeBar
      }
      subtitle {
        isOn
        hideWhenEmpty
        allowEndUserChangeAppearance
        rememberEndUserPreference
        audioSelection
        defaultAppearance
      }
      playbackRate {
        isOn
      }
      afterPlayback {
        selectedOption
        duration
      }
      autoplay {
        isOn
      }
      muted {
        isOn
      }
      seekingTimer {
        isOn
        timer
      }
    }
  }
`;

export default { fetchVideoPlayerSettings };
