export const constants = {
  FETCH_RELATED_CONTENT_PENDING: 'relatedContent/FETCH_RELATED_CONTENT_PENDING',
  FETCH_RELATED_CONTENT_SUCCESS: 'relatedContent/FETCH_RELATED_CONTENT_SUCCESS',
  FETCH_RELATED_CONTENT_FAILURE: 'relatedContent/FETCH_RELATED_CONTENT_FAILURE',

  RESET_RELATED_CONTENTS_STATE: 'relatedContent/RESET_RELATED_CONTENTS_STATE',
};

const fetchRelatedContentPending = payload => ({
  type: constants.FETCH_RELATED_CONTENT_PENDING,
  payload,
});
const fetchRelatedContentSuccess = payload => ({
  type: constants.FETCH_RELATED_CONTENT_SUCCESS,
  payload,
});
const fetchRelatedContentFailure = payload => ({
  type: constants.FETCH_RELATED_CONTENT_FAILURE,
  payload,
});

const resetRelatedContentsState = payload => ({
  type: constants.RESET_RELATED_CONTENTS_STATE,
  payload,
});

export default {
  fetchRelatedContentPending,
  fetchRelatedContentFailure,
  fetchRelatedContentSuccess,
  //
  resetRelatedContentsState,
};
