import { fork, put, call, takeEvery, take } from 'redux-saga/effects';

import relatedContentActions, { constants } from './actions';
import { constants as authConstants } from '../auth/actions';

import queries from './queries';
import { Failure, getCdnDomainName } from '../../../lib/utilities';

const detailsSaga = ({ gqlClient }) => {
  function* fetchRelatedContent({ payload: { id, queryName } }) {
    try {
      const response = yield call(
        [gqlClient, gqlClient.rawRequest],
        queries.relatedContent,
        { id, queryName },
      );

      if (response.errors || !response.data?.related) throw new Failure();

      const { cdn, list, count } = response.data.related;
      const cdnDomainName = getCdnDomainName(cdn);
      yield put(
        relatedContentActions.fetchRelatedContentSuccess({
          list,
          count,
          cdn,
          cdnDomainName,
          queryName,
          id,
        }),
      );
    } catch (e) {
      const errorMessage =
        (e instanceof Failure && e.message) || 'Something went wrong';

      yield put(
        relatedContentActions.fetchRelatedContentFailure({
          id,
          reason: errorMessage,
        }),
      );
    }
  }

  function* fetchRelatedContentWatcher() {
    yield takeEvery(
      constants.FETCH_RELATED_CONTENT_PENDING,
      fetchRelatedContent,
    );
  }

  function* logOutWatcher() {
    yield take(authConstants.LOGOUT_PENDING);
    yield put(relatedContentActions.resetRelatedContentsState());
  }

  function* watcher() {
    yield fork(fetchRelatedContentWatcher);
    yield fork(logOutWatcher);
  }

  return {
    watcher,
  };
};

export default detailsSaga;
