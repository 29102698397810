
const sectionTypes = {
    contentType: 'CONTENT_TYPE',
    contentGroup: 'CONTENT_GROUP',
  };
  
  const homePageSlug = 'home-page';
  
  const swiperSlideDefaultClassNames = {
    slideContainerClass: 'w-256 h-160 relative mr-16 mb-20',
    slideDetailsContainerClass: 'w-256 z-10',
    slideOverlayContainerClass: 'h-160 w-256 z-10',
    slideImageClass: 'h-160 w-256',
  };
  export const SIGN_IN_STEPS = {
    identifier: 0,
    password: 1,
    otpVerification: 2,
    deviceCheck: 3
  };
  const defaultContentTypesLabels = {
    movies: 'movies',
    series: 'series',
    seasons: 'seasons',
    episodes: 'episodes',
    restream: 'RESTREAM',
  };
  
  // TODO:Take it from auth configuration
  export const AUTH_CONFIG = {
    phoneOTPVerificationAllowed: false,
  };
  export const LANGUAGE_CONFIGURATIONS = {
    BOTH: 'BOTH',
    DISPLAY_LANGUAGE: 'DISPLAY_LANGUAGE',
    CONTENT_LANGUAGE: 'CONTENT_LANGUAGE',
    DO_NOT_SHOW: 'DO_NOT_SHOW'
  };
  export const ACCESS_TOKEN = 'revidd-access-token';
  export const REFRESH_TOKEN = 'revidd-refresh-token';
  export const AGE_RESTRICTION_PIN_KEY = 'AGE_RESTRICTION_PIN';
  export const SUBSCRIPTION_FAILED_VIEWED_KEY = 'SUBSCRIPTION_FAILED_VIEWED';
  export const LOGIN_TYPE = 'revidd-login-type';
  
  export const AUTH_VERIFICATION_METHODS = {
    phoneOTPVerification: 'PHONE_OTP_VERIFICATION',
    emailOTPVerification: 'EMAIL_OTP_VERIFICATION',
    passwordVerification: 'PASSWORD_VERIFICATION',
  };
  
  export const AUTH_VERIFICATION_PURPOSES = {
    signIn: 'SIGN_IN',
    verification: 'VERIFICATION',
    signUp: 'SIGN_UP',
  };
  
  export const ROUTES = {
    app: {
      appTabs: 'AppTabs',
      liveChannels: 'LiveChannels',
    },
    auth: {
      signIn: 'SignIn',
      verification: 'AuthVerification',
      forgotPassword: 'ForgotPassword',
      signUp: 'SignUp',
      deviceCheck: 'DeviceCheck',
      countryPicker: 'CountryPicker',
    },
    subscription: {
      subscribe: 'SubscriptionScreen',
    },
    profile: {
      profileStack: 'ProfileStack',
      profileMenus: 'ProfileMenusScreen',
      profile: 'ProfileEdit',
      subscription: 'Subscription', // User subscription
      changePassword: 'ChangePassword',
    },
    payment: {
      razorPayPayment: 'RazorPayPaymentScreen',
      stripeCheckout: 'StripeCheckoutScreen',
      stripePaymentMethods: 'StripePaymentMethodsScreen',
    },
  };
  
  export const OTP_OPTIONS = {
    blockedOTPRequestExpirationTime: 60 * 30, // Default time limit (in seconds) to block user if OTP limits is reached.
    defaultOTPExpiresIn: 60, // Default OTP expire time in seconds
    noOfDigitsInOTP: 4, // Number of digits in OTP
    maxOTPRequests: 3, // Maximum number of OTP requests
    autoSubmitOTPTime: 1000, // Wait time to submit the OTP as long as OTP is auto-detected (in Milliseconds)
    OTPStorageKey: 'rd_auth_int', // Revidd Auth Interaction
  };
  
  export const REGEX = {
    // eslint-disable-next-line
    specialCharacter: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    number: '^[0-9]*$',
  };
  
  export const ERROR_CODES = {
    auth: {
      deviceLimitExceeded: 'auth/DEVICE_LIMITED_EXCEEDED',
    },
  };
  
  export const DYNAMIC_SLUGS = {
    watchHistory: 'watch-history',
    popularVideos: 'popular-videos',
    favorites: 'favorites',
  };
  export const ORIENTATIONS = {
    landscape: 'LANDSCAPE',
    portrait: 'PORTRAIT',
  };
  
  export const SUBSCRIPTION_STATUSES = {
    halted: 'halted',
    invalidOrUnpublishedSubscription: 'INVALID_OR_UNPUBLISHED_SUBSCRIPTION',
    requiresSubscriptionUpgrade: 'REQUIRES_SUBSCRIPTION_UPGRADE',
    noUpgradeOptionAvailable: 'NO_UPGRADE_OPTION_AVAILABLE',
    invalidSubscriptionGroup: 'INVALID_SUBSCRIPTION_GROUP',
    noSubscriptionOptionAvailable: 'NO_SUBSCRIPTION_OPTION_AVAILABLE',
    requiresSubscription: 'REQUIRES_SUBSCRIPTION_SUBSCRIBE',
  };
  
  export const PAYMENT_PROVIDERS = {
    razorpay: 'RAZORPAY',
    stripe: 'STRIPE',
  };
  
  export const PAYMENT_UTILS = {
    cardNumberPlaceholder: '4242 4242 4242 4242',
  };
  
  export const STRIPE_PAYMENT_METHODS = {
    card: 'Card',
  };
  
  export const isShownOnStorefront = 'isShownOnStoreFront';
  
  export const CONTENT_DATA_SORTS = {
    default: 'DEFAULT_LIST',
    ordered: 'ORDERED_LIST',
    shuffled: 'SHUFFELED_LIST',
  };
  
  export const BROWSE_PANEL_STATES = {
    expanded: 'OPENED',
    collapsed: 'COLLAPSED',
    hidden: 'HIDDEN',
  };
  
  // STYLES
  export const PAGE_BANNER_HEIGHT_RATIO = 3.22;
  export const SIDE_BAR_WIDTH_RATIOS = {
    expanded: 0.2,
    collapsed: 0.08,
  };
  export const CONTENT_CARD_BORDER_RADIUS = 12;
  // https://developer.apple.com/design/human-interface-guidelines/tvos/interface-elements/tab-bars/
  export const TAB_BAR_IOS_HEIGHT = 68; // in points
  export const TAB_BAR_TOP_INSET = 46; // in points
  export const NUMBER_OF_CARDS_PER_ROW_IN_VIEW = 5;
  export const CARD_FOCUS_DEBOUNCE_WAIT = 50; // ms
  
  export const FORWARD_REWIND_PERIOD = 10;
  export const FORWARD_REWIND = {
    forward: 'FORWARD',
    rewind: 'REWIND',
  };
  
  export default {
    sectionTypes,
    homePageSlug,
    swiperSlideDefaultClassNames,
    defaultContentTypesLabels,
    ACCESS_TOKEN,
    isShownOnStorefront,
  };
  