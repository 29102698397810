export const constants = {
  GET_STOREFRONT_CONFIG_PENDING: 'storefront/GET_STOREFRONT_CONFIG_PENDING',
  GET_STOREFRONT_CONFIG_SUCCESS: 'storefront/GET_STOREFRONT_CONFIG_SUCCESS',
  GET_STOREFRONT_CONFIG_FAILURE: 'storefront/GET_STOREFRONT_CONFIG_FAILURE',

  FETCH_MONETIZATION_SETTINGS_PENDING:
    'storefront/FETCH_MONETIZATION_SETTINGS_PENDING',
  FETCH_MONETIZATION_SETTINGS_SUCCESS:
    'storefront/FETCH_MONETIZATION_SETTINGS_SUCCESS',
  FETCH_MONETIZATION_SETTINGS_FAILURE:
    'storefront/FETCH_MONETIZATION_SETTINGS_FAILURE',

  RESET_STOREFRONT_STATE: 'storefront/RESET_STOREFRONT_STATE',
};

// ---- GET STOREFRONT CONFIG --- START ------------
const getStorefrontConfigPending = payload => ({
  type: constants.GET_STOREFRONT_CONFIG_PENDING,
  payload,
});

const getStorefrontConfigSuccess = payload => ({
  type: constants.GET_STOREFRONT_CONFIG_SUCCESS,
  payload,
});

const getStorefrontConfigFailure = payload => {
  return {
    type: constants.GET_STOREFRONT_CONFIG_FAILURE,
    payload,
  };
};
// ---- GET STOREFRONT CONFIG --- END ------------

const fetchMonetizationSettingsPending = payload => ({
  type: constants.FETCH_MONETIZATION_SETTINGS_PENDING,
  payload,
});
const fetchMonetizationSettingsSuccess = payload => ({
  type: constants.FETCH_MONETIZATION_SETTINGS_SUCCESS,
  payload,
});
const fetchMonetizationSettingsFailure = payload => ({
  type: constants.FETCH_MONETIZATION_SETTINGS_FAILURE,
  payload,
});

const resetStorefrontState = payload => ({
  type: constants.RESET_STOREFRONT_STATE,
  payload,
});

export default {
  getStorefrontConfigPending,
  getStorefrontConfigSuccess,
  getStorefrontConfigFailure,
  //
  fetchMonetizationSettingsPending,
  fetchMonetizationSettingsSuccess,
  fetchMonetizationSettingsFailure,
  //
  resetStorefrontState,
};
