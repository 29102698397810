import { constants } from './actions';

const initialState = {
  verifyPin: {
    isPinVerified: false,
    loading: false,
    success: false,
    error: false,
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case constants.VERIFY_PIN_PENDING: {
      return {
        ...state,
        verifyPin: {
          ...state.verifyPin,
          loading: true,
          message: '',
        },
      };
    }

    case constants.VERIFY_PIN_SUCCESS: {
      return {
        ...state,
        verifyPin: {
          ...state.verifyPin,
          isPinVerified: true,
          loading: false,
          success: true,
          error: false,
        },
      };
    }

    case constants.VERIFY_PIN_FAILURE: {
      const { reason } = payload;
      return {
        ...state,
        verifyPin: {
          ...state.verifyPin,
          loading: false,
          success: false,
          error: true,
          message: reason,
        },
      };
    }

    case constants.RESET_AGE_RESTRICTION_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default reducer;
