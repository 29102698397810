import { constants } from './actions';

const initialState = {
  videoId: null,
  liveChannel: null, // Live channel content object
  playlist: {
    loading: false,
    success: false,
    error: false,
    message: '',
    //
    media: null,
    avod: null,
    fileHash: null,
    cdnDomainName: null,
  },

  signUrl: {
    data: null,
    loading: false,
    success: false,
    error: false,
    message: '',
  },

  prevNext: {
    next: null,
    previous: null,
    loading: false,
    success: false,
    error: false,
    message: '',
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.FETCH_PLAYER_PLAYLIST_PENDING: {
      return {
        ...state,
        playlist: {
          ...state.playlist,
          loading: true,
        },
      };
    }

    case constants.FETCH_PLAYER_PLAYLIST_SUCCESS: {
      const { data, cdnDomainName } = payload;
      const sources = data[0].actualmedia;
      const avod = data[0];
      let { fileHash } = sources[0];
      if (typeof fileHash === 'undefined') {
        fileHash = '0';
      }
      const { title } = data[0];
      const media = {
        src: `https://${cdnDomainName}/${sources[0].hlsUrl}`,
        poster: `https://${cdnDomainName}/${sources[0].coverUrl}`,
        type: 'application/x-mpegURL',
        title,
      };
      return {
        ...state,
        playlist: {
          ...state.playlist,
          loading: false,
          success: true,
          error: false,
          media,
          avod,
          cdnDomainName,
          fileHash,
        },
      };
    }

    case constants.FETCH_PLAYER_PLAYLIST_FAILURE: {
      return {
        ...initialState,
        playlist: {
          ...initialState.playlist,
          loading: false,
          success: false,
          error: true,
        },
      };
    }

    case constants.SIGN_URL_PENDING: {
      return {
        ...state,
        signUrl: {
          ...state.signUrl,
          loading: true,
        },
      };
    }

    case constants.SIGN_URL_SUCCESS: {
      return {
        ...state,
        signUrl: {
          ...state.signUrl,
          data: payload.data,
          loading: false,
          success: true,
          error: false,
        },
      };
    }

    case constants.SIGN_URL_FAILURE: {
      const { reason } = payload;
      return {
        ...state,
        signUrl: {
          ...state.signUrl,
          data: payload,
          loading: false,
          success: false,
          error: true,
          message: reason,
        },
      };
    }

    case constants.GET_PREVIOUS_AND_NEXT_CONTENT_PENDING: {
      return {
        ...state,
        prevNext: {
          ...state.prevNext,
          loading: true,
        },
      };
    }

    case constants.GET_PREVIOUS_AND_NEXT_CONTENT_SUCCESS: {
      const { next, previous } = payload;
      return {
        ...state,
        prevNext: {
          ...state.prevNext,
          next,
          previous,
          loading: false,
          success: true,
          error: false,
        },
      };
    }

    case constants.GET_PREVIOUS_AND_NEXT_CONTENT_FAILURE: {
      const { reason } = payload;
      return {
        ...state,
        prevNext: {
          ...state.prevNext,
          loading: false,
          success: false,
          error: true,
          message: reason,
        },
      };
    }

    case constants.SET_VIDEO_ID: {
      return {
        ...state,
        videoId: action.payload.id,
      };
    }

    case constants.SET_LIVE_CHANNEL_CONTENT: {
      return {
        ...state,
        liveChannel: action.payload.channel,
      };
    }

    case constants.RESET_PLAYER_PLAYLIST: {
      return initialState;
    }
    default:
      return state;
  }
}

export default reducer;
