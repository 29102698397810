const prefix = 'ageRestriction';

export const constants = {
  VERIFY_PIN_PENDING: `${prefix}/VERIFY_PIN_PENDING`,
  VERIFY_PIN_SUCCESS: `${prefix}/VERIFY_PIN_SUCCESS`,
  VERIFY_PIN_FAILURE: `${prefix}/VERIFY_PIN_FAILURE`,

  RESET_AGE_RESTRICTION_STATE: `${prefix}/RESET_AGE_RESTRICTION_STATE`,
};

const verifyPinPending = payload => {
  return {
    type: constants.VERIFY_PIN_PENDING,
    payload,
  };
};

const verifyPinSuccess = payload => ({
  type: constants.VERIFY_PIN_SUCCESS,
  payload,
});

const verifyPinFailure = payload => ({
  type: constants.VERIFY_PIN_FAILURE,
  payload,
});

const resetAgeRestrictionState = payload => ({
  type: constants.RESET_AGE_RESTRICTION_STATE,
  payload,
});

export default {
  verifyPinPending,
  verifyPinSuccess,
  verifyPinFailure,
  resetAgeRestrictionState,
};
