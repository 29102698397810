import gql from 'graphql-tag';

const fetchLanguages = gql`
  query fetchLanguages($slug: String!) {
    fetchLanguages(slug: $slug) {
      name
      value
      slug
    }
  }
`;
export default { fetchLanguages };
