import { constants } from './actions';

const INITIAL_STATE = {
  loading: false,
  success: false,
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case constants.BOOTSTRAP_PENDING:
      return { ...state, loading: true, success: false };

    case constants.BOOTSTRAP_SUCCESS:
      return { ...state, loading: false, success: true };

    case constants.BOOTSTRAP_FAILURE:
      return { ...state, loading: false, success: false };

    default:
      return state;
  }
}
