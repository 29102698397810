import Item from "@enact/sandstone/Item";
import V2PageView from "./V2PageView";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "../store/modules/navigate/actions";
import { useEffect } from "react";
import pageActions from "../store/modules/pages/actions";
import ImageItem from "@enact/sandstone/ImageItem";
import Scroller from "@enact/sandstone/Scroller";
import { SpottableComponent } from "../components/SpottableComponent";

const HtmlPageView = ({ menu }) => {
  const dispatch = useDispatch();
  const pageConfig = useSelector((state) => state.pages?.pageV2Data);
  useEffect(() => {
    dispatch(
      pageActions.fetchPageInitialDataV2Pending({ path: menu?.htmlPageSlug })
    );
    menu?.children?.map((menuItem) => {
      dispatch(
        pageActions.fetchPageInitialDataV2Pending({
          path: menuItem?.pageSeoSlug,
        })
      );
    });
  }, [dispatch]);
  const handleClick = (slug) => {
    dispatch(navigate({ path: "v2page", props: { slug: slug } }));
  };
  const imagesList = [];
  const fetchPageData = (slug) => {
    console.log(pageConfig?.[slug]?.bannerData?.bannerContent[0]);
    const imageUrl =pageConfig?.[slug]?.bannerData?.bannerContent[0]?.bannerImage?.coverUrl ?pageConfig?.[slug]?.bannerData?.bannerContent[0]?.bannerImage?.coverUrl : pageConfig?.[slug]?.bannerData?.bannerContent[0]?.bannerVideo?.coverUrl; 
    if (imageUrl)
      imagesList.push([
        imageUrl,
        slug,
      ]);
  };

  menu?.children?.map((menuItem) => {
    fetchPageData(menuItem?.pageSeoSlug);
  });
  return (
    <Scroller verticalScrollbar="hidden">
      <div className="grid grid-cols-4 gap-20 p-20">
        {imagesList &&
          imagesList.map(([imageUrl, slug], index) => (
            <SpottableComponent
            className="focus:scale-110 border-8 focus:border-primary border-transparent rounded-[.5rem]"
              onClick={() => {
                handleClick(slug);
              }}
              children={
                <img
                  key={index}
                  src={"https://1577787583.rsc.cdn77.org/" + imageUrl}
                  alt={slug}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    height: "10rem",
                    borderRadius: ".5rem"
                  }}
                />
              }
            ></SpottableComponent>
          ))}
      </div>
    </Scroller>
  );
};

export default HtmlPageView;
