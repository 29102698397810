import { constants } from './actions';

const INITIAL_STATE = {
  open: false,
  contentId: '',
  slug: '',
  screen: '',
};

export default function actionSheet(state = INITIAL_STATE, action) {
  const { payload, type } = action;

  switch (type) {
    case constants.OPEN_ACTION_SHEET:
      return {
        ...state,
        ...payload,
        open: true,
      };

    case constants.CLOSE_ACTION_SHEET:
      return {
        ...state,
        open: false,
        contentId: '',
        slug: '',
        screen: '',
      };
    default:
      return state;
  }
}
