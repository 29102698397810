import { gql } from 'graphql-tag';

const fetchConfig = gql`
  query fetchConfig($locale: String) {
    getConfig(locale: $locale) {
      bannerImageResolution
      cdnInfo {
        videoCdn {
          id
          url
          aliases
          videoContent
          imageContent
        }
        imageCdn {
          id
          url
          aliases
          videoContent
          imageContent
        }
      }
      selectedTheme
      enableAuth
      authDetails {
        providerType
        authProvider
      }
      isAnonymousWithUserDetails
      subscriptionButtonLabelConfig
      subscriptionTrialConfig {
        enable
        trialDays
      }
      certificateUrl
      detailsTabs
      seoConfig
      logoPath
      enableRecurringSubscription
      askUserPhone
      menuItemsAlignment
      validateUserEmail
      faviconPath
      favoritesConfig
      profileLanguageConfig
      socialAuth {
        apple
        facebook
        twitter
        google
      }
      footerLinks
      landingPageEnabled
      maturityRating {
        enable
        cache
        age
      }
      marketing {
        enable
        text
      }
      storeName
      nameQueryNameMappings {
        name
        queryName
      }
      storeFrontLogoSize
      videoRelatedConfig {
        trailerOnDetailPage
        showWatchlistCtaAs
      }
      viewAllDisplayConfig
      access {
        expiresAt
      }
      showPremiumIcon
      showDetailPage
      copyRightsText
      agreementInfo {
        enable
        text
      }
      showChannels {
        showAsMenuItem
      }
      cart {
        enable
      }
      scripts {
        header
        body
        footer
      }
      orgInitStatus
      languageSelectorStyle
      defaultDisplayLanguage
      cardAreaBehaviour
      storeFrontAvatar
      avatarLook
      organizationOwnerEmail
      playerParametersUrl
      # landingPageVolume
      membershipPage {
        enable
        displayOption
        rentalOptionDescription
        pageHeader
        pageSubHeader
        tabsOrder
        freePlanDetails {
          enable
          orderInList
        }
      }
    }
  }
`;

/**
 * This query is used to check whether the
 * org has allowed subscriptions on the content.
 */
const fetchMonetizationSettings = gql`
  query fetchMonetizationSettings {
    fetchMonetizationSettings {
      subscription {
        isSelected
      }
      tvod {
        label
        isSelected
      }
      translations
    }
  }
`;

export default { fetchConfig, fetchMonetizationSettings };
