import { constants } from './actions';

const INITIAL_STATE = {
  settings: {
    data: null,
    loading: false,
    success: false,
    error: false,
    message: '',
  },
};

export default function playerSettings(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.FETCH_PLAYER_SETTINGS_PENDING: {
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: true,
        },
      };
    }

    case constants.FETCH_PLAYER_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          data: payload,
          loading: false,
          success: true,
          error: false,
        },
      };
    }

    case constants.FETCH_PLAYER_SETTINGS_FAILURE: {
      const { reason } = payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: false,
          success: false,
          error: true,
          message: reason,
        },
      };
    }

    default:
      return state;
  }
}
