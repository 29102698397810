import backbutton from './backbutton.svg';
import eye from './eye-regular.svg';
import eye_slash from './eye-slash-regular.svg';
import trash from './trash.svg';
import reviddLogo from '../nichetv_logo.png';
import reviddSplash from '../nichetv_splash.png';

const assets = {
  eye,
  eye_slash,
  backbutton,
  trash,
  reviddLogo,
  reviddSplash
};

export default assets;
