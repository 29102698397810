export const constants = {
  EMAIL_FORGOT_PASSWORD_PENDING: 'forgotPassword/EMAIL_FORGOT_PASSWORD_PENDING',
  EMAIL_FORGOT_PASSWORD_SUCCESS: 'forgotPassword/EMAIL_FORGOT_PASSWORD_SUCCESS',
  EMAIL_FORGOT_PASSWORD_FAILURE: 'forgotPassword/EMAIL_FORGOT_PASSWORD_FAILURE',

  RESET_STATE: 'forgotPassword/RESET_STATE',
};

export const emailForgotPasswordPending = ({ email, route }) => {
  return {
    type: constants.EMAIL_FORGOT_PASSWORD_PENDING,
    payload: { email, route },
  };
};

export const emailForgotPasswordSuccess = ({ message }) => ({
  type: constants.EMAIL_FORGOT_PASSWORD_SUCCESS,
  payload: { message },
});

export const emailForgotPasswordFailure = ({ message }) => ({
  type: constants.EMAIL_FORGOT_PASSWORD_FAILURE,
  payload: { message },
});

export const resetState = () => {
  return {
    type: constants.RESET_STATE,
  };
};

export default {
  emailForgotPasswordPending,
  emailForgotPasswordSuccess,
  emailForgotPasswordFailure,

  resetState,
};
