export const constants = {
  FETCH_LANGUAGE_PENDING: 'language/FETCH_LANGUAGE_PENDING',
  FETCH_LANGUAGE_SUCCESS: 'language/FETCH_LANGUAGE_SUCCESS',
  FETCH_LANGUAGE_FAILURE: 'language/FETCH_LANGUAGE_FAILURE',

  UPDATE_SELECTED_LANGUAGE_PENDING: 'language/UPDATE_SELECTED_LANGUAGE_PENDING',
  UPDATE_SELECTED_LANGUAGE_SUCCESS: 'language/UPDATE_SELECTED_LANGUAGE_SUCCESS',
  UPDATE_SELECTED_LANGUAGE_FAILURE: 'language/UPDATE_SELECTED_LANGUAGE_FAILURE',

  SHOW_LANGUAGE_MODEL: 'language/SHOW_LANGUAGE_MODEL',
};

const showLanguageModel = ({ isActive }) => ({
  type: constants.SHOW_LANGUAGE_MODEL,
  payload: { isActive },
});

const fetchLanguagePending = ({ path }) => {
  return {
    type: constants.FETCH_LANGUAGE_PENDING,
    payload: { path },
  };
};

const fetchLanguageSuccess = ({ path, data }) => ({
  type: constants.FETCH_LANGUAGE_SUCCESS,
  payload: { path, data },
});

const fetchLanguageFailure = () => ({
  type: constants.FETCH_LANGUAGE_FAILURE,
});

// Update selected language ------------ START ------
const updateSelectedLanguagePending = ({ data }) => ({
  type: constants.UPDATE_SELECTED_LANGUAGE_PENDING,
  payload: { data },
});

const updateSelectedLanguageSuccess = ({ data }) => {
  return {
    type: constants.UPDATE_SELECTED_LANGUAGE_SUCCESS,
    payload: { data },
  };
};

const updateSelectedLanguageFailure = () => ({
  type: constants.UPDATE_SELECTED_LANGUAGE_FAILURE,
});
// Update selected language ------------ END ------

export default {
  fetchLanguagePending,
  fetchLanguageSuccess,
  fetchLanguageFailure,

  updateSelectedLanguagePending,
  updateSelectedLanguageSuccess,
  updateSelectedLanguageFailure,

  showLanguageModel,
};
