import gql from 'graphql-tag';

const getLocationCode = gql`
  query getLocationCode {
    getLocationCode
  }
`;

const getContentTypeDefinitions = gql`
  query getMappings {
    getMappings {
      name
      mappings
    }
  }
`;

export default {
  getLocationCode,
  getContentTypeDefinitions,
};
