import gql from 'graphql-tag';

const addToFavorites = gql`
  mutation addFavorites($contentId: String) {
    addFavorites(contentId: $contentId) {
      cdn {
        domainName
      }
      id
      contentId
      contentMeta
    }
  }
`;

const removeFromFavorites = gql`
  mutation removeFavorite($contentId: String) {
    removeFavorite(contentId: $contentId)
  }
`;

export default {
  addToFavorites,
  removeFromFavorites,
};
