export const constants = {
  GET_SUBSCRIPTIONS_PENDING: 'subscriptions/GET_SUBSCRIPTIONS_PENDING',
  GET_SUBSCRIPTIONS_FAILURE: 'subscriptions/GET_SUBSCRIPTIONS_FAILURE',
  GET_SUBSCRIPTIONS_SUCCESS: 'subscriptions/GET_SUBSCRIPTIONS_SUCCESS',
  //
  GET_UPGRADABLE_SUBSCRIPTIONS_PENDING:
    'subscriptions/GET_UPGRADABLE_SUBSCRIPTIONS_PENDING',
  GET_UPGRADABLE_SUBSCRIPTIONS_FAILURE:
    'subscriptions/GET_UPGRADABLE_SUBSCRIPTIONS_FAILURE',
  GET_UPGRADABLE_SUBSCRIPTIONS_SUCCESS:
    'subscriptions/GET_UPGRADABLE_SUBSCRIPTIONS_SUCCESS',

  SUBSCRIPTION_FAILED_INFO_ACCEPTED_PENDING:
    'subscriptions/SUBSCRIPTION_FAILED_INFO_ACCEPTED_PENDING',
  SUBSCRIPTION_FAILED_INFO_ACCEPTED_SUCCESS:
    'subscriptions/SUBSCRIPTION_FAILED_INFO_ACCEPTED_SUCCESS',
  SUBSCRIPTION_FAILED_INFO_ACCEPTED_FAILURE:
    'subscriptions/SUBSCRIPTION_FAILED_INFO_ACCEPTED_FAILURE',

  RESET_SUBSCRIPTIONS: 'subscriptions/RESET_SUBSCRIPTIONS',
  RESET_UPGRADABLE_SUBSCRIPTIONS:
    'subscriptions/RESET_UPGRADABLE_SUBSCRIPTIONS',
  RESET_SUBSCRIPTION_STATE: 'subscriptions/RESET_SUBSCRIPTION_STATE',

  FETCH_VIEWERS_PENDING: 'subscriptions/FETCH_VIEWERS_PENDING',
  FETCH_VIEWERS_SUCCESS: 'subscriptions/FETCH_VIEWERS_SUCCESS',
  FETCH_VIEWERS_FAILURE: 'subscriptions/FETCH_VIEWERS_FAILURE'
};

/// ---------------- GET_SUBSCRIPTIONS START -------
const getSubscriptionsPending = payload => ({
  type: constants.GET_SUBSCRIPTIONS_PENDING,
  payload,
});
const getSubscriptionsSuccess = payload => ({
  type: constants.GET_SUBSCRIPTIONS_SUCCESS,
  payload,
});
const getSubscriptionsFailure = payload => ({
  type: constants.GET_SUBSCRIPTIONS_FAILURE,
  payload,
});
/// ---------------- GET_SUBSCRIPTIONS END -------

/// ---------------- GET_UPGRADABLE_SUBSCRIPTIONS START -------
const getUpgradableSubscriptionsPending = payload => ({
  type: constants.GET_UPGRADABLE_SUBSCRIPTIONS_PENDING,
  payload,
});
const getUpgradableSubscriptionsSuccess = payload => ({
  type: constants.GET_UPGRADABLE_SUBSCRIPTIONS_SUCCESS,
  payload,
});
const getUpgradableSubscriptionsFailure = payload => ({
  type: constants.GET_UPGRADABLE_SUBSCRIPTIONS_FAILURE,
  payload,
});
/// ---------------- GET_UPGRADABLE_SUBSCRIPTIONS END -------

/// ---------------- SUBSCRIPTION_FAILED_INFO_ACCEPTED START -------
const subscriptionFailedInfoAcceptedPending = payload => ({
  type: constants.SUBSCRIPTION_FAILED_INFO_ACCEPTED_PENDING,
  payload,
});

const subscriptionFailedInfoAcceptedSuccess = payload => ({
  type: constants.SUBSCRIPTION_FAILED_INFO_ACCEPTED_SUCCESS,
  payload,
});

const subscriptionFailedInfoAcceptedFailure = payload => ({
  type: constants.SUBSCRIPTION_FAILED_INFO_ACCEPTED_FAILURE,
  payload,
});
/// ---------------- SUBSCRIPTION_FAILED_INFO_ACCEPTED END -------

const resetSubscriptions = payload => ({
  type: constants.RESET_SUBSCRIPTIONS,
  payload,
});

const resetUpgradableSubscriptions = payload => ({
  type: constants.RESET_UPGRADABLE_SUBSCRIPTIONS,
  payload,
});

const resetSubscriptionsState = payload => ({
  type: constants.RESET_SUBSCRIPTION_STATE,
  payload,
});

export const fetchViewersPending = () => ({
  type: constants.FETCH_VIEWERS_PENDING
});

export const fetchViewersSuccess = payload => ({
  type: constants.FETCH_VIEWERS_SUCCESS,
  payload
});

export const fetchViewerFailure = () => ({
  type: constants.FETCH_VIEWERS_FAILURE
});

export default {
  getSubscriptionsPending,
  getSubscriptionsSuccess,
  getSubscriptionsFailure,
  //
  getUpgradableSubscriptionsPending,
  getUpgradableSubscriptionsSuccess,
  getUpgradableSubscriptionsFailure,
  //
  subscriptionFailedInfoAcceptedPending,
  subscriptionFailedInfoAcceptedSuccess,
  subscriptionFailedInfoAcceptedFailure,
  //
  resetSubscriptions,
  resetUpgradableSubscriptions,
  resetSubscriptionsState,
  fetchViewersPending,
  fetchViewersSuccess,
  fetchViewerFailure
};
