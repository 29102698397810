export const constants = {
  FETCH_OTHER_CONTENTS_PENDING: 'otherContents/FETCH_OTHER_CONTENTS_PENDING',
  FETCH_OTHER_CONTENTS_SUCCESS: 'otherContents/FETCH_OTHER_CONTENTS_SUCCESS',
  FETCH_OTHER_CONTENTS_FAILURE: 'otherContents/FETCH_OTHER_CONTENTS_FAILURE',
};

const fetchOtherContentsPending = ({ ids }) => ({
  type: constants.FETCH_OTHER_CONTENTS_PENDING,
  payload: { ids },
});

const fetchOtherContentsSuccess = ({ data }) => ({
  type: constants.FETCH_OTHER_CONTENTS_SUCCESS,
  payload: { data },
});

const fetchOtherContentsFailure = () => ({
  type: constants.FETCH_OTHER_CONTENTS_FAILURE,
});

export default {
  fetchOtherContentsPending,
  fetchOtherContentsFailure,
  fetchOtherContentsSuccess,
};
