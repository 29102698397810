import { toQueryString } from './utilities';

export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
export const playerUrl = process.env.REACT_APP_PLAYER_URL;

export const assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
export const username = process.env.REACT_APP_BASIC_AUTH_USERNAME;
export const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD;
export const orgId = process.env.REACT_APP_ORG_ID;
export const getConfigurationUrl = process.env.REACT_APP_CONFIGURATION_ENDPOINT;

export const getTokenUrl = ({ refreshToken }) =>
  `/v1/mobile/token?rt=${refreshToken}`;

export const getUserExistence = params =>
  `/v1/userexists${toQueryString(params)}`;

export const requestOTP = params => `/v1/otpreq${toQueryString(params)}`;

// Auth urls
export const authV1SignIn = () => '/v1/signin';
export const authV1SignUp = () => '/v1/signup';

export const authV2SignIn = () => '/v2/signin';
export const authV2SignUp = () => '/v2/signup';

export const authV1ChangePassword = () => '/v1/auth/password';

export const signOut = () => '/v1/signout';

export const verifyDeviceV1 = () => `/v1/device/verify`;

export const uploadProfileImage = () => `/v1/user/avatar`;

export const verifyPassword = () => 'v1/user/verify';

export const setOrVerifyPin = () => 'v1/user/pin';

export const sendResetPasswordEmail = (email, route) =>
  `/v1/reset?email=${email}&route=${route}`;

export const getMenuThemeIconUrl = (theme, iconUrl) => {
  return `${assetsBaseUrl}revidd_menu_icons/${theme}/${iconUrl}`;
};
