import { fork, take, put, call, takeEvery } from 'redux-saga/effects';

import queries from './queries';

import { constants as authConstants } from '../auth/actions';
import paymentActions, {
  constants as paymentConstants,
} from './actions';

import { Failure } from '../../../lib/utilities';

const paymentSaga = ({ gqlClient }) => {
  function* _getPaymentProviderWatcher() {
    try {
      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.paymentProvider,
      });

      if (response.errors || !response.data?.paymentProvider)
        throw new Failure();

      const { paymentProvider } = response.data;

      yield put(paymentActions.getPaymentProviderSuccess({ paymentProvider }));
    } catch (e) {
      yield put(paymentActions.getPaymentProviderFailure());
    }
  }

  function* getPaymentProviderWatcher() {
    yield takeEvery(
      paymentConstants.GET_PAYMENT_PROVIDER_PENDING,
      _getPaymentProviderWatcher,
    );
  }

  function* logOutWatcher() {
    yield take(authConstants.LOGOUT_PENDING);
    yield put(paymentActions.resetPaymentState());
  }

  function* watcher() {
    yield fork(getPaymentProviderWatcher);
    yield fork(logOutWatcher);
  }

  return { watcher };
};

export default paymentSaga;
