import { constants } from './actions';

const INITIAL_STATE = {
  config: {
    data: null,
    loading: false,
    success: false,
    error: false,
  },
  //
  monetization: {
    settings: null,
    loading: false,
    success: false,
    error: false,
    message: '',
  },
};

export default function storefront(state = INITIAL_STATE, action) {
  const { payload, type } = action;

  switch (type) {
    case constants.GET_STOREFRONT_CONFIG_PENDING: {
      return {
        ...state,
        config: {
          ...state.config,
          loading: true,
        },
      };
    }

    case constants.GET_STOREFRONT_CONFIG_SUCCESS: {
      const { config } = payload;
      return {
        ...state,
        config: {
          ...state.config,
          data: config,
          loading: false,
          success: true,
        },
      };
    }

    case constants.GET_STOREFRONT_CONFIG_FAILURE: {
      const { reason } = payload;
      return {
        ...state,
        config: {
          ...state.config,
          loading: false,
          success: false,
          error: true,
          message: reason,
        },
      };
    }

    case constants.FETCH_MONETIZATION_SETTINGS_PENDING: {
      return {
        ...state,
        monetization: {
          ...state.monetization,
          loading: true,
        },
      };
    }

    case constants.FETCH_MONETIZATION_SETTINGS_SUCCESS: {
      return {
        ...state,
        monetization: {
          ...state.monetization,
          loading: false,
          success: true,
          error: false,
          settings: payload,
        },
      };
    }

    case constants.FETCH_MONETIZATION_SETTINGS_FAILURE: {
      const { reason } = payload;
      return {
        ...state,
        monetization: {
          ...state.monetization,
          loading: false,
          success: false,
          error: true,
          message: reason,
        },
      };
    }

    case constants.RESET_STOREFRONT_STATE: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
}
