export const constants = {
  GET_PAYMENT_PROVIDER_PENDING: 'payment/GET_PAYMENT_PROVIDER_PENDING',
  GET_PAYMENT_PROVIDER_FAILURE: 'payment/GET_PAYMENT_PROVIDER_FAILURE',
  GET_PAYMENT_PROVIDER_SUCCESS: 'payment/GET_PAYMENT_PROVIDER_SUCCESS',

  RESET_PAYMENT_STATE: 'payment/RESET_PAYMENT_STATE',
};

// ------------ GET PAYMENT PROVIDER - START ----
const getPaymentProviderPending = payload => ({
  type: constants.GET_PAYMENT_PROVIDER_PENDING,
  payload,
});
const getPaymentProviderSuccess = payload => ({
  type: constants.GET_PAYMENT_PROVIDER_SUCCESS,
  payload,
});
const getPaymentProviderFailure = payload => ({
  type: constants.GET_PAYMENT_PROVIDER_SUCCESS,
  payload,
});
// ------------ GET PAYMENT PROVIDER - END ----

const resetPaymentState = payload => ({
  type: constants.RESET_PAYMENT_STATE,
  payload,
});

export default {
  // ------------ GET PAYMENT PROVIDER - START ----
  getPaymentProviderPending,
  getPaymentProviderSuccess,
  getPaymentProviderFailure,
  // ------------ GET PAYMENT PROVIDER - END ----

  resetPaymentState,
};
