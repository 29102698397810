import Button from '../../components/Buttons/PrimaryButton';
import kind from '@enact/core/kind';
import {Panel, Header} from '@enact/sandstone/Panels';
import cx from 'classnames';
import Dropdown from '@enact/sandstone/Dropdown';
import {InputField} from '@enact/sandstone/Input';
import BodyText from '@enact/sandstone/BodyText';
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import countryCodes from 'country-codes-list';
import * as R from 'ramda';
import {
  REGEX,
  AUTH_VERIFICATION_METHODS,
  AUTH_VERIFICATION_PURPOSES,
  ROUTES,
  ERROR_CODES,
  SIGN_IN_STEPS,
} from '../../lib/constants';
import Devices from '../Profile/ManageDevices';
import { navigate } from '../../store/modules/navigate/actions';
import authActions from '../../store/modules/auth/actions';
import commonActions from '../../store/modules/common/actions'
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import assets from '../../assets/icons';
import Image from "@enact/sandstone/Image";
import { toIString } from '@enact/i18n/$L';

const Login = () => {
	const dispatch = useDispatch();
	const {
	  accessToken,
	  message,
	  loading,
	  isEmailId,
	  identifier,
	  currentSignInStep,
	  userExistence,
	  availableSession
	} = useSelector(state => state.auth);
  
	const {
	  loading: { getLocationCode: getLocationCodePending },
	  locationCode
	} = useSelector(state => state.common);
  
	const { displayLanguages } = []//useSelector(state => state.settings?.language);
	const config = useSelector(state => state.settings?.config);
	const { socialAuth, authDetails } = []//config?.data;
  
	// Search for a country object from country codes list
	const getCountry = (countryCodeString, key = 'countryCode') => {
	  return countryCodes.findOne(key, countryCodeString);
	};
  
	// const [, setAuthenticated] = useAuthContex();
	const countryCodesRef = useRef(null);
	const [country, setCountry] = useState(null);
	const [password, setPassword] = useState('');
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [
	  isCountPhoneCodeListVisible,
	  setIsCountPhoneCodeListVisible
	] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  
	// redirect url
	const authRedirectUrl = '/'// router.query?.authRedirect;
	const isPhoneOTPVerificationAllowed = authDetails?.providerType === 'OTP';
  
	const getIdentifierLabel = () => {
	  if (isEmailId) return identifier;
	  return `+${country.countryCallingCode}${identifier}`;
	};
  
	// Sign in using email/phone and password
	const handleSignInUsingIdentifierAndPassword = e => {
	  e.preventDefault();
	  if (!password) return setErrorMessage(('Password required'));
  
	  setErrorMessage('');
	  const signInPayload = {
		[isEmailId ? 'email' : 'phone']: getIdentifierLabel(),
		isPhoneOTPVerificationAllowed,
		password
	  };
  
	  if (authRedirectUrl) {
		signInPayload.redirectUrl = authRedirectUrl;
	  }
  
	  // dispatch sign in action
	  return dispatch(authActions.signinPending(signInPayload));
	};
  
	// Sign in using phone and OTP handler, the callback of onSubmit of OTPVerifier component
	const handleSignInUsignOTPVerificationWithPhone = otpVerifiedParams => {
	  const { OTP, error: OTPErrorMessage } = otpVerifiedParams;
	  if (OTPErrorMessage) return setErrorMessage(OTPErrorMessage);
  
	  const signInPayload = {
		phone: `+${country.countryCallingCode}${identifier}`,
		isPhoneOTPVerificationAllowed,
		otp: OTP
	  };
  
	  if (authRedirectUrl) {
		signInPayload.redirectUrl = authRedirectUrl;
	  }
  
	  // dispatch sign in action
	  return dispatch(authActions.signinPending(signInPayload));
	};
  
	const validateInput = () => {
	  if (!identifier)
		return isPhoneOTPVerificationAllowed
		  ? ('signup.phoneOTP')
		  : toIString('PLEASE_ENTER_A_VALID_EMAIL_ID').toString();
  
	  const validEmailTest =
		!identifier.match(REGEX.email) && toIString('PLEASE_ENTER_A_VALID_EMAIL_ID').toString();
  
	  const validPhoneTest =
		!identifier.match(REGEX.number) && ('Please enter a valid phone number');
  
	  const identifierValidationTest =
		(!isPhoneOTPVerificationAllowed && validEmailTest) || isEmailId
		  ? validEmailTest
		  : validPhoneTest;
	  return identifierValidationTest;
	};
	/**
	 * Validate input identifier value if it is valid then
	 * Make API call to check if user exists
	 */
	const handleIdentifierCheck = e => {
	  e.preventDefault();

	  const _errorMessage = validateInput();
	  setErrorMessage(_errorMessage || '');
	  if (_errorMessage) return null;
  
	  const payload = {
		[isEmailId ? 'email' : 'phone']: getIdentifierLabel()
	  };
  
	  return dispatch(authActions.checkUserExistencePending(payload));
	};
  
	const handleLogOut = () => {
	  dispatch(authActions.logoutPending());
	};
  
	const handleOnDeviceAvailabilityProceed = () => {
	  dispatch(authActions.fetchAvailableSessionPending());
	};
  
	const handleIdentifierChangeRequest = () => {
	  // Remove password on step change
	  setPassword('');
	  setErrorMessage('');
	  dispatch(authActions.setCurrentSignInStep(SIGN_IN_STEPS.identifier));
	};
  
	const handleSignInUsingPasswordRequest = () => {
	  // Remove password on step change
	  setPassword('');
	  dispatch(authActions.setCurrentSignInStep(SIGN_IN_STEPS.password));
	};
  
	const handleOTPGenerateRequest = () => {
	  // set step to OTP verification
	  dispatch(authActions.setCurrentSignInStep(SIGN_IN_STEPS.otpVerification));
	};
  
	const handleIdentifierChange = value => {
	  /**
	   * Check if value exists and is not a phone number
	   */
	  const _isEmailId = Boolean(value && Number.isNaN(+value));
	  dispatch(
		authActions.setIdentifier({ identifier: value, isEmailId: _isEmailId })
	  );
	};
  
	const handleInputChange = e => {
	  const { name, value } = e;
	  if (name === 'email') {
		handleIdentifierChange(value.toLowerCase());
	  } else if (name === 'password') {
		setPassword(value);
	  }
	};
  
	// Handler to toggle password visibility
	const togglePasswordVisibility = () => {
	  setIsPasswordVisible(!isPasswordVisible);
	};
  
	// Country info with country phone code and flag
	const renderCountryInfo = countryObject => {
	  return (
		<p>
		  {countryObject.flag} +{countryObject.countryCallingCode} (
		  {countryObject.countryCode})
		</p>
	  );
	};
  
	// Render country phone codes list
	const renderCountryPhoneCodeList = () => {
	  if (!isCountPhoneCodeListVisible) return null;
  
	  const _countries = countryCodes
		.all()
		.map(({ flag, countryCallingCode, countryCode: _countryCode }) => ({
		  flag,
		  countryCallingCode,
		  countryCode: _countryCode
		}));
  
	  // Set the selected country at the top of the list
	  const countryIndex = _countries.indexOf(country);
	  if (countryIndex > -1) {
		_countries.splice(countryIndex, 1);
	  }
	  const countries = [country, ..._countries];
  
	  return (
		<div className="absolute right-0">
		  <ul
			className="bg-fieldBg max-h-96 overflow-hidden overflow-y-scroll"
			style={{ minHeight: '40vh' }}
		  >
			{countries.map((_country, index) => (
			  <li key={index} value={_country.countryCallingCode}>
				<Button
				  variant="link"
				  className={cx(
					'm-0 text-left text-mainText p-12 hover:bg-formBg w-full',
					// { 'bg-primaryBtn': index === 0 }
				  )}
				  onClick={() => {
					setCountry(_country);
					setIsCountPhoneCodeListVisible(false);
				  }}
				>
				  {renderCountryInfo(_country)}
				</Button>
			  </li>
			))}
		  </ul>
		</div>
	  );
	};
  
	// Only show country codes if identifier is a phone number
	const renderCountryPhoneCodeLabel = () => {
	  if (!isPhoneOTPVerificationAllowed || !identifier || isEmailId) return null;
  
	  return (
		<Button
		  className="flex flex-row items-center gap-12 py-16 px-6 mr-12 text-placeholderText"
		  variant="link"
		  onClick={() => {
			setIsCountPhoneCodeListVisible(!isCountPhoneCodeListVisible);
		  }}
		>
		  {renderCountryInfo(country)}
		  <img src="/icons/arrow_down.svg" alt="arrow-down" />
		</Button>
	  );
	};
  
	// Identifier input field with phone and country codes
	const renderIdentifierInput = () => {
	  const identifierPlaceholderText = isPhoneOTPVerificationAllowed
		? ('Please enter you email id or phone number')
		: toIString('PLEASE_ENTER_YOUR_EMAIL_ID').toString();
	  return (
		  <InputField
			className="min-w-384 h-36 self-start my-3"
			id="signInEmail"
			autoFocus
			name="email"
			value={identifier}
			placeholder={identifierPlaceholderText}
			onChange={(e) => handleIdentifierChange(e.value.toLowerCase())}
		  />
	  );
	};
  
	// Password input container
	const renderPasswordInput = () => {
	  const icon = !isPasswordVisible ? assets.eye_slash : assets.eye;
	  return (
		  <InputField
			className="min-w-384 h-36 self-start my-3"
			id="signInPassword"
			name="password"
			type={isPasswordVisible ? 'text' : 'password'}
			value={password}
			placeholder={toIString('ENTER_PASSWORD').toString()}
			onChange={(e) => setPassword(e.value)}
			iconAfter={icon}
			onClick={togglePasswordVisibility}
		  />
	  );
	};
  
	const getSignupLink = () => {
	  if (!authRedirectUrl) return '/signup';
  
	  return `/signup?authRedirect=${authRedirectUrl}`;
	};
  
	// Action button to navigate to Sign up page
	const renderSignupAction = () => {
	  return (
		<div className="flex flex-row items-center text-14">
		  <span className="text-placeholderText font-500">
			{('Don’t have an account? ')}&nbsp;
		  </span>
		  {/* <CustomLink href={getSignupLink()}> */}
			<Button variant="link">{('Create Account')}</Button>
		  {/* </CustomLink> */}
		</div>
	  )
	};
  
	const renderForgotPasswordAction = () => {
	  return (
		// <CustomLink href="/forgotPassword">
		  <Button variant="link" label={('Forgot Password')} />
		// </CustomLink>
	  );
	};
  
	// Error message container
	const renderErrorMessage = () => {
	  if (!errorMessage) return null;
	  let msg = errorMessage;
	  if(errorMessage === 'Something went wrong') msg = toIString('SOMETHING_WENT_WRONG').toString();
	  return <p className="text-14 text-warningText">{msg}</p>;
	};
  
	// User email or phone number form
	const renderSignInIdentifierStep = () => {
	  return (
		<form
		  noValidate
		  onSubmit={handleIdentifierCheck}
		  className="flex flex-col items-center"
		>
		  {renderIdentifierInput()}
		  <PrimaryButton
			type="submit"
			className="w-sm min-w-384 h-36 self-center my-3 text-center"
			onClick={handleIdentifierCheck}>
				<p className='text-center'>{toIString('CONTINUE').toString()}</p>
			</PrimaryButton>
		  
		  {/* {renderSignupAction()}
		  {renderForgotPasswordAction()}
		  {renderSocialLoginAction()} */}
		</form>
	  );
	};
  
	// Render Identifier  (email or phone number) info to different steps
	const renderIdentifierInfo = () => {
	  const _identifier = getIdentifierLabel();
	  return (
		<div className="flex items-center gap-6 change change1">
		  <p className="text-14 pl-20 text-mainText">{_identifier}</p>
		  <PrimaryButton
			variant="link"
			onClick={handleIdentifierChangeRequest}
		  >{toIString("CHANGE").toString()}</PrimaryButton>
		</div>
	  );
	};
  
	// Show Send OTP option only if the identifier is a phone number
	const renderOTPRequestForm = () => {
	  if (isEmailId) return null;
	  return (
		<div className="flex flex-col items-center">
		  <p className="text-mainText">{('generic.or')}</p>
		  <Button
			label={('signup.getOTP')}
			variant="link"
			onClick={handleOTPGenerateRequest}
		  />
		</div>
	  );
	};
  
	// Password form container
	const renderSignInPasswordStep = () => {
	  return (
		<form
		  noValidate
		  onSubmit={handleSignInUsingIdentifierAndPassword}
		  className="flex flex-col"
		>
		  {renderIdentifierInfo()}
		  {renderPasswordInput()}
		  <PrimaryButton type="submit" loading={loading} className='w-sm min-w-384 h-36 self-center my-3'
		   onClick={handleSignInUsingIdentifierAndPassword}>
	 		   <p className='text-center'>{toIString("SIGN_IN").toString()}</p>
		  </PrimaryButton>
		  {renderOTPRequestForm()}
		</form>
	  );
	};
  
	// OTP Verification container
	const renderSignInOTPVerificationStep = () => {
	  return (
		<div className="flex flex-col items-center gap-12">
		  {/* <OTPVerifier
			isEmailId={isEmailId}
			identifier={identifier}
			countryCode={country.countryCode}
			loading={loading}
			onIdentifierChange={handleIdentifierChangeRequest}
			onSubmit={handleSignInUsignOTPVerificationWithPhone}
			onResendOTP={() => setErrorMessage('')}
		  /> */}
		  <p className="text-mainText">{('generic.or')}</p>
		  <Button
			variant="link"
			label={('signup.signInWithPassword')}
			onClick={handleSignInUsingPasswordRequest}
		  />
		</div>
	  );
	};
  
	const renderSignInContainer = component => {
	  return (
		<>
		  <div className="w-xs sm:min-w-sm  sm:p-40 flex flex-col gap-12">
			{/* <LanguageModel
			  isActive={isLanguageModalOpen}
			  languageConfig={config?.profileLanguageConfig}
			  displayLanguages={displayLanguages}
			  handleCloseClick={() => {
				setIsLanguageModalOpen(false);
			  }}
			  onDisplayLanguageChange={onDisplayLanguageChange}
			/>
			<NavLogo isPhone={isPhone} /> */}
			<Image src={assets.reviddLogo} style={{width:150,height:150}}/>
			<div className="text-mainText text-18 font-600 leading-relaxed pl-20 mb-24">
			  {toIString('SIGN_IN').toString()}
			</div>
			{renderErrorMessage()}
			{component}
		  </div>
		</>
	  );
	};
  
	const renderDeviceCheckHeader = () => {
	  return (
		<div className="mb-24">
		  <h2 className="text-mainText text-24 font-600 leading-relaxed">
			{toIString('DEVICE_LIMIT').toString()}
		  </h2>
		  {/* {renderErrorMessage()} */}
		  <p className="text-14 text-mainText">{toIString('DEVICE_LIMIT_EXCEEDED_MESSAGE').toString()}</p>
		</div>
	  );
	};
  
	const renderDeviceCheckFooter = () => {
	  return (
		<div className="flex mt-32 gap-12">
		  <Button
			// color="primary"
			variant="outline"
			// label={('signup.logout')}
			className="px-28"
			onClick={handleLogOut}
		  >{toIString('LOGOUT').toString()}</Button>
		  <Button
			// label={('signup.proceed')}
			className="px-28"
			loading={availableSession.loading}
			onClick={handleOnDeviceAvailabilityProceed}
		  >{toIString('CONTINUE').toString()}</Button>
		</div>
	  );
	};
  
	// Different steps based on auth flow
	const renderStepBasedSignIn = () => {
	  switch (currentSignInStep) {
		// Step to enter user email id or phone number
		case SIGN_IN_STEPS.identifier: {
		  return renderSignInContainer(renderSignInIdentifierStep());
		}
		// Step to enter password
		case SIGN_IN_STEPS.password: {
		  return renderSignInContainer(renderSignInPasswordStep());
		}
		// Step to sign in using phone OTP verification
		case SIGN_IN_STEPS.otpVerification: {
		  return renderSignInContainer(renderSignInOTPVerificationStep());
		}
  
		// Shows this screen if user is already logged in and has exceeded maximum number of devices
		case SIGN_IN_STEPS.deviceCheck: {
		  return (
			<div>
			  <div className="flex justify-center">
				{/* <NavLogo isPhone={isPhone} /> */}
			  </div>
			  <div className="w-xs sm:min-w-md sm:p-20 h-screen flex flex-col gap-12 justify-center items-stretch text-center">
				<Devices
				  renderHeader={renderDeviceCheckHeader}
				  renderFooter={renderDeviceCheckFooter}
				/>
			  </div>
			</div>
		  );
		}
  
		default:
		  return null;
	  }
	};

	const openLanguageModel = () => {
	  setIsLanguageModalOpen(true);
	};
  
	const renderLanguageSwitcher = () => {
	  return (
		<img
		  className="cursor-pointer text-mainText font-semibold text-14 p-14 self-end mt-20 mr-52"
		  onClick={openLanguageModel}
		  src="/icons/language.svg"
		  alt="language"
		/>
	  );
	};
	const renderContainer = () => {
	  // Hide form if fetching country and country is yet not set
	//   if (getLocationCodePending || !country) return null;
  
	  return (
		<div className="min-h-screen flex flex-col justify-between items-center">
		  {/* {displayLanguages?.length > 1 && renderLanguageSwitcher()}
		  <div /> */}
		  <div className="h-full w-full flex items-end justify-center my-40">
			{/* {renderMetaTag()} */}
			{renderStepBasedSignIn()}
		  </div>
		  {/* <Footer /> */}
		</div>
	  );
	};
  
	const renderContent = () => {
	  return (
		<>
		  {/* {renderMetaTag()} */}
		  {renderContainer()}
		</>
	  );
	};
  
	// useEffect(() => {
	//   if (accessToken && accessToken.length > 0) {
	// 	setAuthenticated(true);
	//   }
	// }, [accessToken]);
  
	/**
	 * Checks for existence of email or phone number
	 * Set required error message and step
	 */
	useEffect(() => {
	  const checkUserExistence = () => {
		if (
		  currentSignInStep !== SIGN_IN_STEPS.identifier ||
		  userExistence.loading ||
		  validateInput()
		)
		  return;
  
		if (!identifier) return;
  
		let _errorMessage = '';
  
		if (isEmailId)
		  _errorMessage =
			userExistence.existence &&
			!userExistence.existence.email &&
			toIString('EMAIL_NOT_FOUND').toString();
		else
		  _errorMessage =
			userExistence.existence &&
			!userExistence.existence.phone &&
			toIString('PHONE_NOT_FOUND').toString();
  
		setErrorMessage(_errorMessage);
  
		if (!_errorMessage)
		  dispatch(authActions.setCurrentSignInStep(SIGN_IN_STEPS.password));
	  };
  
	  checkUserExistence();
	}, [userExistence.loading]);
  
	// Clear error message on step change and reset sign in flow states from store
	useEffect(() => {
	  return () => {
		  if(currentSignInStep === SIGN_IN_STEPS.deviceCheck)
		dispatch(authActions.resetAuthToInit());
	  };
	}, [currentSignInStep, dispatch]);
  
	// Close the country phone codes list on outside click
	useEffect(() => {
	  window.document.onclick = e => {
		if (
		  countryCodesRef.current &&
		  !countryCodesRef.current.contains(e.target)
		)
		  setIsCountPhoneCodeListVisible(false);
	  };
	});
  
	// Set error message coming from backend
	useEffect(() => {
	  if (!loading && message) {
		setErrorMessage(message);
	  }
	}, [message, loading]);
  
	/**
	 * Get location code from backend and set country object if country is not set
	 */
	useEffect(() => {
	  if (!locationCode) dispatch(commonActions.getLocationCodePending());
	  else if (!country) setCountry(getCountry(locationCode));
	}, [locationCode]);

	useEffect(() => {
		if (message === ERROR_CODES.auth.deviceLimitExceeded) {
		  dispatch(authActions.setCurrentSignInStep(SIGN_IN_STEPS.deviceCheck));
		}
	  }, [message]);	
  
	return renderContent();
};
  

const SignIn = kind({
	name: 'SignInPanel',

	render: ({onNavigate, path, ...rest}) => (
		<Panel {...rest} path={path}>
			<Login/>
		</Panel>
	)
});

export default SignIn;
