import * as R from 'ramda';

import { constants } from './actions';

const initialState = {
  favoriteContents: {
    favoriteContentsById: {},
    favorites: [],
    count: 0,
    isMoreContentAvailable: false,
    success: false,
    error: false,
    message: '',
  },
  addOrRemoveFromFavorites: {
    loading: false,
    success: false,
    error: false,
    message: '',
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.GET_FAVORITE_LIST_PENDING: {
      return {
        ...state,
        favoriteContents: {
          ...state.favoriteContents,
          loading: true,
        },
      };
    }

    case constants.GET_FAVORITE_LIST_SUCCESS: {
      const {
        count,
        isMoreContentAvailable,
        refresh,
        favorites,
        favoriteContentsById,
      } = payload;

      const {
        favorites: existingFavorites,
        favoriteContentsById: existingFavoriteContentsById,
      } = state.favoriteContents;

      const _favorites = refresh
        ? favorites
        : [...existingFavorites, ...favorites];
      const _favoriteContentsById = refresh
        ? favoriteContentsById
        : { ...existingFavoriteContentsById, ...favoriteContentsById };
      return {
        ...state,
        favoriteContents: {
          ...state.favoriteContents,
          favorites: _favorites,
          isMoreContentAvailable,
          count,
          favoriteContentsById: _favoriteContentsById,
          loading: false,
          success: true,
          error: false,
        },
      };
    }

    case constants.GET_FAVORITE_LIST_FAILURE: {
      const { reason } = payload;
      return {
        ...state,
        favoriteContents: {
          ...state.favoriteContents,
          loading: false,
          success: false,
          error: true,
          message: reason,
        },
      };
    }

    case constants.ADD_TO_FAVORITES_PENDING: {
      return {
        ...state,
        addOrRemoveFromFavorites: {
          ...state.addOrRemoveFromFavorites,
          loading: true,
        },
      };
    }

    case constants.ADD_TO_FAVORITES_SUCCESS: {
      const { id, content } = payload;

      const {
        favorites: existingFavorites,
        favoriteContentsById: existingFavoriteContentsById,
      } = state.favoriteContents;

      const _favorites = [...existingFavorites, content];
      const _favoriteContentsById = {
        ...existingFavoriteContentsById,
        [id]: content,
      };

      return {
        ...state,
        addOrRemoveFromFavorites: {
          ...state.addOrRemoveFromFavorites,
          loading: false,
          success: true,
          error: false,
        },
        favoriteContents: {
          ...state.favoriteContents,
          favoriteContentsById: _favoriteContentsById,
          favorites: _favorites,
        },
      };
    }

    case constants.ADD_TO_FAVORITES_FAILURE: {
      const { reason } = payload;

      return {
        addOrRemoveFromFavorites: {
          ...state.addOrRemoveFromFavorites,
          loading: false,
          success: false,
          error: true,
          message: reason,
        },
      };
    }

    case constants.REMOVE_FROM_FAVORITES_PENDING: {
      return {
        ...state,
        addOrRemoveFromFavorites: {
          ...state.addOrRemoveFromFavorites,
          loading: true,
        },
      };
    }

    case constants.REMOVE_FROM_FAVORITES_SUCCESS: {
      const { id } = payload;

      const {
        favorites: existingFavorites,
        favoriteContentsById: existingFavoriteContentsById,
      } = state.favoriteContents;

      const _favoriteContentsById = R.omit([id], existingFavoriteContentsById);

      const _favorites = existingFavorites.filter(
        _content => _content.id !== id,
      );

      return {
        ...state,
        addOrRemoveFromFavorites: {
          ...state.addOrRemoveFromFavorites,
          loading: false,
          success: true,
          error: false,
        },
        favoriteContents: {
          ...state.favoriteContents,
          favoriteContentsById: _favoriteContentsById,
          favorites: _favorites,
        },
      };
    }

    case constants.REMOVE_FROM_FAVORITES_FAILURE: {
      const { reason } = payload;

      return {
        addOrRemoveFromFavorites: {
          ...state.addOrRemoveFromFavorites,
          loading: false,
          success: false,
          error: true,
          message: reason,
        },
      };
    }

    case constants.CHECK_CONTENT_IN_FAVORITES_PENDING: {
      return {
        ...state,
        favoriteContents: {
          ...state.favoriteContents,
          loading: true,
        },
      };
    }

    case constants.CHECK_CONTENT_IN_FAVORITES_SUCCESS: {
      const { id, content, isPresentInFavorites } = payload;
      const {
        favorites: existingFavorites,
        favoriteContentsById: existingFavoriteContentsById,
      } = state.favoriteContents;

      let _favorites = [...existingFavorites, content];
      let _favoriteContentsById = {
        ...existingFavoriteContentsById,
        [id]: content,
      };

      if (!isPresentInFavorites) {
        _favoriteContentsById = R.omit([id], _favoriteContentsById);
        _favorites = existingFavorites.filter(_content => _content.id !== id);
      }

      return {
        ...state,
        favoriteContents: {
          ...state.favoriteContents,
          favoriteContentsById: _favoriteContentsById,
          favorites: _favorites,
          loading: false,
          success: true,
          error: false,
        },
      };
    }

    case constants.CHECK_CONTENT_IN_FAVORITES_FAILURE: {
      const { reason } = payload;
      return {
        ...state,
        favoriteContents: {
          ...state.favoriteContents,
          loading: false,
          success: false,
          error: true,
          message: reason,
        },
      };
    }

    case constants.RESET_FAVORITES_STATE: {
      return initialState;
    }

    default:
      return state;
  }
}

export default reducer;
