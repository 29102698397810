import {
  SpotlightContainerDecorator,
  spotlightDefaultClass,
} from "@enact/spotlight/SpotlightContainerDecorator";
import  { platformBack } from "@enact/webos/application";
import Spotlight from "@enact/spotlight";
import kind from "@enact/core/kind";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DYNAMIC_SLUGS } from "../../lib/constants";
import pageActions from "../../store/modules/pages/actions";
import PageBanner from "../../components/PageBanner";
import Container from "../../views/SectionsList";
import Scroller from "@enact/sandstone/Scroller";

const HomeBase = ({ ...rest }) => {
  const dispatch = useDispatch();
  const storefrontConfig = useSelector((state) => state.storefront.config);
  const scrollRef = useRef(null);
  const activeProfile = useSelector(
    (state) => state.auth.profiles.activeProfile
  );
  const pageConfig = useSelector(
    (state) => state.pages?.pageByPageName?.["home-page"]?.pageConfig
  );
  const favoritesConfig = useSelector((state) => {
    return state.storefront?.config?.data?.favoritesConfig;
  });

  const [currentItem, setCurrentItem] = useState(null);
  const firstSectionItemRef = useRef(null);
  const p = useSelector((state) => state.navigate.props);

  useEffect(() => {
    if (!pageConfig)
      dispatch(
        pageActions.fetchPageInitialDataPending({
          path: "home-page",
          locale:
            activeProfile?.language.storefront ||
            storefrontConfig.data?.defaultDisplayLanguage.current,
        })
      );
    // console.log(p.spoton);
    if (p?.spoton) Spotlight.focus(p.spoton);
  }, [dispatch]);
  let lastScrollPos = 0;

  function handleScroll(e) {
    if (
      (e.deltaY > lastScrollPos || e.keyCode === 40) &&
      pageConfig &&
      pageConfig.isPaginated
    ) {
      dispatch(
        pageActions.updatePaginationData({
          path: "home-page",
          newSections: pageConfig?.restSections?.slice(0, 3),
          restSections: pageConfig?.restSections?.slice(3, Infinity),
        })
      );
    }
    lastScrollPos = e.deltaY;
  }
  useEffect(() => {
    window.document.addEventListener("keydown", handleScroll);
    window.document.addEventListener("wheel", handleScroll);
    return () => {
      window.document.removeEventListener("keydown", handleScroll);
      window.document.removeEventListener("wheel", handleScroll);
    };
  });

  const renderBanners = () => {
    if (!pageConfig?.banner?.id) return null;
    return (
      <PageBanner
        id={pageConfig.banner.id}
        path={"home-page"}
        {...rest}
        currentItem={currentItem}
        // nextFocusDown={findNodeHandle(firstSectionItemRef.current)}
        // width={styles.banners.banner.width}
        // height={styles.banners.banner.height}
        // onItemPress={handleSectionItemPress}
        onImageLoad={() => null}
      />
    );
  };

  const renderSwipers = () => {
    if (!pageConfig?.currentSections) return null;

    const sections = pageConfig?.currentSections || [];

    const banners = [];
    if (pageConfig?.banner?.id) {
      banners.push(pageConfig);
    }

    let favoriteSections = [];
    if (
      favoritesConfig?.enable &&
      favoritesConfig?.layout?.section?.isSelected
    ) {
      favoriteSections = [
        {
          title: favoritesConfig?.displayName,
          dynamicSlug: DYNAMIC_SLUGS.favorites,
        },
      ];
    }

    return (
      <Scroller
        className={`${spotlightDefaultClass} top-[62%] h-[35%] absolute`}
        data-spotlight-id="sections"
        verticalScrollbar="hidden"
        direction="vertical"
        focusableScrollbar
        scrollMode="native"
        hoverToScroll={false}
        cbScrollTo={(fn) => (scrollRef.current = fn)}
      >
        <Container
          page="home-page"
          firstSectionItemRef={firstSectionItemRef}
          sections={[...favoriteSections, ...sections]}
          scrollRef={scrollRef}
          // onItemPress={handleSectionItemPress}
          hasPageBanner={pageConfig?.banner?.id !== null} // onViewMorePress={handleViewMorePress}
          // onItemFocus={handleItemFocus}
        />
      </Scroller>
    );
  };

  return (
    <div
      onKeyDown={(e) => {
        if (e.keyCode === 461) platformBack();
      }}
    >
      {renderBanners()}
      {renderSwipers()}
    </div>
  );
};

const Home = kind({
  name: "HomePanel",

  styles: {
    // css,
    // theme,
    // className: 'app'
  },

  render: ({ onNavigate, path, ...rest }) => <HomeBase {...rest} />,
});

export default SpotlightContainerDecorator(
  { continue5WayHold: true, enterTo: "last-focused", preserveId: true },
  Home
);
