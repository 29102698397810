import gql from 'graphql-tag';

const fetchSubscriptionPlans = gql`
  query fetchSubscriptionPlans(
    $limit: Int
    $skip: Int
    $queryAll: Boolean
    $filter: FilterSubscriptionPlan
  ) {
    fetchSubscriptionPlans(
      limit: $limit
      skip: $skip
      filter: $filter
      queryAll: $queryAll
    ) {
      count
      subscriptionPlans {
        id
        groupId
        name
        isFree
        image {
          type
          url
        }
        description
        details {
          id
          duration
          price
          planId
          priceId
          currencyCode
          tag
          shortDescription
        }
        artefactStatus
        isDefault
        expiresOn
        createdAt
        updatedAt
        rank
        currencyCode
      }
    }

    me {
      userSubscription {
        isFree
        status
        expiryDate
        subscriptionPlan {
          id
          groupId
          rank
          name
          isFree
          currencyCode
          details {
            id
            price
            duration
            planId
            priceId
          }
        }
      }
    }
  }
`;

const fetchUpgradeSubscriptionPlans = gql`
  query fetchUpgradeSubscriptionPlans($limit: Int, $skip: Int) {
    fetchUpgradeSubscriptionPlans(limit: $limit, skip: $skip) {
      id
      groupId
      name
      description
      artefactStatus
      isFree
      currencyCode
      details {
        id
        duration
        currencyCode
        planId
        priceId
        price
        finalPrice
      }
      name
      expiresOn
      updatedAt
      currencyCode
      isFree
      rank
      geoRestrictionProfile
    }
  }
`;

const videoSubscriptionByContentId = gql`
  query($videoSubscriptionsByContentIdId: String) {
    videoSubscriptionsByContentId(id: $videoSubscriptionsByContentIdId) {
      viewers {
        count
        limitExceeds
      }
    }
  }
`;

export default {
  fetchSubscriptionPlans,
  fetchUpgradeSubscriptionPlans,
  videoSubscriptionByContentId
};
