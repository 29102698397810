import VirtualList from "@enact/sandstone/VirtualList";
import ri from "@enact/ui/resolution";
import { Image } from "@enact/sandstone/Image";
import { Item } from "@enact/sandstone/Item";
import Spottable from "@enact/spotlight/Spottable";
import { useRef, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getUrlFromContentType } from "../../lib/utilities";
import {
  navigate,
  dummy,
  setFocusedContent,
} from "../../store/modules/navigate/actions";
import pageActions from "../../store/modules/pages/actions";
import Spotlight from "@enact/spotlight";

const Card = ({ myProp, ...rest }) => {
  return <div {...rest}>{myProp}</div>;
};

export const SpottableComponent = Spottable(Card);

const SectionPanel = (props) => {
  const {
    section,
    path,
    contentTypesObjects,
    sectionIndex,
    setCurrentSection,
    currentSection,
  } = props;
  const dataSource = useSelector(
    (state) =>
      state.pages.pageByPageName[path]?.sectionDataBySectionId?.[
        section?.sectionId
      ]
  );
  const cdnInfo = useSelector((state) => state.storefront.config.data?.cdnInfo);
  const storefrontConfig = useSelector((state) => state.storefront.config);
  const [timeoutId, setTimeoutId] = useState(null);
  const magicMouseState = useSelector(
    (state) => state.navigate.magicMouseState
  );
  const activeProfile = useSelector(
    (state) => state.auth.profiles.activeProfile
  );
  const dispatch = useDispatch();
  const isLandscape = useMemo(
    () => section?.coverImageOrientation === "LANDSCAPE",
    [section]
  );

  // useEffect(()=>{
  //   if(sectionIndex === currentSection){
  //     containerRef.current({ index: 0 });
  //   }
  // },[currentSection]);

  useEffect(() => {
    if (!dataSource && !contentTypesObjects)
      dispatch(
        pageActions.fetchSectionDataPending({
          sectionId: section?.sectionId,
          path,
          contentGroupSlug: section?.contentGroupSlug,
          type: section?.type,
          locale:
            activeProfile?.language.storefront ||
            storefrontConfig.data?.defaultDisplayLanguage.current,
        })
      );
    else dispatch(dummy());
  }, [section]);
  const onDetailClick = (content, isCollectionList) => {
    const id = content?.id;
    if (content.type === "CHANNEL" || content.type === "LIVE_EVENT") {
      const streamUrl = (() => {
        switch (content?.type) {
          case "CHANNEL":
            return content?.restreamHlsUrl;
          case "LIVE_EVENT":
            return content?.sourceLink;
          default:
            return null;
        }
      })();
      dispatch(
        navigate({
          path: "play",
          props: { id: id, type: content?.type, url: streamUrl },
        })
      );
    } else if (isCollectionList) {
      dispatch(
        navigate({
          path: "detail/collections",
          props: { id, path, sectionId: section?.sectionId },
        })
      );
    } else if (section.title === "seasons")
      dispatch(navigate({ path: "detail/seasons", props: { id } }));
    else
      dispatch(
        navigate({
          path: `detail`,
          props: {
            id,
            autoplay: dataSource?.autoplay,
            isOnLoop: dataSource?.isOnLoop,
            firstItemId: dataSource?.data?.[0]?.id,
            contentGroupId: section.coactiveProfilententGroupId,
          },
        })
      );
  };
  const containerRef = useRef(null);

  const onFocusHandler = (index) => {
    if (!magicMouseState) {
      containerRef.current({ index: index });
    } else {
      Spotlight.getCurrent().focus();
    }
    const selectedCard = dataSource?.data?.[index];
    selectedCard["sectionIndex"] = sectionIndex;

    if (sectionIndex === 0) {
      dispatch(setFocusedContent(dataSource?.data?.[index]));
    } else {
      // Clear the previous timeout
      clearTimeout(timeoutId);

      // Set a new timeout
      setTimeoutId(
        setTimeout(() => {
          dispatch(setFocusedContent(dataSource?.data?.[index]));
        }, 1000)
      ); // Adjust the delay time as needed
    }
  };

  const renderShimmer = () => {
    return (
      <div
        className={
          "flex flex-col w-full h-auto items-start justify-start space-x-8 transfrom-all duration-500 px-6 pt-16 md:pt-32"
        }
      >
        <div className="w-full h-128 rounded-4 bg-shimmerBg animate-pulse md:mb-40 box-border inline-flex" />

        <div className="hidden space-x-8 md:flex">
          <div className="md:w-320 rounded-4 bg-shimmerBg animate-pulse" />
        </div>
      </div>
    );
  };

  let imageTypeArr = [
    "coverimage",
    "detailPageBannerImage",
    "coverImageForApple",
    "coverImagePortrait",
  ];
  if (!isLandscape)
    imageTypeArr = [
      "coverImagePortrait",
      "detailPageBannerImage",
      "coverImageForApple",
      "coverimage",
    ];
  if (dataSource?.isCollectionList)
    imageTypeArr = ["coverImageForCard", "coverImageForPortrait"];
  const renderItem = ({ index, ...rest }) => {
    const width = 190;
    const _coverImageUrl = getUrlFromContentType(
      dataSource?.data?.[index] || contentTypesObjects?.[index],
      cdnInfo?.imageCdn.url,
      imageTypeArr,
      {
        width: width * 2,
      },
      dataSource?.isCollectionList
    );
    return (
      <SpottableComponent
        {...rest}
        onSpotlightUp={() => {
          if(sectionIndex === 0) dispatch(setFocusedContent(null));
        }}
        onFocus={() => onFocusHandler(index)}
        className={`m-0 p-0 leading-none scale rounded-4 border-[16px] border-[transparent] focus:border-primary`}
        id={dataSource?.data?.[index]?.id + "-" + sectionIndex}
        inline
        style={{
          height: `${!isLandscape ? "13.8rem" : "8.7rem"}`,
          width: `${!isLandscape ? "10rem" : "15rem"}`,
        }}
        onClick={() =>
          onDetailClick(
            dataSource?.data[index] || contentTypesObjects?.[index],
            dataSource?.isCollectionList
          )
        }
        spotlightId={`${
          dataSource?.data?.[index]?.id || contentTypesObjects?.[index]?.id
        }-card`}
        myProp={
          _coverImageUrl ? (
            <img
              src={_coverImageUrl}
              className="rounded-2 justify-center flex items-center"
              style={{
                height: `${!isLandscape ? "13.2rem" : "8.1rem"}`,
                width: `${!isLandscape ? "9.5rem" : "15rem"}`,
                objectFit: "cover"
              }}
            />
          ) : (
            renderShimmer()
          )
        }
      />
    );
  };

  function onFocusHandlerSectionList() {
    if (!magicMouseState) setCurrentSection(sectionIndex);
  }

  const renderSectionList = () => {
    return (
      <>
        <div
          className="text-[1.2rem] font-semibold mb-[0.5rem]"
          id={section?.sectionId}
        >
          {section.title}
        </div>
        <VirtualList
          spotlightId={section.sectionId}
          itemSize={isLandscape ? ri.scale(250) : ri.scale(170)}
          dataSize={
            contentTypesObjects?.length ||
            dataSource?.totalItemsInSection ||
            dataSource?.data?.length
          }
          direction="horizontal"
          itemRenderer={renderItem}
          className={`${
            isLandscape
              ? "h-[9.2rem] w-full mt-2 flex justify-center items-center"
              : "h-[14.2rem] w-full mt-2 flex justify-center items-center"
          }`}
          key="translate"
          spacing={isLandscape ? ri.scale(10) : ri.scale(5)}
          // id={section.sectionId}
          horizontalScrollbar="hidden"
          onFocus={onFocusHandlerSectionList}
          cbScrollTo={(fn) => (containerRef.current = fn)}
          noScrollByWheel
        />
      </>
    );
  };

  return (
    // <Scroller className={spotlightDefaultClass} data-spotlight-id={section.sectionId} direction="horizontal" className="w-full">
    renderSectionList()
    //  </Scroller>
  );
};
const SectContainer = Spottable({ emulateMouse: false }, SectionPanel);
export default SectContainer;

