export const constants = {
  GET_LOCATION_CODE_PENDING: 'common/GET_LOCATION_CODE_PENDING',
  GET_LOCATION_CODE_SUCCESS: 'common/GET_LOCATION_CODE_SUCCESS',
  GET_LOCATION_CODE_FAILURE: 'common/GET_LOCATION_CODE_FAILURE',

  RESET_LOCATION_CODE: 'common/RESET_LOCATION_CODE',

  FETCH_CONTENT_TYPE_DEFINITIONS_PENDING:
    'common/FETCH_CONTENT_TYPE_DEFINITIONS_PENDING',
  FETCH_CONTENT_TYPE_DEFINITIONS_SUCCESS:
    'common/FETCH_CONTENT_TYPE_DEFINITIONS_SUCCESS',
  FETCH_CONTENT_TYPE_DEFINITIONS_FAILURE:
    'common/FETCH_CONTENT_TYPE_DEFINITIONS_FAILURE',
};

const getLocationCodePending = payload => ({
  type: constants.GET_LOCATION_CODE_PENDING,
  payload,
});

const getLocationCodeSuccess = payload => ({
  type: constants.GET_LOCATION_CODE_SUCCESS,
  payload,
});

const getLocationCodeFailure = payload => ({
  type: constants.GET_LOCATION_CODE_FAILURE,
  payload,
});

const resetLocationCodeState = () => ({
  type: constants.RESET_LOCATION_CODE,
});

const fetchContentTypeDefinitionsPending = () => ({
  type: constants.FETCH_CONTENT_TYPE_DEFINITIONS_PENDING,
});

const fetchContentTypeDefinitionsSuccess = ({ definitions }) => ({
  type: constants.FETCH_CONTENT_TYPE_DEFINITIONS_SUCCESS,
  payload: { definitions },
});

const fetchContentTypeDefinitionsFailure = () => ({
  type: constants.FETCH_CONTENT_TYPE_DEFINITIONS_FAILURE,
});

export default {
  getLocationCodePending,
  getLocationCodeSuccess,
  getLocationCodeFailure,
  resetLocationCodeState,

  fetchContentTypeDefinitionsPending,
  fetchContentTypeDefinitionsSuccess,
  fetchContentTypeDefinitionsFailure,
};
