import { take, put, call, fork, select } from 'redux-saga/effects';

import * as urls from '../../../lib/urls';

import ageRestrictionActions, { constants } from './actions';
import { Failure } from '../../../lib/utilities';
import { AGE_RESTRICTION_PIN_KEY } from '../../../lib/constants';

const ageRestrictionSaga = ({ restClient }) => {
  function* verifyPinWatcher() {
    while (true) {
      try {
        const { payload } = yield take(constants.VERIFY_PIN_PENDING);
        const me = yield select(state => state.auth.user);

        const response = yield call(
          restClient.makeRequest,
          urls.setOrVerifyPin(),
          'POST',
          payload,
        );

        if (!response.ok) throw new Failure(response.data?.message);

        yield put(ageRestrictionActions.verifyPinSuccess());
        // yield call(
        //   [AsyncStorage, AsyncStorage.setItem],
        //   `${AGE_RESTRICTION_PIN_KEY}-${me.id}`,
        //   Date.now().toString(),
        // );
      } catch (e) {
        const errorMessage =
          (e instanceof Failure && e.message) || 'Something went wrong';
        yield put(
          ageRestrictionActions.verifyPinFailure({ reason: errorMessage }),
        );
      }
    }
  }

  function* watcher() {
    yield fork(verifyPinWatcher);
  }

  return {
    watcher,
  };
};

export default ageRestrictionSaga;
