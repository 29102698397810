import React, { useEffect } from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  resizeImage,
  getCdnDataFromCdn,
  getContentTypeFromQueryName,
} from "../lib/utilities";
import BodyText from "@enact/sandstone/BodyText";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import { navigate } from "../store/modules/navigate/actions";
import Image from "@enact/sandstone/Image";
import Spottable from "@enact/spotlight/Spottable";
import { spotlightDefaultClass } from "@enact/spotlight/SpotlightContainerDecorator";
import Spotlight from "@enact/spotlight";
import { toIString } from "@enact/i18n/$L";
import { LOGIN_TYPE } from "../lib/constants";

const BannerWithInfo = ({
  item,
  isRestricted,
  i,
  setBannerLoaded,
  seriesId,
  seasonId,
  autoPlay,
  isOnLoop,
  firstItemId,
  contentGroupId,
  rtl,
}) => {
  const { playlist, videoId, signUrl, prevNext } = useSelector(
    (state) => state.playlist
  );
  const contentWatchTime = useSelector(
    (state) => state.contents.contentWatchTimeById?.[item?.content?.id]
  );
  const cdn = useSelector((state) => state.storefront.config.data?.cdnInfo);
  const cdnInfo =
    item?.content?.coverimage?.[0].cdnDomainName || cdn?.imageCdn?.url;
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      Spotlight.focus(`watch-now-btn`);
    }, 1500);
  }, [item]);
  const loginType = window.localStorage.getItem(LOGIN_TYPE);

  const renderTitle = () => {
    const titleImage = item?.content?.titleImage?.[0];
    if (titleImage?.mediaPath) {
      return (
        <div className="w-full flex items-center">
          <img
            src={`https://${cdnInfo}/${titleImage.mediaPath}`}
            alt={item?.contentData?.title}
            className="w-184 h-73 lg:w-480 lg:h-192"
          />
        </div>
      );
    }

    return (
      <h1 className={cx("font-semibold text-20 max-w-sm")}>
        {item?.content?.title && item.content.title}
      </h1>
    );
  };

  const renderInfo = () => {
    return (
      <div>
        {/* <p className="font-semibold text-tab lg:text-xl my-12">
          {item?.content?.language &&
            item.content.language.toUpperCase()}
        </p> */}
        <BodyText
          className={cx(
            "md:line-clamp-4 line-clamp-1 text-xs lg:text-sm leading-4",
            rtl ? "pl-52" : "pr-52",
            "max-w-sm mt-7 mx-0"
          )}
        >
          {item?.content?.description}
        </BodyText>
      </div>
    );
  };
  const ctaLabel = () => {
    let label = toIString("LOADING");
    if (signUrl?.data?.url) label = toIString("WATCH_NOW");
    if (
      parseInt(contentWatchTime?.watchTime, 10) >=
      parseInt(item?.content?.actualmedia?.[0]?.duration, 10)
    )
      label = toIString("WATCH_AGAIN");
    else if (parseInt(contentWatchTime?.watchTime, 10) > 0)
      label = toIString("CONTINUE_WATCHING");
    else if (Boolean(signUrl?.error)) label = toIString("WATCH_NOW");
    return label.toString();
  };
  const onPlayClick = () => {
    if (loginType !== "TYPE2") {
      const streamUrl = (() => {
        switch (item?.content?.type) {
          case "CHANNEL":
            return item?.content?.restreamHlsUrl;
          case "LIVE_EVENT":
            return item?.content?.sourceLink;
          default:
            return null;
        }
      })();
      dispatch(
        navigate({
          path: "play",
          props: {
            id: item?.content.id,
            seasonId,
            seriesId,
            autoPlay,
            isOnLoop,
            firstItemId,
            contentGroupId,
            type: item?.content?.type,
            url: streamUrl,
          },
        })
      );
    } else {
      dispatch(navigate({ path: "signin", props: {} }));
    }
  };
  return (
    <div
      className="relative flex h-488 bg-headerBg flex-row w-full"
      data-spotlight-id={`${item?.content?.id}-cover`}
    >
      <div
        className={cx(
          "z-20 flex flex-col h-24 self-center text-mainText items-start",
          seriesId ? "justify-center" : ""
        )}
      >
        <div className={cx("absolute", rtl ? "md:pr-96" : "pl-16", "max-w-md")}>
          {renderTitle()}
          {renderInfo()}
          {!isRestricted && (
            <PrimaryButton
              spotlightId={`watch-now-btn`}
              className={`w-min mt-16 px-14 sm:px-24 py-10 sm:py-12 normal-case selected ${spotlightDefaultClass}`}
              disabled={Boolean(signUrl.loading)}
              onClick={onPlayClick}
            >
              {ctaLabel()}
            </PrimaryButton>
          )}
          {/* <a href='https://player.livepush.io/emvSO-96PXs7b'>click me</a> */}
        </div>
      </div>
      <div className="w-full">
        <div
          className={cx(
            "absolute z-10 w-full bg-infobanner-to-t",
            seriesId ? "h-360" : "h-full"
            // rtl ? 'mr-3 bg-infobanner-to-l' : 'ml-3 bg-infobanner-to-r'
          )}
        />
        <Image
          onLoad={i === 0 ? setBannerLoaded : null}
          className={cx(
            "object-cover w-full m-0",
            seriesId ? "h-360" : "h-full"
          )}
          style={{
            transition: "all 1.5s",
          }}
          data-spotlight-id={`${item?.content?.id}-cover`}
          placeholder={resizeImage(
            `https://${cdnInfo}/${
              item?.content?.detailPageBannerImage?.[0]?.coverUrl ||
              item?.content?.coverimage?.[0].coverUrl
            }`,
            {
              width: 380,
            }
          )}
          src={resizeImage(
            `https://${cdnInfo}/${
              item?.content?.detailPageBannerImage?.[0]?.coverUrl ||
              item?.content?.coverimage?.[0].coverUrl
            }`,
            {
              width: parseInt(window?.outerWidth, 10),
            }
          )}
          alt="banner"
        />
      </div>
    </div>
  );
};

export default Spottable(BannerWithInfo);
