import { constants } from './actions';

const initialState = {
  episodesByIds: {
    /*
    [id]: { season: { id, title, episodes } }
    */
  },
  // Used For season fetching state with episodes id as key
  extraDataByIds: {
    // [id]: { loading, success, error }
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    // case HYDRATE: {

    //   return { ...state, ...action.payload.contentTypes };
    // }

    case constants.FETCH_SEASON_BY_EPISODE_PENDING: {
      const idsExtraData = {};
      idsExtraData[action.payload.id] = {
        loading: true,
        success: false,
        error: false,
        errorMessage: null,
      };

      return {
        ...state,
        extraDataByIds: {
          ...state.extraDataByIds,
          ...idsExtraData,
        },
      };
    }

    case constants.FETCH_SEASON_BY_EPISODE_SUCCESS: {
      const idsExtraData = {};
      const episodeId = {};
      const _id = action.payload.id;
      const episodes = action.payload.data?.episodes;
      idsExtraData[_id] = {
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
      };
      episodeId[_id] = {
        season: { ...action.payload.data, episodes: episodes.map(ep => ep.id) },
      };

      return {
        ...state,
        extraDataByIds: {
          ...state.extraDataByIds,
          ...idsExtraData,
        },
        episodesByIds: {
          ...state.episodesByIds,
          ...episodeId,
        },
      };
    }

    case constants.FETCH_SEASON_BY_EPISODE_FAILURE: {
      const idsExtraData = {};
      idsExtraData[action.payload.id] = {
        loading: false,
        success: false,
        error: true,
        errorMessage: action.payload.error,
      };

      return {
        ...state,
        extraDataByIds: {
          ...state.extraDataByIds,
          ...idsExtraData,
        },
      };
    }

    default:
      return state;
  }
}

export default reducer;
