import React from 'react';
import assets from '../../assets/icons';

function SplashScreen() {
    return (
        <div className='flex flex-col items-center justify-center h-full w-full'>
            <img src={assets.reviddSplash} style={{ width: "100%" , height: "100%", resize:'block',
                alignItems: 'center', justifyContent: 'center' }} />
        </div>
    )
}

export default SplashScreen;
