export const constants = {
  FETCH_DEVICES_PENDING: 'devices/FETCH_DEVICES_PENDING',
  FETCH_DEVICES_SUCCESS: 'devices/FETCH_DEVICES_SUCCESS',
  FETCH_DEVICES_FAILURE: 'devices/FETCH_DEVICES_FAILURE',

  DELETE_DEVICE_PENDING: 'devices/DELETE_DEVICE_PENDING',
  DELETE_DEVICE_SUCCESS: 'devices/DELETE_DEVICE_SUCCESS',
  DELETE_DEVICE_FAILURE: 'devices/DELETE_DEVICE_FAILURE',
};

const fetchDevicesPending = () => ({
  type: constants.FETCH_DEVICES_PENDING,
});

const fetchDevicesSuccess = ({ devices, count }) => ({
  type: constants.FETCH_DEVICES_SUCCESS,
  payload: { devices, count },
});

const fetchDevicesFailrue = () => ({
  type: constants.FETCH_DEVICES_FAILURE,
});

const deleteDevicePending = payload => ({
  type: constants.DELETE_DEVICE_PENDING,
  payload,
});

const deleteDeviceSuccess = ({ id }) => ({
  type: constants.DELETE_DEVICE_SUCCESS,
  payload: { id },
});

const deleteDeviceFailure = () => ({
  type: constants.DELETE_DEVICE_FAILURE,
});

export default {
  fetchDevicesPending,
  fetchDevicesSuccess,
  fetchDevicesFailrue,
  deleteDevicePending,
  deleteDeviceSuccess,
  deleteDeviceFailure,
};
