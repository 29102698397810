import { constants } from './actions';

import { SIGN_IN_STEPS } from '../../../lib/constants';

const initialState = {
  me: null,
  //
  accessToken: null,
  refreshToken: null,
  expiresAt: '',
  //
  isUserAuthenticated: false,
  isLoggedOut: false,
  setFromServer: false,
  provider: '',
  //
  loading: false,
  error: false,
  message: '',
  //
  identifier: '',
  isEmailId: true,
  currentSignInStep: SIGN_IN_STEPS.identifier,
  // temp state for user existence check
  userExistence: {
    loading: false,
    success: false,
    error: false,
    existence: null
  },
  availableSession: {
    session: null,
    loading: false,
    success: false,
    error: false
  },

  meetRoomToken: {
    jwtToken: null,
    loading: false,
    error: false
  },

  // User profiles
  profiles: {
    loading: {
      fetchProfiles: false,
      createProfile: false,
      updateProfile: false,
      deleteProfile: false
    },
    error: {
      fetchProfiles: false,
      createProfile: false,
      updateProfile: false,
      deleteProfile: false
    },
    count: null,
    activeProfile: null,
    data: []
  },

  selectActiveProfile: {
    loading: false,
    success: false,
    error: false
  },

  userSubscriptionsLoading: false,
  userSubscriptionsError: false
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.SET_IDENTIFIER: {
      const { identifier, isEmailId } = payload;
      return {
        ...state,
        identifier,
        isEmailId
      };
    }

    case constants.SET_MESSAGE: {
      return { ...state, message: payload };
    }

    case constants.SET_CURRENT_SIGN_IN_STEP: {
      return { ...state, currentSignInStep: payload };
    }

    case constants.CLEAR_SERVER_FLAG: {
      return {
        ...state,
        setFromServer: false
      };
    }

    case constants.SET_AUTHENTICATED: {
      return {
        ...state,
        isUserAuthenticated: action.payload.isUserAuthenticated
      };
    }
 
    // Setting tokens or expiresAt to store
    case constants.SET_AUTH_VARIABLES: {
      return {
        ...state,
        ...payload,
      };
    }

    case constants.LOGOUT_SUCCESS: {
      return {
        ...initialState,
        isLoggedOut: true
      };
    }

    case constants.SET_TOKENS: {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expiresAt: action.payload.expiresAt,
        setFromServer: action.payload.isServer,
        provider: action.payload.provider
      };
    }

    case constants.SET_ME: {
      return {
        ...state,
        me: action.payload.me
      };
    }

    case constants.SIGNIN_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case constants.SIGNIN_SUCCESS: {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expiresAt: action.payload.expiresAt,
        loading: false
      };
    }

    case constants.SIGNIN_FAILURE: {
      return {
        ...state,
        accessToken: null,
        expiresAt: '',
        loading: false,
        error: true,
        message: action.payload.reason
      };
    }

    case constants.FETCH_JWT_TOKEN_PENDING: {
      return {
        ...state,
        meetRoomToken: {
          loading: true,
          error: false
        }
      };
    }

    case constants.FETCH_JWT_TOKEN_SUCCESS: {
      return {
        ...state,
        meetRoomToken: {
          jwtToken: action.payload.data,
          loading: false,
          error: false
        }
      };
    }

    case constants.FETCH_JWT_TOKEN_FAILURE: {
      return {
        ...state,
        meetRoomToken: {
          loading: false,
          error: true
        }
      };
    }

    case constants.SOCIAL_SIGNIN_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case constants.SOCIAL_SIGNIN_SUCCESS: {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expiresAt: action.payload.expiresAt,
        loading: false,
        isUserAuthenticated: true
      };
    }

    case constants.SOCIAL_SIGNIN_FAILURE: {
      return {
        ...state,
        accessToken: null,
        expiresAt: '',
        loading: false,
        error: true,
        message: action.payload.reason
      };
    }

    case constants.SIGNUP_PENDING: {
      return {
        ...state,
        loading: true
      };
    }

    case constants.SIGNUP_SUCCESS: {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expiresAt: action.payload.expiresAt,
        loading: false
      };
    }

    case constants.SIGNUP_FAILURE: {
      return {
        ...state,
        accessToken: null,
        expiresAt: '',
        loading: false,
        error: true,
        message: action.payload.reason
      };
    }

    case constants.FETCH_AVAILABLE_SESSION_PENDING: {
      return {
        ...state,
        availableSession: {
          loading: true
        }
      };
    }
    case constants.FETCH_AVAILABLE_SESSION_SUCCESS: {
      return {
        ...state,
        availableSession: {
          session: payload,
          success: true,
          loading: false
        }
      };
    }
    case constants.FETCH_AVAILABLE_SESSION_FAILURE: {
      const { reason } = payload;
      return {
        ...state,
        message: reason,
        availableSession: {
          success: false,
          loading: false,
          error: true
        }
      };
    }

    case constants.SET_SUBSCRIPTION_STATUS: {
      return {
        ...state,
        me: {
          ...state.me,
          userSubscription: {
            ...state.me.userSubscription,
            status: action.payload,
            isFree: false
          }
        }
      };
    }

    case constants.CHECK_USER_EXISTENCE_PENDING: {
      return {
        ...state,
        userExistence: {
          loading: true
        }
      };
    }

    case constants.CHECK_USER_EXISTENCE_SUCCESS: {
      return {
        ...state,
        userExistence: {
          loading: false,
          success: true,
          existence: action.payload
        }
      };
    }

    case constants.CHECK_USER_EXISTENCE_FAILURE: {
      return {
        ...state,
        userExistence: {
          loading: false,
          success: false,
          error: true,
          existence: action.payload
        }
      };
    }

    // QR code authentication for tv apps.
    case constants.QRCODE_AUTHENTICATION_PENDING: {
      return {
        ...state
      };
    }

    case constants.QRCODE_AUTHENTICATION_SUCCESS: {
      return {
        ...state
      };
    }

    case constants.QRCODE_AUTHENTICATION_FAILURE: {
      return {
        ...state
      };
    }

    case constants.SET_IS_PIN_EXISTS: {
      return {
        ...state,
        me: {
          ...state.me,
          isPinExists: payload?.isPinExists
        }
      };
    }

    case constants.RESET_AUTH_TO_INIT: {
      return {
        ...state,
        message: '',
        userExistence: {
          loading: false,
          success: false,
          error: false,
          existence: null
        }
      };
    }
    case constants.RESET_AUTH: {
      return {
        ...initialState
      };
    }

    case constants.SET_USER_PROFILES: {
      return {
        ...state,
        profiles: {
          ...state.profiles,
          count: payload.profiles.count,
          data: payload.profiles.list
        }
      };
    }

    case constants.CREATE_USER_PROFILE_PENDING: {
      return {
        ...state,
        profiles: {
          ...state.profiles,
          loading: {
            ...state.profiles.loading,
            createProfile: true
          }
        }
      };
    }

    case constants.CREATE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        profiles: {
          ...state.profiles,
          loading: {
            ...state.profiles.loading,
            createProfile: false
          },
          count: state.profiles.count + 1,
          data: [...state.profiles.data, payload.newProfile]
        }
      };
    }

    case constants.CREATE_USER_PROFILE_FAILURE: {
      return {
        ...state,
        profiles: {
          ...state.profiles,
          loading: {
            ...state.profiles.loading,
            createProfile: false
          },
          error: {
            ...state.profiles.error,
            createProfile: true
          }
        }
      };
    }

    case constants.UPDATE_USER_PROFILE_PENDING: {
      return {
        ...state,
        profiles: {
          ...state.profiles,
          loading: {
            ...state.profiles.loading,
            updateProfile: true
          }
        }
      };
    }
    case constants.UPDATE_USER_PROFILE_SUCCESS: {
      const editedProfileId = payload.editedProfile?.id;
      const newData = [...state.profiles.data];
      const editedObjIndex = newData.findIndex(item => {
        return item.id === editedProfileId;
      });
      newData[editedObjIndex] = { ...payload.editedProfile };
      return {
        ...state,
        profiles: {
          ...state.profiles,
          loading: {
            ...state.profiles.loading,
            updateProfile: false
          },
          data: [...newData]
        }
      };
    }
    case constants.UPDATE_USER_PROFILE_FAILURE: {
      return {
        ...state,
        profiles: {
          ...state.profiles,
          loading: {
            ...state.profiles.loading,
            updateProfile: false
          },
          error: {
            ...state.profiles.error,
            updateProfile: true
          }
        }
      };
    }
    case constants.DELETE_USER_PROFILE_PENDING: {
      return {
        ...state,
        profiles: {
          ...state.profiles,
          loading: {
            ...state.profiles.loading,
            deleteProfile: true
          }
        }
      };
    }
    case constants.DELETE_USER_PROFILE_SUCCESS: {
      const deletedProfileId = payload.deletedId;
      const newData = [...state.profiles.data];
      const filteredNewData = newData.filter(item => {
        return item.id !== deletedProfileId;
      });
      return {
        ...state,
        profiles: {
          ...state.profiles,
          loading: {
            ...state.profiles.loading,
            deleteProfile: false
          },
          data: [...filteredNewData]
        }
      };
    }
    case constants.DELETE_USER_PROFILE_FAILURE: {
      return {
        ...state,
        profiles: {
          ...state.profiles,
          loading: {
            ...state.profiles.loading,
            deleteProfile: false
          },
          error: {
            ...state.profiles.error,
            deleteProfile: true
          }
        }
      };
    }

    case constants.SET_ACTIVE_USER_PROFILE: {
      return {
        ...state,
        profiles: {
          ...state.profiles,
          activeProfile: payload.profile
        }
      };
    }

    case constants.SELECT_ACTIVE_PROFILE_PENDING: {
      return {
        ...state,
        selectActiveProfile: {
          ...state.selectActiveProfile,
          loading: true
        }
      };
    }

    case constants.SELECT_ACTIVE_PROFILE_SUCCESS: {
      return {
        ...state,
        profiles: {
          ...state.profiles,
          activeProfile: payload.profile.id
        },
        selectActiveProfile: {
          ...state.selectActiveProfile,
          loading: false,
          success: true,
          error: false
        }
      };
    }

    case constants.SELECT_ACTIVE_PROFILE_FAILURE: {
      return {
        ...state,
        selectActiveProfile: {
          ...state.selectActiveProfile,
          loading: false,
          success: false,
          error: true
        }
      };
    }

    case constants.GET_USER_SUBSCRIPTION_PENDING: {
      return {
        ...state,
        userSubscriptionsLoading: true
      };
    }

    case constants.GET_USER_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        userSubscriptionsLoading: false,
        userSubscriptionsError: false,
        me: {
          ...state.me,
          userSubscription: payload.userSubscription
        }
      };
    }

    case constants.GET_USER_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        userSubscriptionsLoading: false,
        userSubscriptionError: true
      };
    }

    default:
      return state;
  }
}
