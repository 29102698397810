import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ri from '@enact/ui/resolution';
import Spottable from '@enact/spotlight/Spottable';
import ImageItem from '@enact/sandstone/ImageItem'
import { autoSize, getAvatarUrl } from '../../../lib/utilities';
import navigate from '../../../store/modules/navigate/actions';
import authActions from '../../../store/modules/auth/actions';
import bootstrapActions from '../../../store/modules/bootstrap/actions';
import VirtualList from '@enact/ui/VirtualList';
import { Panel } from '@enact/sandstone/Panels';

function ManageProfiles(props) {
  const dispatch = useDispatch();

  const { activeProfile } = useSelector(state => state.auth);
  const { profiles } = useSelector(state => state.profile);
  const {me} = useSelector(state => state.auth);

  const [mode, setMode] = useState('MANAGE');
  const uhd = window.innerWidth > 1280 && window.innerHeight > 720;

  const handleActiveProfileChange = ({profile}) => {
    if (!activeProfile || activeProfile?.id !== profile.id) {
      window.localStorage.setItem(`activeProfile-${me.id}`,JSON.stringify(profile))
      dispatch(authActions.setActiveUserProfile({ profile }));
      dispatch(bootstrapActions.bootstrapPending());
    }

    if (activeProfile && activeProfile.id === profile.id) {
      // navigation.goBack();
    }
  };
  const handleLogOut = () => {
    dispatch(authActions.logoutPending());
  };

  const renderLogOutAction = () => {
    if (mode !== 'SELECT') return null;
    return (
      <View py={20}>
        <CustomButton label="Log out" onPress={handleLogOut} />
      </View>
    );
  };
  const setActiveProfile = ({ profile }) => {
    // Don't set active profile if it is already set
    if (activeProfile === profile.id) {
      dispatch(navigate({path:'/home'}));
      return;
    }

    handleActiveProfileChange({ profile });
  };
  const renderName = (profile) => {
    if (activeProfile === profile.id) {
      return (
        <span
          title={profile.name}
          className="text-14 font-500 text-mainText cursor-pointer mb-14 max-w-192 overflow-hidden whitespace-nowrap overflow-ellipsis"
        >
          {profile.name}
        </span>
      );
    }

    return (
      <span
        title={profile.name}
        className="text-14 font-500 text-placeholderText cursor-pointer mb-14 max-w-192 overflow-hidden whitespace-nowrap overflow-ellipsis"
      >
        {profile.name}
      </span>
    );
  };
  const renderProfile = (data) => {
    const { index, ...rest } = data;
    const imageContainerStyle = {
      height: '100px',
      width: '100px',
      borderRadius: '50%',
      cursor: 'pointer',
      border: '6px solid rgba(255, 255, 255, 0.1)',
      marginBottom: '2px',
      visibility: activeProfile === profiles?.data.list[index]?.id ? 'visible' : 'hidden'
    };
  
    const imageWrapperStyle = {
      height: '100%',
      width: '100%',
      borderRadius: '50%',
      visibility: 'visible'
    };
    return (
      <ImageItem src={getAvatarUrl(profiles?.data.list[index]?.avatar)} className='w-96 h-118 m-0' onClick={() => setActiveProfile({profile: profiles?.data.list[index]})}>
        {renderName(profiles?.data.list[index])}
      </ImageItem>
    );
  };

  const renderProfiles = () => {
    return (
      <VirtualList
        itemSize={ri.scale(150)}
        dataSize={profiles.data.count}
        direction='horizontal'
        itemRenderer={renderProfile}
        className='w-full h-184'
        key='translate'
        spacing={5}
        id="profiles"
      />
    );
  };

  const renderContainer = () => {
    if (!profiles.success || !profiles.data.list) return null;
    return (
      <div className="flex items-center justify-center h-full">
        <div className="px-10">
          {renderProfiles()}
          {renderLogOutAction()}
        </div>
      </div>
    );
  };

  // useEffect(() => {
  //   const { mode } = props;
  //   // setMode(params?.options?.mode);
  //   if (mode === 'SELECT') {
  //     navigation.setOptions({ headerTitle: 'Choose your profile' });
  //   }
  // }, []);

  return <Panel>{renderContainer()}</Panel>;
}

export default Spottable(ManageProfiles);
