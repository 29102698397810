import gql from 'graphql-tag';

const searchContent = gql`
  query searchContent(
    $ids: [String]
    $queryName: String
    $skip: Int
    $limit: Int
  ) {
    searchContent(
      ids: $ids
      queryName: $queryName
      skip: $skip
      limit: $limit
    ) {
      data
      cdn {
        domainName
        aliases
      }
    }
  }
`;

export default {
  searchContent,
};
