import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageBanner from "../components/PageBanner";
import Spottable from "@enact/spotlight/Spottable";
import Spotlight from "@enact/spotlight";
import Scroller from "@enact/sandstone/Scroller";
import Container from "../views/SectionsList";
import { navigate } from "../store/modules/navigate/actions";
import pageActions from "../store/modules/pages/actions";
import { spotlightDefaultClass } from "@enact/spotlight/SpotlightContainerDecorator";

function PageView({ slug, subMenu }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.me);
  const { loading } = useSelector((state) => state.bootstrap);
  const scrollRef = useRef(null);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!user && !loading) dispatch(navigate({ path: "signin", props: {} }));
    }, 1500);
    return () => clearTimeout(timer);
  }, [user]);

  const pageConfig = useSelector(
    (state) => state.pages?.pageByPageName?.[slug]?.pageConfig
  );

  const [currentItem, setCurrentItem] = useState(null);
  const firstSectionItemRef = useRef(null);
  const p = useSelector((state) => state.navigate.props);

  useEffect(() => {
    if (!pageConfig && slug)
      dispatch(pageActions.fetchPageInitialDataPending({ path: slug }));
    // console.log(p.spoton);
    if (p?.spoton) Spotlight.focus(p.spoton);
  }, [dispatch]);

  const renderBanners = () => {
    if (!pageConfig?.banner?.id) return null;
    return (
      <PageBanner
        id={pageConfig.banner.id}
        path={pageConfig.id}
        // containerStyle={styles.banners.container}
        currentItem={currentItem}
        // nextFocusDown={findNodeHandle(firstSectionItemRef.current)}
        // width={styles.banners.banner.width}
        // height={styles.banners.banner.height}
        // onItemPress={handleSectionItemPress}
        onImageLoad={() => null}
      />
    );
  };

  const renderSwipers = () => {
    if (!pageConfig?.sections) return null;

    const sections = pageConfig?.sections || [];

    const banners = [];
    if (pageConfig?.banner?.id) {
      banners.push(pageConfig);
    }
    return (
      <Scroller
        className={`${spotlightDefaultClass} top-[62%] h-[40%] absolute`}
        data-spotlight-id="sections"
        verticalScrollbar="hidden"
        direction="vertical"
        focusableScrollbar
        scrollMode="native"
        hoverToScroll={false}
        cbScrollTo={(fn) => (scrollRef.current = fn)}
      >
        <Container
          page={slug}
          firstSectionItemRef={firstSectionItemRef}
          sections={[...sections]}
          // onItemPress={handleSectionItemPress}
          scrollRef={scrollRef}
          hasPageBanner={pageConfig?.banner?.id !== null} // onViewMorePress={handleViewMorePress}
          // onItemFocus={handleItemFocus}
        />
      </Scroller>
    );
  };
  return (
    <div>
      {renderBanners()}
      {renderSwipers()}
    </div>
  );
}

export default Spottable(PageView);
