import gql from 'graphql-tag';

const fetchEpg = gql`
  query getEpgByChannelIds($startDate: DateTime!) {
    getEpgByChannelIds(startDate: $startDate)
  }
`;

export default {
  fetchEpg
};
