import { constants } from './actions';

const initialState = {
  pageV2Data: {},
  pagesExtraDataBySlug: {},
  pageByPageName: {
    // [path]: {
    //   pageConfig: {id: 123, banner: {id: 'abc'}, sections: [{}, {}]},
    //   bannerData: [{id:'123', data: {}}],
    //   sectionDataByContentGroup: {
    //     [contentGroupSLug/id]: { itemsCount: number, data: [] }
    //     ...
    //   },
    //   extraDataBySectionId: {
    //     [sectionId]: { loading: true, error: false }
    //     ...
    //   },
    //   sectionDataBySectionId: {
    //        [sectionId]: {data: [], totalItemsInSection, cdnDomainName, alias, loading, success, error }
    //   }
    // };
  },
  pageByPageLayOut: {},
  bannerCollectionsBySlug: {},
  pageError: null,
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.FETCH_PAGE_INITIAL_DATA_V2_PENDING: {
      return {
        ...state,
        bannerLoading: false,
        bannerError: null,
        pageLoading: true
      };
    }

    case constants.FETCH_PAGE_INITIAL_DATA_V2_SUCCESS: {
      const { path, data, banner, bannerData, contentSections } = payload;
      return {
        ...state,
        pageLoading: false,
        cdnDomainName: data?.cdnInfo,
        pageV2Data: {
          ...state.pageV2Data,
          [path]: {
            ...state.pageV2Data?.[path],
            ...data,
            ...banner,
            ...bannerData,
            ...contentSections
          }
        },
        pageError: null
      };
    }

    case constants.FETCH_PAGE_INITIAL_DATA_V2_FAILURE: {
      return {
        ...state,
        pageLoading: false,
        pageError: action.payload
      };
    }

    case constants.FETCH_PAGE_INITIAL_DATA_PENDING: {
      return {
        ...state,
        bannerCollection: [],
        bannerLoading: false,
        bannerError: null,
        pageLoading: true,
        pagesExtraDataBySlug: {
          ...state.pagesExtraDataBySlug,
          [payload.path]: {
            ...state.pagesExtraDataBySlug?.[payload.path],
            loading: true,
            error: false,
          },
        },
      };
    }

    case constants.FETCH_PAGE_INITIAL_DATA_SUCCESS: {
      const { path, data } = payload;
      return {
        ...state,
        pageLoading: false,
        pageByPageName: {
          ...state.pageByPageName,
          [path]: {
            ...state.pageByPageName[path],
            ...data,
          },
        },
        pagesExtraDataBySlug: {
          ...state.pagesExtraDataBySlug,
          [payload.path]: {
            ...state.pagesExtraDataBySlug?.[payload.path],
            loading: false,
            error: false,
          },
        },
        pageError: null,
      };
    }

    case constants.FETCH_PAGE_INITIAL_DATA_FAILURE: {
      return {
        ...state,
        pageLoading: false,
        pageError: action.payload,
        pagesExtraDataBySlug: {
          ...state.pagesExtraDataBySlug,
          [payload.path]: {
            ...state.pagesExtraDataBySlug?.[payload.path],
            loading: true,
            error: false,
          },
        },
      };
    }

    case constants.UPDATE_PAGINATION_DATA: {
      const { path, newSections, restSections } = action.payload;

      return {
        ...state,
        pageByPageName: {
          ...state.pageByPageName,
          [path]: {
            ...state.pageByPageName[path],
            pageConfig: {
              ...state.pageByPageName[path].pageConfig,
              currentSections: [...state.pageByPageName[path].pageConfig.currentSections, ...newSections],
              restSections,
              isPaginated : restSections.length > 0
            }
          }
        }
      };
    }

    case constants.FETCH_PAGE_LAYOUT_PENDING: {
      return {
        ...state,
      };
    }

    case constants.FETCH_PAGE_LAYOUT_SUCCESS: {
      return {
        ...state,
        pageByPageLayOut: action.payload,
      };
    }

    case constants.FETCH_PAGE_LAYOUT_FAILURE: {
      return {
        ...state,
      };
    }

    case constants.FETCH_BANNER_DATA_PENDING: {
      return {
        ...state,
        bannerCollectionsBySlug: {
          ...state.bannerCollectionsBySlug,
          [action.payload.path]: {
            loading: true,
            error: false,
          },
        },
      };
    }

    case constants.FETCH_BANNER_DATA_SUCCESS: {
      return {
        ...state,
        bannerCollectionsBySlug: {
          ...state.bannerCollectionsBySlug,
          [action.payload.path]: {
            ...state.bannerCollectionsBySlug[action.payload.slug],
            loading: false,
            error: false,
            data: action.payload.bannerCollection,
          },
        },
      };
    }

    case constants.FETCH_BANNER_DATA_FAILURE: {
      return {
        ...state,
        bannerCollectionsBySlug: {
          ...state.bannerCollectionsBySlug,
          [action.payload.path]: {
            loading: false,
            error: true,
          },
        },
      };
    }

    case constants.FETCH_CONTENT_GROUP_PENDING: {
      return {
        ...state,
        pageByPageName: {
          ...state.pageByPageName,
          [action.payload.path]: {
            ...state.pageByPageName[action.payload.path],
            extraDataByContentGroup: {
              ...state.pageByPageName[action.payload.path]
                .extraDataByContentGroup[action.payload.slug],
              loading: true,
            },
          },
        },
      };
    }

    case constants.FETCH_CONTENT_GROUP_SUCCESS: {
      return {
        ...state,
        pageByPageName: {
          ...state.pageByPageName,
          [action.payload.path]: {
            ...state.pageByPageName[action.payload.path],
            extraDataByContentGroup: {
              ...state.pageByPageName[action.payload.path]
                .extraDataByContentGroup[action.payload.slug],
              loading: false,
              error: null,
            },
            sectionDataByContentGroup: {
              ...state.pageByPageName[action.payload.path]
                .sectionDataByContentGroup,
              [action.payload.slug]: action.payload.data,
            },
          },
        },
      };
    }

    case constants.FETCH_CONTENT_GROUP_FAILURE: {
      return {
        ...state,
        pageByPageName: {
          ...state.pageByPageName,
          [action.payload.path]: {
            ...state.pageByPageName[action.payload.path],
            extraDataByContentGroup: {
              ...state.pageByPageName[action.payload.path]
                .extraDataByContentGroup[action.payload.slug],
              loading: false,
              error: action.payload.error,
            },
            sectionDataByContentGroup: {
              ...state.pageByPageName[action.payload.path]
                .sectionDataByContentGroup,
              [action.payload.slug]: {},
            },
          },
        },
      };
    }

    case constants.FETCH_SECTION_DATA_PENDING: {
      const { path, sectionId } = payload;
      return {
        ...state,
        pageByPageName: {
          ...state.pageByPageName,
          [path]: {
            ...state.pageByPageName?.[path],
            sectionDataBySectionId: {
              ...state.pageByPageName?.[path]?.sectionDataBySectionId,
              [sectionId]: {
                ...state.pageByPageName?.[path]?.sectionDataBySectionId?.[
                  sectionId
                ],
                loading: true,
                success: false,
                error: false,
              },
            },
          },
        },
      };
    }

    case constants.FETCH_SECTION_DATA_SUCCESS: {
      const {
        cdnDomainName,
        alias,
        path,
        data,
        autoplay,
        isOnLoop,
        sectionId,
        totalItemsInSection,
        isMoreContentAvailable,
      } = payload;
      return {
        ...state,
        pageByPageName: {
          ...state.pageByPageName,
          [path]: {
            ...state.pageByPageName?.[path],
            sectionDataBySectionId: {
              ...state.pageByPageName?.[path]?.sectionDataBySectionId,
              [sectionId]: {
                data,
                autoplay,
                isOnLoop,
                cdnDomainName,
                alias,
                totalItemsInSection,
                isMoreContentAvailable,
                loading: false,
                success: true,
                error: false,
              },
            },
          },
        },
      };
    }
    case constants.FETCH_SECTION_DATA_FAILURE: {
      const { path, sectionId } = payload;
      return {
        ...state,
        pageByPageName: {
          ...state.pageByPageName,
          [path]: {
            ...state.pageByPageName?.[path],
            sectionDataBySectionId: {
              ...state.pageByPageName?.[path]?.sectionDataBySectionId,
              [sectionId]: {
                ...state.pageByPageName?.[path]?.sectionDataBySectionId?.[
                  sectionId
                ],
                loading: false,
                success: false,
                error: true,
              },
            },
          },
        },
      };
    }
    case constants.RESET_PAGE_DATA:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
