export const constants = {
  FETCH_STATIC_SECTION_PENDING: 'common/FETCH_STATIC_SECTION_PENDING',
  FETCH_STATIC_SECTION_SUCCESS: 'common/FETCH_STATIC_SECTION_SUCCESS',
  FETCH_STATIC_SECTION_FAILURE: 'common/FETCH_STATIC_SECTION_FAILURE',

  REMOVE_STATIC_ITEM_PENDING: 'common/REMOVE_STATIC_ITEM_PENDING',
  REMOVE_STATIC_ITEM_SUCCESS: 'common/REMOVE_STATIC_ITEM_SUCCESS',
  REMOVE_STATIC_ITEM_FAILURE: 'common/REMOVE_STATIC_ITEM_FAILURE',
};

// Static sections include sections like continue watching, most watched,
// my list, watch later sections that are static
const fetchStaticSectionPending = payload => ({
  type: constants.FETCH_STATIC_SECTION_PENDING,
  payload,
});

const fetchStaticSectionSuccess = payload => ({
  type: constants.FETCH_STATIC_SECTION_SUCCESS,
  payload,
});

const fetchStaticSectionFailure = payload => ({
  type: constants.FETCH_STATIC_SECTION_FAILURE,
  payload,
});

// Removal of static section item - start -----------------
const removeStaticItemPending = payload => ({
  type: constants.REMOVE_STATIC_ITEM_PENDING,
  payload,
});

const removeStaticItemSuccess = payload => ({
  type: constants.REMOVE_STATIC_ITEM_SUCCESS,
  payload,
});

const removeStaticItemFailure = payload => ({
  type: constants.REMOVE_STATIC_ITEM_FAILURE,
  payload,
});
// Removal of static section item - end -----------------

export default {
  fetchStaticSectionPending,
  fetchStaticSectionFailure,
  fetchStaticSectionSuccess,
  //
  removeStaticItemPending,
  removeStaticItemSuccess,
  removeStaticItemFailure,
};
