const prefix = 'auth';

export const constants = {
  SET_IDENTIFIER: `${prefix}/identifier`,
  SET_MESSAGE: `${prefix}/SET_MESSAGE`,
  SET_CURRENT_SIGN_IN_STEP: `${prefix}/SET_CURRENT_SIGN_IN_STEP`,

  SET_TOKENS: `${prefix}/SET_TOKENS`,
  SET_ME: `${prefix}/SET_ME`,
  START_TOKEN_REFRESH_WATCHER: `${prefix}/START_TOKEN_REFRESH_WATCHER`,
  SET_AUTH_VARIABLES: 'auth/SET_AUTH_VARIABLES',

  SIGNIN_PENDING: `${prefix}/SIGNIN_PENDING`,
  SIGNIN_SUCCESS: `${prefix}/SIGNIN_SUCCESS`,
  SIGNIN_FAILURE: `${prefix}/SIGNIN_FAILURE`,

  SOCIAL_SIGNIN_PENDING: `${prefix}/SOCIAL_SIGNIN_PENDING`,
  SOCIAL_SIGNIN_SUCCESS: `${prefix}/SOCIAL_SIGNIN_SUCCESS`,
  SOCIAL_SIGNIN_FAILURE: `${prefix}/SOCIAL_SIGNIN_FAILURE`,

  // QR code authentication for tv apps.
  QRCODE_AUTHENTICATION_PENDING: `${prefix}/QRCODE_AUTHENTICATION_PENDING`,
  QRCODE_AUTHENTICATION_SUCCESS: `${prefix}/QRCODE_AUTHENTICATION_SUCCESS`,
  QRCODE_AUTHENTICATION_FAILURE: `${prefix}/QRCODE_AUTHENTICATION_FAILURE`,

  SIGNUP_PENDING: `${prefix}/SIGNUP_PENDING`,
  SIGNUP_SUCCESS: `${prefix}/SIGNUP_SUCCESS`,
  SIGNUP_FAILURE: `${prefix}/SIGNUP_FAILURE`,

  LOGOUT_PENDING: `${prefix}/LOGOUT_PENDING`,
  LOGOUT_SUCCESS: `${prefix}/LOGOUT_SUCCESS`,
  LOGOUT_FAILURE: `${prefix}/LOGOUT_FAILURE`,

  FETCH_AVAILABLE_SESSION_PENDING: `${prefix}/FETCH_AVAILABLE_SESSION_PENDING`,
  FETCH_AVAILABLE_SESSION_SUCCESS: `${prefix}/FETCH_AVAILABLE_SESSION_SUCCESS`,
  FETCH_AVAILABLE_SESSION_FAILURE: `${prefix}/FETCH_AVAILABLE_SESSION_FAILURE`,

  FETCH_JWT_TOKEN_PENDING: `${prefix}/FETCH_JWT_TOKEN_PENDING`,
  FETCH_JWT_TOKEN_SUCCESS: `${prefix}/FETCH_JWT_TOKEN_SUCCESS`,
  FETCH_JWT_TOKEN_FAILURE: `${prefix}/FETCH_JWT_TOKEN_FAILURE`,

  SET_AUTHENTICATED: `${prefix}/SET_AUTHENTICATED`,

  CLEAR_SERVER_FLAG: `${prefix}/CLEAR_SERVER_FLAG`,

  SET_SUBSCRIPTION_STATUS: `${prefix}/SUBSCRIPTION_STATUS`,

  CHECK_USER_EXISTENCE_PENDING: `${prefix}/CHECK_USER_EXISTENCE_PENDING`,
  CHECK_USER_EXISTENCE_SUCCESS: `${prefix}/CHECK_USER_EXISTENCE_SUCCESS`,
  CHECK_USER_EXISTENCE_FAILURE: `${prefix}/CHECK_USER_EXISTENCE_FAILURE`,

  RESET_AUTH_TO_INIT: `${prefix}/RESET_AUTH_TO_INIT`,

  RESET_AUTH: `${prefix}/RESET_AUTH`,

  SET_IS_PIN_EXISTS: 'auth/SET_IS_PIN_EXISTS',

  SET_USER_PROFILES: `${prefix}/SET_USER_PROFILES`,
  SET_ACTIVE_USER_PROFILE: `${prefix}/SET_ACTIVE_USER_PROFILE`,

  SELECT_ACTIVE_PROFILE_PENDING: `${prefix}/SELECT_ACTIVE_PROFILE_PENDING`,
  SELECT_ACTIVE_PROFILE_SUCCESS: `${prefix}/SELECT_ACTIVE_PROFILE_SUCCESS`,
  SELECT_ACTIVE_PROFILE_FAILURE: `${prefix}/SELECT_ACTIVE_PROFILE_FAILURE`,

  CREATE_USER_PROFILE_PENDING: `${prefix}/CREATE_USER_PROFILE_PENDING`,
  CREATE_USER_PROFILE_SUCCESS: `${prefix}/CREATE_USER_PROFILE_SUCCESS`,
  CREATE_USER_PROFILE_FAILURE: `${prefix}/CREATE_USER_PROFILE_FAILURE`,

  UPDATE_USER_PROFILE_PENDING: `${prefix}/UPDATE_USER_PROFILE_PENDING`,
  UPDATE_USER_PROFILE_SUCCESS: `${prefix}/UPDATE_USER_PROFILE_SUCCESS`,
  UPDATE_USER_PROFILE_FAILURE: `${prefix}/UPDATE_USER_PROFILE_FAILURE`,

  DELETE_USER_PROFILE_PENDING: `${prefix}/DELETE_USER_PROFILE_PENDING`,
  DELETE_USER_PROFILE_SUCCESS: `${prefix}/DELETE_USER_PROFILE_SUCCESS`,
  DELETE_USER_PROFILE_FAILURE: `${prefix}/DELETE_USER_PROFILE_FAILURE`,

  GET_USER_SUBSCRIPTION_PENDING: `${prefix}/GET_USER_SUBSCRIPTION_PENDING`,
  GET_USER_SUBSCRIPTION_SUCCESS: `${prefix}/GET_USER_SUBSCRIPTION_SUCCESS`,
  GET_USER_SUBSCRIPTION_FAILURE: `${prefix}/GET_USER_SUBSCRIPTION_FAILURE`
};

export const setIdentifier = payload => ({
  type: constants.SET_IDENTIFIER,
  payload
});

export const setMessage = payload => {
  return {
    type: constants.SET_MESSAGE,
    payload
  };
};

const setAuthVariables = ({ expiresAt } = {}) => ({
  type: constants.SET_AUTH_VARIABLES,
  payload: { expiresAt },
});

export const setCurrentSignInStep = payload => {
  return {
    type: constants.SET_CURRENT_SIGN_IN_STEP,
    payload
  };
};

export const setTemporaryAccessToken = payload => {
  return {
    type: constants.SET_TEMP_ACCESS_TOKEN,
    payload
  };
};

export const setTokens = payload => {
  return {
    type: constants.SET_TOKENS,
    payload
  };
};

export const setMe = payload => {
  return {
    type: constants.SET_ME,
    payload
  };
};

export const clearServerFlag = () => ({
  type: constants.CLEAR_SERVER_FLAG
});

export const startTokenRefreshWatcher = payload => {
  return {
    type: constants.START_TOKEN_REFRESH_WATCHER,
    payload
  };
};

export const signinPending = payload => ({
  type: constants.SIGNIN_PENDING,
  payload
});

export const signinFailure = ({ reason } = {}) => ({
  type: constants.SIGNIN_FAILURE,
  payload: { reason }
});

export const signinSuccess = payload => ({
  type: constants.SIGNIN_SUCCESS,
  payload
});

export const fetchJwtTokenPending = payload => ({
  type: constants.FETCH_JWT_TOKEN_PENDING,
  payload
});

export const fetchJwtTokenSuccess = payload => ({
  type: constants.FETCH_JWT_TOKEN_SUCCESS,
  payload
});

export const fetchJwtTokenFailure = payload => ({
  type: constants.FETCH_JWT_TOKEN_FAILURE,
  payload
});

export const socialSigninPending = ({ query } = {}) => ({
  type: constants.SOCIAL_SIGNIN_PENDING,
  payload: { query }
});

export const socialSigninSuccess = payload => ({
  type: constants.SOCIAL_SIGNIN_SUCCESS,
  payload
});

export const socialSigninFailure = ({ reason } = {}) => ({
  type: constants.SOCIAL_SIGNIN_FAILURE,
  payload: { reason }
});

export const signupPending = payload => ({
  type: constants.SIGNUP_PENDING,
  payload
});

export const signupFailure = ({ reason } = {}) => ({
  type: constants.SIGNUP_FAILURE,
  payload: { reason }
});

export const signupSuccess = payload => ({
  type: constants.SIGNUP_SUCCESS,
  payload
});

export const logoutPending = () => ({
  type: constants.LOGOUT_PENDING
});

export const logoutSuccess = () => ({
  type: constants.LOGOUT_SUCCESS
});

export const logoutFailure = () => ({
  type: constants.LOGOUT_FAILURE
});

export const fetchAvailableSessionPending = payload => ({
  type: constants.FETCH_AVAILABLE_SESSION_PENDING,
  payload
});

export const fetchAvailableSessionSuccess = payload => ({
  type: constants.FETCH_AVAILABLE_SESSION_SUCCESS,
  payload
});

export const fetchAvailableSessionFailure = payload => ({
  type: constants.FETCH_AVAILABLE_SESSION_FAILURE,
  payload
});

export const setAuthenticated = ({ isUserAuthenticated }) => ({
  type: constants.SET_AUTHENTICATED,
  payload: { isUserAuthenticated }
});

export const setSubscriptionStatus = ({ status }) => ({
  type: constants.SET_SUBSCRIPTION_STATUS,
  payload: status
});

export const checkUserExistencePending = payload => ({
  type: constants.CHECK_USER_EXISTENCE_PENDING,
  payload
});

export const checkUserExistenceSuccess = payload => ({
  type: constants.CHECK_USER_EXISTENCE_SUCCESS,
  payload
});

export const checkUserExistenceFailure = payload => ({
  type: constants.CHECK_USER_EXISTENCE_FAILURE,
  payload
});

export const setIsPinExists = payload => {
  return {
    type: constants.SET_IS_PIN_EXISTS,
    payload
  };
};

export const resetAuthToInit = () => ({
  type: constants.RESET_AUTH_TO_INIT
});

export const resetAuthState = () => ({
  type: constants.RESET_AUTH
});

export const setUserProfiles = payload => ({
  type: constants.SET_USER_PROFILES,
  payload
});

export const createUserProfilePending = payload => ({
  type: constants.CREATE_USER_PROFILE_PENDING,
  payload
});

export const createUserProfileSuccess = payload => ({
  type: constants.CREATE_USER_PROFILE_SUCCESS,
  payload
});

export const createUserProfileFailure = payload => ({
  type: constants.CREATE_USER_PROFILE_FAILURE,
  payload
});

export const updateUserProfilePending = payload => ({
  type: constants.UPDATE_USER_PROFILE_PENDING,
  payload
});

export const updateUserProfileSuccess = payload => ({
  type: constants.UPDATE_USER_PROFILE_SUCCESS,
  payload
});

export const updateUserProfileFailure = payload => ({
  type: constants.UPDATE_USER_PROFILE_FAILURE,
  payload
});

export const deleteUserProfilePending = payload => ({
  type: constants.DELETE_USER_PROFILE_PENDING,
  payload
});

export const deleteUserProfileSuccess = payload => ({
  type: constants.DELETE_USER_PROFILE_SUCCESS,
  payload
});

export const deleteUserProfileFailure = payload => ({
  type: constants.DELETE_USER_PROFILE_FAILURE,
  payload
});

export const setActiveUserProfile = payload => ({
  type: constants.SET_ACTIVE_USER_PROFILE,
  payload
});

/**
 *  Different from `setActiveUserProfile`
 *  It selects an active profile and its executes in its saga
 */
export const selectActiveProfilePending = payload => ({
  type: constants.SELECT_ACTIVE_PROFILE_PENDING,
  payload
});

export const selectActiveProfileSuccess = payload => ({
  type: constants.SELECT_ACTIVE_PROFILE_SUCCESS,
  payload
});

export const selectActiveProfileFailure = payload => ({
  type: constants.SELECT_ACTIVE_PROFILE_FAILURE,
  payload
});

export const getUserSubscriptionPending = () => ({
  type: constants.GET_USER_SUBSCRIPTION_PENDING
});

export const getUserSubscriptionSuccess = payload => ({
  type: constants.GET_USER_SUBSCRIPTION_SUCCESS,
  payload
});

export const getUserSubscriptionFailure = () => ({
  type: constants.GET_USER_SUBSCRIPTION_FAILURE
});

export const qrcodeAuthenticationPending = ({ deviceId } = {}) => ({
  type: constants.QRCODE_AUTHENTICATION_PENDING,
  payload: { deviceId }
});

export const qrcodeAuthenticationSuccess = () => ({
  type: constants.QRCODE_AUTHENTICATION_SUCCESS
});

export const qrcodeAuthenticationFailure = () => ({
  type: constants.QRCODE_AUTHENTICATION_FAILURE
});

export default {
  setIdentifier,
  setMessage,
  setCurrentSignInStep,
  setTemporaryAccessToken,
  setTokens,
  setAuthVariables,
  setMe,
  startTokenRefreshWatcher,
  signinPending,
  signinFailure,
  signinSuccess,

  fetchJwtTokenPending,
  fetchJwtTokenSuccess,
  fetchJwtTokenFailure,

  socialSigninPending,
  socialSigninSuccess,
  socialSigninFailure,
  signupFailure,
  signupSuccess,
  signupPending,
  logoutPending,
  logoutFailure,
  logoutSuccess,
  //
  fetchAvailableSessionPending,
  fetchAvailableSessionSuccess,
  fetchAvailableSessionFailure,
  //
  clearServerFlag,
  setAuthenticated,
  setSubscriptionStatus,
  checkUserExistencePending,
  checkUserExistenceSuccess,
  checkUserExistenceFailure,
  resetAuthToInit,
  resetAuthState,
  setUserProfiles,

  createUserProfilePending,
  createUserProfileSuccess,
  createUserProfileFailure,

  updateUserProfilePending,
  updateUserProfileSuccess,
  updateUserProfileFailure,

  deleteUserProfilePending,
  deleteUserProfileSuccess,
  deleteUserProfileFailure,

  setActiveUserProfile,

  selectActiveProfilePending,
  selectActiveProfileSuccess,
  selectActiveProfileFailure,

  getUserSubscriptionPending,
  getUserSubscriptionSuccess,
  getUserSubscriptionFailure,

  qrcodeAuthenticationPending,
  qrcodeAuthenticationSuccess,
  qrcodeAuthenticationFailure
};
