import { put, take, fork, call, select } from "redux-saga/effects";

import LS2Request from "@enact/webos/LS2Request";
import { ACCESS_TOKEN, LOGIN_TYPE, REFRESH_TOKEN, ROUTES } from "../../../lib/constants";
import {
  getConfigurationUrl,
  getTokenUrl,
  orgId,
  password,
  username,
} from "../../../lib/urls";

import { getTimeStampFromDateString, Failure } from "../../../lib/utilities";
import actions, { constants } from "./actions";
import authActions, { setTokens } from "../auth/actions";
import profileActions from "../profile/actions";
import storefrontActions from "../storefront/actions";
import menuActions, { constants as menuConstants } from "../menuItems/actions";
import channelActions, {
  constants as channelConstants,
} from "../channels/actions";
import commonActions, { constants as commonConstants } from "../common/actions";

import queries from "../../graphql/queries";
import profileQueries from "../profile/queries";
import { navigate } from "../navigate/actions";

export default function ({
  gqlClient,
  restClient,
  deliveryClient,
  configClient,
}) {
  const basicAuthDetails = unescape(
    encodeURIComponent(`${username}:${password}`)
  );

  const encodedBasicAuth = window
    .btoa(basicAuthDetails, "binary")
    .toString("base64");
  const ORG_ID = orgId;

  function* getConfiguration() {
    try {
      console.log("Configuration", encodedBasicAuth, ORG_ID);
      const api = gqlClient;
      api.setHeader("Authorization", `Basic ${encodedBasicAuth}`);
      api.setHeader("organization", ORG_ID);
      const configResponse = yield call([api, gqlClient.query], {
        // url: getConfigurationUrl,
        query: queries.configuration.fetchConfig,
        variables: {},
      });
      if (!configResponse.ok) return null;
      const { configuration } = configResponse.data;
      return configuration;
    } catch (error) {
      console.log(error);
    }
  }

  function* anonymousSignIn() {
    try {
      const response = yield call(
        restClient.makeRequest,
        "/v1/ano/signin",
        "POST"
      );
      if (response.ok) {
        const { accessToken, refreshToken } = response.data;
        // Dispatch the action to set tokens

        window.localStorage.setItem(ACCESS_TOKEN, accessToken);
        window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
        yield put(setTokens({ accessToken, refreshToken }));
        // Continue with the rest of the bootstrap logic
        // yield call(handleBootstrap);
      } else {
        // Handle the case where the anonymous sign-in fails
        console.error("Anonymous sign-in failed:", response.error);
      }
    } catch (error) {
      console.error("Error during anonymous sign-in:", error);
    }
  }
  function* bootstrap() {
    while (true) {
      yield take(constants.BOOTSTRAP_PENDING);

      // check if there is a token in the async store
      try {
        yield put(storefrontActions.getStorefrontConfigPending());
        let token = window.localStorage.getItem(ACCESS_TOKEN);
        let refreshToken = window.localStorage.getItem(REFRESH_TOKEN);
        const api = gqlClient;
        api.setHeader("Authorization", `Basic ${encodedBasicAuth}`);
        api.setHeader("organization", ORG_ID);
        const configResponse = yield call([api, gqlClient.query], {
          query: queries.configuration.fetchConfig,
          variables: {},
        });
        if(!configResponse?.data?.getConfig) throw console.error("Something Went Wrong While Fetching!");
        console.log("Configuration", configResponse);
        const {enableAuth,isAnonymousWithUserDetails} = configResponse?.data?.getConfig || {};
        console.log(enableAuth,isAnonymousWithUserDetails);
        if (enableAuth){
          if(window.localStorage.getItem(LOGIN_TYPE) !== 'TYPE1'){
            window.localStorage.removeItem(ACCESS_TOKEN);
            window.localStorage.removeItem(REFRESH_TOKEN);
            token = null;
            refreshToken = null;
          }
          window.localStorage.setItem(LOGIN_TYPE,'TYPE1');
        } 
        if (!enableAuth && isAnonymousWithUserDetails){
          window.localStorage.setItem(LOGIN_TYPE,'TYPE2'); 
          yield call(anonymousSignIn);
          token = window.localStorage.getItem(ACCESS_TOKEN);

          refreshToken = window.localStorage.getItem(REFRESH_TOKEN);
        } 
        if (!enableAuth && !isAnonymousWithUserDetails){
          window.localStorage.setItem(LOGIN_TYPE,'TYPE3');
          yield call(anonymousSignIn);
          token = window.localStorage.getItem(ACCESS_TOKEN);

          refreshToken = window.localStorage.getItem(REFRESH_TOKEN);
        }

        //

        if (
          !token ||
          !refreshToken ||
          token === "undefined" ||
          refreshToken === "undefined"
        ) {
          // Call the anonymousSignIn saga to handle anonymous user sign-in
          // yield call(anonymousSignIn);
        }
        // Call getToken API with existing refreshToken

        const tokenResponse = yield call(
          restClient.makeRequest,
          getTokenUrl({ refreshToken }),
          "GET"
        );
        if (!tokenResponse.ok) throw new Failure("tokenResponse error");

        const {
          data: { accessToken: AT, refreshToken: RT, expiresAt },
        } = tokenResponse;
        if (AT && RT) {
          window.localStorage.setItem(ACCESS_TOKEN, AT);
          window.localStorage.setItem(REFRESH_TOKEN, RT);
        } else throw new Failure("tokenResponse error");
        yield put(setTokens(tokenResponse.data));
        const expiresAtMs = getTimeStampFromDateString(expiresAt);

        restClient.setHeader("Authorization", `Bearer ${AT}`);
        gqlClient.setHeader("Authorization", `Bearer ${AT}`);
        deliveryClient.setHeader("Authorization", `Bearer ${AT}`);

        const response = yield call([gqlClient, gqlClient.query], {
          query: queries.auth.getMe,
        });

        // GraphQL has always `errors` field if any error occurs
        if (response.errors) throw new Failure("me");

        // fetch content type definitions
        yield put(commonActions.fetchContentTypeDefinitionsPending());
        // Fetch live channels
        yield put(channelActions.fetchChannelsPending());

        // setUser. This will load auth protected stack
        yield put(authActions.setMe({ me: response.data.me }));
        const profilesResponse = yield call(
          [gqlClient, gqlClient.rawRequest],
          profileQueries.fetchProfiles
        );

        if (profilesResponse.errors || !profilesResponse?.data?.fetchProfiles)
          throw new Failure("prof");

        const profiles = profilesResponse.data.fetchProfiles;

        yield put(
          profileActions.fetchProfilesSuccess({
            data: profiles,
          })
        );
        yield put(authActions.setUserProfiles({ profiles }));
        const me = yield select((state) => state.auth.me);
        const localActiveProfile = window.localStorage.getItem(
          `activeProfile-${me.id}`
        );

        let activeProfile =
          localActiveProfile && JSON.parse(localActiveProfile);
        if (!activeProfile) {
          if (profiles?.count === 1) {
            activeProfile = profiles.list.find(
              (item) => item.isDefault === true
            );
            yield put(
              authActions.setActiveUserProfile({ profile: activeProfile })
            );
            window.localStorage.setItem(
              `activeProfile-${me.id}`,
              JSON.stringify(activeProfile)
            );
            yield put(
              menuActions.fetchHeaderMenuPending({
                locale: activeProfile?.language.storefront,
              })
            );
          } else {
            yield put(
              navigate({ path: "manageProfile", props: { mode: "SELECT" } })
            );
          }
        }
        if (localActiveProfile) {
          activeProfile = JSON.parse(localActiveProfile);
          yield put(
            authActions.setActiveUserProfile({
              profile: activeProfile,
            })
          );
          yield put(
            menuActions.fetchHeaderMenuPending({
              locale: activeProfile?.language.storefront,
            })
          );
        }
        activeProfile?.language.storefront ? window.localStorage.setItem(
          "locale",
          activeProfile?.language.storefront
        ) : window.localStorage.setItem('locale','en');
        // Fetch header menu items
        // yield put(menuActions.fetchHeaderMenuPending({locale: activeProfile?.language.storefront}));
        // wait until the content type definitions and header menu items are fetched or the fetch failed
        yield take([
          commonConstants.FETCH_CONTENT_TYPE_DEFINITIONS_SUCCESS,
          commonConstants.FETCH_CONTENT_TYPE_DEFINITIONS_FAILURE,
          menuConstants.FETCH_HEADER_MENU_SUCCESS,
          menuConstants.FETCH_HEADER_MENU_FAILURE,
          channelConstants.FETCH_CHANNELS_SUCCESS,
          channelConstants.FETCH_CHANNELS_FAILURE,
        ]);
        if (activeProfile?.id) {
          restClient.setHeader("Profile", activeProfile?.id);
          gqlClient.setHeader("Profile", activeProfile?.id);
          deliveryClient.setHeader("Profile", activeProfile?.id);
          yield put(navigate({ path: "home", props: {} }));
        }
        // Start watching the token refresh status
        yield put(
          authActions.startTokenRefreshWatcher({ expiresAt: expiresAtMs })
        );
        yield put(actions.bootstrapSuccess());
      } catch (e) {
        // Fail silently if an error occurs in bootstrap as this is redirect to
        // login page anyways.
        console.log(e);
        yield put(authActions.setMe({ user: null }));
        yield put(actions.bootstrapFailure());
      }
    }
  }

  function* watcher() {
    yield fork(bootstrap);
  }

  return {
    watcher,
  };
}
