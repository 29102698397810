import kind from "@enact/core/kind";
import Spottable from "@enact/spotlight/Spottable";
import { useDispatch } from 'react-redux';
import Item from '@enact/sandstone/Item';
import authActions from '../../store/modules/auth/actions';
import {navigate} from '../../store/modules/navigate/actions';
import Language from "../Profile/ManageLanguage";
import { toIString } from "@enact/i18n/$L";

const SettingsBase = ({...rest})=>{

    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(authActions.logoutPending());
        dispatch(navigate({path:'/signin',props:{}}))
    }
    const handleProfile = () => {
        dispatch(navigate({path:'/manageProfile',props:{}}))
    }
    const handleDevices = () => {
        dispatch(navigate({path:'/manageDevice', props:{}}))
    }
    const handleLanguage = () => {
        dispatch(navigate({path:'/manageLanguage', props:{}}))
    }
    return (
        <>
            <Item onClick={handleProfile} className='selected m-0'>{toIString('MANAGE_PROFILES').toString()}</Item>
            <Item onClick={handleDevices} className='selected m-0'>{toIString('MANAGE_DEVICES').toString()}</Item>
            <Item onClick={handleLanguage} className='selected m-0'>{toIString('MANAGE_LANGUAGES').toString()}</Item>
            <Item onClick={handleLogout} className='selected m-0'>{toIString('LOGOUT').toString()}</Item>
        </>
    )
}

const Settings = kind({
    name: 'Settings',
    styles:{},
    render: ({onNavigate, path, ...rest}) => (
        <SettingsBase/>
	)
});

export default Spottable(Settings);