export const constants = {
    FETCH_EPG_PENDING: 'EPG/FETCH_EPG_PENDING',
    FETCH_EPG_SUCCESS: 'EPG/FETCH_EPG_SUCCESS',
    FETCH_EPG_FAILURE: 'EPG/FETCH_EPG_FAILURE'
  };
  
  export const fetchEpgPending = payload => ({
    type: constants.FETCH_EPG_PENDING,
    payload
  });
  
  export const fetchEpgSuccess = payload => ({
    type: constants.FETCH_EPG_SUCCESS,
    payload
  });
  
  export const fetchEpgFailure = () => ({
    type: constants.FETCH_EPG_FAILURE
  });
  
  export default {
    fetchEpgPending,
    fetchEpgFailure,
    fetchEpgSuccess
  };
  