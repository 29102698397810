import { fork, put, call, takeEvery, take } from 'redux-saga/effects';

import contentActions, { constants } from './actions';
import { constants as authConstants } from '../auth/actions';

import queries from './queries';
import mutations from './mutations';
import { Failure, getCdnDomainName } from '../../../lib/utilities';

const contentsSaga = ({ gqlClient }) => {
  function* _getContentsByIdsWatcher({ payload: { ids, locale } }) {
    try {
      const variables = { ids, locale };
      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.getContentsByIds,
        variables,
      });

      if (response.errors || !response.data?.searchContent) throw new Failure();

      const { data: contents, cdn } = response.data.searchContent;
      const cdnDomainName = getCdnDomainName(cdn);

      yield put(
        contentActions.getContentsByIdsSuccess({
          ids,
          cdn,
          contents,
          cdnDomainName,
        }),
      );
    } catch (e) {
      const errorMessage =
        (e instanceof Failure && e.message) || 'Something went wrong';
      yield put(
        contentActions.getContentsByIdsFailure({
          ids,
          reason: errorMessage,
        }),
      );
    }
  }

  function* _getContentsByQueryNameWatcher({
    payload: { queryName, skip, limit = 10 },
  }) {
    try {
      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.searchContent,
        variables: { queryName, skip, limit },
      });

      if (response.errors || !response.data?.searchContent) throw new Failure();

      const { data: contents, cdn, count } = response.data.searchContent;

      const cdnDomainName = getCdnDomainName(cdn);

      yield put(
        contentActions.getContentsByQueryNameSuccess({
          queryName,
          cdn,
          cdnDomainName,
          contents,
          count,
          isMoreContentAvailable: contents.length < count,
        }),
      );
    } catch (e) {
      const errorMessage =
        (e instanceof Failure && e.message) || 'Something went wrong';
      yield put(
        contentActions.getContentsByQueryNameFailure({
          queryName,
          reason: errorMessage,
        }),
      );
    }
  }

  function* _getContentsByCollectionWatcher({
    payload: { slug, skip, limit = 10 },
  }) {
    try {
      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.fetchContentGroup,
        variables: { slug, skip, limit },
      });

      if (response.errors || !response.data?.fetchContentGroup)
        throw new Failure();

      const { name, contentData } = response.data.fetchContentGroup;
      const { cdn, count, list } = contentData;

      const cdnDomainName = getCdnDomainName(cdn);
      yield put(
        contentActions.getContentsByCollectionSuccess({
          slug,
          cdnDomainName,
          cdn,
          contents: list,
          count,
          name,
        }),
      );
    } catch (e) {
      const errorMessage =
        (e instanceof Failure && e.message) || 'Something went wrong';
      yield put(
        contentActions.getContentsByCollectionFailure({
          slug,
          reason: errorMessage,
        }),
      );
    }
  }

  function* _getContentAccessibilityWatcher({ payload: { id } }) {
    try {
      const response = yield call(
        [gqlClient, gqlClient.rawRequest],
        queries.getContentAccessibility,
        {
          contentId: id,
        },
      );

      if (response.errors || !response.data) throw new Failure();

      const { isUserEligibleToWatch } = response.data;

      yield put(
        contentActions.getContentsAccessibilityByIdSuccess({
          id,
          isUserEligibleToWatch,
        }),
      );
    } catch (e) {
      const errorMessage =
        (e instanceof Failure && e.message) || 'Something went wrong';
      yield put(
        contentActions.getContentsAccessibilityByIdFailure({
          id,
          reason: errorMessage,
        }),
      );
    }
  }

  function* _getContentWatchTimeWatcher({ payload: { id } }) {
    try {
      const response = yield call(
        [gqlClient, gqlClient.rawRequest],
        queries.getContentWatchDuration,
        { contentId: id },
      );

      if (response.errors || !response.data) throw new Failure();

      const { getContentWatchDuration } = response.data;

      yield put(
        contentActions.getContentWatchTimeSuccess({
          id,
          getContentWatchDuration,
        }),
      );
    } catch (e) {
      const errorMessage =
        (e instanceof Failure && e.message) || 'Something went wrong';
      yield put(
        contentActions.getContentWatchTimeFailure({ id, reason: errorMessage }),
      );
    }
  }

  function* _updateContentWatchTimeWatcher({ payload: { id, duration } }) {
    try {
      const response = yield call(
        [gqlClient, gqlClient.rawRequest],
        mutations.updateContentWatchDuration,
        {
          contentId: id,
          duration,
        },
      );
      if (response.errors || !response.data?.updateDuration)
        throw new Failure();

      yield put(contentActions.updateContentWatchTimeSuccess({id}));
    } catch (e) {
      const errorMessage =
        (e instanceof Failure && e.message) || 'Something went wrong';
      yield put(
        contentActions.updateContentWatchTimeFailure({
          id,
          reason: errorMessage,
        }),
      );
    }
  }

  function* getContentsByIdsWatcher() {
    yield takeEvery(
      constants.GET_CONTENTS_BY_IDS_PENDING,
      _getContentsByIdsWatcher,
    );
  }

  function* getContentsByQueryNameWatcher() {
    yield takeEvery(
      constants.GET_CONTENTS_BY_QUERY_NAME_PENDING,
      _getContentsByQueryNameWatcher,
    );
  }

  function* getContentAccessibilityWatcher() {
    yield takeEvery(
      constants.GET_CONTENT_ACCESSIBILITY_BY_IDS_PENDING,
      _getContentAccessibilityWatcher,
    );
  }

  function* getContentWatchTimeWatcher() {
    yield takeEvery(
      constants.GET_CONTENT_WATCH_TIME_PENDING,
      _getContentWatchTimeWatcher,
    );
  }

  function* updateContentWatchTimeWatcher() {
    yield takeEvery(
      constants.UPDATE_CONTENT_WATCH_TIME_PENDING,
      _updateContentWatchTimeWatcher,
    );
  }

  function* getContentsByCollectionWatcher() {
    yield takeEvery(
      constants.GET_CONTENTS_BY_COLLECTION_PENDING,
      _getContentsByCollectionWatcher,
    );
  }

  function* logOutWatcher() {
    yield take(authConstants.LOGOUT_PENDING);
    yield put(contentActions.resetContentsState());
  }

  function* watcher() {
    yield fork(getContentsByIdsWatcher);
    yield fork(getContentsByQueryNameWatcher);
    yield fork(getContentsByCollectionWatcher);
    yield fork(getContentAccessibilityWatcher);
    yield fork(getContentWatchTimeWatcher);
    yield fork(updateContentWatchTimeWatcher);
    yield fork(logOutWatcher);
  }

  return {
    watcher,
  };
};

export default contentsSaga;
