export const constants = {
  BOOTSTRAP_PENDING: 'bootstrap/BOOTSTRAP_PENDING',
  BOOTSTRAP_SUCCESS: 'bootstrap/BOOTSTRAP_SUCCESS',
  BOOTSTRAP_FAILURE: 'bootstrap/BOOTSTRAP_FAILURE',
};

const bootstrapPending = () => ({
  type: constants.BOOTSTRAP_PENDING,
});

const bootstrapSuccess = () => ({
  type: constants.BOOTSTRAP_SUCCESS,
});

const bootstrapFailure = () => ({
  type: constants.BOOTSTRAP_FAILURE
})

export default {
  bootstrapPending,
  bootstrapSuccess,
  bootstrapFailure
};
