import gql from 'graphql-tag';

const getDevices = gql`
  query devices {
    devices {
      count
      list {
        id
        deviceId
        meta {
          device
          location
        }
      }
    }
  }
`;

const fetchOrderDetails = gql`
  query fetchOrderDetails($limit: Int, $skip: Int) {
    transactionHistory(limit: $limit, skip: $skip) {
      count
      transactions {
        amount
        status
        expiryDate
        startDate
        currencyCode
        paymentMethod
        paymentProvider
        subscriptionPlan {
          name
        }
        id
      }
    }
  }
`;

const fetchProfiles = gql`
  query fetchProfiles {
    fetchProfiles {
      count
      list {
        name
        isDefault
        id
        avatar {
          baseurl
          key
        }
        language {
          content
          storefront
        }
      }
    }
  }
`;

const createProfileMutation = gql`
  mutation createProfileMutation($input: ProfileInput) {
    createProfileMutation(input: $input) {
      name
      id
      language {
        content
        storefront
      }
      avatar {
        baseurl
        key
      }
    }
  }
`;

const updateProfileMutation = gql`
  mutation updateProfileMutation($input: updateProfileDetails, $id: String!) {
    updateProfileMutation(input: $input, id: $id) {
      id
      name
      isDefault
      language {
        content
        storefront
      }
      avatar {
        baseurl
        key
      }
    }
  }
`;

const deleteProfileMutation = gql`
  mutation deleteProfileMutation($id: String) {
    deleteProfileMutation(id: $id)
  }
`;

export default {
  getDevices,
  fetchProfiles,
  fetchOrderDetails,
  createProfileMutation,
  deleteProfileMutation,
  updateProfileMutation,
};
