import {
  put,
  take,
  fork,
  call,
  select,
  delay,
  takeLatest,
} from 'redux-saga/effects';
import deviceinfo from '@enact/webos/deviceinfo';
import actions, { constants } from './actions';
import bootstrapActions from '../bootstrap/actions';
import LS2Request from '@enact/webos/LS2Request';
import { ACCESS_TOKEN, REFRESH_TOKEN, ERROR_CODES } from '../../../lib/constants';
import { getTimeStampFromDateString, Failure } from '../../../lib/utilities';

import * as urls from '../../../lib/urls';

export default function({ restClient, deliveryClient, gqlClient }) {
  function* pollForTokenRefresh() {
    // Refresh the token 1hr before it expires
    try {
      const { expiresAt } = yield select(state => state.auth);

      const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);

      if (!expiresAt && (!refreshToken || refreshToken === 'undefined')) throw new Failure();

      const waitTime = expiresAt > Date.now() + 360000 ? 3600000 : 0;

      // Wait until a safe expiration time
      yield delay(waitTime);

      // get new token
      const tokenResponse = yield call(
        restClient.makeRequest,
        urls.getTokenUrl({ refreshToken }),
        'GET',
      );

      const {
        data: { accessToken: AT, refreshToken: RT, expiresAt: nextExpiryTime },
      } = tokenResponse;
      if(AT && RT){
        window.localStorage.setItem(ACCESS_TOKEN, AT);
        window.localStorage.setItem(REFRESH_TOKEN,RT);

        restClient.setHeader('Authorization', `Bearer ${AT}`);
        gqlClient.setHeader('Authorization', `Bearer ${AT}`);
        deliveryClient.setHeader('Authorization', `Bearer ${AT}`);
      }

      const expiresAtMs = getTimeStampFromDateString(nextExpiryTime);

      yield put(
        actions.setAuthVariables({expiresAt:expiresAtMs})
      );
      // restart the poll refresh new token
      yield put(actions.startTokenRefreshWatcher({expiresAt:expiresAtMs}));
    } catch (e) {
      // do nothing
    }
  }

  function* pollForTokenRefreshWatcher() {
    yield takeLatest(
      constants.START_TOKEN_REFRESH_WATCHER,
      pollForTokenRefresh,
    );
  }

  function* fetchAvailableSessionWatcher() {
    while (true) {
      try {
        yield take(constants.FETCH_AVAILABLE_SESSION_PENDING);
        function getDevice() {
          return new Promise((resolve, reject) => {
            new LS2Request().send({
              service: 'luna://com.webos.service.sm',
              method: 'deviceid/getIDs',
              parameters: {
                idType: ['LGUDID'],
              },
              onSuccess: function (inResponse) {
                const device = inResponse.idList;
                resolve(device);
              },
              onFailure: function (inError) {
                console.log('Failed to get system ID information');
                console.log('[' + inError.errorCode + ']: ' + inError.errorText);
                reject(inError);
              },
            });
          });
        }
        const device = yield getDevice();
        function getDeviceInfo() {
          return new Promise((resolve, reject) => {
            deviceinfo((info)=> resolve(info), err => reject(err));
          })
        }
        const deviceInfo = yield getDeviceInfo();
        const response = yield call(
          restClient.makeRequest,
          urls.verifyDeviceV1(),
          'POST',
          {
            storefront: 'app',
            deviceId: device[0].idValue,
            deviceMeta: {
              brand: 'LG TV',
              sdkVersion: deviceinfo.sdkVersion,
              model: deviceInfo.modelName,
              version: deviceInfo.version,
              isUhd: deviceinfo.uhd,
              isApp: true,
              isTv: true,
            },
          },
        );

        if (!response.ok) throw new Failure(response);

        const {
          deviceLimitExceeds,
          accessToken,
          refreshToken,
          expiresAt,
        } = response.data;

        // Again if device limit exceeds, throw error
        if (deviceLimitExceeds)
          throw new Failure('Please remove any one of the active devices');
        if(!(accessToken && refreshToken)) throw new Failure('Token error')
        yield put(actions.fetchAvailableSessionSuccess());

        // Convert expiresAt date to utc epoch timestamp
        const expiresAtMs = getTimeStampFromDateString(expiresAt);
        yield put(actions.startTokenRefreshWatcher());

        restClient.setHeader('Authorization', `Bearer ${accessToken}`);
        gqlClient.setHeader('Authorization', `Bearer ${accessToken}`);
        deliveryClient.setHeader('Authorization', `Bearer ${accessToken}`);

        if( typeof window !== 'undefined') {
          window.localStorage.setItem(ACCESS_TOKEN,accessToken)
          window.localStorage.setItem(REFRESH_TOKEN, refreshToken)
        }
        yield put(actions.setAuthVariables({ expiresAt: expiresAtMs }));

        yield put(actions.resetAuthToInit());
        yield put(actions.signinSuccess({accessToken,refreshToken,expiresAt}));

        // After success full response trigger bootstrap
        yield put(bootstrapActions.bootstrapPending());
      } catch (e) {
        const errorMessage =
          (e instanceof Failure && e.message) || 'Something went wrong';
        yield put(
          actions.fetchAvailableSessionFailure({
            reason: errorMessage,
          }),
        );
      }
    }
  }

  function* signinWatcher() {
    while (true) {
      try {
        const { payload } = yield take(constants.SIGNIN_PENDING);

        const { isPhoneOTPVerificationAllowed, ...restPayload } = payload;

        const url = isPhoneOTPVerificationAllowed
          ? urls.authV2SignIn()
          : urls.authV1SignIn();
        // function getDevice() {
        //   return new Promise((resolve, reject) => {
        //     new LS2Request().send({
        //       service: 'luna://com.webos.service.sm',
        //       method: 'deviceid/getIDs',
        //       parameters: {
        //         idType: ['LGUDID'],
        //       },
        //       onSuccess: function (inResponse) {
        //         const device = inResponse.idList;
        //         resolve(device);
        //       },
        //       onFailure: function (inError) {
        //         console.log('Failed to get system ID information');
        //         console.log('[' + inError.errorCode + ']: ' + inError.errorText);
        //         reject(inError);
        //       },
        //     });
        //   });
        // }
        // const device = yield getDevice();
        function getDeviceInfo() {
          return new Promise((resolve, reject) => {
            deviceinfo((info)=> resolve(info), err => reject(err));
          })
        }
        // const deviceInfo = yield getDeviceInfo();
        const response = yield call(restClient.makeRequest, url, 'POST', {
          ...restPayload,
          storefront: 'app',
          // deviceId: device[0].idValue,
          deviceMeta: {
            brand: 'LG TV',
            // sdkVersion: deviceinfo.sdkVersion,
            // model: deviceInfo.modelName,
            // version: deviceInfo.version,
            // isUhd: deviceinfo.uhd,
            isApp: true,
            isTv: true,
          },
        });

        if (!response.ok)
          throw new Failure(response.data && response.data.message);

        const {
          data: { accessToken, refreshToken, expiresAt, deviceLimitExceeds },
        } = response;

        restClient.setHeader('Authorization', `Bearer ${accessToken}`);
        gqlClient.setHeader('Authorization', `Bearer ${accessToken}`);
        deliveryClient.setHeader('Authorization', `Bearer ${accessToken}`);

        /**
         * Check no. of active sessions on various devices
         * After user identity is verified and is authenticated,
         * If no. of active device limit is crossed then ask them to remove any one the current active devices
         */
        if (deviceLimitExceeds)
          throw new Failure(ERROR_CODES.auth.deviceLimitExceeded);
        if( typeof window !== 'undefined') {
          window.localStorage.setItem(ACCESS_TOKEN,accessToken)
          window.localStorage.setItem(REFRESH_TOKEN, refreshToken)}

        // Convert expiresAt date to utc epoch timestamp
        const expiresAtMs = getTimeStampFromDateString(expiresAt);

        // Setting refresh token to store along with accessToken and expiresAt
        yield put(actions.startTokenRefreshWatcher({ expiresAt: expiresAtMs }));

        // After success full response trigger bootstrap
        yield put(bootstrapActions.bootstrapPending());

        yield put(actions.signinSuccess({accessToken,refreshToken,expiresAt}));
      } catch (e) {
        const errorMessage =
          (e instanceof Failure && e.message) || 'Something went wrong';
        yield put(actions.signinFailure({ reason: errorMessage }));
      }
    }
  }

  function* logoutWatcher() {
    while (true) {
      try {
        yield take(constants.LOGOUT_PENDING);

        const response = yield call(restClient.makeRequest, urls.signOut());
        const me = yield select(state => state.auth.me);
        if (!response.ok) throw new Failure();

        window.localStorage.removeItem(ACCESS_TOKEN);
        window.localStorage.removeItem(REFRESH_TOKEN);
        restClient.setHeader('Authorization', null);
        deliveryClient.setHeader('Authorization', null);
        gqlClient.setHeader('Authorization', null);

        window.localStorage.removeItem(`activeProfile-${me.id}`);

        restClient.setHeader('Profile', null);
        gqlClient.setHeader('Profile', null);
        deliveryClient.setHeader('Profile', null);

        yield put(actions.setActiveUserProfile({ profile: null }));

        yield put(actions.logoutSuccess());
      } catch (e) {
        yield put(actions.logoutFailure());
      }
    }
  }

  function* signup() {
    while (true) {
      try {
        const { payload } = yield take(constants.SIGNUP_PENDING);

        const { isPhoneOTPVerificationAllowed, ...restPayload } = payload;

        const url = isPhoneOTPVerificationAllowed
          ? urls.authV2SignUp()
          : urls.authV1SignUp();

        // const device = yield DeviceInfo.getDevice();
        // const deviceType = yield DeviceInfo.getDeviceType();
        // const carrier = yield DeviceInfo.getCarrier();
        // const deviceName = yield DeviceInfo.getDeviceName();
        // const systemName = yield DeviceInfo.getSystemName();
        // const isEmulator = yield DeviceInfo.isEmulator();
        // const isTablet = yield DeviceInfo.isTablet();

        const response = yield call(restClient.makeRequest, url, 'POST', {
          ...restPayload,
          storefront: 'app',
          // deviceId: DeviceInfo.getUniqueId(),
          deviceMeta: {
            // manufacturer: DeviceInfo.getManufacturer(),
            // brand: DeviceInfo.getBrand(),
            // applicationBuildNumber: DeviceInfo.getBuildNumber(),
            // model: DeviceInfo.getModel(),
            // version: DeviceInfo.getVersion(),
            // carrier,
            // device,
            // deviceType,
            // deviceName,
            // systemName,
            // isEmulator,
            // isTablet,
            isApp: true,
          },
        });

        if (!response.ok)
          throw new Failure(response.data && response.data.message);

        const {
          data: { accessToken, refreshToken, expiresAt },
        } = response;

        restClient.setHeader('Authorization', `Bearer ${accessToken}`);
        gqlClient.setHeader('Authorization', `Bearer ${accessToken}`);
        deliveryClient.setHeader('Authorization', `Bearer ${accessToken}`);

        // yield call(AsyncStorage.setItem, ACCESS_TOKEN, accessToken);
        // yield call(AsyncStorage.setItem, REFRESH_TOKEN, refreshToken);

        // Convert expiresAt date to utc epoch timestamp
        const expiresAtMs = getTimeStampFromDateString(expiresAt);

        // Setting refresh token to store along with accessToken and expiresAt
        yield put(actions.setAuthVariables({ expiresAt: expiresAtMs }));

        // After success full signup response trigger bootstrap pending
        yield put(bootstrapActions.bootstrapPending());
        yield put(actions.signupSuccess());
      } catch (e) {
        const errorMessage =
          (e instanceof Failure && e.message) || 'Something went wrong';
        yield put(actions.signupFailure({ reason: errorMessage }));
      }
    }
  }

  function* checkUserExistenceWatcher() {
    while (true) {
      try {
        const {
          payload: { email, phone },
        } = yield take(constants.CHECK_USER_EXISTENCE_PENDING);
        let responses = {};

        if (email) {
          const emailExistence = yield call(
            restClient.makeRequest,
            urls.getUserExistence({ email }),
            'GET',
          );
          responses = {
            email: emailExistence.ok ? emailExistence.data : null,
          };
        }

        if (phone) {
          const phoneExistence = yield call(
            restClient.makeRequest,
            urls.getUserExistence({ phone }),
            'GET',
          );
          responses = {
            ...responses,
            phone: phoneExistence.ok ? phoneExistence.data : null,
          };
        }

        yield put(actions.checkUserExistenceSuccess(responses));
      } catch (e) {
        yield put(actions.checkUserExistenceFailure('Something went wrong'));
      }
    }
  }

  function* watcher() {
    yield fork(pollForTokenRefreshWatcher);
    yield fork(checkUserExistenceWatcher);
    yield fork(fetchAvailableSessionWatcher);
    yield fork(signinWatcher);
    yield fork(signup);
    yield fork(logoutWatcher);
  }

  return {
    watcher,
  };
}
