import { constants } from './actions';

const INITIAL_STATE = {
  // This state will contain the content types by
  // their query names and should be used to
  // identify if a field is shown on storefront
  contentTypeDefinitionsByQueryName: {},
  locationCode: '',
  loading: {
    locationCode: false,
    contentTypeDefinitions: false,
  },
  success: {
    locationCode: false,
    contentTypeDefinitions: false,
  },
  error: {
    locationCode: false,
    contentTypeDefinitions: false,
  },
};

export default function common(state = INITIAL_STATE, action) {
  const { payload, type } = action;

  switch (type) {
    case constants.FETCH_CONTENT_TYPE_DEFINITIONS_PENDING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          contentTypeDefinitions: false,
        },
        error: {
          ...state.error,
          contentTypeDefinitions: false,
        },
      };
    }

    case constants.FETCH_CONTENT_TYPE_DEFINITIONS_FAILURE: {
      return {
        ...state,
        loading: {
          ...state.loading,
          contentTypeDefinitions: false,
        },
        error: {
          ...state.error,
          contentTypeDefinitions: true,
        },
      };
    }

    case constants.FETCH_CONTENT_TYPE_DEFINITIONS_SUCCESS: {
      return {
        ...state,
        contentTypeDefinitionsByQueryName: action.payload.definitions,
        loading: {
          ...state.loading,
          contentTypeDefinitions: false,
        },
        error: {
          ...state.error,
          contentTypeDefinitions: false,
        },
      };
    }

    case constants.GET_LOCATION_CODE_PENDING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          locationCode: true,
        },
      };
    }

    case constants.GET_LOCATION_CODE_SUCCESS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          locationCode: false,
        },
        success: {
          ...state.success,
          locationCode: true,
        },
        locationCode: payload,
      };
    }

    case constants.GET_LOCATION_CODE_FAILURE: {
      return {
        ...state,
        loading: {
          ...state.loading,
          locationCode: false,
        },
        success: {
          ...state.success,
          locationCode: false,
        },
        error: {
          ...state.error,
          locationCode: true,
        },
      };
    }

    case constants.RESET_LOCATION_CODE: {
      return {
        ...state,
        locationCode: '',
      };
    }

    default:
      return state;
  }
}
