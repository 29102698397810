import React from 'react';
import kind from '@enact/core/kind';
import Button from '@enact/sandstone/Button';
import Spottable from '@enact/spotlight/Spottable'

const PrimaryButton = kind({
  name: 'PrimaryButton',
  styles: {
    // publicClassNames: true
  },
  render: ({ children, icon, iconPosition, roundedBorder, size, disabled, onClick,spotlightId, className, ...rest }) =>  (
    <Button
      size={size}
      className={className + "bg-primary"}
      icon={icon}
      roundBorder={roundedBorder}
      iconPosition={iconPosition}
      disabled={disabled}
      spotlightId={spotlightId}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  )
});

PrimaryButton.defaultProps = {
  disabled: false
};

export default Spottable(PrimaryButton);
