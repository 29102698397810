import { combineReducers } from 'redux';

import app from './app/reducer';
import auth from './auth/reducer';
import pages from './pages/reducer';
import bootstrap from './bootstrap/reducer';
import staticSections from './staticSections/reducer';
import contents from './contents/reducer';
import episodes from './episodes/reducer';
import seasons from './seasons/reducer';
import search from './search/reducer';
import menuItems from './menuItems/reducer';
import subscriptions from './subscriptions/reducers';
import profile from './profile/reducer';
import player from './player/reducer';
import playlist from './playlist/reducer';
import deviceManagement from './deviceManagement/reducer';
import common from './common/reducer';
import storefront from './storefront/reducer';
import favorites from './favorites/reducer';
import actionSheet from './actionSheet/reducer';
import relatedContent from './relatedContent/reducer';
import channels from './channels/reducer';
import language from './language/reducer';
import payment from './payment/reducer';
import ageRestriction from './ageRestriction/reducer';
import forgotPassword from './forgotPassword/reducer';
import otherContents from './otherContent/reducer';
import navigate from './navigate/reducer';
import epg from './epg/reducer';

export default combineReducers({
  actionSheet,
  ageRestriction,
  app,
  auth,
  bootstrap,
  channels,
  epg,
  common,
  contents,
  deviceManagement,
  episodes,
  favorites,
  forgotPassword,
  language,
  menuItems,
  pages,
  payment,
  player,
  playlist,
  profile,
  relatedContent,
  search,
  seasons,
  staticSections,
  storefront,
  subscriptions,
  otherContents,
  navigate
});
