import { TabLayout, Tab } from "@enact/sandstone/TabLayout";
import { useDispatch, useSelector } from "react-redux";
import kind from "@enact/core/kind";
import Search from "../Search";
import { useState, useEffect } from "react";
import Home from "../Home";
import PageView from "../../views/PageView";
import $L, { toIString } from "@enact/i18n/$L";
import {
  SpotlightContainerDecorator,
  spotlightDefaultClass,
} from "@enact/spotlight/SpotlightContainerDecorator";
import assets from "../../assets/icons";
import { getMenuThemeIconUrl } from "../../lib/urls";
import IconItem from "@enact/sandstone/IconItem";
import c from "../../custom.css";
import Settings from "../Settings/Settings";
import menuActions from "../../store/modules/menuItems/actions";
// import liveChannelActions from '~/store/modules/channels/actions';
import { I18nContextDecorator } from "@enact/i18n/I18nDecorator";
import { dummy, navigate } from "../../store/modules/navigate/actions";
import bootstrapActions from "../../store/modules/bootstrap/actions";
import SplashScreen from "../Splash";
import { useRef } from "react";
import Item from "@enact/sandstone/Item";
import CollectionView from "../../views/CollectionView";
import { Panel } from "@enact/sandstone/Panels";
import HtmlPageView from "../../views/HtmlPageView";
import ChannelGuide from "../ChannelGuide";
import { setMenuIndex } from "../../store/modules/navigate/actions";

const MainBase = ({ updateLocale, locale, ...rest }) => {
  const dispatch = useDispatch();
  const { menuIndex, props } = useSelector((state) => state.navigate);
  const [index, setIndex] = useState(menuIndex);
  const headerMenu = useSelector((state) => state.menuItems.headerMenuTree);
  const storefrontConfig = useSelector((state) => state.storefront.config);
  const user = useSelector((state) => state.auth.me);
  const { loading, success } = useSelector((state) => state.bootstrap);
  const isAnonymous = useSelector((state) => state.auth.provider);
  const userRef = useRef(user);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [initalLoad, setInitialLoad] = useState(true);
  useEffect(() => {
    userRef.current = user;
  }, [user]);
  useEffect(() => {
    if (!user) dispatch(bootstrapActions.bootstrapPending());
    else
      dispatch(
        menuActions.fetchHeaderMenuPending({
          locale:
            window.localStorage.getItem("locale") ||
            storefrontConfig.data?.defaultDisplayLanguage.current,
        })
      );
  }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!userRef.current && !loading && !success) {
        dispatch(
          menuActions.fetchHeaderMenuPending({
            locale:
              window.localStorage.getItem("locale") ||
              storefrontConfig.data?.defaultDisplayLanguage.current,
          })
        );
        dispatch(navigate({ path: "signin", props: {} }));
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [dispatch]);
  const tabSelectionHandler = (e, i, event) => {
    if (event === "tab" || e.keyCode === 13) {
      dispatch(setMenuIndex(i));
      setIndex(i);
    }
  };
  const renderMenuItems = () => {
    // If the header menu is not fetched yet, or if home page is the only header
    // menu item, do not run this function as home page is shown by default
    if (!headerMenu || headerMenu?.length === 1) return null;
    const filteredMenuList = headerMenu.map((menu, i) => {
      if (menu.title === "Home") {
        return (
          <Tab
            className="m-0"
            onTabClick={(e) => {
              tabSelectionHandler(e,i + 1,"tab");
            }}
            key={menu.title}
            // Slug is passed to load the page config
            title={toIString("HOME").toString()}
            icon={getMenuThemeIconUrl(
              storefrontConfig.data?.menuTheme,
              menu?.iconUrl
            )}
          >
            <Home {...rest} />
          </Tab>
        );
      } else if (menu.menuTargetType === "Page") {
        return (
          <Tab
            className="m-0"
            onTabClick={(e) => {
              tabSelectionHandler(e,i + 1,"tab");
            }}
            key={menu.title}
            // Slug is passed to load the page config
            title={menu.title}
            icon={getMenuThemeIconUrl(
              storefrontConfig.data?.menuTheme,
              menu?.iconUrl
            )}
          >
            <PageView slug={menu.pageSlug} subMenu={menu.children} />
          </Tab>
        );
      } else if (menu.menuTargetType === "Content Group") {
        return (
          <Tab
            className="m-0"
            onTabClick={(e) => {
              tabSelectionHandler(e,i + 1,"tab");
            }}
            key={menu.title}
            // Slug is passed to load the page config
            title={menu.title}
            icon={getMenuThemeIconUrl(
              storefrontConfig.data?.menuTheme,
              menu?.iconUrl
            )}
          >
            <CollectionView
              pageSlug={menu.pageSlug}
              subMenu={menu.children}
              slug={menu.contentGroupSlug}
            />
          </Tab>
        );
      } else if (menu.menuTargetType === "Link") {
        return (
          <Tab
            className="m-0"
            onTabClick={(e) => {
              tabSelectionHandler(e,i + 1,"tab");
            }}
            key={menu.title}
            title={menu.title}
            icon={getMenuThemeIconUrl(
              storefrontConfig.data?.menuTheme,
              menu?.iconUrl
            )}
          >
            <Item onClick={() => window.open(menu.link)}>Redirect to link</Item>
          </Tab>
        );
      } else if (menu.menuTargetType === "Html Page") {
        return (
          <Tab
            className="m-0"
            onTabClick={(e) => {
              tabSelectionHandler(e,i + 1,"tab");
            }}
            key={menu.title}
            // Slug is passed to load the page config
            title={menu.title}
            icon={getMenuThemeIconUrl(
              storefrontConfig.data?.menuTheme,
              menu?.iconUrl
            )}
          >
            <HtmlPageView menu={menu} />
          </Tab>
        );
      }
    });

    return filteredMenuList;
  };
  return success ? (
    <TabLayout
      index={index}
      className={rest.className}
      collapsed={isCollapsed}
      onExpand={(event) => {
        if (initalLoad) {
          event.preventDefault();
          setInitialLoad(false);
        }else{
          setIsCollapsed(false);
        }
      }}
      onCollapse={() => setIsCollapsed(true)}
    >
      <IconItem
        icon={assets.reviddLogo}
        disabled
        id="logo"
        spotlightDisabled={true}
      />
      {renderMenuItems()}
      <Tab
        className="m-0"
        title={"Channel Guide"}
        icon={"channel"}
        onTabClick={(e) => {
          tabSelectionHandler(e,headerMenu.length + 1,"tab");
        }}
      >
        <ChannelGuide />
      </Tab>
      <Tab
        className="m-0"
        title={toIString("SEARCH").toString()}
        icon={getMenuThemeIconUrl(
          storefrontConfig.data?.menuTheme,
          "ic_search.svg"
        )}
        onTabClick={(e) => {
          tabSelectionHandler(e,headerMenu.length + 2,"tab");
        }}
      >
        <Search />
      </Tab>
      {isAnonymous === "anonymous" ? null : (
        <Tab
          className="m-0"
          title={toIString("PROFILE").toString()}
          icon={getMenuThemeIconUrl(
            storefrontConfig.data?.menuTheme,
            "ic_profile.svg"
          )}
          onTabClick={(e) => {
            tabSelectionHandler(e,headerMenu.length + 3,"tab");
          }}
        >
          <Settings />
        </Tab>
      )}
    </TabLayout>
  ) : (
    <SplashScreen />
  );
};
const Main = kind({
  name: "HomePanel",

  styles: {
    css: {
      c,
    },
    // theme,
    // className: 'app'
  },

  render: ({ onNavigate, path, updateLocale, locale, ...rest }) => (
    <MainBase updateLocale={updateLocale} locale={locale} {...rest} />
  ),
});

export default SpotlightContainerDecorator(
  { continue5WayHold: true, enterTo: "last-focused", preserveId: true },
  Main
);
