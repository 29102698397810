import _ from "lodash";
import PropTypes from "prop-types";

import SectionPanel from "../components/SectionPanel";
import Scroller from "@enact/sandstone/Scroller";
import React, { useEffect, useRef, useState } from "react";
import { spotlightDefaultClass } from "@enact/spotlight/SpotlightContainerDecorator";
import Spottable from "@enact/spotlight/Spottable";
import Spotlight from "@enact/spotlight";
import { useSelector } from "react-redux";

const SectionsList = (props) => {
  const {
    isScreen,
    hasPageBanner,
    page,
    firstSectionItemRef,
    sections,
    renderSectionItem,
    onItemPress,
    // onViewMorePress,
    onNavigationItemPress,
    onItemFocus,
    contentTypesObject,
    scrollRef
  } = props;

  // const _styles = isScreen ? stylesIfIsScreen : styles;
  const _sections = Object.values(sections);
  const [currentSection, setCurrentSection] = useState(-1);
  const isHandlingEvent = useRef(false);
  const magicMouseState = useSelector((state) => state.navigate.magicMouseState);
  const focusedContent = useSelector((state)=>state.navigate.focusedContent);

  useEffect(() => {
    function handleScroll(e) {
      if (!isHandlingEvent?.current && magicMouseState) {
        isHandlingEvent.current = true;
        if (e.deltaY > 0) {
          if (currentSection < _sections.length - 1) {
            setCurrentSection((prevSection) => {
              setTimeout(() => {
                if (
                  Spotlight.getSpottableDescendants(
                    _sections?.[prevSection + 1]?.sectionId
                  )[0]
                ) {
                  Spotlight.getSpottableDescendants(
                    sections?.[prevSection + 1]?.sectionId
                  )[0].focus();
                }
              }, 500);
              return prevSection + 1;
            });
          }
        } else {
          if (currentSection > 0) {
            setCurrentSection((prevSection) => {
              setTimeout(() => {
                if (
                  Spotlight.getSpottableDescendants(
                    _sections?.[prevSection - 1]?.sectionId
                  )[0]
                ) {
                  Spotlight.getSpottableDescendants(
                      sections?.[prevSection - 1]?.sectionId
                    )[0].focus();
                }
              }, 500);
              return prevSection - 1;
            });
          }
        }
        setTimeout(() => {
          isHandlingEvent.current = false;
        }, 400); // Adjust the timeout value as needed
      }
    }
    window.document.addEventListener("wheel", handleScroll);
    return () => {
      window.document.removeEventListener("wheel", handleScroll);
    };
  }, [_sections, currentSection, magicMouseState, scrollRef, sections, setCurrentSection]);

  let panels = [];
  _sections.map((section, index) => {
    const obj = contentTypesObject?.[section?.contentType];
    panels.push(
      <SectionPanel
        path={page}
        section={section}
        sectionIndex={index}
        setCurrentSection={setCurrentSection}
        contentTypesObjects={obj}
        currentSection={currentSection}
        key={index}
      />
    );
  });
  // }
  return panels;
};

SectionsList.propTypes = {
  isScreen: PropTypes.bool,
  firstSectionItemRef: PropTypes.any,
  sections: PropTypes.array,
  page: PropTypes.string,
  renderSectionItem: PropTypes.func,
  onItemPress: PropTypes.func,
  onNavigationItemPress: PropTypes.func,
  // onViewMorePress: PropTypes.func,
  onItemFocus: PropTypes.func,
  scrollRef: PropTypes.object,
};

SectionsList.defaultProps = {
  sections: [],
};
const Container = Spottable(SectionsList); //SpotlightContainerDecorator({continue5WayHold:true,enterTo:'last-focused'},SectionsList);
export default React.memo(Container);
