import gql from 'graphql-tag';

const relatedContent = gql`
  query relatedContent($id: String!, $queryName: String!) {
    related(queryName: $queryName, id: $id) {
      cdn {
        domainName
        aliases
      }
      count
      list
    }
  }
`;

export default {
  relatedContent,
};
