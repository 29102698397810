import { fork, put, call, take } from 'redux-saga/effects';
import queries from '../../graphql/queries';

import playerActions, { constants } from './actions';
import { Failure } from '../../../lib/utilities';

const playerSaga = ({ gqlClient }) => {
  function* getPlayerSettingsWatcher() {
    while (true) {
      yield take(constants.FETCH_PLAYER_SETTINGS_PENDING);
      try {
        const response = yield call([gqlClient, gqlClient.query], {
          query: queries.player.fetchVideoPlayerSettings,
        });

        if (
          response.errors ||
          !response.data ||
          !response.data?.fetchVideoPlayerSettings
        )
          throw new Failure();

        yield put(
          playerActions.fetchPlayerSettingsSuccess({
            payload: response.data?.fetchVideoPlayerSettings,
          }),
        );
      } catch (e) {
        const errorMessage =
          (e instanceof Failure && e.message) || 'Something went wrong';
        yield put(
          playerActions.fetchPlayerSettingsFailure({ reason: errorMessage }),
        );
      }
    }
  }

  function* watcher() {
    yield fork(getPlayerSettingsWatcher);
  }

  return {
    watcher,
  };
};

export default playerSaga;
