import {
  fork,
  put,
  call,
  debounce,
  take,
  takeLatest,
  select,
} from 'redux-saga/effects';

import searchActions, { constants } from './actions';

import queries from '../../graphql/queries';

const searchSaga = ({ gqlClient }) => {
  function* searchContentTypes({ payload: { term } }) {
    // make an api call to fetch the content type array
    try {
      const variables = {
        term,
      };

      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.search.searchContent,
        variables,
      });

      const { data, cdn, count } = response.data.searchContentByTerm;

      yield put(
        searchActions.searchContentTypesSuccess({
          // On empty data is returned as [{}] so convert to []
          data:
            data?.length === 1 && Object.keys(data?.[0])?.length === 0
              ? []
              : data,
          cdn,
          searchTerm: term,
          count,
        }),
      );
    } catch (e) {
      console.log('e in search', e);
      yield put(searchActions.searchContentTypesFailure());
    }
  }

  function* searchContentTypesWatcher() {
    yield takeLatest(
      constants.SEARCH_CONTENT_TYPES_PENDING,
      searchContentTypes,
    );
  }

  function* fetchDefaultSearchContent() {
    while (true) {
      const {
        payload: { limit = 10 },
      } = yield take(constants.FETCH_DEFAULT_SEARCH_CONTENT_PENDING);

      try {
        const skip = yield select(state => state.search.default.data.length);

        const response = yield call([gqlClient, gqlClient.query], {
          query: queries.staticSections.getSectionByDynamicSlug,
          variables: {
            slug: 'popular-videos',
            limit,
            skip,
          },
        });

        const {
          list: data,
          cdn: { domainName: cdnDomainName },
        } = response.data.getVideosByType;

        yield put(
          searchActions.fetchDefaultSearchContentSuccess({
            data,
            cdn: cdnDomainName,
            isMoreContentAvailable: data.length >= limit,
          }),
        );
      } catch (e) {
        yield put(searchActions.fetchDefaultSearchContentFailure());
      }
    }
  }

  function* watcher() {
    yield fork(searchContentTypesWatcher);
    yield fork(fetchDefaultSearchContent);
  }

  return {
    watcher,
  };
};

export default searchSaga;
