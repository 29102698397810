export const constants = {
  FETCH_SERIES_BY_SEASON_PENDING: 'seasons/FETCH_SERIES_BY_SEASON_PENDING',
  FETCH_SERIES_BY_SEASON_SUCCESS: 'seasons/FETCH_SERIES_BY_SEASON_SUCCESS',
  FETCH_SERIES_BY_SEASON_FAILURE: 'seasons/FETCH_SERIES_BY_SEASON_FAILURE',
};

// For fetching a particular season's series
const fetchSeriesBySeasonPending = ({ id }) => ({
  type: constants.FETCH_SERIES_BY_SEASON_PENDING,
  payload: { id },
});

const fetchSeriesBySeasonSuccess = ({ id, data }) => ({
  type: constants.FETCH_SERIES_BY_SEASON_SUCCESS,
  payload: { id, data },
});

const fetchSeriesBySeasonFailure = ({ id, error }) => ({
  type: constants.FETCH_SERIES_BY_SEASON_FAILURE,
  payload: { id, error },
});

export default {
  fetchSeriesBySeasonFailure,
  fetchSeriesBySeasonPending,
  fetchSeriesBySeasonSuccess,
};
