import React, { useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PageBanner from '../components/PageBanner';
import ri from '@enact/ui/resolution';
import { VirtualList, VirtualGridList } from '@enact/sandstone/VirtualList';
import {Image} from '@enact/sandstone/Image';
import {Item} from '@enact/sandstone/Item';

import {getUrlFromContentType} from '../lib/utilities';
import { navigate, dummy } from '../store/modules/navigate/actions';
import contentActions from '../store/modules/contents/actions';
import pageActions from '../store/modules/pages/actions';
import application, { platformBack } from '@enact/webos/application';
import Scroller from '@enact/sandstone/Scroller';

function CollectionView({slug, pageSlug, subMenu}) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.me);
    const {loading} = useSelector(state => state.bootstrap)
    const contentCollection = useSelector(
      state => state.contents.contentsByCollection?.[slug],
    );

    const pageConfig = useSelector(
        state => state.pages?.pageByPageName?.[pageSlug]?.pageConfig,
      );
    useEffect(() => {
    if (!contentCollection?.contents?.length)
        dispatch(
        contentActions.getContentsByCollectionPending({
            slug,
            skip: 0,
            limit: 30,
        }),
        );
    }, [dispatch, slug]);

    useEffect(() => {
    if(!pageConfig && slug) dispatch(pageActions.fetchPageInitialDataPending({ path: pageSlug }));
    // console.log(p.spoton);
    // if(p?.spoton) Spotlight.focus(p.spoton)
    }, [dispatch]);

    const cdnInfo = useSelector(state => state.storefront.config.data?.cdnInfo);
    const uhd = window.innerWidth > 1280 && window.innerHeight > 720;
    const isLandscape = true;
    const renderBanners = () => {
        if (!pageConfig?.banner?.id) return null;
        return (
          <PageBanner
            id={pageConfig.banner.id}
            path={pageConfig.id}
            // containerStyle={styles.banners.container}
            // currentItem={currentItem}
            // nextFocusDown={findNodeHandle(firstSectionItemRef.current)}
            // width={styles.banners.banner.width}
            // height={styles.banners.banner.height}
            // onItemPress={handleSectionItemPress}
            onImageLoad={() => null}
          />
        );
    };
    const renderShimmer = () => {
        return (
          <div
          className={
            'flex flex-col w-full h-auto items-start justify-start space-x-8 transfrom-all duration-500 px-6 pt-16 md:pt-32'
          }
        >
          <div className="w-full h-128 rounded-4 bg-shimmerBg animate-pulse md:mb-40 box-border inline-flex" />
    
          <div className="hidden space-x-8 md:flex">
              <div
                className="md:w-320 rounded-4 bg-shimmerBg animate-pulse"
              />
          </div>
        </div>
        )
      };
      const onDetailClick = (id) => {
        // if(section.title === 'seasons')
        //   dispatch(navigate({path: 'detail/seasons', props: {id} }))
        // else
          dispatch(navigate({path:`detail`,props:{id}}))
        
      };
      let imageTypeArr = ['coverimage', 'detailPageBannerImage', 'coverImageForApple', 'coverImagePortrait'];
      if(!isLandscape)
        imageTypeArr = ['coverImagePortrait', 'detailPageBannerImage', 'coverImageForApple', 'coverimage'];
      const renderItem = ({index, ...rest}) => {
        const width = 190;
        const _coverImageUrl =
          // coverImageUrl && cdnDomainName
          //   ? `https://${cdnDomainName}/${coverImageUrl}`
             getUrlFromContentType(
                contentCollection?.contents?.[index],
                cdnInfo?.imageCdn.url,
                imageTypeArr,
                {
                  width: width * 2
                },
              );
        return (
            <Item {...rest} // onFocus={() => onFocusHandler(index)}
            className={uhd ? (isLandscape ? "w-136 h-96 m-0 p-0" : "w-128 h-192 m-0 p-0") : (isLandscape ? "w-208 h-118 m-0 p-0": "w-170 h-256 m-0 p-0 scale" )} inline style={isLandscape ? {maxWidth:'23rem'} : {maxWidth:'20rem'}}
            onClick={() => onDetailClick(contentCollection?.contents?.[index].id)}
            >
              {/* <Image src={_coverImageUrl} style={{height: 184, width: 324}}/> */} 
                {_coverImageUrl ? <Image src={_coverImageUrl} style={isLandscape ? {height: 184, width: 324} : {height: 410, width: 260, marginInline:2}}/> : renderShimmer()}
            </Item>
        );
      }
    
      const renderSectionList = () => {
        // const overriddenStyles = hasPageBanner
        //   ? { marginTop: 0 }
        //   : { height: '85%' };
          return (
              <>
            <div className='md:text-base text-body font-semibold'>{contentCollection?.name}</div>
            <VirtualList
              itemSize={isLandscape ? ri.scale(330) : ri.scale(260)}
              dataSize={contentCollection?.count}
              direction='horizontal'
              itemRenderer={renderItem}
              className={isLandscape ? 'w-full h-128': uhd ? 'w-full h-200' : 'w-full h-288'}
              key='translate'
              spacing={isLandscape ? 5: 10}
            //   id={section.sectionId}
              horizontalScrollbar='hidden'
            //   cbScrollTo={(fn) => (containerRef.current = fn)}
            />
            </>
          )
      };
    return (
        <Scroller>
            {renderBanners()}
            {renderSectionList()}
        </Scroller>
    )
}

export default CollectionView
