import BodyText from '@enact/sandstone/BodyText';
import ri from '@enact/ui/resolution';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import VirtualList from '@enact/sandstone/VirtualList';
import {navigate} from '../../../store/modules/navigate/actions';
import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import assets from '../../../assets/icons';
import deviceManagementActions from '../../../store/modules/deviceManagement/actions';
import IconItem from '@enact/sandstone/IconItem';
import { Panel } from '@enact/sandstone/Panels';
import { toIString } from '@enact/i18n/$L';

const Devices = props => {
  const { renderHeader, renderFooter, style } = props;

  const dispatch = useDispatch();
  const flatListRef = useRef(null);

  const { loading, error, count, list, deviceDelete } = useSelector(
    state => state.deviceManagement,
  );

  useEffect(() => {
    dispatch(deviceManagementActions.fetchDevicesPending());
  }, [dispatch]);

  const handleDeleteDevice = id => {
    return () => {
      dispatch(deviceManagementActions.deleteDevicePending({ id }));
    };
  };

  const joinString = (...params) => {
    return params.filter(t => t).join(', ');
  };

  const renderDeviceInfo = device => {
    const {
      meta: { device: _device },
    } = device;

    // if the device is app, check for isApp key in device meta
    if (_device.isApp) {
      if (!_device.model && !_device.systemName) return null;
      return (
        <div size="body">
          {joinString(_device.model, _device.systemName)}
        </div>
      );
    }
    if (!_device.browser && !_device.platform) return null;
    return (
      <div size="body">
        {joinString(_device.browser, _device.platform)}
      </div>
    );
  };

  const renderDeviceLocation = device => {
    const {
      meta: { location },
    } = device;
    if (!location.city && !location.country) return null;
    return (
      <div size="body">
        {joinString(location.city, location.country)}
      </div>
    );
  };

  const renderDeviceDeleteAction = device => {
    // if (device.id === deviceDelete.id && deviceDelete.loading)
    //   return <CircularProgress color="primary" />;
    return <IconItem icon={assets.trash} width={20} height={20} onClick={handleDeleteDevice(device.id)}/>;
  };
  const containerRef = useRef(null);

  const onFocusHandler = (index) => {
    containerRef.current({ index: index });
  };
  const renderDevice = ({ index, ...rest }) => {
    return (
      <>
          <div className={"w-vw65 h-52 flex justify-between m-0 p-16 rounded selected"} onFocus={() => onFocusHandler(index)}>
            {/* <div className='flex w-vw65 justify-between'> */}
              <div>
                {renderDeviceInfo(list[index])}
                {renderDeviceLocation(list[index])}
              </div>
            <div>{renderDeviceDeleteAction(list[index])}</div>
            {/* </div> */}
          </div>
          <hr className='w-vw65'/>
      </>
    );
  };

  const renderTotalCount = () => {
    const deviceStr = toIString('TOTAL_DEVICE_COUNT');
    return (
    //   <Flex p={16}>
        <BodyText className='p-16'>{deviceStr.format({count})}</BodyText>
    //   </Flex>
    );
  };

  const renderHeaderFromProp = () => {
    return (typeof renderHeader === 'function' && renderHeader()) || null;
  };
  const renderBackButton = () => {
    return (
      <PrimaryButton data-spotlight-id='btn'
        backgroundOpacity="transparent" icon={assets.backbutton}
        type="button" 
        className="absolute top-10 left-10"
        onClick={()=>dispatch(navigate({path:'home',props:{}}))}/>
    );
  };
  const _renderHeader = () => {
    return (
      <>
        {renderHeaderFromProp()}
        {renderTotalCount()}
      </>
    );
  };

  const _renderFooter = () => {
    return (typeof renderFooter === 'function' && renderFooter()) || null;
  };
  const renderList = () => {
    return (
      <>
      {renderBackButton()}
        {_renderHeader()}
        <VirtualList
          id='devices'
          itemSize={ri.scale(100)}
          dataSize={count}
          itemRenderer={renderDevice}
          direction='vertical'
          className='w-full h-full'
          key='translate'
          spacing={5}
          verticalScrollbar='hidden'
          cbScrollTo={(fn) => (containerRef.current = fn)}
        />
        {_renderFooter()}
      </>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <></>;
    }
    if (error) {
      return <BodyText>{toIString("SOMETHING_WENT_WRONG").toString()}</BodyText>;
    }
    return renderList();
  };

  return <Panel className='py-1/6'>{renderContent()}</Panel>;
};

export default Devices;
