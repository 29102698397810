import gql from 'graphql-tag';

const getContentById = gql`
  query searchContent($ids: [String]) {
    searchContent(ids: $ids) {
      data
      cdn {
        domainName
        aliases
      }
    }
  }
`;

const previousAndNextContent = gql`
  query previousAndNextContent($queryName: String!, $id: String!) {
    previousAndNextContent(id: $id, queryName: $queryName) {
      next
      previous
    }
  }
`;

export default {
  getContentById,
  previousAndNextContent,
};
