import * as R from 'ramda';
import { fork, put, take, call } from 'redux-saga/effects';

import storefrontActions, {
  constants,
} from './actions';

import { constants as authConstants } from '../auth/actions';
import queries from './queries';

import { Failure } from '../../../lib/utilities';

export default function({ gqlClient }) {
  function* getStorefrontConfigWatcher() {
    while (true) {
      try {
        yield take(constants.GET_STOREFRONT_CONFIG_PENDING);

        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          queries.getConfig,
        );

        if (response.data.errors || !response.data || !response.data.getConfig)
          throw new Failure();

        const { getConfig } = response.data;

        const { nameQueryNameMappings } = getConfig;
        const nameQueryNameMappingsObject = R.indexBy(
          R.prop('queryName'),
          nameQueryNameMappings,
        );

        yield put(
          storefrontActions.getStorefrontConfigSuccess({
            config: { ...getConfig, nameQueryNameMappingsObject },
          }),
        );
      } catch (e) {
        const errorMessage =
          (e instanceof Failure && e.message) || 'Something went wrong';
        yield put(
          storefrontActions.getStorefrontConfigFailure({
            reason: errorMessage,
          }),
        );
      }
    }
  }

  /**
   * This function is used to check whether the
   * org has allowed subscriptions on the content.
   */
  function* fetchMonetizationsSettingsWatcher() {
    while (true) {
      try {
        yield take(constants.FETCH_MONETIZATION_SETTINGS_PENDING);

        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          queries.fetchMonetizationSettings,
        );

        if (response.errors || !response.data?.fetchMonetizationSettings)
          throw new Failure();

        yield put(
          storefrontActions.fetchMonetizationSettingsSuccess(
            response.data.fetchMonetizationSettings,
          ),
        );
      } catch (e) {
        const errorMessage =
          (e instanceof Failure && e.message) || 'Something went wrong';
        yield put(
          storefrontActions.fetchMonetizationSettingsFailure({
            reason: errorMessage,
          }),
        );
      }
    }
  }

  function* logOutWatcher() {
    yield take(authConstants.LOGOUT_PENDING);
    yield put(storefrontActions.resetStorefrontState());
  }

  function* watcher() {
    yield fork(getStorefrontConfigWatcher);
    yield fork(fetchMonetizationsSettingsWatcher);
    yield fork(logOutWatcher);
  }

  return {
    watcher,
  };
}
