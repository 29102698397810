export const constants = {
  SET_SEARCH_TERM: 'search/SET_SEARCH_TERM',
  SEARCH_CONTENT_TYPES_PENDING: 'search/SEARCH_CONTENT_TYPES_PENDING',
  SEARCH_CONTENT_TYPES_FAILURE: 'search/SEARCH_CONTENT_TYPES_FAILURE',
  SEARCH_CONTENT_TYPES_SUCCESS: 'search/SEARCH_CONTENT_TYPES_SUCCESS',
  SEARCH_CONTENT_TYPES_RESET: 'search/SEARCH_CONTENT_TYPES_RESET',

  FETCH_DEFAULT_SEARCH_CONTENT_PENDING:
    'search/FETCH_DEFAULT_SEARCH_CONTENT_PENDING',
  FETCH_DEFAULT_SEARCH_CONTENT_SUCCESS:
    'search/FETCH_DEFAULT_SEARCH_CONTENT_SUCCESS',
  FETCH_DEFAULT_SEARCH_CONTENT_FAILURE:
    'search/FETCH_DEFAULT_SEARCH_CONTENT_FAILURE',
};

const setSearchTerm = ({ term }) => ({
  type: constants.SET_SEARCH_TERM,
  payload: { term },
});

const searchContentTypesPending = ({ term, limit, skip }) => ({
  type: constants.SEARCH_CONTENT_TYPES_PENDING,
  payload: { term, limit, skip },
});

const searchContentTypesSuccess = ({ data, count, cdn, searchTerm }) => ({
  type: constants.SEARCH_CONTENT_TYPES_SUCCESS,
  payload: { data, count, cdn, searchTerm },
});

const searchContentTypesFailure = () => ({
  type: constants.SEARCH_CONTENT_TYPES_FAILURE,
});

const searchContentTypesReset = () => ({
  type: constants.SEARCH_CONTENT_TYPES_RESET,
});

const fetchDefaultSearchContentPending = ({ limit, skip } = {}) => ({
  type: constants.FETCH_DEFAULT_SEARCH_CONTENT_PENDING,
  payload: { limit, skip },
});

const fetchDefaultSearchContentSuccess = ({
  data,
  isMoreContentAvailable,
  cdn,
}) => ({
  type: constants.FETCH_DEFAULT_SEARCH_CONTENT_SUCCESS,
  payload: { data, isMoreContentAvailable, cdn },
});

const fetchDefaultSearchContentFailure = () => ({
  type: constants.FETCH_DEFAULT_SEARCH_CONTENT_FAILURE,
});

export default {
  setSearchTerm,

  searchContentTypesFailure,
  searchContentTypesPending,
  searchContentTypesSuccess,

  fetchDefaultSearchContentPending,
  fetchDefaultSearchContentSuccess,
  fetchDefaultSearchContentFailure,

  searchContentTypesReset,
};
