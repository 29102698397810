import { fork, put, call, take } from 'redux-saga/effects';
import { Failure } from '../../../lib/utilities';

import channelActions, { constants } from './actions';

import queries from './queries';

const channelSaga = ({ gqlClient }) => {
  function* fetchChannels() {
    while (true) {
      try {
        yield take(constants.FETCH_CHANNELS_PENDING);

        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          queries.fetchChannels,
        );
        if (response.errors || !response.data.searchContent)
          throw new Failure();

        const { cdn, count, data } = response.data.searchContent;

        yield put(
          channelActions.fetchChannelsSuccess({
            cdn,
            count,
            data,
          }),
        );
      } catch (e) {
        yield put(channelActions.fetchChannelsFailure());
      }
    }
  }

  function* fetchChannelContent() {
    while (true) {
      try {
        const {
          payload: { id },
        } = yield take(constants.FETCH_CHANNEL_CONTENT_PENDING);

        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          queries.channel.fetchChannelContent,
          { ids: [id] },
        );

        if (response.errors || !response.data.searchContent)
          throw new Failure();

        const { data, cdn } = response.data.searchContent;

        yield put(
          channelActions.fetchChannelContentSuccess({
            data,
            cdn,
          }),
        );
      } catch (e) {
        yield put(channelActions.fetchChannelContentFailure());
      }
    }
  }

  function* watcher() {
    yield fork(fetchChannels);

    yield fork(fetchChannelContent);
  }

  return {
    watcher,
  };
};

export default channelSaga;
