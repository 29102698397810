import { fork, put, call, take } from 'redux-saga/effects';

import otherContentsActions, { constants } from './actions';

import queries from './queries';

const otherContentSaga = ({ gqlClient }) => {
  function* fetchOtherTabsContent() {
    while (true) {
      try {
        const {
          payload: { ids },
        } = yield take(constants.FETCH_OTHER_CONTENTS_PENDING);

        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          queries.searchContent,
          { ids },
        );

        if (!response?.data) throw new Error();
        const { cdn, data } = response.data.searchContent;
        // const cdn = getCdnDataFromCdn(cdnInfo);

        yield put(
          otherContentsActions.fetchOtherContentsSuccess({
            data: {
              cdn,
              list: data,
            },
          }),
        );
      } catch (e) {
        yield put(otherContentsActions.fetchOtherContentsFailure());
      }
    }
  }

  function* watcher() {
    yield fork(fetchOtherTabsContent);
  }

  return {
    watcher,
  };
};

export default otherContentSaga;
