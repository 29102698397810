import { fork, put, take, call } from 'redux-saga/effects';

import commonActions, { constants } from './actions';
import queries from './queries';

import { Failure } from '../../../lib/utilities';

export default function({ gqlClient }) {
  function* getLocationCodeWatcher() {
    while (true) {
      try {
        yield take(constants.GET_LOCATION_CODE_PENDING);

        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          queries.getLocationCode,
        );

        if (!response.ok || response.data?.errors) throw new Failure();

        const { getLocationCode } = response.data;
        yield put(commonActions.getLocationCodeSuccess(getLocationCode));
      } catch (e) {
        const errorMessage =
          (e instanceof Failure && e.message) || 'Something went wrong';
        yield put(commonActions.getLocationCodeFailure({ errorMessage }));
      }
    }
  }

  function* fetchContentTypeDefinitions() {
    while (true) {
      try {
        yield take(constants.FETCH_CONTENT_TYPE_DEFINITIONS_PENDING);

        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          queries.getContentTypeDefinitions,
        );

        const data = response.data.getMappings;

        /**
         *
         * The getmappings response returns the fields as an array of objects
         * Converting them to an object with field's name as key and the object as its value
         * converts an object from this,
         *  {
              name: 'movie',
              mappings: [{
                title: {displayName: 'cinema', isSHown: false}
              },
              {
                cast: {displayName: 'cinema', isSHown: false}
              }
              ]
            },

            to this,
            {
              movie: {
                title: { displayName: 'cinema', isSHown: false },
                cast: { displayName: 'cinema', isSHown: false }
              },
              episode: {
                title: { displayName: 'short', isSHown: false },
                cast: { displayName: 'cinema', isSHown: true }
              }
            }
         *
         */
        const normalizedDefinitions = {};

        data.forEach(contentType => {
          let mappingsObj = {};

          contentType.mappings.forEach(mapping => {
            mappingsObj = { ...mappingsObj, ...mapping };
          });

          normalizedDefinitions[contentType.name] = mappingsObj;
        });

        yield put(
          commonActions.fetchContentTypeDefinitionsSuccess({
            definitions: normalizedDefinitions,
          }),
        );
      } catch (e) {
        commonActions.fetchContentTypeDefinitionsFailure();
      }
    }
  }

  function* watcher() {
    yield fork(getLocationCodeWatcher);
    yield fork(fetchContentTypeDefinitions);
  }

  return {
    watcher,
  };
}
