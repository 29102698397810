export const constants = {
  FETCH_SEASON_BY_EPISODE_PENDING: 'episodes/FETCH_SEASON_BY_EPISODE_PENDING',
  FETCH_SEASON_BY_EPISODE_SUCCESS: 'episodes/FETCH_SEASON_BY_EPISODE_SUCCESS',
  FETCH_SEASON_BY_EPISODE_FAILURE: 'episodes/FETCH_SEASON_BY_EPISODE_FAILURE',
};

const fetchSeasonByEpisodePending = ({ id }) => ({
  type: constants.FETCH_SEASON_BY_EPISODE_PENDING,
  payload: { id },
});

const fetchSeasonByEpisodeSuccess = ({ id, data }) => ({
  type: constants.FETCH_SEASON_BY_EPISODE_SUCCESS,
  payload: { id, data },
});

const fetchSeasonByEpisodeFailure = ({ id, error }) => ({
  type: constants.FETCH_SEASON_BY_EPISODE_FAILURE,
  payload: { id, error },
});

export default {
  fetchSeasonByEpisodeFailure,
  fetchSeasonByEpisodePending,
  fetchSeasonByEpisodeSuccess,
};
