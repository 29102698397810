import gql from 'graphql-tag';

const getConfig = gql`
  query getConfig {
    getConfig {
      cdnInfo {
        videoCdn {
          id
          url
          aliases
          videoContent
          imageContent
        }
        imageCdn {
          id
          url
          aliases
          videoContent
          imageContent
        }
      }
      cdn {
        domainName
        aliases
      }
      trackingCode
      logoPath
      faviconPath
      auth
      favoritesConfig
      detailsTabs
      socialAuth {
        facebook
        twitter
        google
      }
      landingPageEnabled
      selectedTheme
      maturityRating {
        enable
        age
        cache
      }
      storeName
      nameQueryNameMappings {
        name
        queryName
      }
      authProvider
      storeFrontDomain
      liveEvent {
        enable
      }
      menuTheme
      profileLanguageConfig
      defaultDisplayLanguage
    }
  }
`;

/**
 * This query is used to check whether the
 * org has allowed subscriptions on the content.
 */
const fetchMonetizationSettings = gql`
  query fetchMonetizationSettings {
    fetchMonetizationSettings {
      subscription {
        isSelected
      }
    }
  }
`;

export default { getConfig, fetchMonetizationSettings };
