export const constants = {
  FETCH_MENU_ITEMS_PENDING: 'menuItems/FETCH_MENU_ITEMS_PENDING',
  FETCH_MENU_ITEMS_SUCCESS: 'menuItems/FETCH_MENU_ITEMS_SUCCESS',
  FETCH_MENU_ITEMS_FAILURE: 'menuItems/FETCH_MENU_ITEMS_FAILURE',

  FETCH_HEADER_MENU_PENDING: 'menuItems/FETCH_HEADER_MENU_PENDING',
  FETCH_HEADER_MENU_SUCCESS: 'menuItems/FETCH_HEADER_MENU_SUCCESS',
  FETCH_HEADER_MENU_FAILURE: 'menuItems/FETCH_HEADER_MENU_FAILURE',

  FETCH_FOOTER_MENU_PENDING: 'menuItems/FETCH_FOOTER_MENU_PENDING',
  FETCH_FOOTER_MENU_SUCCESS: 'menuItems/FETCH_FOOTER_MENU_SUCCESS',
  FETCH_FOOTER_MENU_FAILURE: 'menuItems/FETCH_FOOTER_MENU_FAILURE',

  RESET_MENU_ITEMS: 'menuItems/RESET_MENU_ITEMS'
};

const fetchMenuItemsPending = ({locale}) => ({
  type: constants.FETCH_MENU_ITEMS_PENDING,
  payload: {locale}
});

const fetchMenuItemsSuccess = ({ menuItemsTree }) => {
  return {
    type: constants.FETCH_MENU_ITEMS_SUCCESS,
    payload: { menuItemsTree },
  };
};

const fetchMenuItemsFailure = () => ({
  type: constants.FETCH_MENU_ITEMS_FAILURE,
});

const fetchHeaderMenuPending = (payload) => ({
  type: constants.FETCH_HEADER_MENU_PENDING,
  payload,
});

const fetchHeaderMenuSuccess = ({ tree }) => {
  return {
    type: constants.FETCH_HEADER_MENU_SUCCESS,
    payload: { tree },
  };
};

const fetchHeaderMenuFailure = () => ({
  type: constants.FETCH_HEADER_MENU_FAILURE,
});

const fetchFooterMenuPending = () => ({
  type: constants.FETCH_FOOTER_MENU_PENDING,
});

const fetchFooterMenuSuccess = ({ tree }) => {
  return {
    type: constants.FETCH_FOOTER_MENU_SUCCESS,
    payload: { tree },
  };
};

const fetchFooterMenuFailure = () => ({
  type: constants.FETCH_FOOTER_MENU_FAILURE,
});

const resetMenuItems = () => ({
  type: constants.RESET_MENU_ITEMS,
})

export default {
  fetchMenuItemsFailure,
  fetchMenuItemsPending,
  fetchMenuItemsSuccess,

  fetchHeaderMenuPending,
  fetchHeaderMenuFailure,
  fetchHeaderMenuSuccess,

  fetchFooterMenuPending,
  fetchFooterMenuSuccess,
  fetchFooterMenuFailure,

  resetMenuItems,
};
