/* eslint-disable */
import { SpotlightContainerDecorator } from "@enact/spotlight/SpotlightContainerDecorator";
import kind from "@enact/core/kind";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@enact/sandstone/Button";
import Scroller from "@enact/sandstone/Scroller";
import ChannelTimeline from "./ChannelTimeLine";
import { fetchEpgPending } from "../../store/modules/epg/actions";
import c from "./channelguide.css";
import { getHourAndMinutes } from "../../lib/utilities";
import EPGComponentBase from "./EPGComponentBase";
import moment from "moment";

const ChannelGuideScreen = () => {
  const [nearestHourDate, setNearestHourDate] = useState();
  const [indicatorStartPositon, setIndicatorPosition] = useState();
  const dispatch = useDispatch();
  const { loading, error, data } = useSelector(state => state.epg);

  useEffect(() => {
    const current_Date = new Date();

    // this will give local time with minutes and seconds
    const now = new Date(current_Date.setMinutes(0)).setSeconds(0);

    // we need to round the current local time to previous hour
    const trimmedNow = new Date(now);
    // then convert the previous hour trimmed time to utc
    const utcNow = trimmedNow.toISOString();

    // nearest hour calculation is used on the frontend to start the
    // timeline from the nearest previous hour
    const zeroedMinutes = new Date(current_Date).setMinutes(0);
    const zeroedSeconds = new Date(zeroedMinutes).setSeconds(0);
    setNearestHourDate(zeroedSeconds);

    const startPos = ((Date.parse(current_Date) - zeroedSeconds) / 60000) * 6.7;
    setIndicatorPosition(startPos);
    if(data.length === 0){
      dispatch(fetchEpgPending({ startDate: utcNow }));
    }
  }, [dispatch]);
  const renderContent = () => {
    if (loading)
      return (
        <div className="grid place-items-center h-1/2">
          <p className="text-mainText text-xl">Loading ...</p>
        </div>
      );

    if (error)
      return (
        <div className="grid place-items-center h-1/2">
          <p className="text-mainText text-xl">Error</p>
        </div>
      );

    return <EPGComponentBase data={data}  />;
  };

  return (
    <div className="bg-bodyBg h-screen w-screen flex-col items-center justify-start pt-52">
      <span className="ml-20 text-mainText text-subHeading">
        {"Channel Guide"} | {getHourAndMinutes()} &nbsp; UTC
        {moment().utcOffset() / 60 > 0
          ? `+${moment().utcOffset() / 60}`
          : moment().utcOffset() / 60}
      </span>
      {/* <ChannelTimeline /> */}
      {renderContent()}
    </div>
  );
};

const ChannelGuide = kind({
  name: "FreeTVPanel",
  css: {
    c,
  },
  render: ({ onNavigate, path, ...rest }) => <ChannelGuideScreen />,
});
export default SpotlightContainerDecorator(
  { continue5WayHold: true, enterTo: "last-focused", preserveId: true },
  ChannelGuide
);
