import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spotlight from "@enact/spotlight";
import Scroller from "@enact/sandstone/Scroller";
import Container from "../views/SectionsList";
import { navigate } from "../store/modules/navigate/actions";
import pageActions from "../store/modules/pages/actions";
import kind from "@enact/core/kind";
import ri from '@enact/ui/resolution';
import SpotlightContainerDecorator from "@enact/spotlight/SpotlightContainerDecorator";
import V2PageBanner from "../components/PageBanner/V2PageBanner";
import PrimaryButton from "../components/Buttons/PrimaryButton";
import assets from "../assets/icons";

const V2 = ({ ...rest }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.me);
  const { loading } = useSelector((state) => state.bootstrap);
  const slug = useSelector((state) => state.navigate?.props?.slug);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!user && !loading) {dispatch(navigate({ path: "signin", props: {} }))};
    }, 1500);
    return () => clearTimeout(timer);
  }, [user,loading,dispatch]);
  const onBackClick = () => {
    console.log("Back Clicked!");
    dispatch(navigate({ path: "home", props: { spoton: `-card` } }));
  };
  useEffect(() => {
    function keyListener(event) {
      const key = event.keyCode;
      if (key === 461) {onBackClick()};
    }
    window.document.addEventListener("keydown", keyListener);
    return () => {
      window.document.removeEventListener("keydown", keyListener);
    };
  });
  const pageConfig = useSelector((state) => state.pages?.pageV2Data?.[slug]);
  const [currentItem, setCurrentItem] = useState(null);
  const firstSectionItemRef = useRef(null);
  const p = useSelector((state) => state.navigate.props);

  useEffect(() => {
    if (!pageConfig && slug) {
      dispatch(pageActions.fetchPageInitialDataV2Pending({ path: slug }));
    }
    // console.log(p.spoton);
    if (p?.spoton) Spotlight.focus(p.spoton);
  }, [pageConfig,slug,p?.spoton,dispatch]);

  const renderBanners = () => {
    if (!pageConfig?.banner?.id) return null;
    return (
      <V2PageBanner
        id={pageConfig.banner.id}
        path={slug}
        // containerStyle={styles.banners.container}
        {...rest}
        currentItem={currentItem}
        // nextFocusDown={findNodeHandle(firstSectionItemRef.current)}
        // width={styles.banners.banner.width}
        // height={styles.banners.banner.height}
        // onItemPress={handleSectionItemPress}
        // onImageLoad={() => null}
      />
    );
  };

  const renderSwipers = () => {
    if (!pageConfig?.contentSections) return null;

    const sections = pageConfig?.contentSections || [];

    const banners = [];
    if (pageConfig?.banner?.id) {
      banners.push(pageConfig);
    }
    return (
      <Container
        page={slug}
        firstSectionItemRef={firstSectionItemRef}
        sections={[...sections]}
        // onItemPress={handleSectionItemPress}
        hasPageBanner={pageConfig?.banner?.id !== null} // onViewMorePress={handleViewMorePress}
        // onItemFocus={handleItemFocus}
      />
    );
  };
  const renderBackButton = () => {
    return (
      <PrimaryButton
        data-spotlight-id="btn"
        backgroundOpacity="transparent"
        icon={assets.backbutton}
        type="button"
        className="absolute top-10 left-10 z-50 selected"
        onClick={()=>onBackClick()}
      />
    );
  };
  return (
    <Scroller verticalScrollbar="hidden">
      {renderBackButton()}
      <div
        onKeyDown={(e) => {
          console.log(e.keyCode);
          if (e.keyCode === 461) {
            onBackClick();
          }
        }}
        className="ml-40"
      >
        {renderBanners()}
        {renderSwipers()}
      </div>
    </Scroller>
  );
};

const V2PageView = kind({
  name: "HomePanel",

  styles: {
    // css,
    // theme,
    // className: 'app'
  },

  render: ({ ...rest }) => <V2 {...rest} />,
});
export default SpotlightContainerDecorator(
  { continue5WayHold: true, enterTo: "last-focused", preserveId: true },
  V2PageView
);
