import gql from 'graphql-tag';

const paymentProvider = gql`
  query paymentProvider {
    paymentProvider {
      provider
      key
    }
  }
`;

export default {
  paymentProvider,
};
