import { constants } from './actions';

const initialState = {
  data: {},
  orderHistory: {
    orders: [],
    limit: 5,
    isMoreItemAvailable: false,
    loading: false,
    success: false,
    error: false,
    message: '',
  },
  avatar: {
    loading: false,
    error: false,
  },
  profiles: {
    data: null,
    loading: false,
    success: false,
    error: false,
  },

  createProfile: {
    loading: false,
    error: false,
    success: false,
  },

  deleteProfile: {
    loading: false,
    error: false,
    success: false,
  },

  updateProfile: {
    loading: false,
    error: false,
    success: false,
    message: '',
  },

  // Will result changes in activeProfile
  updateUserProfile: {
    loading: false,
    success: false,
    error: false,
  },
  loading: false,
  error: false,
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.FETCH_PROFILE_INFO_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case constants.FETCH_PROFILE_INFO_SUCCESS: {
      const { data } = payload;
      return { ...state, data, loading: false, error: false };
    }

    case constants.FETCH_PROFILE_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case constants.UPDATE_PROFILE_INFO_PENDING: {
      return {
        ...state,
        updateProfile: {
          ...state.updateProfile,
          loading: true,
          message: '',
        },
      };
    }

    case constants.UPDATE_PROFILE_INFO_SUCCESS: {
      const { profile } = payload;
      return {
        ...state,
        data: { ...state.data, ...profile },
        updateProfile: {
          ...state.updateProfile,
          loading: false,
          success: true,
        },
      };
    }

    case constants.UPDATE_PROFILE_INFO_FAILURE: {
      const { reason } = payload;
      return {
        ...state,
        updateProfile: {
          ...state.updateProfile,
          loading: false,
          success: false,
          error: true,
          message: reason,
        },
      };
    }

    /// ---------------- FETCH PROFILES REDUCER START -------
    case constants.FETCH_PROFILES_PENDING: {
      return {
        ...state,
        profiles: {
          data: state.profiles.data,
          loading: true,
        },
      };
    }
    case constants.FETCH_PROFILES_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        profiles: {
          data,
          loading: false,
          success: true,
        },
      };
    }
    case constants.FETCH_PROFILES_FAILURE: {
      return {
        ...state,
        profiles: {
          ...state.profiles,
          loading: true,
          success: false,
          error: true,
        },
      };
    }
    /// ---------------- FETCH PROFILES REDUCER END -------

    case constants.CREATE_USER_PROFILE_PENDING: {
      return {
        ...state,
        createProfile: {
          loading: true,
          success: false,
          error: false,
        },
      };
    }

    case constants.CREATE_USER_PROFILE_SUCCESS: {
      const { newProfile } = payload;

      return {
        ...state,
        createProfile: {
          loading: false,
          success: true,
          error: false,
        },
        profiles: {
          ...state.profiles,
          data: {
            count: state.profiles.data.count + 1,
            list: [...state.profiles.data.list, newProfile],
          },
        },
      };
    }

    case constants.CREATE_USER_PROFILE_FAILURE: {
      return {
        ...state,
        createProfile: {
          loading: false,
          success: false,
          error: true,
        },
      };
    }

    case constants.DELETE_USER_PROFILE_PENDING: {
      return {
        ...state,
        deleteProfile: {
          loading: true,
          success: false,
          error: false,
        },
      };
    }

    case constants.DELETE_USER_PROFILE_SUCCESS: {
      const deletedProfileId = payload.deletedId;
      const newData = [...state.profiles.data.list];
      const filteredNewData = newData.filter(item => {
        return item.id !== deletedProfileId;
      });

      return {
        ...state,
        deleteProfile: {
          loading: false,
          success: true,
          error: false,
        },
        profiles: {
          ...state.profiles,
          data: {
            count: state.profiles.data.count - 1,
            list: [...filteredNewData],
          },
        },
      };
    }

    case constants.DELETE_USER_PROFILE_FAILURE: {
      return {
        ...state,
        deleteProfile: {
          loading: false,
          success: false,
          error: true,
        },
      };
    }

    case constants.RESET_PROFILE_STATUS_STATE: {
      const { operationType } = payload;

      return {
        ...state,
        [operationType]: initialState[operationType],
      };
    }

    /// ---------------- UPDATE USER PROFILE REDUCER START -------
    case constants.UPDATE_USER_PROFILE_PENDING: {
      return {
        ...state,
        updateUserProfile: {
          loading: true,
        },
      };
    }
    case constants.UPDATE_USER_PROFILE_SUCCESS: {
      const { profile } = payload;
      return {
        ...state,
        activeProfile: profile || state.activeProfile,
        updateUserProfile: {
          loading: false,
          success: true,
        },
      };
    }
    case constants.UPDATE_USER_PROFILE_FAILURE: {
      return {
        ...state,
        updateUserProfile: {
          loading: false,
          success: false,
          error: true,
        },
      };
    }

    /// ---------------- UPDATE USER PROFILE REDUCER END -------

    case constants.GET_ORDER_HISTORY_PENDING: {
      return {
        ...state,
        orderHistory: {
          ...state.orderHistory,
          loading: true,
        },
      };
    }

    case constants.GET_ORDER_HISTORY_SUCCESS: {
      const { isMoreItemAvailable, refresh, orders } = payload;
      const _orders = refresh
        ? orders
        : [...state.orderHistory.orders, ...orders];
      return {
        ...state,
        orderHistory: {
          ...state.orderHistory,
          isMoreItemAvailable,
          orders: _orders,
          loading: false,
          success: true,
        },
      };
    }

    case constants.GET_ORDER_HISTORY_FAILURE: {
      return {
        ...state,
        orderHistory: {
          loading: false,
          success: false,
          error: true,
        },
      };
    }

    case constants.RESET_ORDER_HISTORY: {
      return {
        ...state,
        orderHistory: initialState.orderHistory,
      };
    }

    case constants.MODIFY_PROFILE_INFO: {
      const { field, value } = payload;

      return {
        ...state,
        data: { ...state.data, [field]: value },
      };
    }

    case constants.UPDATE_PROFILE_IMAGE_PENDING: {
      return { ...state, avatar: { loading: true } };
    }

    case constants.UPDATE_PROFILE_IMAGE_SUCCESS: {
      const { avatar } = payload;

      if (Object.keys(avatar).length !== 0) {
        return {
          ...state,
          data: { ...state.data, avatar },
          avatar: { loading: false },
        };
      }
      return { ...state, avatar: { loading: false } };
    }

    case constants.UPDATE_PROFILE_IMAGE_FAILURE: {
      return {
        ...state,
        avatar: { loading: false, error: true },
      };
    }

    default:
      return state;
  }
}

export default reducer;
