import { constants } from './actions';

const initialState = {
  channels: {
    cdn: {},
    count: 0,
    data: [],
    loading: false,
    error: false,
  },

  channelData: {
    data: {},
    cdn: {},
    loading: false,
    error: false,
  },
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case constants.FETCH_CHANNELS_PENDING: {
      return {
        ...state,
        channels: { ...state.channels, loading: true },
      };
    }

    case constants.FETCH_CHANNELS_SUCCESS: {
      return {
        ...state,
        channels: {
          ...state.channels,
          cdn: payload.cdn,
          count: payload.count,
          data: payload.data,
          loading: false,
          error: false,
        },
      };
    }

    case constants.FETCH_CHANNELS_FAILURE: {
      return {
        ...state,
        channels: { ...state.channels, loading: false, error: true },
      };
    }

    case constants.FETCH_CHANNEL_CONTENT_PENDING: {
      return {
        ...state,
        channelData: { ...state.channelData, loading: true },
      };
    }

    case constants.FETCH_CHANNEL_CONTENT_SUCCESS: {
      return {
        ...state,
        channelData: {
          ...state.channelData,
          data: payload.data[0],
          cdn: payload.cdn,
          loading: false,
          error: false,
        },
      };
    }

    case constants.FETCH_CHANNEL_CONTENT_FAILURE: {
      return {
        ...state,
        channelData: { ...state.channelData, loading: false, error: true },
      };
    }

    default:
      return state;
  }
}

export default reducer;
