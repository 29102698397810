/* eslint-disable no-param-reassign */
import { create as apiCreate } from 'apisauce';

/**
 * Create an api wrapper based on baseurl
 * @constructor
 * @param {string} baseURL - base url to make request
 * @returns {object} A list of the API functions
 */
const create = (baseURL = '') => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  // here are some default headers
  const headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json; charset=utf-8',
    credentials: 'include',
  };

  const api = apiCreate({
    // base URL is read from the "constructor"
    baseURL,
    headers,
    // 15 second timeout.
    timeout: 15000,
    withCredentials: true,
  });

  api.addResponseTransform(response => {
    if (response.status === 401) {
      // When the auth token expires, backend throws `401`.
      // Force logout the user and take to auth screen.
    }
  });

  const { setHeader, getBaseURL } = api;
  /**
   * @param string url
   * @param string method
   * @param object data
   * @returns {*|promise}
   */
  const makeRequest = (url, method = 'GET', body = {}) => {
    switch (method) {
      case 'GET':
        return api.get(url);

      case 'POST':
        return api.post(url, JSON.stringify(body));

      case 'FORM':
        return api.post(url, body, {
          headers: {
            'Content-Type': null,
            encType:
              'multipart/form-data; boundary=6ff46e0b6b5148d984f148b6542e5a5d',
          },
        });

      case 'SFORM':
        return api.post(url, body, {
          headers: {
            'Content-Type':
              'multipart/form-data; boundary=6ff46e0b6b5148d984f148b6542e5a5d',
          },
        });

      case 'PUT':
        return api.put(url, JSON.stringify(body));

      case 'DELETE':
        return api.delete(url, {}, { data: body });

      default:
        return api.get(url);
    }
  };

  /**
   * Show error messgae based on error code from server
   *
   * @param error: string, one of
   *        [ CLIENT_ERROR,
   *          SERVER_ERROR,
   *          TIMEOUT_ERROR,
   *          CONNECTION_ERROR,
   *          NETWORK_ERROR
   *        ]
   * @returns string: Appropriate message based on the error.
   *          if message is received as an argument that value is returned for
   *           CLIENT_ERROR
   */
  const showError = (error, message = 'Incorrect username or password') => {
    switch (error) {
      case 'CLIENT_ERROR':
        break;
      case 'SERVER_ERROR':
        message = 'Server Error';
        break;
      case 'TIMEOUT_ERROR':
        message = 'Server took too long to respond';
        break;
      case 'CONNECTION_ERROR':
        message = 'Server not available, bad dns';
        break;
      case 'NETWORK_ERROR':
        message = 'Network not available';
        break;
      default:
        message = 'Unidentified error';
    }
    return message;
  };

  // ------
  // STEP 3
  // ------
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  // methods in step 2.
  //
  // Notice we're not returning back the `api` created in step 1?  That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    // a list of the API functions from step 2
    makeRequest,
    showError,
    // additional utilities
    setHeader,
    getBaseURL,
    internalClient: api,
  };
};

// let's return back our create method as the default.
export default {
  create,
};
