import gql from 'graphql-tag';

const getMe = gql`
  query me {
    me {
      id
      username
      email
      avatar {
        cdn {
          domainName
          aliases
        }
        path
      }
      dateOfBirth
      registeredOn
      userStatus
      lastAccessedFrom
      noOfActiveDevices
      ltvOfUser
      isDisabled
      isPasswordExists
      isPinExists
      userSubscription {
        isFree
        subscriptionPlan {
          id
          name
          description
          currencyCode
          rank
        }
        status
        startDate
        expiryDate
        isRecurring
        paymentProvider
      }
    }
  }
`;

export default { getMe };
