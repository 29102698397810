
// import { DEFAULT_THEME_NAME } from '~/theme';

import { constants } from './actions';
import { BROWSE_PANEL_STATES } from '../../../lib/constants';

const INITIAL_STATE = {
  toast: {
    visible: false,
    message: null,
    options: {},
  },
  themes: {
    // currentThemeName: Config.THEME //|| DEFAULT_THEME_NAME,
  },
  layout: {
    browsePanelState: BROWSE_PANEL_STATES.collapsed,
    isBrowsePanelOpen: false,
    isBrowsePanelVisible: true,
  },
  share: {
    loading: false,
    success: false,
    error: false,
    message: '',
  },
};

export default function app(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.SET_CURRENT_THEME: {
      const { themeName } = payload;
      return {
        ...state,
        themes: {
          ...state.themes,
          currentThemeName: themeName,
        },
      };
    }

    case constants.SHOW_BROWSE_PANEL: {
      return {
        ...state,
        layout: {
          ...state.layout,
          browsePanelState: BROWSE_PANEL_STATES.collapsed,
          isBrowsePanelOpen: false,
          isBrowsePanelVisible: true,
        },
      };
    }

    case constants.HIDE_BROWSE_PANEL: {
      return {
        ...state,
        layout: {
          ...state.layout,
          browsePanelState: BROWSE_PANEL_STATES.hidden,
          isBrowsePanelOpen: false,
          isBrowsePanelVisible: false,
        },
      };
    }

    case constants.OPEN_BROWSE_PANEL: {
      return {
        ...state,
        layout: {
          ...state.layout,
          browsePanelState: BROWSE_PANEL_STATES.expanded,
          isBrowsePanelOpen: true,
          isBrowsePanelVisible: true,
        },
      };
    }

    case constants.CLOSE_BROWSE_PANEL: {
      return {
        ...state,
        layout: {
          ...state.layout,
          browsePanelState: BROWSE_PANEL_STATES.collapsed,
          isBrowsePanelOpen: false,
          isBrowsePanelVisible: true,
        },
      };
    }

    case constants.SHOW_TOAST: {
      const { message, ...options } = payload;
      return {
        ...state,
        toast: {
          visible: true,
          message,
          options,
        },
      };
    }

    case constants.CLOSE_TOAST: {
      return {
        ...state,
        toast: {
          visible: false,
          message: '',
          options: {},
        },
      };
    }

    case constants.SHARE_PENDING: {
      return {
        ...state,
        share: {
          loading: true,
        },
      };
    }
    case constants.SHARE_SUCCESS: {
      return {
        ...state,
        share: {
          loading: false,
          success: true,
        },
      };
    }
    case constants.SHARE_FAILURE: {
      const { reason } = payload;
      return {
        ...state,
        share: {
          loading: false,
          success: true,
          error: true,
          message: reason,
        },
      };
    }
    default:
      return state;
  }
}
