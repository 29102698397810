import { fork, put, call, take, select } from 'redux-saga/effects';

import { Failure } from '../../../lib/utilities';
import profileActions, { constants } from './actions';
import appActions from '../app/actions';
import authActions from '../auth/actions';

import authQueries from '../auth/queries';
import profileQueries from './queries';
import mutations from '../../graphql/mutations';

import { ACCESS_TOKEN } from '../../../lib/constants';
import * as urls from '../../../lib/urls';

const profileInfoSaga = ({ gqlClient, restClient, deliveryClient }) => {
  function* fetchProfileInfo() {
    while (true) {
      yield take(constants.FETCH_PROFILE_INFO_PENDING);

      try {
        const {
          data: { me },
        } = yield call([gqlClient, gqlClient.query], {
          query: authQueries.getMe,
        });

        yield put(
          profileActions.fetchProfileInfoSuccess({
            data: me,
          }),
        );
      } catch (e) {
        yield put(profileActions.fetchProfileInfoFailure());
      }
    }
  }

  function* getOrderHistoryWatcher() {
    while (true) {
      try {
        const { payload } = yield take(constants.GET_ORDER_HISTORY_PENDING);
        const { refresh, ...restPayload } = payload;
        const response = yield call([gqlClient, gqlClient.query], {
          query: profileQueries.fetchOrderDetails,
          variables: restPayload,
        });

        if (response.errors) throw new Failure();

        const { transactionHistory } = response.data;

        const isMoreItemAvailable =
          transactionHistory?.transactions?.length >= restPayload.limit;
        yield put(
          profileActions.getOrderHistorySuccess({
            orders: transactionHistory.transactions,
            isMoreItemAvailable,
            refresh,
          }),
        );
      } catch (e) {
        const errorMessage =
          (e instanceof Failure && e.message) || 'Something went wrong';
        yield put(
          profileActions.getOrderHistoryFailure({ message: errorMessage }),
        );
      }
    }
  }

  function* updateProfileImage() {
    while (true) {
      yield take(constants.UPDATE_PROFILE_IMAGE_PENDING);
      const avatar = {};
      try {
        yield put(profileActions.updateProfileImageSuccess({ avatar }));
      } catch (e) {
        yield put(profileActions.updateProfileImageFailure());
      }
    }
  }

  function* updateProfileInfo() {
    while (true) {
      const {
        payload: { newAvatar, ...data },
      } = yield take(constants.UPDATE_PROFILE_INFO_PENDING);

      const variables = {
        data,
      };

      try {
        if (newAvatar) {
          yield put(
            profileActions.updateProfileImagePending({ uri: newAvatar }),
          );
        }
        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          mutations.profile.updateProfile,
          variables,
        );

        if (response.errors || !response.data) throw new Failure();
        const { updateProfile } = response.data;
        yield put(
          profileActions.updateProfileInfoSuccess({ profile: updateProfile }),
        );
        yield put(
          appActions.showToast({ message: 'Profile updated successfully' }),
        );
      } catch (e) {
        const errorMessage =
          (e instanceof Failure && e.message) || 'Something went wrong';
        yield put(
          profileActions.updateProfileInfoFailure({ reason: errorMessage }),
        );
      }
    }
  }

  // Saga only for updating user profile content languages
  function* updateUserProfileWatcher() {
    while (true) {
      try {
        const { payload } = yield take(constants.UPDATE_USER_PROFILE_PENDING);
        const { name, contentLanguages, displayLanguage, id } = payload;

        const response = yield call(
          [gqlClient, gqlClient.rawRequest],
          mutations.profile.updateProfileMutation,
          {
            input: {
              name,
              language: {
                content: contentLanguages,
                storefront: displayLanguage,
              },
            },
            id,
          },
        );
        if (!response?.data?.updateProfileMutation) throw new Failure();

        const { updateProfileMutation } = response.data;
        yield put(
          profileActions.updateUserProfileSuccess({
            profile: updateProfileMutation,
          }),
        );
        yield put(
          authActions.setActiveUserProfile({profile: updateProfileMutation})
        )

      } catch (e) {
        yield put(profileActions.updateUserProfileFailure());
      }
    }
  }
  function* createUserProfile() {
    while (true) {
      try {
        // const profileState = yield select(state => state.profile);

        // // At max only 5 profiles can be managed
        // if (data.list.length === 5) return;

        const { payload } = yield take(constants.CREATE_USER_PROFILE_PENDING);
        const { name, languages } = payload;

        if (!name) return;

        const mutationVariables = {
          name,
          language: {
            content: languages,
          },
        };

        const response = yield call([gqlClient, gqlClient.mutation], {
          mutation: profileQueries.createProfileMutation,
          variables: { input: mutationVariables },
        });

        yield put(
          profileActions.createUserProfileSuccess({
            newProfile: response.data.createProfileMutation,
          }),
        );
      } catch (e) {
        yield put(profileActions.createUserProfileFailure());
      }
    }
  }

  function* deleteUserProfile() {
    while (true) {
      try {
        const { payload } = yield take(constants.DELETE_USER_PROFILE_PENDING);
        const { id } = payload;

        const profiles = yield select(state => {
          return state.profile.profiles.data.list;
        });

        const defaultProfile = profiles.find(profile => {
          return profile?.isDefault;
        });

        const response = yield call([gqlClient, gqlClient.mutation], {
          mutation: profileQueries.deleteProfileMutation,
          variables: { id },
        });

        if (!response?.data?.deleteProfileMutation) throw new Error();

        // TODO: Need to re-check with Pavan, it should set some profile id instead of null
        restClient.setHeader('Profile', null);
        gqlClient.setHeader('Profile', null);
        deliveryClient.setHeader('Profile', null);

        yield put(authActions.setActiveProfile({ profile: defaultProfile }));

        yield put(
          profileActions.deleteUserProfileSuccess({
            deletedId: id,
          }),
        );
      } catch (e) {
        yield put(profileActions.deleteUserProfileFailure());
      }
    }
  }

  function* watcher() {
    yield fork(fetchProfileInfo);
    yield fork(getOrderHistoryWatcher);
    yield fork(updateProfileInfo);
    yield fork(updateUserProfileWatcher);
    yield fork(updateProfileImage);
    yield fork(createUserProfile);
    yield fork(deleteUserProfile);
  }

  return {
    watcher,
  };
};

export default profileInfoSaga;
