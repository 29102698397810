import gql from 'graphql-tag';

const getSectionByDynamicSlug = gql`
  query getSectionByDynamicSlug($limit: Int, $skip: Int, $slug: String) {
    getVideosByType(limit: $limit, slug: $slug, skip: $skip) {
      cdn {
        domainName
        aliases
      }
      count
      list {
        contentId
        duration
        contentMeta
        id
      }
    }
  }
`;

export default { getSectionByDynamicSlug };
