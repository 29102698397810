import gql from 'graphql-tag';

const updateContentWatchDuration = gql`
  mutation updateDuration($contentId: String!, $duration: String!) {
    updateDuration(contentId: $contentId, duration: $duration)
  }
`;

export default {
  updateContentWatchDuration,
};
