import { constants } from './actions';

const initialState = {
  menuItemsTree: [],
  headerMenuTree: [],
  footerMenuTree: [],
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case constants.FETCH_MENU_ITEMS_PENDING: {
      return {
        ...state,
      };
    }

    case constants.FETCH_MENU_ITEMS_SUCCESS: {
      const { menuItemsTree } = payload;
      return { ...state, menuItemsTree };
    }

    case constants.FETCH_MENU_ITEMS_FAILURE: {
      return {
        ...state,
      };
    }

    case constants.FETCH_HEADER_MENU_PENDING: {
      return {
        ...state,
      };
    }

    case constants.FETCH_HEADER_MENU_SUCCESS: {
      const { tree } = payload;
      return { ...state, headerMenuTree: tree };
    }

    case constants.FETCH_HEADER_MENU_FAILURE: {
      return {
        ...state,
      };
    }

    case constants.FETCH_FOOTER_MENU_PENDING: {
      return {
        ...state,
      };
    }
    case constants.FETCH_FOOTER_MENU_SUCCESS: {
      const { tree } = payload;
      return { ...state, footerMenuTree: tree };
    }

    case constants.FETCH_FOOTER_MENU_FAILURE: {
      return {
        ...state,
      };
    }

    case constants.RESET_MENU_ITEMS:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
