import { fork, put, call, takeEvery } from "redux-saga/effects";

import epgActions, { constants } from "./actions";

import queries from "../../graphql/queries";

const epgSaga = ({ gqlClient }) => {
  function* fetchEpg({ payload: { startDate } }) {
    try {
      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.epg.fetchEpg,
        variables: {
          startDate,
        },
      });

      if (response?.data?.getEpgByChannelIds) {
        yield put(
          epgActions.fetchEpgSuccess({
            data: response.data.getEpgByChannelIds,
          })
        );
      } else {
        throw new Error("Failed to fetch EPG");
      }
    } catch (e) {
      yield put(epgActions.fetchEpgFailure());
    }
  }

  function* fetchEpgWatcher() {
    yield takeEvery(constants.FETCH_EPG_PENDING, fetchEpg);
  }

  function* watcher() {
    yield fork(fetchEpgWatcher);
  }

  return {
    watcher,
  };
};

export default epgSaga;
