import { constants } from './actions';

const initialState = {
  seasonsByIds: {
    /*
    [id]: {
      series: { id, title },
    }
    */
  },
  extraDataByIds: {
    // [id]: { loading, success, error }
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    // case HYDRATE: {

    //   return { ...state, ...action.payload.contentTypes };
    // }
    case constants.FETCH_SERIES_BY_SEASON_PENDING: {
      const idsExtraData = {};
      idsExtraData[action.payload.id] = {
        loading: true,
        success: false,
        error: false,
        errorMessage: null,
      };

      return {
        ...state,
        extraDataByIds: {
          ...state.extraDataByIds,
          ...idsExtraData,
        },
      };
    }

    case constants.FETCH_SERIES_BY_SEASON_SUCCESS: {
      const idsExtraData = {};
      const seasonId = {};
      const _id = action.payload.id;
      const seasons = action.payload.data?.seasons;
      idsExtraData[_id] = {
        loading: false,
        success: true,
        error: false,
        errorMessage: null,
      };
      seasonId[_id] = {
        ...state.seasonsByIds[_id],
        series: {
          ...action.payload.data,
          seasons: seasons.map(season => season.id),
        },
      };

      return {
        ...state,
        extraDataByIds: {
          ...state.extraDataByIds,
          ...idsExtraData,
        },
        seasonsByIds: {
          ...state.seasonsByIds,
          ...seasonId,
        },
      };
    }

    case constants.FETCH_SERIES_BY_SEASON_FAILURE: {
      const idsExtraData = {};
      idsExtraData[action.payload.id] = {
        loading: false,
        success: false,
        error: true,
        errorMessage: action.payload.error,
      };

      return {
        ...state,
        extraDataByIds: {
          ...state.extraDataByIds,
          ...idsExtraData,
        },
      };
    }

    default:
      return state;
  }
}

export default reducer;
