import Spotlight from "@enact/spotlight";
import "shaka-player/dist/controls.css";
const shaka = require("shaka-player/dist/shaka-player.ui.js");
import { useEffect, useRef, useState } from "react";
import { Provider, useSelector } from "react-redux";
import { SpottableComponent } from "../../components/SpottableComponent";
import PrimaryButton from "../Buttons/PrimaryButton";
import assets from "../../assets/icons";
import Spinner from "@enact/sandstone/Spinner";

const VideoPlay = ({
  manifestUri,
  keySystem,
  onEnd,
  currentTime,
  onBackClick,
  isVideo,
}) => {
  const videoRef = useRef(null);
  const settings = useSelector((state) => state.player.settings?.data);
  const videoComponent = useRef(null);
  const videoContainer = useRef(null);
  const [textTracks, SetTextTracks] = useState([]);
  const [showSubtitles, setShowSubtitles] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTimeDisplay, setCurrentTimeDisplay] = useState("00:00");
  const [totalTimeDisplay, setTotalTimeDisplay] = useState("00:00");
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [showPlaybackSpeeds, setShowPlaybackSpeeds] = useState(false);
  const [seekBarProgress, setSeekBarProgress] = useState(null);
  const [showControls, setShowControls] = useState(true);
  const [seeking, setSeeking] = useState(false);
  const [loading, setLoading] = useState(true);

  const handlePlaybackSpeed = (speed) => {
    setPlaybackSpeed(speed);
    if (videoComponent.current) {
      videoComponent.current.playbackRate = speed;
    }
  };

  useEffect(() => {
    if (videoComponent.current) Spotlight.focus("videoComp");
  }, [videoComponent.current]);
  useEffect(() => {
    let hideControlsTimer;
    function keyListener(event) {
      const key = event.keyCode;
      setShowControls(true); // Show controls
      clearTimeout(hideControlsTimer); // Reset timer
      hideControlsTimer = setTimeout(() => {
        setShowControls(false); // Hide controls after 5 seconds of inactivity
      }, 2000);
      switch (key) {
        case 461:
          onBackClick(videoComponent.current.currentTime);
          break;
        case 415:
          videoComponent.current.play();
          break;
        case 19:
          videoComponent.current.pause();
          break;
        case 417:
          if (Boolean(settings?.seekingTimer?.isOn))
            videoComponent.current.currentTime =
              videoComponent.current.currentTime +
              parseInt(settings.seekingTimer.timer, 10);
          break;
        case 412:
          if (Boolean(settings?.seekingTimer?.isOn))
            videoComponent.current.currentTime =
              videoComponent.current.currentTime -
              parseInt(settings.seekingTimer.timer, 10);
          break;
        // case 40:
        //   dispatch(navigate({path: 'home', props:{spoton:`${id}-card`}}));
        //   break;
        // case 13:
        //   if (videoComponent.current.paused) videoComponent.current.play();
        //   else if (!videoComponent.current.ended)
        //     videoComponent.current.pause();
        // console.log(videoComponent.current.volume);
        // video.volume = video.volume == 0 ? video.volume+0.1 : video.volume-0.1;
        // break;
        case 448:
          break;
        case 449:
          videoComponent.current.muted = true;
        default:
          break;
      }
    }
    window.document.addEventListener("keydown", keyListener);
    return () => {
      window.document.removeEventListener("keydown", keyListener);
      clearTimeout(hideControlsTimer);
    };
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (videoComponent.current) {
        const currentTime = videoComponent.current.currentTime;
        const duration = videoComponent.current.duration;

        setDuration(duration);
        setSeekBarProgress((currentTime / duration) * 100);

        setCurrentTimeDisplay(formatTime(currentTime));
        setTotalTimeDisplay(formatTime(duration));
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  const formatTime = (timeInSeconds) => {
    const pad = (num, size) => ("000" + num).slice(size * -1);
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
  };

  const handleSeek = (event) => {
    const newTime =
      (event.nativeEvent.offsetX / event.target.offsetWidth) * duration;
    videoComponent.current.currentTime = newTime;
  };

  const handleVideoEnd = () => {
    onEnd(settings?.autoPlay);
  };

  useEffect(() => {
    const onErrorEvent = (event) => {
      onError(event.detail);
    };

    const onError = (error) => {
      console.error("Error code", error.code, "object", error);
    };

    const initPlayer = async () => {
      const video = videoComponent.current;
      const container = videoContainer.current;

      const player = new shaka.Player(video);
      videoComponent.current.currentTime = currentTime || 0;

      // Enable DRM (Widevine, PlayReady, etc.) by specifying the license server URL.
      if (keySystem)
        player.configure({
          drm: {
            servers: {
              "com.widevine.alpha": keySystem, //"https://wv.service.expressplay.com/hms/wv/rights/?ExpressPlayToken=BwA1Wc7PKcsAJGQ5NjljYjU0LTM1YzYtNDg0Yi04M2U2LTIxNWMwZWZjOGUyYQAAAIBL7ZUPQ8NwM8ZlTG-4wkSbJ_jP8MI02_onvIqH146mW_4-EPwJ9RiL6evqliJrOS2T60o1puzPatID0DRskGbZg-ExAEDv8jnQTv7OJs70XGrdEdgchQXsqFo3wG9nIVdeI8mcSvAuxlf1lIbGaX13i_WTMhrPo40lOuJBC-Dq4N41E70DaGK0xGQ7v6ozOywEUbPM",
            },
          },
        });

      if (isVideo) {
        // Set buffering goal to a smaller value for faster startup
        player.configure("streaming.bufferingGoal", 5);

        // Set buffer behind to manage memory usage
        player.configure("streaming.bufferBehind", 10);

        // Set rebuffering goal to a smaller value
        player.configure("streaming.rebufferingGoal", 2);
      } else {
        // Configure settings for faster start-up and low latency for channels
        player.configure("streaming.bufferingGoal", 2); // Lower buffering goal for faster startup
        player.configure("streaming.rebufferingGoal", 1); // Lower rebuffering goal for quicker recovery
        player.configure("streaming.lowLatencyMode", true); // Enable low latency mode if supported
        player.configure("streaming.stallThreshold", 1); // Threshold to detect stalls
        player.configure("streaming.stallSkip", 0.1); // Skip ahead this much if a stall is detected
      }
      // Listen for error events
      player.addEventListener("error", onErrorEvent);
      player.setTextTrackVisibility(true);

      try {
        // Try to load a manifest
        await player.load(manifestUri);
        SetTextTracks(player.getTextTracks());
        console.log("The video has now been loaded!");
        videoComponent.current["shakaPlayer"] = player;
        setLoading(false);
        video.play();
      } catch (error) {
        onError(error);
      }
    };

    initPlayer();

    // Clean up event listeners and player instance
    return () => {
      const video = videoComponent.current;
      if (video) {
        const player = video["shakaPlayer"];
        if (player) {
          player.unload();
        }
      }
    };
  }, []);
  const handleSubtitleClick = (track) => {
    if (videoComponent.current) {
      const player = videoComponent.current["shakaPlayer"];
      console.log(player);
      if (player) {
        // Hide previous subtitles
        player.setTextTrackVisibility(false);

        // Show selected subtitles
        player.selectTextTrack(track, /* clearBuffer */ true);

        // Update state to reflect the change in subtitles
        SetTextTracks(player.getTextTracks());
        player.setTextTrackVisibility(true);
        console.log(player);
      }
    }
  };
  const renderPlaybackSpeedOptions = () => {
    const playbackSpeeds = [0.5, 0.75, 1, 1.25, 1.5, 2]; // Add more speeds if needed
    return (
      <div className="absolute flex flex-col items-center justify-center bg-formBg">
        {playbackSpeeds.map((speed) => (
          <PrimaryButton
            key={speed}
            backgroundOpacity="transparent"
            type="button"
            className="z-50 selected"
            onClick={() => {
              setShowPlaybackSpeeds(!showPlaybackSpeeds);
              handlePlaybackSpeed(speed);
            }}
          >
            {speed}x
          </PrimaryButton>
        ))}
      </div>
    );
  };
  const renderSubtitles = () => {
    return (
      <div className="absolute flex flex-col items-center justify-center bg-formBg">
        {textTracks?.map((track, index) => {
          console.log(track);
          return (
            <PrimaryButton
              key={index}
              backgroundOpacity="transparent"
              type="button"
              className="z-50 selected"
              onClick={() => {
                setShowSubtitles(!showSubtitles);
                handleSubtitleClick(track);
              }}
            >
              {track.label}
            </PrimaryButton>
          );
        })}
      </div>
    );
  };
  const renderPlayerControls = () => {
    return (
      <div
        className={`z-50 w-screen h-screen absolute flex justify-between left-0 flex-col ${
          showControls ? "" : "hidden"
        }`}
      >
        <div className="flex w-screen flex-row items-center justify-between">
          <p></p>
          {isVideo && (
            <div className="justify-evenly">
              {textTracks.length > 0 && (
                <PrimaryButton
                  icon="closedcaption"
                  backgroundOpacity="transparent"
                  type="button"
                  className="z-50 selected"
                  onClick={() => setShowSubtitles(!showSubtitles)}
                />
              )}
              <PrimaryButton
                icon="playspeed"
                backgroundOpacity="transparent"
                type="button"
                className="z-50 selected"
                onClick={() => setShowPlaybackSpeeds(!showPlaybackSpeeds)}
              />
              {/* <PrimaryButton
              icon="closedcaption"
              backgroundOpacity="transparent"
              type="button"
              className="z-50"
              onClick={() => {
                console.log("subtitles clicked!");
              }}
            /> */}
            </div>
          )}
        </div>
        <div className="flex items-center justify-center">
          {loading ? (
            <div className="absolute selected top-50 left-50 z-50">
              <Spinner />
            </div>
          ) : (
            <PrimaryButton
              spotlightId="videoComp"
              icon={videoComponent.current?.paused ? "play" : "pause"} // Toggle icon
              backgroundOpacity="transparent"
              type="button"
              className="absolute selected top-50 left-50 z-50"
              onClick={() => {
                if (videoComponent.current.paused)
                  videoComponent.current.play();
                else if (!videoComponent.current.ended)
                  videoComponent.current.pause();
              }}
            />
          )}
        </div>
        <div>
          {isVideo && (
            <div
              className={`mb-40 mx-5 relative h-8 bg-gray-300 ${
                showControls ? "" : "hidden"
              }`}
              onClick={handleSeek}
            >
              <div className="h-full bg-formBg relative w-full">
                <div
                  className="h-full bg-primary"
                  style={{ width: `${seekBarProgress}%` }}
                />
                <SpottableComponent
                  className="h-10 w-10 z-50"
                  spotlightId="seekbarPointer"
                  onSpotlightLeft={() => {
                    if (Boolean(settings?.seekingTimer?.isOn))
                      videoComponent.current.currentTime =
                        videoComponent.current.currentTime -
                        parseInt(settings.seekingTimer.timer, 10);
                  }}
                  onSpotlightRight={() => {
                    if (Boolean(settings?.seekingTimer?.isOn))
                      videoComponent.current.currentTime =
                        videoComponent.current.currentTime +
                        parseInt(settings.seekingTimer.timer, 10);
                  }}
                  children={
                    <div
                      className="absolute h-10 w-10 bg-primary rounded-full cursor-pointer border-8 border-mainText top-4"
                      style={{
                        left: `${seekBarProgress}%`,
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  }
                />
              </div>
              <div className="flex justify-between mt-1 text-xs text-gray-500">
                <div className="current-time">{currentTimeDisplay}</div>
                <div className="total-time">{totalTimeDisplay}</div>
              </div>
            </div>
          )}
        </div>
        {showSubtitles && (
          <div className="absolute justify-end items-end z-50 p-4 left-[85vw] mt-32">
            {renderSubtitles()}
          </div>
        )}
        {showPlaybackSpeeds && (
          <div className="absolute justify-end items-end z-50 p-4 left-[85vw] mt-32">
            {renderPlaybackSpeedOptions()}
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <SpottableComponent>{renderPlayerControls()}</SpottableComponent>
      <div
        className="video-container"
        ref={videoContainer}
        data-shaka-player-container
        data-shaka-player-cast-receiver-id="07AEE832"
      >
        <video
          ref={videoComponent}
          autoPlay
          data-shaka-player
          id="video"
          style={{
            width: `${window.innerWidth + "px"}`,
            height: `${window.innerHeight + "px"}`,
          }}
          autoFocus
          onPointerOver={(e) => e.target.focus()}
          onEnded={handleVideoEnd}
          data-spotlight-id="video"
        ></video>
      </div>
    </>
  );
};

export default VideoPlay;
