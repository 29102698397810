import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import RadioItem from '@enact/sandstone/RadioItem';
import ri from '@enact/ui/resolution';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import pageActions from '../../../store/modules/pages/actions';
import assets from '../../../assets/icons';
import menuActions from '../../../store/modules/menuItems/actions';
import bootstrapActions from '../../../store/modules/bootstrap/actions';
import languageActions from '../../../store/modules/language/actions';
import profileActions from '../../../store/modules/profile/actions';
import BodyText from '@enact/sandstone/BodyText';
import { VirtualGridList } from '@enact/sandstone/VirtualList';
import { Panel } from '@enact/sandstone/Panels';
import { navigate } from '../../../store/modules/navigate/actions';
import { toIString } from '@enact/i18n/$L';

function Language({updateLocale, ...rest}) {
  const dispatch = useDispatch();
  const displayLanguages = useSelector(state => state.language.data);
  const {me, profiles} = useSelector(state => state.auth);
  const activeProfile = profiles?.activeProfile;
  const defaultLanguage = useSelector(state => state.storefront.config.data?.defaultDisplayLanguage);
  const [selectedLanguage, setSelectedLanguage] = useState(activeProfile.language.storefront || defaultLanguage?.current); 
  useEffect(() => {
    dispatch(languageActions.fetchLanguagePending({path:'display-language-tv'}));
  }, []);

  const renderLanguage = ({index}) => {
    return (
      <RadioItem name='language' onClick={()=>setSelectedLanguage(displayLanguages[index].code)} value={displayLanguages[index].code} selected={displayLanguages[index].code === selectedLanguage}>{displayLanguages[index].name}</RadioItem>
    )
  }
  const renderLanguages = () => {
    return (
      <VirtualGridList itemRenderer={renderLanguage} className="text-center h-1/6"
        itemSize={{
        minHeight: ri.scale(100),
        minWidth: ri.scale(229)
      }} dataSize={displayLanguages.length}/>
    )
  }
  const renderBackButton = () => {
    return (
      <PrimaryButton data-spotlight-id='btn'
        backgroundOpacity="transparent" icon={assets.backbutton}
        type="button" 
        className="absolute top-10 left-10"
        onClick={()=>dispatch(navigate({path:'home',props:{}}))}/>
    );
  };
  const renderContent = () => {
    // if (error || contentLanguageError)
    //   return renderCenteredContainer(<BodyText>LANGUAGE_NOT_FOUND</BodyText>);

    // if (!loading && !contentLanguages?.length)
    //   return renderCenteredContainer(<CircularProgress />);

    return (
      <Panel>
        {renderBackButton()}
        <div className="my-48 mx-24">
        <BodyText>{toIString("DISPLAY_LANGUAGE").toString()}</BodyText>
        {renderLanguages()}
        <PrimaryButton onClick={ () => {
          dispatch(profileActions.updateUserProfilePending({ 
            displayLanguage: selectedLanguage, id: activeProfile.id, name: activeProfile.name, contentLanguage: activeProfile.language.content 
          }));
          window.localStorage.removeItem(`activeProfile-${me.id}`);
          updateLocale(selectedLanguage);
          dispatch(pageActions.resetPageData());
          dispatch(menuActions.resetMenuItems());
          window.localStorage.setItem('locale',selectedLanguage);
        }}>{toIString("SAVE").toString()}</PrimaryButton>
        </div>
      </Panel>
    );
  };

  return renderContent();
}

export default Language;
