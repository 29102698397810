export const constants = {
  FETCH_PLAYER_SETTINGS_PENDING: 'playerSettings/FETCH_PLAYER_SETTINGS_PENDING',
  FETCH_PLAYER_SETTINGS_SUCCESS: 'playerSettings/FETCH_PLAYER_SETTINGS_SUCCESS',
  FETCH_PLAYER_SETTINGS_FAILURE: 'playerSettings/FETCH_PLAYER_SETTINGS_FAILURE',
};

const fetchPlayerSettingsPending = () => ({
  type: constants.FETCH_PLAYER_SETTINGS_PENDING,
});

const fetchPlayerSettingsSuccess = ({ payload }) => {
  return {
    type: constants.FETCH_PLAYER_SETTINGS_SUCCESS,
    payload,
  };
};

const fetchPlayerSettingsFailure = payload => ({
  type: constants.FETCH_PLAYER_SETTINGS_FAILURE,
  payload,
});

export default {
  fetchPlayerSettingsPending,
  fetchPlayerSettingsSuccess,
  fetchPlayerSettingsFailure,
};
