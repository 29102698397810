export const constants = {
  FETCH_PLAYER_PLAYLIST_PENDING: 'playerPlaylist/FETCH_PLAYER_PLAYLIST_PENDING',
  FETCH_PLAYER_PLAYLIST_SUCCESS: 'playerPlaylist/FETCH_PLAYER_PLAYLIST_SUCCESS',
  FETCH_PLAYER_PLAYLIST_FAILURE: 'playerPlaylist/FETCH_PLAYER_PLAYLIST_FAILURE',
  GET_PREVIOUS_AND_NEXT_CONTENT_PENDING:
    'playerPlaylist/GET_PREVIOUS_AND_NEXT_CONTENT_PENDING',
  GET_PREVIOUS_AND_NEXT_CONTENT_SUCCESS:
    'playerPlaylist/GET_PREVIOUS_AND_NEXT_CONTENT_SUCCESS',
  GET_PREVIOUS_AND_NEXT_CONTENT_FAILURE:
    'playerPlaylist/GET_PREVIOUS_AND_NEXT_CONTENT_FAILURE',

  SIGN_URL_PENDING: 'playerPlaylist/SIGN_URL_PENDING',
  SIGN_URL_SUCCESS: 'playerPlaylist/SIGN_URL_SUCCESS',
  SIGN_URL_FAILURE: 'playerPlaylist/SIGN_URL_FAILURE',

  SET_VIDEO_ID: 'playerPlaylist/SET_VIDEO_ID',
  SET_LIVE_CHANNEL_CONTENT: 'playerPlaylist/SET_LIVE_CHANNEL_CONTENT',

  RESET_PLAYER_PLAYLIST: 'playerPlaylist/RESET_PLAYER_PLAYLIST',
};

const fetchPlayerPlaylistPending = payload => ({
  type: constants.FETCH_PLAYER_PLAYLIST_PENDING,
  payload,
});

const fetchPlayerPlaylistSuccess = payload => {
  return {
    type: constants.FETCH_PLAYER_PLAYLIST_SUCCESS,
    payload,
  };
};

const fetchPlayerPlaylistFailure = payload => ({
  type: constants.FETCH_PLAYER_PLAYLIST_FAILURE,
  payload,
});

const signUrlPending = payload => ({
  type: constants.SIGN_URL_PENDING,
  payload,
});

const signUrlSuccess = payload => {
  return {
    type: constants.SIGN_URL_SUCCESS,
    payload,
  };
};

const signUrlFailure = payload => ({
  type: constants.SIGN_URL_FAILURE,
  payload,
});

const resetPlayerPlaylist = payload => {
  return {
    type: constants.RESET_PLAYER_PLAYLIST,
    payload,
  };
};

const getPreviousAndNextContentPending = payload => ({
  type: constants.GET_PREVIOUS_AND_NEXT_CONTENT_PENDING,
  payload,
});

const getPreviousAndNextContentSuccess = payload => ({
  type: constants.GET_PREVIOUS_AND_NEXT_CONTENT_SUCCESS,
  payload,
});

const getPreviousAndNextContentFailure = payload => ({
  type: constants.GET_PREVIOUS_AND_NEXT_CONTENT_FAILURE,
  payload,
});

const setVideoId = payload => ({
  type: constants.SET_VIDEO_ID,
  payload,
});

const setLiveChannelContent = payload => ({
  type: constants.SET_LIVE_CHANNEL_CONTENT,
  payload,
});

export default {
  fetchPlayerPlaylistPending,
  fetchPlayerPlaylistSuccess,
  fetchPlayerPlaylistFailure,
  signUrlPending,
  signUrlSuccess,
  signUrlFailure,
  getPreviousAndNextContentPending,
  getPreviousAndNextContentSuccess,
  getPreviousAndNextContentFailure,
  setVideoId,
  setLiveChannelContent,
  //
  resetPlayerPlaylist,
};
