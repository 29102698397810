import { fork } from 'redux-saga/effects';

import authSaga from './modules/auth/sagas';
import pageSaga from './modules/pages/sagas';
import bootstrap from './modules/bootstrap/sagas';
import channelsSaga from './modules/channels/sagas';
import staticSections from './modules/staticSections/sagas';
import contentsSaga from './modules/contents/sagas';
import relatedContentsSaga from './modules/relatedContent/sagas';
import episodesSaga from './modules/episodes/sagas';
import SeasonsSaga from './modules/seasons/sagas';
import searchSaga from './modules/search/sagas';
import menuItemsSaga from './modules/menuItems/sagas';
import profileInfoSaga from './modules/profile/sagas';
import favoritesSaga from './modules/favorites/sagas';
import playerSaga from './modules/player/sagas';
import playlistSaga from './modules/playlist/sagas';
import commonSaga from './modules/common/sagas';
import storefrontSaga from './modules/storefront/sagas';
import deviceManagementSaga from './modules/deviceManagement/sagas';
import ageRestrictionSaga from './modules/ageRestriction/sagas';
import languageSaga from './modules/language/sagas';
import paymentSaga from './modules/payment/sagas';
import subscriptionsSaga from './modules/subscriptions/sagas';
import otherContentSaga from './modules/otherContent/sagas';
import epgSaga from './modules/epg/sagas';

import gqlClient from '../services/graphqlClient';
import api from '../services/apiClient';

import { apiEndpoint as apiEndpointUrl, getConfigurationUrl } from '../lib/urls';

const apiEndpoint = `${apiEndpointUrl}store`;
const deliveryApi = `${apiEndpointUrl}`;
const gqlEndpoint = `${apiEndpointUrl}store/graphql`;

export const apiClient = api.create(apiEndpoint);
export const deliveryClient = api.create(deliveryApi);
export const graphqlClient = gqlClient.create(gqlEndpoint);
export const configClient = gqlClient.create(getConfigurationUrl);

export const handler = {
  gqlClient: graphqlClient,
  restClient: apiClient,
  deliveryClient: deliveryClient,
  configClient: configClient,
};

export default function* rootSaga() {
  yield fork(authSaga(handler).watcher);
  yield fork(pageSaga(handler).watcher);
  yield fork(bootstrap(handler).watcher);
  yield fork(channelsSaga(handler).watcher);
  yield fork(staticSections(handler).watcher);
  yield fork(contentsSaga(handler).watcher);
  yield fork(relatedContentsSaga(handler).watcher);
  yield fork(episodesSaga(handler).watcher);
  yield fork(SeasonsSaga(handler).watcher);
  yield fork(searchSaga(handler).watcher);
  yield fork(menuItemsSaga(handler).watcher);
  yield fork(profileInfoSaga(handler).watcher);
  yield fork(playerSaga(handler).watcher);
  yield fork(playlistSaga(handler).watcher);
  yield fork(commonSaga(handler).watcher);
  yield fork(deviceManagementSaga(handler).watcher);
  yield fork(storefrontSaga(handler).watcher);
  yield fork(ageRestrictionSaga(handler).watcher);
  yield fork(languageSaga(handler).watcher);
  yield fork(favoritesSaga(handler).watcher);
  yield fork(paymentSaga(handler).watcher);
  yield fork(subscriptionsSaga(handler).watcher);
  yield fork(otherContentSaga(handler).watcher);
  yield fork(epgSaga(handler).watcher);
}
