
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {kind} from '@enact/core/kind';

import {SpotlightContainerDecorator, spotlightDefaultClass} from '@enact/spotlight/SpotlightContainerDecorator';
// import Detail from '../../views/Detail';
import {navigate} from '../../../store/modules/navigate/actions';
import contentTypeActions from '../../../store/modules/contents/actions';
import BannerWithInfo from '../../../views/BannerWithInfo';
import playerPlaylistActions from '../../../store/modules/playlist/actions';
import assets from '../../../assets/icons';
import spotlight from '@enact/spotlight';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import Seasons from '../../../views/Seasons'

const EpisodeDetail = ({...rest}) => {
  const dispatch = useDispatch();
  const props = useSelector(state => state.navigate?.props);
  const id = props?.id
  const { contentsById, contentAccessibilityById } = useSelector(
    state => state.contents
  );
  const me = useSelector(state => state.auth.me);

  // Creating these because they don't exist unless a call is made for an id
  let loading = true;
  let error = false;

  // Set Detail Page State
//   if (extraDataByIds[id]) {
//     loading = extraDataByIds[id].loading;
//     error = extraDataByIds[id].error;
//   }
  useEffect(() => {
    // Do not fetch the details if fetched on server
    if (id && !contentsById?.[id]?.id)
      dispatch(
        contentTypeActions.getContentsByIdsPending({
          ids: [id]
        })
      );

    if (me.id && id && !contentAccessibilityById?.[id])
      dispatch(
        contentTypeActions.getContentsAccessibilityByIdPending({ id })
      );
  }, [dispatch, id]);

  useEffect(() => {
    if(contentAccessibilityById?.[id]?.isUserEligibleToWatch){
      dispatch(playerPlaylistActions.signUrlPending({ id }));
      dispatch(contentTypeActions.getContentWatchTimePending({ id }));
    }
  }, [contentAccessibilityById?.[id]])
  useEffect(() => {
    function keyListener(event) {
      const key = event.keyCode;
      if(key === 461) onBackClick();
    }
    window.document.addEventListener("keydown", keyListener);
    return () => {
      window.document.removeEventListener('keydown',keyListener);
    }
  })
  const onBackClick = () => {
    dispatch(navigate({path:'detail/seasons',props:{id: props.seasonId, seriesId: props.seriesId}}))
  }
  const renderBackButton = () => {
    return (
      <PrimaryButton data-spotlight-id='btn'
        backgroundOpacity="transparent" icon={assets.backbutton}
        type="button" 
        className="absolute top-10 left-10 z-50"
        onClick={onBackClick}/>
    );
  };
  const renderBanner = () => {
    // If content accessibility is currently being fetched
    // only consider access when the user is logged in.
    // As this will be false for a loggedout user and the banner
    // will never load if null is returned
    // if (
    //   typeof contentAccessibilityById[id]?.loading &&
    //   me.id
    // ) {
    //   // Can return a Banner Shimmer
    //   return null;
    // }

    let rentOptionsPresent = false;
    if (typeof contentsById[id]?.tvod !== 'undefined') {
      rentOptionsPresent = !!contentsById[id].tvod.length;
    }

    // embedded the youtube player if we add the media source type as youtube link in RMC
    const item = contentsById[id];
    if (item?.mediaSource === 'Youtube Link' && item?.mediaSourceUrl) {
      return (
        <div className="w-full h-full" style={{ marginTop: '5rem' }}>
          <iframe
            width={window.innerWidth * 1}
            height={window.innerHeight * 0.82}
            src={item.mediaSourceUrl}
            title={item.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      );
    }
    return (
      <BannerWithInfo item={contentsById[id]} onSpotlightDown={()=>{spotlight.focus(`${id}-cover`)}}
        isRestricted={!contentAccessibilityById[id]?.isUserEligibleToWatch}
        seasonId={props.seasonId} seriesId={props.seriesId}
      />
    //   <Banner
    //     item={contentTypesByIds[id]}
    //     cdnDomainName={cdn}
    //     isRestricted={!contentTypesByIds[id]?.canAccess}
    //     onSubscribeClick={handleSubscribeClick}
    //     onRentClick={rentOptionsPresent && handleRentClick}
    //   />
    );
  };

//   const renderFooter = () => (isAuthenticated ? <Footer /> : null);
  const isBackPressed = (e) => {
    if(e.keyCode == 461) onBackClick();
  }
  return (
    <>
      <div className="flex flex-col items-center justify-center text-mainText h-full w-full" onKeyDown={(e)=>isBackPressed(e)}>
        {renderBackButton()}
        {renderBanner()}
        {/* {renderDetails()} */}
      </div>
      {/* {renderFooter()} */}
    </>
  );
};

export default  SpotlightContainerDecorator({continue5WayHold:true,enterTo:'last-focused',preserveId:true},EpisodeDetail);
