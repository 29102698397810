import gql from 'graphql-tag';

const getContentsByIds = gql`
  query searchContent($ids: [String], $locale: String) {
    searchContent(ids: $ids, locale: $locale) {
      data
      cdn {
        domainName
        aliases
      }
    }
  }
`;

const searchContent = gql`
  query searchContent(
    $ids: [String]
    $queryName: String
    $skip: Int
    $limit: Int
  ) {
    searchContent(
      ids: $ids
      queryName: $queryName
      skip: $skip
      limit: $limit
    ) {
      data
      count
      cdn {
        domainName
        aliases
      }
    }
  }
`;

const fetchContentGroup = gql`
  query fetchContentGroup(
    $contentGroupId: String
    $slug: String
    $limit: Int
    $skip: Int
  ) {
    fetchContentGroup(
      contentGroupId: $contentGroupId
      slug: $slug
      contentDataLimit: $limit
      contentDataSkip: $skip
    ) {
      name
      type
      contentData {
        cdn {
          domainName
        }
        count
        list
      }
    }
  }
`;

const getContentAccessibility = gql`
  query isUserEligibleToWatch($contentId: String) {
    isUserEligibleToWatch(contentId: $contentId)
  }
`;

const getContentWatchDuration = gql`
  query getContentWatchDuration($contentId: String!) {
    getContentWatchDuration(contentId: $contentId)
  }
`;

export default {
  getContentsByIds,
  searchContent,
  fetchContentGroup,
  getContentAccessibility,
  getContentWatchDuration,
};
