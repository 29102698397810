export const constants = {
  GET_FAVORITE_LIST_PENDING: 'favoriteList/GET_FAVORITE_LIST_PENDING',
  GET_FAVORITE_LIST_SUCCESS: 'favoriteList/GET_FAVORITE_LIST_SUCCESS',
  GET_FAVORITE_LIST_FAILURE: 'favoriteList/GET_FAVORITE_LIST_FAILURE',

  ADD_TO_FAVORITES_PENDING: 'favoriteList/ADD_TO_FAVORITES_PENDING',
  ADD_TO_FAVORITES_SUCCESS: 'favoriteList/ADD_TO_FAVORITES_SUCCESS',
  ADD_TO_FAVORITES_FAILURE: 'favoriteList/ADD_TO_FAVORITES_FAILURE',

  REMOVE_FROM_FAVORITES_PENDING: 'favoriteList/REMOVE_FROM_FAVORITES_PENDING',
  REMOVE_FROM_FAVORITES_SUCCESS: 'favoriteList/REMOVE_FROM_FAVORITES_SUCCESS',
  REMOVE_FROM_FAVORITES_FAILURE: 'favoriteList/REMOVE_FROM_FAVORITES_FAILURE',

  CHECK_CONTENT_IN_FAVORITES_PENDING:
    'favoriteList/CHECK_CONTENT_IN_FAVORITES_PENDING',
  CHECK_CONTENT_IN_FAVORITES_SUCCESS:
    'favoriteList/CHECK_CONTENT_IN_FAVORITES_SUCCESS',
  CHECK_CONTENT_IN_FAVORITES_FAILURE:
    'favoriteList/CHECK_CONTENT_IN_FAVORITES_FAILURE',

  RESET_FAVORITES_STATE: 'favoriteList/RESET_FAVORITES_STATE',
};

const getFavoriteListPending = payload => ({
  type: constants.GET_FAVORITE_LIST_PENDING,
  payload,
});

const getFavoriteListSuccess = payload => ({
  type: constants.GET_FAVORITE_LIST_SUCCESS,
  payload,
});

const getFavoriteListFailure = payload => ({
  type: constants.GET_FAVORITE_LIST_FAILURE,
  payload,
});

const addToFavoritesListPending = payload => ({
  type: constants.ADD_TO_FAVORITES_PENDING,
  payload,
});

const addToFavoritesListSuccess = payload => ({
  type: constants.ADD_TO_FAVORITES_SUCCESS,
  payload,
});

const addToFavoritesListFailure = payload => ({
  type: constants.ADD_TO_FAVORITES_FAILURE,
  payload,
});

const removeFromFavoritesListPending = payload => ({
  type: constants.REMOVE_FROM_FAVORITES_PENDING,
  payload,
});

const removeFromFavoritesListSuccess = payload => ({
  type: constants.REMOVE_FROM_FAVORITES_SUCCESS,
  payload,
});

const removeFromFavoritesListFailure = payload => ({
  type: constants.REMOVE_FROM_FAVORITES_FAILURE,
  payload,
});

const checkContentIdInFavoritesPending = payload => ({
  type: constants.CHECK_CONTENT_IN_FAVORITES_PENDING,
  payload,
});

const checkContentIdInFavoritesSuccess = payload => ({
  type: constants.CHECK_CONTENT_IN_FAVORITES_SUCCESS,
  payload,
});

const checkContentIdInFavoritesFailure = payload => ({
  type: constants.CHECK_CONTENT_IN_FAVORITES_FAILURE,
  payload,
});

const resetFavoritesState = () => ({
  type: constants.RESET_FAVORITES_STATE,
});

export default {
  getFavoriteListPending,
  getFavoriteListSuccess,
  getFavoriteListFailure,

  addToFavoritesListPending,
  addToFavoritesListSuccess,
  addToFavoritesListFailure,

  removeFromFavoritesListPending,
  removeFromFavoritesListSuccess,
  removeFromFavoritesListFailure,

  checkContentIdInFavoritesPending,
  checkContentIdInFavoritesSuccess,
  checkContentIdInFavoritesFailure,

  resetFavoritesState,
};
