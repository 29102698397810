export const constants = {
  SET_CURRENT_THEME: 'app/SET_CURRENT_THEME',

  SHOW_BROWSE_PANEL: 'app/SHOW_BROWSE_PANEL',
  HIDE_BROWSE_PANEL: 'app/HIDE_BROWSE_PANEL',
  OPEN_BROWSE_PANEL: 'app/OPEN_BROWSE_PANEL',
  CLOSE_BROWSE_PANEL: 'app/CLOSE_BROWSE_PANEL',

  SHOW_TOAST: 'app/SHOW_TOAST',
  CLOSE_TOAST: 'app/CLOSE_TOAST',

  SHARE_PENDING: 'app/SHARE_PENDING',
  SHARE_SUCCESS: 'app/SHARE_SUCCESS',
  SHARE_FAILURE: 'app/SHARE_FAILURE',
};

const setCurrentTheme = payload => ({
  type: constants.SET_CURRENT_THEME,
  payload,
});

const showToast = payload => ({
  type: constants.SHOW_TOAST,
  payload,
});

const closeToast = payload => ({
  type: constants.CLOSE_TOAST,
  payload,
});

const showBrowsePanel = payload => ({
  type: constants.SHOW_BROWSE_PANEL,
  payload,
});

const hideBrowsePanel = payload => ({
  type: constants.HIDE_BROWSE_PANEL,
  payload,
});

const openBrowsePanel = payload => ({
  type: constants.OPEN_BROWSE_PANEL,
  payload,
});

const closeBrowsePanel = payload => ({
  type: constants.CLOSE_BROWSE_PANEL,
  payload,
});

const sharePending = payload => ({ type: constants.SHARE_PENDING, payload });
const shareSuccess = payload => ({ type: constants.SHARE_SUCCESS, payload });
const shareFailure = payload => ({ type: constants.SHARE_FAILURE, payload });

export default {
  setCurrentTheme,
  //
  showBrowsePanel,
  hideBrowsePanel,
  openBrowsePanel,
  closeBrowsePanel,
  //
  showToast,
  closeToast,
  //
  sharePending,
  shareSuccess,
  shareFailure,
};
