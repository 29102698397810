import { constants } from './actions';

const initialState = {
  loading: false,
  error: false,
  message: '',
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.EMAIL_FORGOT_PASSWORD_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }

    case constants.EMAIL_FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        message: action.payload.message,
      };
    }

    case constants.EMAIL_FORGOT_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload.message,
      };
    }

    case constants.RESET_STATE: {
      return { ...initialState };
    }

    default:
      return state;
  }
}

export default reducer;
