
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {kind} from '@enact/core/kind';

import {SpotlightContainerDecorator, spotlightDefaultClass} from '@enact/spotlight/SpotlightContainerDecorator';
// import Detail from '../../views/Detail';
import {navigate} from '../../../store/modules/navigate/actions';
import contentTypeActions from '../../../store/modules/contents/actions';
import BannerWithInfo from '../../../views/BannerWithInfo';
import { Scroller } from '@enact/sandstone/Scroller';
import assets from '../../../assets/icons';
import spotlight from '@enact/spotlight';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import Episodes from '../../../views/Episodes';

const SeasonDetail = ({...rest}) => {
  const dispatch = useDispatch();
  const props = useSelector(state => state.navigate?.props);
  const id = props?.id
  const { contentsById, contentAccessibilityById } = useSelector(
    state => state.contents
  );
  const me = useSelector(state => state.auth.me);

  const storefrontConfig = useSelector(state => state.storefront.config);
  const activeProfile = useSelector(state => state.auth.profiles.activeProfile);

  useEffect(() => {
    // Do not fetch the details if fetched on server
    if (id && !contentsById?.[id]?.id)
      dispatch(
        contentTypeActions.getContentsByIdsPending({
          ids: [id],
          locale: activeProfile?.language.storefront || storefrontConfig.data?.defaultDisplayLanguage.current
        })
      );

    if (me.id && id && !contentAccessibilityById?.[id])
      dispatch(
        contentTypeActions.getContentsAccessibilityByIdPending({ id })
      );
  }, [dispatch, id]);

  useEffect(() => {
    function keyListener(event) {
      const key = event.keyCode;
      if(key === 461) onBackClick();
    }
    window.document.addEventListener("keydown", keyListener);
    return () => {
      window.document.removeEventListener('keydown',keyListener);
    }
  })
  const onBackClick = () => {
    if(props.seriesId) dispatch(navigate({path:'detail',props:{id: props.seriesId}}))
    else dispatch(navigate({path: 'home', props: {}}))
  }
  const renderBackButton = () => {
    return (
      <PrimaryButton data-spotlight-id='btn'
        backgroundOpacity="transparent" icon={assets.backbutton}
        type="button" 
        className="absolute top-10 left-10 z-50"
        onClick={onBackClick}/>
    );
  };
  const renderBanner = () => {
    // If content accessibility is currently being fetched
    // only consider access when the user is logged in.
    // As this will be false for a loggedout user and the banner
    // will never load if null is returned
    // if (
    //   typeof contentAccessibilityById[id]?.loading &&
    //   me.id
    // ) {
    //   // Can return a Banner Shimmer
    //   return null;
    // }

    let rentOptionsPresent = false;
    if (typeof contentsById[id]?.tvod !== 'undefined') {
      rentOptionsPresent = !!contentsById[id].tvod.length;
    }

    // embedded the youtube player if we add the media source type as youtube link in RMC
    const item = contentsById[id];
    if (item?.mediaSource === 'Youtube Link' && item?.mediaSourceUrl) {
      return (
        <div className="w-full h-full" style={{ marginTop: '5rem' }}>
          <iframe
            width={window.innerWidth * 1}
            height={window.innerHeight * 0.82}
            src={item.mediaSourceUrl}
            title={item.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      );
    }
    return (
      <BannerWithInfo item={contentsById[id]} onSpotlightDown={()=>{spotlight.focus(`${id}-cover`)}}
        isRestricted={!contentAccessibilityById[id]?.isUserEligibleToWatch}
      />
    );
  };


  const renderEpisodes = () => {
    // If series has no seasons
    // if (!contentsById[id]?.seasonscount || contentsById[id]?.seasonscount === 0) return null;

    // Convert [ {id: 'seasonId'}, ...] to ['seasonId', 'seasonId1', ...]
    const episodeIds = contentsById[id]?.content.episodes?.map(ep => ep.id);

    if (!episodeIds?.length) return null;

    return <Episodes episodeIds={episodeIds} seasonId={id} seriesId={props.seriesId}/>;
  };

//   const renderFooter = () => (isAuthenticated ? <Footer /> : null);
  const isBackPressed = (e) => {
    if(e.keyCode == 461) onBackClick();
  }
  return (
    <Scroller verticalScrollbar='hidden'>
      <div className="flex flex-col items-center justify-center text-mainText h-full w-full" onKeyDown={(e)=>isBackPressed(e)}>
        {/* {renderBackButton()} */}
        {renderBanner()}
        {contentsById[id]?.content?.queryName?.includes('seasons') && renderEpisodes()}
        {/* {renderDetails()} */}
      </div>
      {/* {renderFooter()} */}
    </Scroller>
  );
};

export default  SpotlightContainerDecorator({continue5WayHold:true,enterTo:'last-focused',preserveId:true},SeasonDetail);
