import gql from 'graphql-tag';

const updateProfile = gql`
  mutation updateProfile($data: UpdateProfileInput) {
    updateProfile(data: $data) {
      id
      username
      email
      dateOfBirth
      registeredOn
      userStatus
      currentSubscriptionPlan
      currentSubscriptionExpiresOn
      language {
        content
      }
      lastAccessedFrom
      isPinExists
      noOfActiveDevices
      ltvOfUser
      isDisabled
      userSubscription {
        subscriptionPlan {
          id
          name
          description
          details {
            id
            duration
            price
            planId
          }
          image {
            url
            type
          }
          artefactStatus
          isDefault
          createdAt
          expiresOn
          updatedAt
          currencyCode
        }
        status
        startDate
        expiryDate
        isRecurring
        paymentProvider
      }
    }
  }
`;

const deleteDevice = gql`
  mutation deleteDevice($id: ID!) {
    removeDevice(id: $id)
  }
`;

const updateProfileMutation = gql`
  mutation updateProfileMutation($input: updateProfileDetails, $id: String!) {
    updateProfileMutation(input: $input, id: $id) {
      id
      name
      isDefault
      language {
        content
        storefront
      }
      avatar {
        baseurl
        key
      }
    }
  }
`;

export default {
  updateProfile,
  deleteDevice,
  updateProfileMutation,
};
