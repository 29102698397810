import { constants } from './actions';

const initialState = {
  getSubscriptions: {
    subscriptions: [],
    subscriptionsById: null,
    count: 0,
    loading: false,
    success: false,
    error: false,
    message: '',
  },
  getUpgradableSubscriptions: {
    data: null,
    loading: false,
    success: false,
    error: false,
    message: '',
  },
  subscriptionFailedInfo: {
    loading: false,
    success: false,
    error: false,
    message: '',
  },
  simultaneousViews: {},
};

function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    /// ---------------- GET_SUBSCRIPTIONS START -------
    case constants.GET_SUBSCRIPTIONS_PENDING: {
      return {
        ...state,
        getSubscriptions: {
          ...state.getSubscriptions,
          loading: true,
          message: '',
        },
      };
    }
    case constants.GET_SUBSCRIPTIONS_SUCCESS: {
      const { subscriptions, count, subscriptionsById } = payload;
      return {
        ...state,
        getSubscriptions: {
          ...state.getSubscriptions,
          subscriptions,
          subscriptionsById,
          count,
          loading: false,
          success: true,
          error: false,
        },
      };
    }
    case constants.GET_SUBSCRIPTIONS_FAILURE: {
      const { reason } = payload;
      return {
        ...state,
        getSubscriptions: {
          ...state.getSubscriptions,
          loading: false,
          success: false,
          error: true,
          message: reason,
        },
      };
    }
    /// ---------------- GET_SUBSCRIPTIONS END -------

    /// ---------------- GET_UPGRADABLE_SUBSCRIPTIONS START -------
    case constants.GET_UPGRADABLE_SUBSCRIPTIONS_PENDING: {
      return {
        ...state,
        getUpgradableSubscriptions: {
          ...state.getUpgradableSubscriptions,
          loading: true,
          message: '',
        },
      };
    }
    case constants.GET_UPGRADABLE_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        getUpgradableSubscriptions: {
          ...state.getUpgradableSubscriptions,
          data: payload,
          loading: false,
          success: true,
          error: false,
        },
      };
    }
    case constants.GET_UPGRADABLE_SUBSCRIPTIONS_FAILURE: {
      const { reason } = payload;
      return {
        ...state,
        getUpgradableSubscriptions: {
          ...state.getUpgradableSubscriptions,
          loading: false,
          success: false,
          error: true,
          message: reason,
        },
      };
    }
    /// ---------------- GET_UPGRADABLE_SUBSCRIPTIONS END -------

    /// ---------------- SUBSCRIPTION_FAILED_INFO_ACCEPTED START -------
    case constants.SUBSCRIPTION_FAILED_INFO_ACCEPTED_PENDING: {
      return state;
    }
    case constants.SUBSCRIPTION_FAILED_INFO_ACCEPTED_SUCCESS: {
      return state;
    }
    case constants.SUBSCRIPTION_FAILED_INFO_ACCEPTED_FAILURE: {
      return state;
    }
    /// ---------------- SUBSCRIPTION_FAILED_INFO_ACCEPTED END -------

    case constants.RESET_SUBSCRIPTIONS: {
      return { ...state, getSubscriptions: initialState.getSubscriptions };
    }

    case constants.RESET_UPGRADABLE_SUBSCRIPTIONS: {
      return {
        ...state,
        getUpgradableSubscriptions: initialState.getUpgradableSubscriptions,
      };
    }

    case constants.RESET_SUBSCRIPTION_STATE: {
      return initialState;
    }
    case constants.FETCH_VIEWERS_PENDING: {
      return {
        ...state,
        success: false,
        error: false
      };
    }

    case constants.FETCH_VIEWERS_SUCCESS: {
      return {
        ...state,
        success: true,
        error: false,
        simultaneousViews: action.payload.simultaneousViews
      };
    }

    case constants.FETCH_VIEWERS_FAILURE: {
      return {
        ...state,
        success: false,
        error: true
      };
    }
    default:
      return state;
  }
}

export default reducer;
