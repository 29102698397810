import React from 'react';

export const DEFAULT_THEME_NAME = 'defaultTheme';

export const themes = {
  defaultTheme: {
    // Theme colors
    primary: '#2AADE3',
    secondary: '#F36722',
    separator: '#1C2537',
    disabled: '#353C49',

    // Text Colors
    mainText: '#FFFFFF',
    subText: '#A3A9B2', //
    labelText: '#FFFFFF', //
    placeholderText: '#A3A9B2',
    inputText: '#FFFFFF',
    helpText: '#222140', //
    activeText: '#222140', //

    // Button colors
    primaryBtnText: '#FFFFFF',
    secondaryBtnText: '#A3A9B2',
    outlineBtnText: '#FFFFFF', // Default outline button color

    // General / Snackbar  text colors
    successText: '#FFFFFF',
    infoText: '#FFFFFF', //
    warningText: '#FF6D4D', //
    errorText: '#FFFFFF',

    // Status chip text colors
    successStatusText: '#2BACE2',
    infoStatusText: '#2781D5',
    warningStatusText: '#FF6D4D',
    dangerStatusText: '#F72B50',
    outlineStatusText: '#828894',

    // Button colors
    primaryBtn: '#2AADE3',
    secondaryBtn: '#F36722',
    outlineBtn: 'rgba(255, 255, 255, 0.2)',
    carouselBtn: '#222140', //
    // TODO: Remove after Banner Quick actions final integration
    vrBtn: 'rgba(255, 255, 255, 0.2)', // color for vr button as per new UI changes

    // Button colors for Disabled background colors
    primaryBtnDisabled: '#353C49',
    secondaryBtnDisabled: '#222140', //

    // Background colors
    bodyBg: '#060818',
    formBg: '#0E1726',
    fieldBg: '#1A273E',
    tagBg: '#060818',
    loginBg: '#0E1726',
    playBg: '#060818',
    headerBg: '#1A1C2D',
    dialogBg: '#323950',
    dialogBgLight: '#384158',
    shimmerBg: '#1A273E',

    // General / Snackbar  background colors
    successBg: '#088866',
    infoBg: '#0044e2',
    warningBg: '#FA7C08',
    errorBg: '#F72B50',

    // Status chip background colors
    successStatusBg: 'rgba(182, 233, 255, 0.26)',
    infoStatusBg: '#F0F6FC',
    warningStatusBg: '#FFEAE6',
    dangerStatusBg: 'rgba(255, 109, 77, 0.26)',

    // BORDER colors
    border: '#1C2537',
    borderOutlineBtn: '#FFFFFF',
    mainBorder: '#FFFFFF',
    borderLight: '#A3A9B2',
    outlineStatusBorder: '#828894', // Outline status chip border color

    // MultiBar Progress colors
    primaryProgressBg: '#FFFFFF',

    // Menu colors
    menuIemBg: '#1A1C2D',
    menuIemBgHover: '#FFFFFF',
    menuItemText: '#FFFFFF',
    menuItemTextHover: '#060818',
    //
    sectionHandle: 'rgb(6, 8, 24,0.8)'
  },
  // Voot
  theme1: {
    // Theme colors
    primary: '#7f0dff',
    secondary: '#29d4b6',
    separator: '#222500',
    disabled: '#353C49',

    // Text Colors
    mainText: '#FFFFFF',
    subText: '#A3A9B2', //
    labelText: '#FFFFFF', //
    placeholderText: '#A3A9B2',
    inputText: '#FFFFFF',
    helpText: '#222140', //
    activeText: '#222140', //

    // General / Snackbar  text colors
    successText: '#FFFFFF',
    infoText: '#FFFFFF', //
    warningText: '#FF6D4D', //
    errorText: '#FFFFFF',

    // Status chip text colors
    successStatusText: '#22f344',
    infoStatusText: '#2781D5',
    warningStatusText: '#FF6D4D',
    dangerStatusText: '#F72B50',
    outlineStatusText: '#828894',

    // Button colors
    primaryBtn: '#7f0dff',
    secondaryBtn: '#29d4b6',
    outlineBtn: 'rgba(255, 255, 255, 0.2)',
    carouselBtn: '#7f0dff', //

    // Button colors for Disabled state
    primaryBtnDisabled: '#353C49',
    secondaryBtnDisabled: '#222140', //
    primaryBtnText: '#FFFFFF',
    secondaryBtnText: '#A3A9B2',
    outlineBtnText: '#7f0dff', // Default outline button color

    // Background colors
    bodyBg: '#0d0620',
    formBg: '#120632',
    fieldBg: '#0d0620',
    tagBg: '#060818',
    loginBg: '#2248f4',
    playBg: '#060818',
    headerBg: '#0d0620',
    dialogBg: '#0d0620',
    dialogBgLight: '#0d0620',
    shimmerBg: '#192133',

    // General / Snackbar  background colors
    successBg: '#088866',
    infoBg: '#0044e2',
    warningBg: '#FA7C08',
    errorBg: '#F72B50',

    // Status chip background colors
    successStatusBg: 'rgba(182, 233, 255, 0.26)',
    infoStatusBg: '#F0F6FC',
    warningStatusBg: '#FFEAE6',
    dangerStatusBg: 'rgba(255, 109, 77, 0.26)',

    // BORDER colors
    border: '#1C2537',
    borderOutlineBtn: '#7f0dff',
    mainBorder: '#FFFFFF',
    borderLight: '#A3A9B2',
    outlineStatusBorder: '#828894', // Outline status chip border color

    // MultiBar Progress colors
    primaryProgressBg: '#FFFFFF',

    // Menu colors
    menuIemBg: '#0d0620',
    menuIemBgHover: '#7f0dff',
    menuItemText: '#FFFFFF',
    menuItemTextHover: '#0d0620',
    //
    sectionHandle: 'rgb(13, 6, 32,0.8)'
  },
  // AHA
  theme2: {
    // Theme colors
    primary: '#ff6c2f',
    secondary: '#29d4b6',
    separator: '#222500',
    disabled: '#353C49',

    // Text Colors
    mainText: '#FFFFFF',
    subText: '#7a7d82', //
    labelText: '#FFFFFF', //
    placeholderText: '#868788',
    inputText: '#FFFFFF',
    helpText: '#868788', //
    activeText: '#868788', //

    // General / Snackbar  text colors
    successText: '#FFFFFF',
    infoText: '#FFFFFF', //
    warningText: '#FF6D4D', //
    errorText: '#FFFFFF',

    // Status chip text colors
    successStatusText: '#22f344',
    infoStatusText: '#2781D5',
    warningStatusText: '#FF6D4D',
    dangerStatusText: '#F72B50',
    outlineStatusText: '#828894',

    // Button colors
    primaryBtn: '#ff6c2f',
    secondaryBtn: '#29d4b6',
    outlineBtn: 'rgba(255, 255, 255, 0.2)',
    carouselBtn: '#222', //

    // Button colors for Disabled state
    primaryBtnDisabled: '#353C49',
    secondaryBtnDisabled: '#222140', //
    primaryBtnText: '#FFFFFF',
    secondaryBtnText: '#A3A9B2',
    outlineBtnText: '#ff6c2f',

    // Background colors
    bodyBg: '#000000',
    formBg: '#222',
    fieldBg: '#000',
    tagBg: '#222',
    loginBg: '#222',
    playBg: '#000000',
    headerBg: '#000000',
    dialogBg: '#222',
    dialogBgLight: '#222',
    shimmerBg: '#222',

    // General / Snackbar  background colors
    successBg: '#088866',
    infoBg: '#ff6c2f',
    warningBg: '#FA7C08',
    errorBg: '#F72B50',

    // Status chip background colors
    successStatusBg: 'rgba(182, 233, 255, 0.26)',
    infoStatusBg: '#F0F6FC',
    warningStatusBg: '#FFEAE6',
    dangerStatusBg: 'rgba(255, 109, 77, 0.26)',

    // BORDER colors
    border: '#1C2537',
    borderOutlineBtn: '#ff6c2f',
    mainBorder: '#FFFFFF',
    borderLight: '#A3A9B2',
    outlineStatusBorder: '#828894', // Outline status chip border color

    // MultiBar Progress colors
    primaryProgressBg: '#FFFFFF',

    // Menu colors
    menuIemBg: '#000',
    menuIemBgHover: '#ff6c2f',
    menuItemText: '#FFFFFF',
    menuItemTextHover: '#000',
    //
    sectionHandle: 'rgb(0, 0, 0,0.8)'
  },
  // Disney hotstar
  theme3: {
    // Theme colors
    primary: '#1f80e0',
    secondary: '#f15151',
    separator: '#222500',
    disabled: '#353C49',

    // Text Colors
    mainText: '#FFFFFF',
    subText: '#7a7d82', //
    labelText: '#FFFFFF', //
    placeholderText: '#868788',
    inputText: '#FFFFFF',
    helpText: '#868788', //
    activeText: '#868788', //

    // General / Snackbar  text colors
    successText: '#FFFFFF',
    infoText: '#FFFFFF', //
    warningText: '#FF6D4D', //
    errorText: '#FFFFFF',

    // Status chip text colors
    successStatusText: '#22f344',
    infoStatusText: '#2781D5',
    warningStatusText: '#FF6D4D',
    dangerStatusText: '#F72B50',
    outlineStatusText: '#828894',

    // Button colors
    primaryBtn: '#1f80e0',
    secondaryBtn: '#f15151',
    outlineBtn: 'rgba(255, 255, 255, 0.2)',
    carouselBtn: '#1f80e0', //

    // Button colors for Disabled state
    primaryBtnDisabled: '#353C49',
    secondaryBtnDisabled: '#222140', //
    primaryBtnText: '#FFFFFF',
    secondaryBtnText: '#A3A9B2',
    outlineBtnText: '#1f80e0',

    // Background colors
    bodyBg: '#0c111b',
    formBg: '#192133',
    fieldBg: '#0c111b',
    tagBg: '#192133',
    loginBg: '#192133',
    playBg: '#192133',
    headerBg: '#121926',
    dialogBg: '#192133',
    dialogBgLight: '#192133',
    shimmerBg: '#192133',

    // General / Snackbar  background colors
    successBg: '#088866',
    infoBg: '#ff6c2f',
    warningBg: '#FA7C08',
    errorBg: '#F72B50',

    // Status chip background colors
    successStatusBg: 'rgba(182, 233, 255, 0.26)',
    infoStatusBg: '#F0F6FC',
    warningStatusBg: '#FFEAE6',
    dangerStatusBg: 'rgba(255, 109, 77, 0.26)',

    // BORDER colors
    border: '#121926',
    borderOutlineBtn: '#1f80e0',
    mainBorder: '#FFFFFF',
    borderLight: '#A3A9B2',
    outlineStatusBorder: '#828894', // Outline status chip border color

    // MultiBar Progress colors
    primaryProgressBg: '#FFFFFF',

    // Menu colors
    menuIemBg: '#192133',
    menuIemBgHover: '#0c111b',
    menuItemText: '#FFFFFF',
    menuItemTextHover: '#fff',
    //
    sectionHandle: 'rgb(12, 17, 27,0.8)'
  },
  // Wiflix
  theme4: {
    // Theme colors
    primary: '#D33B30',
    secondary: '#ed532d',
    separator: '#D33B30',
    disabled: '#353C49',

    // Text Colors
    mainText: '#FFFFFF',
    subText: '#7a7d82', //
    labelText: '#FFFFFF', //
    placeholderText: '#868788',
    inputText: '#FFFFFF',
    helpText: '#868788', //
    activeText: '#868788', //

    // General / Snackbar  text colors
    successText: '#FFFFFF',
    infoText: '#FFFFFF', //
    warningText: '#ed532d', //
    errorText: '#FFFFFF',

    // Status chip text colors
    successStatusText: '#22f344',
    infoStatusText: '#2781D5',
    warningStatusText: '#FF6D4D',
    dangerStatusText: '#F72B50',
    outlineStatusText: '#828894',

    // Button colors
    primaryBtn: '#D33B30',
    secondaryBtn: '#ed532d',
    outlineBtn: 'rgba(255, 255, 255, 0.2)',

    carouselBtn: '#222', //
    primaryBtnText: '#FFFFFF',
    secondaryBtnText: '#A3A9B2',
    outlineBtnText: '#D33B30',

    // Button colors for Disabled state
    primaryBtnDisabled: '#353C49',
    secondaryBtnDisabled: '#222140', //

    // Background colors
    bodyBg: '#141414',
    formBg: '#000',
    fieldBg: '#141414',
    tagBg: '#000',
    loginBg: '#000',
    playBg: '#000000',
    headerBg: '#000000',
    dialogBg: '#141414',
    dialogBgLight: '#141414',
    shimmerBg: '#000',

    // General / Snackbar  background colors
    successBg: '#088866',
    infoBg: '#ff6c2f',
    warningBg: '#FA7C08',
    errorBg: '#F72B50',

    // Status chip background colors
    successStatusBg: 'rgba(182, 233, 255, 0.26)',
    infoStatusBg: '#F0F6FC',
    warningStatusBg: '#FFEAE6',
    dangerStatusBg: 'rgba(255, 109, 77, 0.26)',

    // BORDER colors
    border: '#141414',
    borderOutlineBtn: '#D33B30',
    mainBorder: '#FFFFFF',
    borderLight: '#A3A9B2',
    outlineStatusBorder: '#828894', // Outline status chip border color

    // MultiBar Progress colors
    primaryProgressBg: '#FFFFFF',

    // Menu colors
    menuIemBg: '#000',
    menuIemBgHover: '#D33B30',
    menuItemText: '#FFFFFF',
    menuItemTextHover: '#000',
    //
    sectionHandle: 'rgb(20, 20, 20,0.8)'
  },
  // Twitch
  theme5: {
    // Theme colors
    primary: '#772ce8',
    secondary: '#adadb8',
    separator: '#dededf',
    disabled: '#353C49',

    // Text Colors
    mainText: '#000',
    subText: '#1f1f23', //
    labelText: '#000', //
    placeholderText: '#3b3b44',
    inputText: '#000',
    helpText: '#3b3b44', //
    activeText: '#1f1f23', //

    // General / Snackbar  text colors
    successText: '#000',
    infoText: '#000', //
    warningText: '#ed532d', //
    errorText: '#000',

    // Status chip text colors
    successStatusText: '#22f344',
    infoStatusText: '#2781D5',
    warningStatusText: '#FF6D4D',
    dangerStatusText: '#F72B50',
    outlineStatusText: '#828894',

    // Button colors
    primaryBtn: '#772ce8',
    secondaryBtn: '#adadb8',
    outlineBtn: 'rgba(255, 255, 255, 0.2)',

    carouselBtn: '#adadb8', //
    primaryBtnText: '#FFFFFF',
    secondaryBtnText: '#000',
    outlineBtnText: '#772ce8',

    // Button colors for Disabled state
    primaryBtnDisabled: '#353C49',
    secondaryBtnDisabled: '#222140', //

    // Background colors
    bodyBg: '#f7f7f8',
    formBg: '#fff',
    fieldBg: '#0000001a',
    tagBg: '#000',
    loginBg: '#fff',
    playBg: '#f2f2f2',
    headerBg: '#fff',
    dialogBg: '#fff',
    dialogBgLight: '#0000001a',
    shimmerBg: '#0000001a',

    // General / Snackbar  background colors
    successBg: '#088866',
    infoBg: '#ff6c2f',
    warningBg: '#FA7C08',
    errorBg: '#F72B50',

    // Status chip background colors
    successStatusBg: 'rgba(182, 233, 255, 0.26)',
    infoStatusBg: '#F0F6FC',
    warningStatusBg: '#FFEAE6',
    dangerStatusBg: 'rgba(255, 109, 77, 0.26)',

    // BORDER colors
    border: '#141414',
    borderOutlineBtn: '#772ce8',
    mainBorder: '#FFFFFF',
    borderLight: '#A3A9B2',
    outlineStatusBorder: '#828894', // Outline status chip border color

    // MultiBar Progress colors
    primaryProgressBg: '#FFFFFF',

    // Menu colors
    menuIemBg: '#fff',
    menuIemBgHover: '#f2f2f2',
    menuItemText: '#000',
    menuItemTextHover: '#000',
    //
    sectionHandle: 'rgb(247, 247, 248,0.8)'
  }
};

export const ThemeContext = React.createContext({});

ThemeContext.displayName = 'ThemeContext';

export const getThemeByThemeName = (themeName) => {
  // Return theme by theme name, if theme name doesn't exists then return default theme
  return themes[themeName] || themes[DEFAULT_THEME_NAME];
};

export const createCSSVariable = (name, prefix = 'color') => {
  return `--${prefix}-${name}`;
};

export const getVariableValue = (variants, prefix = 'color') => {
  return `var(--${prefix}-${variants})`;
};
