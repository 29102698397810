import gql from 'graphql-tag';

const fetchPageData = gql`
  query fetchPage($id: String, $slug: String) {
    fetchPage(id: $id, slug: $slug) {
      name
      id
      banner {
        id
      }
      sections: contentSections {
        type
        title
        queryName
        sectionId
        contentGroupId
        contentGroupSlug
        dynamicSlug
        coverImageOrientation
        shuffle
      }
    }
  }
`;

const fetchPagelayout = gql`
  query fetchPages($limit: Int, $skip: Int) {
    fetchPages(limit: $limit, skip: $skip) {
      pages {
        id
        name
        slug
        banner {
          id
        }
        staticBannerImage
        contentGroup {
          contentGroupId
          contentGroupSlug
        }
      }
    }
  }
`;

const fetchBannerData = gql`
  query fetchBanner($id: String, $slug: String, $locale: String) {
    fetchBanner(id: $id, slug: $slug, language: $locale) {
      id
      name
      bannerContent {
        cdn {
          domainName
          aliases
        }
        queryName
        id: bannerContentId
        bannerImage
        bannerVideo
        contentData
      }
    }
  }
`;

const fetchHomePageSlug = gql`
  query fetchHomePageSlug {
    fetchMenuItems {
      pageHomeSlug
    }
  }
`;

const fetchContentGroupData = gql`
  query fetchContentGroup(
    $slug: String
    $limit: Int
    $skip: Int
    $contentDataSort: ContentDataSortEnum
    $locale: String
  ) {
    fetchContentGroup(
      slug: $slug
      contentDataSort: $contentDataSort
      contentDataLimit: $limit
      contentDataSkip: $skip
      locale: $locale
    ) {
      autoplay
      isOnLoop
      contentData {
        cdn {
          domainName
          aliases
        }
        count
        seed
        data: list
      }
    }
  }
`;

const fetchPageByName = gql`
  query getPagebyName {
    fetchPage(name: "All Hindi Movies") {
      banner {
        id
      }
      sections: contentSections {
        title
        queryName
        sectionId
        contentGroupId
        contentGroupSlug
        dynamicSlug
      }
    }
  }
`;

const fetchContentObjects = gql`
  query fetchContentObjects(
    $ids: [String]
    $queryName: String
    $limit: Int
    $skip: Int
    $contentDataSort: ContentDataSortEnum
  ) {
    searchContent(
      ids: $ids
      queryName: $queryName
      limit: $limit
      skip: $skip
      contentDataSort: $contentDataSort
    ) {
      cdn {
        domainName
        aliases
      }
      data
      count
    }
  }
`;

const fetchHomePageData = gql`
  query fetchPage($slug: String, $locale: String) {
    fetchPage(slug: $slug, locale: $locale) {
      id
      banner {
        id
      }
      sections: contentSections {
        type
        title
        queryName
        sectionId
        contentGroupId
        contentGroupSlug
        dynamicSlug
        coverImageOrientation
        shuffle
      }
    }
  }
`;
const fetchPageDataV2 = gql`
  query FetchPageV2(
    $slug: String
    $seoSlug: String
    $language: [String]
    $locale: String
    $limit: Int
    $skip: Int
  ) {
    fetchPageV2(
      slug: $slug
      seoSlug: $seoSlug
      language: $language
      locale: $locale
      limit: $limit
      skip: $skip
    ) {
      id
      slug
      cdnInfo {
        videoCdn {
          id
          url
          aliases
          videoContent
          imageContent
        }
        imageCdn {
          id
          url
          aliases
          videoContent
          imageContent
        }
      }
      name
      banner {
        id
      }
      seoSlug
      seoTitle
      seoDescription
      isChannelPage
      contentSections {
        sectionId
        title
        description
        contentGroupSlug
        contentGroupSeoSlug
        contentGroupId
        queryName
        type
        dynamicSlug
        geoRestrictionProfile {
          id
          name
        }
        coverImageOrientation
        upfrontTitles
        shuffle
        showSectionName
        language
        sectionBannerMedia {
          id
          sectionBannerImage
          sectionBannerVideo
        }
        translations
        contentTypeData {
          cdnInfo {
            videoCdn {
              id
              url
              aliases
              videoContent
              imageContent
            }
            imageCdn {
              id
              url
              aliases
              videoContent
              imageContent
            }
          }
          count
          data
        }
        contentGrpData {
          id
          name
          type
          language
          contentType {
            contentTypeId
            name
            queryName
          }
          content {
            count
            list {
              contentId
              title
              queryName
            }
          }
          filter
          slug
          contentData {
            cdnInfo {
              videoCdn {
                id
                url
                aliases
                videoContent
                imageContent
              }
              imageCdn {
                id
                url
                aliases
                videoContent
                imageContent
              }
            }
            count
            seed
            list
          }
          isSingleContentType
          createdAt
          updatedAt
          deletedAt
          collectionType
          description
          seoTitle
          seoDescription
          seoSlug
          banner
          staticBannerImage
          coverImageForCard
          coverImageForPortrait
          subscription
          tvod
          isFree
          collectionHeader
          collectionList {
            id
            contentGroupId
            name
            meta {
              id
              name
              type
              language
              filter
              slug
              contentData {
                cdnInfo {
                  videoCdn {
                    id
                    url
                    aliases
                    videoContent
                    imageContent
                  }
                  imageCdn {
                    id
                    url
                    aliases
                    videoContent
                    imageContent
                  }
                }
                count
                seed
                list
              }
              isSingleContentType
              createdAt
              updatedAt
              deletedAt
              collectionType
              description
              seoTitle
              seoDescription
              seoSlug
              banner
              staticBannerImage
              coverImageForCard
              coverImageForPortrait
              subscription
              tvod
              isFree
              collectionHeader
              sortBy
              autoplay
              isOnLoop
              detailPageStyle
              releasedDate
              maturityRating
              totalDuration
            }
          }
          sortBy
          autoplay
          isOnLoop
        }
        popularVideos {
          cdn {
            domainName
            aliases
          }
          cdnInfo {
            videoCdn {
              id
              url
              aliases
              videoContent
              imageContent
            }
            imageCdn {
              id
              url
              aliases
              videoContent
              imageContent
            }
          }
          count
          list {
            contentId
            duration
            contentMeta
            createdAt
            updatedAt
            id
          }
        }
      }
      contentSectionsCount
      pageType
      staticBannerImage
      bannerData {
        id
        name
        bannerType
        isSingleContentType
        contentType {
          contentTypeId
          name
          queryName
        }
        bannerContent {
          cdnInfo {
            videoCdn {
              id
              url
              aliases
              videoContent
              imageContent
            }
            imageCdn {
              id
              url
              aliases
              videoContent
              imageContent
            }
          }
          queryName
          displayCTAs
          bannerContentId
          scheduleDate
          isDefault
          bannerImage
          bannerImageApp
          bannerVideo
          contentData
          isEligibleToWatch
        }
        createdAt
        updatedAt
        deletedAt
        translations
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;


export default {
  fetchPageData,
  fetchPageDataV2,
  fetchPagelayout,
  fetchHomePageData,
  fetchBannerData,
  fetchContentGroupData,
  fetchPageByName,
  fetchHomePageSlug,
  // query to fetch the content objects data
  fetchContentObjects,
};
