import { fork, put, call, takeEvery } from 'redux-saga/effects';

import episodesActions, { constants } from './actions';
import seasonsActions from '../seasons/actions';

import queries from '../../graphql/queries';

const EpisodesSaga = ({ gqlClient }) => {
  function* fetchSeasonByEpisode({ payload: { id } }) {
    try {
      const variables = {
        id,
        contenttype: 'seasons',
        relationField: 'episodes',
      };

      const response = yield call([gqlClient, gqlClient.query], {
        query: queries.contentTypes.getRelatedContentById,
        variables,
      });

      if (!response?.data?.relatedContent?.data)
        throw new Error('Season Fetching Failed');

      if (!response.data.relatedContent.data.length)
        throw new Error('Season Fetching Failed');

      const {
        id: seasonId,
        title,
        episodescount: episodesCount,
        episodes,
      } = response.data.relatedContent.data[0];

      yield put(
        episodesActions.fetchSeasonByEpisodeSuccess({
          id,
          data: { id: seasonId, title, episodesCount, episodes },
        }),
      );

      // Fetching Series from SeasonId
      yield put(seasonsActions.fetchSeriesBySeasonPending({ id: seasonId }));
    } catch (e) {
      yield put(episodesActions.fetchSeasonByEpisodeFailure({ id, e }));
    }
  }

  function* fetchSeasonByEpisodeWatcher() {
    yield takeEvery(
      constants.FETCH_SEASON_BY_EPISODE_PENDING,
      fetchSeasonByEpisode,
    );
  }

  function* watcher() {
    yield fork(fetchSeasonByEpisodeWatcher);
  }

  return {
    watcher,
  };
};

export default EpisodesSaga;
