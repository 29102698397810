/* eslint-disable */
import  { useEffect, useState } from 'react';

const ChannelTimeline = () => {
  const [currentTime, setCurrentTime] = useState(new Date(new Date().toLocaleString("en-US", {timeZone: "IST"})));

  useEffect(() => {
    // Update the current time every minute
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Generate time slots starting from the current time in 30-minute increments
  const timeSlots = [];
  let currentHour = currentTime.getHours();
  let currentMinute = currentTime.getMinutes(); // Get the exact minutes
  let count = 0;
  while (count < 24) {
    if(currentHour>23) currentHour = 0;
    while (currentMinute < 60) {
      // Round down the minutes to the nearest 30-minute increment
      const roundedMinute = Math.floor(currentMinute / 30) * 30;

      const time = `${currentHour.toString().padStart(2, '0')}:${roundedMinute.toString().padStart(2, '0')}`;
      timeSlots.push(time);

      // Move to the next 30-minute slot
      currentMinute = roundedMinute + 30;
    }
    currentHour++;
    currentMinute = 0;
    count++;
  }


  return (
    <div style={{width:"fit-content"}} >
      <div className="time-line overflow-hidden text-subText" style={{width:`${timeSlots.length * 10}rem`}}>
      <p className='text-[0.8rem] text-mainText font-medium'>Channel</p>
        {timeSlots.map((time, index) => (
          <div className="time-slot" key={index}>
            <span className='text-[0.7rem] text-mainText'>{time}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChannelTimeline;
