
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {I18nContextDecorator} from '@enact/i18n/I18nDecorator';
import {SpotlightContainerDecorator, spotlightDefaultClass} from '@enact/spotlight/SpotlightContainerDecorator';
import {navigate,setFocusedContent} from '../../store/modules/navigate/actions';
import contentTypeActions from '../../store/modules/contents/actions';
import BannerWithInfo from '../../views/BannerWithInfo';
import assets from '../../assets/icons';
import spotlight from '@enact/spotlight';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import Seasons from '../../views/Seasons'
import Scroller from '@enact/sandstone/Scroller';
import playerPlaylistActions from '../../store/modules/playlist/actions';
import { getContentTypeFromQueryName } from "../../lib/utilities";

const Details = ({...rest}) => {
  const dispatch = useDispatch();
  const props = useSelector(state => state.navigate?.props);
  const id = props?.id || props.seriesId;
  const { contentsById, contentAccessibilityById } = useSelector(
    state => state.contents
  );
  const storefrontConfig = useSelector(state => state.storefront.config);
  const activeProfile = useSelector(state => state.auth.profiles.activeProfile);
  const me = useSelector(state => state.auth.me);

  useEffect(() => {
    // Do not fetch the details if fetched on server
    if (id && !contentsById?.[id]?.id)
      dispatch(
        contentTypeActions.getContentsByIdsPending({
          ids: [id],
          locale: activeProfile?.language.storefront || storefrontConfig.data?.defaultDisplayLanguage.current
        })
      );

    if (me?.id && id && !contentAccessibilityById?.[id])
      dispatch(
        contentTypeActions.getContentsAccessibilityByIdPending({ id })
      );
  }, [dispatch, id]);

  useEffect(() => {
    if(contentAccessibilityById?.[id]?.isUserEligibleToWatch){
      if(contentsById[id]?.content?.queryName){
        const type = getContentTypeFromQueryName(contentsById[id]?.content?.queryName);
        if(type === 'seasons' || type === 'series') return;
      }
      dispatch(playerPlaylistActions.signUrlPending({ id }));
      dispatch(contentTypeActions.getContentWatchTimePending({ id }));
      dispatch(playerPlaylistActions.setVideoId({id }));
    }
  }, [contentAccessibilityById?.[id]])
  useEffect(() => {
    function keyListener(event) {
      const key = event.keyCode;
      if(key === 461) onBackClick();
    }
    window.document.addEventListener("keydown", keyListener);
    return () => {
      window.document.removeEventListener('keydown',keyListener);
    }
  })
  
  const onBackClick = () => {
    dispatch(setFocusedContent(null));
    dispatch(navigate({path:'home',props:{spoton:`${id}-card`}}))
  }
  const renderBackButton = () => {
    return (
      <PrimaryButton data-spotlight-id='btn'
        backgroundOpacity="transparent" icon={assets.backbutton}
        type="button" 
        className="absolute top-10 left-10 z-50 selected"
        onClick={onBackClick}/>
    );
  };
  const renderBanner = () => {

    let rentOptionsPresent = false;
    if (typeof contentsById[id]?.tvod !== 'undefined') {
      rentOptionsPresent = !!contentsById[id].tvod.length;
    }

    // embedded the youtube player if we add the media source type as youtube link in RMC
    const item = contentsById[id];
    if (item?.mediaSource === 'Youtube Link' && item?.mediaSourceUrl) {
      return (
        <div className="w-full h-full" style={{ marginTop: '5rem' }}>
          <iframe
            width={window.innerWidth * 1}
            height={window.innerHeight * 0.82}
            src={item.mediaSourceUrl}
            title={item.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      );
    }
    return (
      <BannerWithInfo item={contentsById[id]} 
      onSpotlightDown={()=>{spotlight.focus(`${id}-btn`)}}
        isRestricted={!contentAccessibilityById[id]?.isUserEligibleToWatch} rtl={rest.rtl}
        isOnLoop={props?.isOnLoop} autoPlay={props?.autoplay} firstItemId={props?.firstItemId} contentGroupId={props?.contentGroupId}
      />
    );
  };
  const renderSeasons = () => {
    // If series has no seasons
    // if (!contentsById[id]?.seasonscount || contentsById[id]?.seasonscount === 0) return null;

    // Convert [ {id: 'seasonId'}, ...] to ['seasonId', 'seasonId1', ...]
    const seasonIds = contentsById[id]?.content.seasons?.map(season => season.id);
    if (!seasonIds?.length) return null;

    return <Seasons seasonIds={seasonIds} seriesId={id}/>;
  };

  const isBackPressed = (e) => {
    if(e.keyCode == 461) onBackClick();
  }
  return (
    <Scroller verticalScrollbar='hidden'>
      <div className="flex flex-col items-center justify-center text-mainText h-full w-full" onKeyDown={(e)=>isBackPressed(e)}>
        {renderBackButton()}
        {renderBanner()}
        {contentsById[id]?.content?.queryName?.includes('series') && renderSeasons()}
        {/* {renderDetails()} */}
      </div>
    </Scroller>
  );
};

export default  SpotlightContainerDecorator({continue5WayHold:true,enterTo:'last-focused',preserveId:true},I18nContextDecorator({rtlProp:'rtl'},Details));
