import { constants } from './actions';

const initialState = {
  contentsById: {
    /**
     * [id]:{
     *  loading: boolean;
     *  success: boolean;
     *  error: boolean;
     *  content: object;
     *  message: boolean;
     * }
     */
  },

  contentsByQueryName: {
    /**
     * [queryName]: {
     *  loading: boolean;
     *  success: boolean;
     *  error: boolean;
     *  cdnDomainName: string;
     *  cdn: any;
     *  isMoreContentAvailable: boolean;
     *  contents: [],
     * }
     */
  },

  contentsByCollection: {
    /**
     * [slug]: {
     *  name: string;
     *  loading: boolean;
     *  success: boolean;
     *  error: boolean;
     *  cdn:any;
     *  cdnDomainName: string;
     *  isMoreContentAvailable: boolean;
     *  contents: [];
     * }
     */
  },

  contentAccessibilityById: {
    /**
     * [id]:{
     *  loading: boolean;
     *  success: boolean;
     *  error: boolean;
     *  isUserEligibleToWatch: boolean;
     *  message: boolean;
     * }
     */
  },

  contentWatchTimeById: {
    /**
     * [id]:{
     *  loading: boolean;
     *  success: boolean;
     *  error: boolean;
     *  watchTime: number;
     *  message: boolean;
     * }
     */
  },

  updateWatchTimeById: {
    /**
     * [id]:{
     *  loading: boolean;
     *  success: boolean;
     *  error: boolean;
     *  message: boolean;
     * }
     */
  },
};

/**
 *
 * @param {*} ids
 * @param { { [contentId:string]: T } } contentsById
 * @param {T} payload
 * @returns { { [contentId:string]: T } }
 */
const mapContentsById = (ids, contentsById, payload) => {
  const newContentsById = {};
  ids.forEach(id => {
    newContentsById[id] = {
      ...contentsById[id],
      ...payload,
    };
  });
  return newContentsById;
};

/**
 * @param {*} contents
 * @param {*} contentsById
 * @param {*} payload
 * @returns { { [contentId:string]: T } }
 */
const mapContentsByIds = (contents, contentsById, payload) => {
  const newContentsById = {};
  contents.forEach(content => {
    newContentsById[content.id] = {
      ...contentsById[content.id],
      content,
      ...payload,
    };
  });
  return newContentsById;
};

function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    /// ---------------- GET_CONTENTS_BY_IDS START -------
    case constants.GET_CONTENTS_BY_IDS_PENDING: {
      const { ids } = payload;
      const contentPendingStateByIds = mapContentsById(
        ids,
        state.contentsById,
        { loading: true },
      );
      return {
        ...state,
        contentsById: {
          ...state.contentsById,
          ...contentPendingStateByIds,
        },
      };
    }
    case constants.GET_CONTENTS_BY_IDS_SUCCESS: {
      const { contents, cdn, cdnDomainName } = payload;
      const contentsState = mapContentsByIds(contents, state.contentsById, {
        cdn,
        cdnDomainName,
        loading: false,
        success: true,
        error: false,
      });

      return {
        ...state,
        contentsById: {
          ...state.contentsById,
          ...contentsState,
        },
      };
    }

    case constants.GET_CONTENTS_BY_IDS_FAILURE: {
      const { ids, reason } = payload;
      const contentPendingStateByIds = mapContentsById(
        ids,
        state.contentsById,
        { loading: false, success: false, error: true, message: reason },
      );
      return {
        ...state,
        contentsById: {
          ...state.contentsById,
          ...contentPendingStateByIds,
        },
      };
    }
    /// ---------------- GET_CONTENTS_BY_IDS END -------

    /// ---------------- GET_CONTENT_BY_QUERY_NAME START -------
    case constants.GET_CONTENTS_BY_QUERY_NAME_PENDING: {
      const { queryName } = payload;
      return {
        ...state,
        contentsByQueryName: {
          ...state.contentsByQueryName,
          [queryName]: {
            ...state.contentsByQueryName?.[queryName],
            loading: true,
          },
        },
      };
    }
    case constants.GET_CONTENTS_BY_QUERY_NAME_SUCCESS: {
      const {
        queryName,
        cdn,
        cdnDomainName,
        contents,
        isMoreContentAvailable,
        count,
      } = payload;
      return {
        ...state,
        contentsByQueryName: {
          ...state.contentsByQueryName,
          [queryName]: {
            ...state.contentsByQueryName?.[queryName],
            contents,
            cdnDomainName,
            cdn,
            count,
            isMoreContentAvailable,
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    }
    case constants.GET_CONTENTS_BY_QUERY_NAME_FAILURE: {
      const { queryName, reason } = payload;
      return {
        ...state,
        contentsByQueryName: {
          ...state.contentsByQueryName,
          [queryName]: {
            ...state.contentsByQueryName?.[queryName],
            loading: false,
            success: false,
            error: true,
            message: reason,
          },
        },
      };
    }
    /// ---------------- GET_CONTENT_BY_QUERY_NAME END -------

    /// ---------------- GET_CONTENTS_BY_COLLECTION START -------
    case constants.GET_CONTENTS_BY_COLLECTION_PENDING: {
      const { slug } = payload;
      return {
        ...state,
        contentsByCollection: {
          ...state.contentsByCollection,
          [slug]: {
            ...state.contentsByCollection?.[slug],
            loading: true,
          },
        },
      };
    }

    case constants.GET_CONTENTS_BY_COLLECTION_SUCCESS: {
      const {
        slug,
        cdn,
        cdnDomainName,
        contents,
        name,
        count,
        isMoreContentAvailable,
      } = payload;
      return {
        ...state,
        contentsByCollection: {
          ...state.contentsByCollection,
          [slug]: {
            ...state.contentsByCollection?.[slug],
            contents: [
              ...(state.contentsByCollection?.[slug]?.contents || []),
              ...contents,
            ],
            cdnDomainName,
            cdn,
            name,
            isMoreContentAvailable,
            count,
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    }
    case constants.GET_CONTENTS_BY_COLLECTION_FAILURE: {
      const { slug, reason } = payload;
      return {
        ...state,
        contentsByCollection: {
          ...state.contentsByCollection,
          [slug]: {
            ...state.contentsByCollection?.[slug],
            loading: false,
            success: false,
            error: true,
            message: reason,
          },
        },
      };
    }
    /// ---------------- GET_CONTENTS_BY_COLLECTION END -------

    /// ---------------- GET_CONTENT_ACCESSIBILITY_BY_IDS START -------
    case constants.GET_CONTENT_ACCESSIBILITY_BY_IDS_PENDING: {
      const { id } = payload;
      return {
        ...state,
        contentAccessibilityById: {
          ...state.contentAccessibilityById,
          [id]: {
            ...state.contentAccessibilityById?.[id],
            loading: true,
          },
        },
      };
    }
    case constants.GET_CONTENT_ACCESSIBILITY_BY_IDS_SUCCESS: {
      const { id, isUserEligibleToWatch } = payload;
      return {
        ...state,
        contentAccessibilityById: {
          ...state.contentAccessibilityById,
          [id]: {
            ...state.contentAccessibilityById?.[id],
            isUserEligibleToWatch,
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    }
    case constants.GET_CONTENT_ACCESSIBILITY_BY_IDS_FAILURE: {
      const { id, reason } = payload;
      return {
        ...state,
        contentAccessibilityById: {
          ...state.contentAccessibilityById,
          [id]: {
            ...state.contentAccessibilityById?.[id],
            loading: false,
            success: false,
            error: true,
            message: reason,
          },
        },
      };
    }
    /// ---------------- GET_CONTENT_ACCESSIBILITY_BY_IDS END -------

    /// ---------------- GET_CONTENT_WATCH_TIME START -------
    case constants.GET_CONTENT_WATCH_TIME_PENDING: {
      const { id } = payload;
      return {
        ...state,
        contentWatchTimeById: {
          ...state.contentWatchTimeById,
          [id]: {
            ...state.contentWatchTimeById?.[id],
            loading: true,
          },
        },
      };
    }
    case constants.GET_CONTENT_WATCH_TIME_SUCCESS: {
      const { id, getContentWatchDuration } = payload;
      return {
        ...state,
        contentWatchTimeById: {
          ...state.contentWatchTimeById,
          [id]: {
            ...state.contentWatchTimeById?.[id],
            watchTime: getContentWatchDuration,
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    }
    case constants.GET_CONTENT_WATCH_TIME_FAILURE: {
      const { id, reason } = payload;
      return {
        ...state,
        contentWatchTimeById: {
          ...state.contentWatchTimeById,
          [id]: {
            ...state.contentWatchTimeById?.[id],
            loading: false,
            success: false,
            error: true,
            message: reason,
          },
        },
      };
    }
    /// ---------------- GET_CONTENT_WATCH_TIME END -------

    /// ---------------- UPDATE_CONTENT_WATCH_TIME START -------
    case constants.UPDATE_CONTENT_WATCH_TIME_PENDING: {
      const { id } = payload;
      return {
        ...state,
        updateWatchTimeById: {
          ...state.updateWatchTimeById,
          [id]: {
            ...state.updateWatchTimeById?.[id],
            loading: true,
          },
        },
      };
    }
    case constants.UPDATE_CONTENT_WATCH_TIME_SUCCESS: {
      const { id } = payload;
      return {
        ...state,
        updateWatchTimeById: {
          ...state.updateWatchTimeById,
          [id]: {
            ...state.updateWatchTimeById?.[id],
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    }
    case constants.UPDATE_CONTENT_WATCH_TIME_FAILURE: {
      const { id, reason } = payload;
      return {
        ...state,
        updateWatchTimeById: {
          ...state.updateWatchTimeById,
          [id]: {
            ...state.updateWatchTimeById?.[id],
            loading: false,
            success: false,
            error: true,
            message: reason,
          },
        },
      };
    }
    /// ---------------- UPDATE_CONTENT_WATCH_TIME END -------

    case constants.RESET_CONTENTS_BY_ID_STATE: {
      return {
        ...state,
        contentsById: initialState.contentsById,
      };
    }
    case constants.RESET_CONTENTS_BY_QUERY_NAME_STATE: {
      return {
        ...state,
        contentsByQueryName: initialState.contentsByQueryName,
      };
    }
    case constants.RESET_CONTENTS_BY_COLLECTION_STATE: {
      return {
        ...state,
        contentsByCollection: initialState.contentsByCollection,
      };
    }
    case constants.RESET_CONTENTS_STATE: {
      return initialState;
    }

    default:
      return state;
  }
}

export default reducer;
