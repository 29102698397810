import { gql } from 'graphql-tag';

const fetchChannels = gql`
  query searchContent {
    searchContent(type: RESTREAM) {
      cdn {
        domainName
        aliases
      }
      count
      data
    }
  }
`;

const fetchChannelContent = gql`
  query searchContent($ids: [String]) {
    searchContent(ids: $ids) {
      cdn {
        domainName
        aliases
      }
      count
      data
    }
  }
`;

export default {
  fetchChannels,
  fetchChannelContent,
};
