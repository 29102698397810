import gql from 'graphql-tag';

const fetchMenuItems = gql`
  query fetchMenuItems($limit: Int, $skip: Int) {
    fetchMenuItems(limit: $limit, skip: $skip) {
      menuItems {
        menuTree
      }
    }
  }
`;

const fetchHeaderMenu = gql`
  query fetchHeaderMenu($locale: String) {
    fetchMenu(type: "HEADER", language: $locale) {
      menuTree
    }
  }
`;

const fetchFooterMenu = gql`
  query fetchFooterMenu {
    fetchMenu(type: "FOOTER") {
      menuTree
    }
  }
`;

export default { fetchMenuItems, fetchHeaderMenu, fetchFooterMenu };
